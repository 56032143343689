import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { formatDate } from '../../../util';
import { authActions } from '../../../state/ducks/auth';
import { transactionsActions } from '../../../state/ducks/transactions';
import { initTimer, stopTimer } from '../../../util/initTimer';
import { SIZE_PER_PAGE, TOAST_CONFIG } from '../../../config/constants';
import {
  ContentHeader, ExportExcel, Filter, FormElement, Loading, TableList, TransactionRow,
  AmountFormat,
} from '../../../components';

class ListQr extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      dateStart: '',
      dateEnd: '',
      transactionCode: '',
      cuit: '',
      accSender: '',
      accDestiny: '',
      status: '',
      first: true,
    }
  }

  componentDidMount() {
    const { fetchStatusQr, fetchTypeUserQr } = this.props;
    fetchStatusQr();
    fetchTypeUserQr();
    stopTimer();
    initTimer(this.props);
    this.callApiList();
  }

  callApiList = (getExcel = false) => {
    const {
      t, showMessage, fetchQrOperationsList,
      transactions: { extractions: { totalElements }, typeUserQr, statusQr } ,
    } = this.props;
    const {
      page, dateStart, dateEnd, transactionCode, cuit, userType,
      accSender, accDestiny, status, first
    } = this.state;
    const userQr = typeUserQr.find(item => item.id === userType);
    let stateQr = status;
    if (status === '' && first === true) {
      stateQr = statusQr.length > 0 ? statusQr[0].id : 1;
      this.setState({
        first: false,
        status: stateQr,
      });
    }
    fetchQrOperationsList({
      data: {
        page: getExcel ? 0 : page,
        pageSize: !getExcel ? SIZE_PER_PAGE : totalElements,
        export: getExcel,
        date_from: formatDate(dateStart),
        date_to: formatDate(dateEnd),
        code: transactionCode,
        cuit,
        type_user: userQr !== undefined ? userQr.id : '',
        source: accSender,
        destinatary: accDestiny,
        status: stateQr,
      }, callback: (response) => {
        this.setState({ loading: false });
        if (response) {
          if (!response.success) {
            toast.dismiss();
            showMessage({
              message: t('connectionError'),
              config: TOAST_CONFIG.ERROR,
            });
          }
        }
      },
    });
  }

  handleInputChange = (e) => {
    stopTimer();
    initTimer(this.props);
    const { target: { value: targetValue, name } } = e;
    this.setState({ [name]: targetValue });
  };

  onChangeDate = (date, name) => {
    stopTimer();
    initTimer(this.props);
    const dateValue = date !== null ? date : '';
    this.setState({ [name]: dateValue });
  };

  onChangePage = (selectPage) => {
    stopTimer();
    initTimer(this.props);
    this.setState(() => ({
      page: selectPage ? selectPage.selected : 0,
    }), () => {
      this.callApiList();
    });
  };

  onSubmit = (event) => {
    event.preventDefault();
    this.onChangePage();
  };

  render () {
    const { t,
      transactions: { loading, paymentsQr: { list, totalElements }, typeUserQr, statusQr }
     } = this.props;
    const {
      page, dateStart, dateEnd, transactionCode, cuit, accSender, accDestiny, status, userType
    } = this.state;
    return (
      <div>
        { loading && <Loading /> }
        <Fragment>
          <div className='wow animated slideInDown'>
            <div className='wow animated slideInDown'>
              <Filter title={t('listPaymentsQr')} onSubmit={e => this.onSubmit(e)}>
                <FormElement
                  name='dateStart'
                  onChange={ev => this.onChangeDate(ev, 'dateStart')}
                  label={t('createdDateFrom')}
                  value={dateStart}
                  typeElement='datepicker'
                  maxDate={dateEnd || new Date()}
                />
                <FormElement
                  name='dateEnd'
                  onChange={ev => this.onChangeDate(ev, 'dateEnd')}
                  value={dateEnd}
                  label={t('createdDateUntil')}
                  typeElement='datepicker'
                  maxDate={new Date()}
                  minDate={dateStart}
                />
                <FormElement
                  name='transactionCode'
                  onChange={this.handleInputChange}
                  value={transactionCode}
                  label={t('codeTransaction')}
                />
                <FormElement
                  name='cuit'
                  onChange={this.handleInputChange}
                  value={cuit}
                  label={'CUIT'}
                  typeElement='input'
                />
                <FormElement
                  name='accSender'
                  onChange={this.handleInputChange}
                  value={accSender}
                  label={t('accSender')}
                  typeElement='input'
                />
                <FormElement
                  name='accDestiny'
                  onChange={this.handleInputChange}
                  value={accDestiny}
                  label={t('accountDestination')}
                  typeElement='input'
                />
                <FormElement
                  name='userType'
                  onChange={this.handleInputChange}
                  label={t('userType')}
                  value={userType}
                  optionsSelect={typeUserQr}
                  placeholder="Seleccione..."
                  typeElement="select"
                  controlClasses="select"
                />
                <FormElement
                  name='status'
                  onChange={this.handleInputChange}
                  label={t('state')}
                  value={status}
                  optionsSelect={statusQr}
                  placeholder="Seleccione..."
                  typeElement="select"
                  controlClasses="select"
                />
              </Filter>
              <ContentHeader
                headingRightText={t('Total operaciones')}
                headingRightSubText={`${totalElements}`}
              >
                <ExportExcel title={t('listPaymentsQr')} onclick={() => { this.callApiList(true) }} />
                <TableList
                  tableClasses="floating-table"
                  totalElements={parseFloat(totalElements)}
                  onChangePage={this.onChangePage}
                  forcePage={page}
                  head={(
                    <tr>
                      <th>{`${t('date')} / ${t('hour')}`}</th>
                      <th>{t('userType')}</th>
                      <th>{t('CUIT/CUIL')}</th>
                      <th>{`${t('agency')}/${t('passenger')}`}</th>
                      <th>{t('codeofTransaction')}</th>
                      <th>{t('accSender')}</th>
                      <th>{t('accountDestination')}</th>
                      <th>{t('amount')}</th>
                      <th>{t('state')}</th>
                      <th>{t('actions')}</th>
                    </tr>
                  )}
                  colNumber={10}
                  lenghtData={totalElements !== undefined ? totalElements : 0}
                >
                  {list && (list.map((qr, i) => (
                    <TransactionRow key={i}>
                      <td>{qr.date}</td>
                      <td>{qr.type_user_source}</td>
                      <td>{qr.cuit_source}</td>
                      <td>{qr.user_source}</td>
                      <td>{qr.transaction_code}</td>
                      <td>{qr.account_source}</td>
                      <td>{qr.account_destinatary}</td>
                      <td>
                        <AmountFormat
                          name='amount'
                          value={qr.amount}
                          currency={'AR$'}
                          displayType='text'
                        />
                      </td>
                      <td>{qr.statusTxt}</td>
                      <td>
                        <Link
                          to={{
                            pathname: '/detail-qr',
                            state: { qr: qr },
                          }}
                        >
                          <button className='button is-outlined is-small'>{t('seeDetail')}</button>
                        </Link>
                      </td>
                    </TransactionRow>
                  )))}
                </TableList>
              </ContentHeader>
            </div>
          </div>
        </Fragment>
      </div>
    );
  }
}

ListQr.propTypes = {
  t: PropTypes.func.isRequired,
  showMessage: PropTypes.func,
  fetchQrOperationsList: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, transactions }) => ({
  auth, transactions
})

export default compose(connect(mapStateToProps, {
   ...authActions,
   ...transactionsActions,
}), withNamespaces())(ListQr);
