import { usersActions, usersTypes } from '../ducks/users';
import { API_URL, HEADERS }         from '../../config/constants';
import * as fileSaver               from "file-saver";

const fetchUsers = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== usersTypes.FETCH_USERS) {
    return;
  }
  const {
    data: dataSend, callback, data: { export: exportExcel }
  } = action.payload || {};
  dispatch({
    type: usersTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/api/list-users',
        data: dataSend,
        responseType: exportExcel ? 'blob' : 'json',
      },
      authorization: true,
      onStart: usersActions.startFetch,
      onComplete: (responseData) => {
        if (exportExcel) {
          const blob = responseData.data
          const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fileSaver.saveAs(file, 'Administracion_usuarios.xlsx');
        } else {
          const users = responseData.data.data;
          dispatch(usersActions.fetchUsersData({ data: users }));
          callback(responseData.data);
        }

      },
      onError: (response) => {
        console.log('error', response);
        // callback(response);
      },
      onEnd: usersActions.endFetch,
    },
  });
};

const fetchUploadDocuments = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== usersTypes.FETCH_UPLOAD_DOCUMENTS) {
    return;
  }
  const {
    callback = () => {},
    data, token, expToken
  } = action.payload || {};

  fetch(`${API_URL}console/save-picture-passenger`, {
    method: 'POST',
    body: data,
    headers: HEADERS({
      token,
      expToken,
    }),
  })
    .then(response => response.json())
    .then((response) => {
      callback(response);
      if (response.success) {
        dispatch(usersActions.updateUploadDocuments(response.data));
      } else {
        dispatch(usersActions.updateUploadDocuments({ data: null }));
      }
    })
    .catch((response) => {
      callback(response);
    });

};

const detailUser = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== usersTypes.FETCH_USERS_DETAIL) {
    return;
  }
  const {
    data, callback,
  } = action.payload || {};
  dispatch({
    type: usersTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/api/detail-user',
        data,
      },
      authorization: true,
      onStart: usersActions.startFetch,
      onComplete: (responseData) => {
        dispatch(usersActions.detailUserData(responseData.data));
        callback(responseData.data);
      },
      onError: (response) => {
        console.log(response);
      },
      onEnd: usersActions.endFetch,
    },
  });
};

const dataFiscal = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== usersTypes.FETCH_USER_DATA_FISCAL) {
    return;
  }
  const {
    data, callback,
  } = action.payload || {};

  dispatch({
    type: usersTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/api/fiscal-configuration-user?id_person=${data.id_person}&id_role=${data.id_role}`,
        data,
      },
      authorization: true,
      onStart: usersActions.startFetch,
      onComplete: (responseData) => {
        dispatch(usersActions.dataFiscalData(responseData.data));
        callback(responseData.data);
      },
      onError: (response) => {
        console.log(response);
      },
      onEnd: usersActions.endFetch,
    },
  });
};

const editDataFiscal = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== usersTypes.EDIT_USER_DATA_FISCAL) {
    return;
  }
  const {
    id_person, id_role, data, callback,
  } = action.payload || {};

  dispatch({
    type: usersTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: `/api/update-fiscal-configuration-user?id_person=${id_person}&id_role=${id_role}`,
        data,
      },
      authorization: true,
      onStart: usersActions.startFetch,
      onComplete: (responseData) => {
        dispatch(usersActions.updateDataFiscal(responseData.data));
        callback(responseData.data);
      },
      onError: (response) => {
        console.log(response);
      },
      onEnd: usersActions.endFetch,
    },
  });
};

const fetchStatusIva = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== usersTypes.FETCH_STATUS_IVA) {
    return;
  }

  dispatch({
    type: usersTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: '/api/list-status-iva',
      },
      authorization: true,
      onStart: usersActions.startFetch,
      onComplete: (responseData) => {
        dispatch(usersActions.setStatusIva(responseData.data));
      },
      onError: (response) => {
        console.log(response);
      },
      onEnd: usersActions.endFetch,
    },
  });
};

const fetchListStatusUser = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== usersTypes.FETCH_LIST_STATUS_USER) {
    return;
  }

  dispatch({
    type: usersTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: '/api/list-status-user',
      },
      authorization: true,
      onStart: usersActions.startFetch,
      onComplete: (responseData) => {
        dispatch(usersActions.updateListStatusUser(responseData.data));
      },
      onError: (response) => {
        console.log(response);
      },
      onEnd: usersActions.endFetch,
    },
  });
};

const fetchTypePerson = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== usersTypes.FETCH_TYPE_PERSON) {
    return;
  }

  dispatch({
    type: usersTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: '/api/list-person-types',
      },
      authorization: true,
      onStart: usersActions.startFetch,
      onComplete: (responseData) => {
        dispatch(usersActions.setTypePerson(responseData.data));
      },
      onError: (response) => {
        console.log(response);
      },
      onEnd: usersActions.endFetch,
    },
  });
};

const fetchListVirtualAccounts = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== usersTypes.FETCH_LIST_VIRTUAL_ACCOUNTS) {
    return;
  }

  const { data: dataSend, callback, data: { export: exportExcel } } = action.payload || {};
  dispatch({
    type: usersTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/console/list-virtual-accounts',
        data: dataSend,
        responseType: exportExcel ? 'blob' : 'json',
      },
      authorization: true,
      onStart: usersActions.startFetch,
      onComplete: (responseData) => {
        if (exportExcel) {
          const blob = responseData.data
          const file = new Blob([blob],
            { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fileSaver.saveAs(file, 'transacciones_comisiones.xlsx');
        } else {
          const { data } = responseData;
          dispatch(usersActions.setListVirtualAccounts(data));
          callback(data);
        }

      },
      onError: (response) => {
        callback(response);
      },
      onEnd: usersActions.endFetch,
    },
  });
};

const editDataUser = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== usersTypes.EDIT_DATA_USER) {
    return;
  }
  const {
    data, callback,
  } = action.payload || {};

  dispatch({
    type: usersTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/console/update-person-user',
        data,
      },
      authorization: true,
      onStart: usersActions.startFetch,
      onComplete: (responseData) => {
        dispatch(usersActions.updateDataUser(responseData.data));
        callback(responseData.data);
      },
      onError: (response) => {
        console.log(response);
      },
      onEnd: usersActions.endFetch,
    },
  });
};

const editDataAliasUser = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== usersTypes.EDIT_DATA_ALIAS_USER) {
    return;
  }
  const {
    data, callback,
  } = action.payload || {};

  dispatch({
    type: usersTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/console/update-person-alias',
        data,
      },
      authorization: true,
      onStart: usersActions.startFetch,
      onComplete: (responseData) => {
        dispatch(usersActions.updateDataAliasUser(responseData.data));
        callback(responseData.data);
      },
      onError: (response) => {
        console.log(response);
      },
      onEnd: usersActions.endFetch,
    },
  });
};

const fetchAllUsers = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== usersTypes.FETCH_ALL_USERS) {
    return;
  }
  const {
    data, callback,
  } = action.payload || {};

  dispatch({
    type: usersTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/api/list-users',
        data,
      },
      authorization: true,
      onStart: usersActions.startFetch,
      onComplete: (responseData) => {
        const users = responseData.data.data;
        dispatch(usersActions.fetchAllUsersData({ data: users }));
        callback(responseData.data);
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: usersActions.endFetch,
    },
  });
};

const fetchListUserExcel = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== usersTypes.FETCH_LIST_USER_EXCEL) {
    return;
  }
  const { data, token, expToken, callback } = action.payload || {};
  fetch(new Request(`${API_URL}console/export-list-users`, {
    method: 'POST',
    body: JSON.stringify(data),
    responseType: 'blob',
    headers: HEADERS({
      token,
      expToken,
      contentType: 'application/json; charset=UTF-8',
    }),
  })).then(response => response.blob()).then((blob) => {
    const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    fileSaver.saveAs(file, 'Administracion_usuarios.xlsx');

  }).catch(error => console.log(error));
};

const fetchTransactionsPerUser = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== usersTypes.FETCH_TRANSACTIONS_PER_USER) {
    return;
  }
  const {
    page,
    month,
    email,
    identification,
    callback,
  } = action.payload || {};

  dispatch({
    type: usersTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/api/user-last-transactions?page=${page}&month=${month}&person=${email}&identification=${identification}`,
      },
      authorization: true,
      onStart: usersActions.startFetch,
      onComplete: (responseData) => {
        dispatch(usersActions.updateTransactionsPerUser(responseData.data.data));
        callback(responseData.data);
      },
      onError: (response) => {
        console.log(response);
      },
      onEnd: usersActions.endFetch,
    },
  });
};

const editAddressUser = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== usersTypes.EDIT_ADDRESS_USER) {
    return;
  }
  const {
    data, callback,
  } = action.payload || {};

  dispatch({
    type: usersTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/console/update-person-address',
        data,
      },
      authorization: true,
      onStart: usersActions.startFetch,
      onComplete: (responseData) => {
        dispatch(usersActions.updateAddressUser(responseData.data));
        callback(responseData.data);
      },
      onError: (response) => {
        console.log(response);
      },
      onEnd: usersActions.endFetch,
    },
  });
};

const editEntitiesUser = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== usersTypes.EDIT_ENTITIES_USER) {
    return;
  }
  const {
    data, callback,
  } = action.payload || {};

  dispatch({
    type: usersTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/console/update-came-entites-person',
        data,
      },
      authorization: true,
      onStart: usersActions.startFetch,
      onComplete: (responseData) => {
        dispatch(usersActions.updateEntitiesUser(responseData.data));
        callback(responseData.data);
      },
      onError: (response) => {
        console.log(response);
      },
      onEnd: usersActions.endFetch,
    },
  });
};


const fetchUsersOperator = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== usersTypes.FETCH_USERS_OPERATOR) {
    return;
  }
  const { data: { status, name, document_number }, callback } = action.payload || {};

  dispatch({
    type: usersTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/console/list-admin-users?name=${name}&status=${status}&document_number=${document_number}`,
      },
      authorization: true,
      onStart: usersActions.startFetch,
      onComplete: ({ data }) => {
        dispatch(usersActions.setUsersOperator(data));
        callback(data);
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: usersActions.endFetch,
    },
  });
};

const newUserOperator = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== usersTypes.NEW_USER_OPERATOR) {
    return;
  }
  const {
    data, callback,
  } = action.payload || {};

  dispatch({
    type: usersTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/console/create-user-console',
        data,
      },
      authorization: true,
      onStart: usersActions.startFetch,
      onComplete: (responseData) => {
        callback(responseData.data);
      },
      onError: (response) => {
        console.log(response);
        callback(response);
      },
      onEnd: usersActions.endFetch,
    },
  });
};

const updateStatusUser = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== usersTypes.UPDATE_STATUS_USER) {
    return;
  }
  const { data, callback } = action.payload || {};

  dispatch({
    type: usersTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/console/update-user-status',
        data,
      },
      authorization: true,
      onStart: usersActions.startFetch,
      onComplete: (responseData) => {
        callback(responseData.data);
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: usersActions.endFetch,
    },
  });
};

const assignedRemoveDriver = ({ dispatch }) => next => (action) => {

  next(action);
  if (action.type !== usersTypes.ASIGNED_REMOVE_DRIVER) {
    return;
  }
  const { data, callback } = action.payload || {};

  dispatch({
    type: usersTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/console/assign-role-driver',
        data,
      },
      authorization: true,
      onStart: usersActions.startFetch,
      onComplete: (responseData) => {
        callback(responseData.data);
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: usersActions.endFetch,
    },
  });

}

const fetchListOperators = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== usersTypes.FETCH_LIST_OPERATORS) {
    return;
  }
  const { data, callback, data: { export: exportExcel } } = action.payload || {};
  dispatch({
    type: usersTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/console/list-operators',
        data,
        responseType: exportExcel ? 'blob' : 'json',
      },
      authorization: true,
      onStart: usersActions.startFetch,
      onComplete: (responseData) => {
        if (exportExcel) {
          const blob = responseData.data
          const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fileSaver.saveAs(file, 'Listado de operadores.xlsx');
        } else {
          const operators = responseData.data.data;
          dispatch(usersActions.updateListOperators({ data: operators }));
          callback(responseData.data);
        }
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: usersActions.endFetch,
    },
  });
};

const fetchRolOperator = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== usersTypes.FETCH_ROL_OPERATOR) {
    return;
  }
  const { callback = () => { } } = action.payload || {};
  dispatch({
    type: usersTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: '/console/list-rol-operator'
      },
      authorization: true,
      onStart: usersActions.startFetch,
      onComplete: ({ data: { data } }) => {
        dispatch(usersActions.updateRolOperator({ data }));
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: usersActions.endFetch,
    },
  });
}

const updateStateOperator = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== usersTypes.UPDATE_STATE_OPERATOR) {
    return;
  }
  const { data, callback } = action.payload || {};

  dispatch({
    type: usersTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/console/update-operator-status',
        data,
      },
      authorization: true,
      onStart: usersActions.startFetch,
      onComplete: (responseData) => {
        callback(responseData.data);
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: usersActions.endFetch,
    },
  });
};

const fetchOperatorAgency = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== usersTypes.FETCH_OPERATOR_AGENCY) {
    return;
  }
  const { callback = () => { } } = action.payload || {};
  dispatch({
    type: usersTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: '/console/list-agency-filter'
      },
      authorization: true,
      onStart: usersActions.startFetch,
      onComplete: ({ data: { data } }) => {
        dispatch(usersActions.updateOperatorAgency({ data }));
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: usersActions.endFetch,
    },
  });
}

const fetchStatusFilter = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== usersTypes.FETCH_STATUS_FILTER) {
    return;
  }
  const { callback = () => { } } = action.payload || {};
  dispatch({
    type: usersTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: '/console/list-status-filter'
      },
      authorization: true,
      onStart: usersActions.startFetch,
      onComplete: ({ data: { data } }) => {
        dispatch(usersActions.updateStatusFilter({ data }));
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: usersActions.endFetch,
    },
  });
}

const fetchProvince = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== usersTypes.FETCH_PROVINCE) {
    return;
  }
  const { data, callback } = action.payload || {};
  dispatch({
    type: usersTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: '/console/list-province',
      },
      authorization: true,
      onStart: usersActions.startFetch,
      onComplete: ({ data }) => {
        dispatch(usersActions.updateProvince(data));
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: usersActions.endFetch,
    },
  });
};

const fetchLocations = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== usersTypes.FETCH_LOCATIONS) {
    return;
  }
  const { data: { id }, callback } = action.payload || {};
  dispatch({
    type: usersTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/console/list-locations?provincia=${id}`,
      },
      authorization: true,
      onStart: usersActions.startFetch,
      onComplete: ({ data }) => {
        dispatch(usersActions.updateLocations(data));
        callback(data);
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: usersActions.endFetch,
    },
  });
};

const fetchResetPassOperator = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== usersTypes.FETCH_RESET_PASS_OPERATOR) {
    return;
  }
  const { data, callback } = action.payload || {};

  dispatch({
    type: usersTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/console/request-password-reset',
        data,
      },
      authorization: true,
      onStart: usersActions.startFetch,
      onComplete: (responseData) => {
        callback(responseData.data);
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: usersActions.endFetch,
    },
  });
};

const fetchImg = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== usersTypes.FETCH_IMG) {
    return;
  }
  const { data, callback } = action.payload || {};
  dispatch({
    type: usersTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: `/api/download-file`,
        data,
        responseType: data ? 'blob' : 'json',
      },
      authorization: true,
      onStart: usersActions.startFetch,
      onComplete: (responseData) => {
        if (responseData) {
          const blob = responseData.data
         // const img = new Blob([blob], { type: 'image/png' });
         // fileSaver.saveAs(img, 'Selfie.png');
          callback(blob);
        } else {
          const image = responseData.data.data;
          // dispatch(usersActions.fetchImgSelfie({ data: image }));
          callback(responseData.data);
        }
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: usersActions.endFetch,
    },
  });
};

const fetchPdf = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== usersTypes.FETCH_PDF) {
    return;
  }
  const { data, callback } = action.payload || {};
  dispatch({
    type: usersTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: `/api/download-file`,
        data,
        responseType: data ? 'blob' : 'json',
      },
      authorization: true,
      onStart: usersActions.startFetch,
      onComplete: (responseData) => {
        if (responseData) {
          const blob = responseData.data
          const pdf = new Blob([blob], {type: 'application/pdf'});
          var fileURL = URL.createObjectURL(pdf);
          window.open(fileURL);
        } else {
          const image = responseData.data.data;
         // dispatch(usersActions.fetchImgSelfie({ data: image }));
          callback(responseData.data);
        }
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: usersActions.endFetch,
    },
  });
};

const fetchAccountsState = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== usersTypes.FETCH_ACCOUNTS_STATE) {
    return;
  }
  const { data, callback } = action.payload || {};
  dispatch({
    type: usersTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/console/list-system-accounts',
        data
      },
      authorization: true,
      onStart: usersActions.startFetch,
      onComplete: ({ data }) => {
        dispatch(usersActions.updateAccountsState(data));
        callback(data);
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: usersActions.endFetch,
    },
  });
};

export default [
  fetchUploadDocuments,
  fetchUsers,
  detailUser,
  dataFiscal,
  editDataFiscal,
  fetchStatusIva,
  fetchTypePerson,
  fetchListVirtualAccounts,
  editDataUser,
  editDataAliasUser,
  fetchAllUsers,
  fetchListStatusUser,
  fetchListUserExcel,
  fetchTransactionsPerUser,
  editAddressUser,
  editEntitiesUser,
  fetchUsersOperator,
  newUserOperator,
  updateStatusUser,
  assignedRemoveDriver,
  fetchListOperators,
  fetchRolOperator,
  updateStateOperator,
  fetchOperatorAgency,
  fetchStatusFilter,
  fetchProvince,
  fetchLocations,
  fetchResetPassOperator,
  fetchImg,
  fetchPdf,
  fetchAccountsState,
];
