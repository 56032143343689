import React, { Component, Fragment } from 'react';
import { withNamespaces }             from 'react-i18next';
import { compose }                    from 'redux';
import { Link }                       from 'react-router-dom';
import { toast }                      from 'react-toastify';
import { connect }                    from 'react-redux';
import {
  AmountFormat,
  ContentHeader,
  Filter,
  FormElement,
  Loading,
  TableList,
  TransactionRow,
  ExportExcel,
}                                     from '../../../components';
import {
  initTimer,
  stopTimer,
}                                     from '../../../util/initTimer';
import { TOAST_CONFIG, SIZE_PER_PAGE }               from '../../../config/constants';
import { authActions }                from '../../../state/ducks/auth';
import { usersActions }               from '../../../state/ducks/users';
import { agencyActions }              from '../../../state/ducks/agency';
import './styles.scss';

class InfoAgency extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      indexRow: '',
      agency: '',
      page: 0,
    };
  }

  componentDidMount() {
    initTimer(this.props);
    const { fetchAgencyList, location, agency: { agencyListSelect } } = this.props;
    fetchAgencyList();

    if (location !== undefined && location.state !== undefined && location.state.agency !== undefined) {

      var agencyInfo = '';
      agencyInfo =  agencyListSelect.find(item => item.name === location.state.agency);

      this.setState({
        agency: agencyInfo ? agencyInfo.id : '' ,
      }, () => {
        this.callApiList();
      });
    } else {
      this.callApiList();
    }
  }

  callApiList = (getExcel = false) => {
    const {
      t, showMessage, fetchInfoAgencies,
      agency: { agenciesInfo: { totalElements } },
    } = this.props;
    const { agency, page } = this.state;

    const data = {
      agency,
      pageSize: !getExcel ? SIZE_PER_PAGE : totalElements,
      page: getExcel ? 0 : page,
      export: getExcel,
     };

    fetchInfoAgencies({
      data,
      callback: (response) => {
        this.setState({ loading: false });
        if (response) {
          if (!response.success) {
            toast.dismiss();
            showMessage({
              message: t('connectionError'),
              config: TOAST_CONFIG.ERROR,
            });
          }
        } else {
          toast.dismiss();
          showMessage({
            message: t('connectionError'),
            config: TOAST_CONFIG.ERROR,
          });
        }
      },
    });
  }

  onChangePage = (selectPage) => {
    stopTimer();
    initTimer(this.props);
    this.setState(() => ({
      page: selectPage ? selectPage.selected : 0,
    }), () => {
      this.callApiList();
    });
  };

  onSubmit = (event) => {
    event.preventDefault();
    // this.onChangePage();
    this.callApiList();
  };

  handleToggle(index) {
    this.setState({ collapsed: !this.state.collapsed, indexRow: index });
  }

  handleInputChange = (event) => {
    stopTimer();
    initTimer(this.props);
    const {
      target: {
        value: targetValue, name,
      },
    } = event;
    this.setState({
      [name]: targetValue,
    });
  };

  render() {
    const { t, agency: { loading, agenciesInfo: { list, totalElements }, agencyListSelect } } = this.props;
    const { collapsed, agency, indexRow } = this.state;
    return (
      <div className="agency-list">
        {loading && <Loading />}
        <Fragment>
          <div className="wow animated slideInDown">
            <div className="wow animated slideInDown">
              <Filter
                title={t('adminRed')}
                subtitle={t('infoAgency')}
                onSubmit={(ev) => {
                  this.onSubmit(ev);
                }}
              >

                <FormElement
                  name="agency"
                  onChange={this.handleInputChange}
                  value={agency}
                  label={t('agency')}
                  optionsSelect={agencyListSelect}
                  placeholder={t('selectAngency')}
                  typeElement="select"
                  controlClasses="select select-agency"
                />
              </Filter>
            </div>
            <ContentHeader
              headingRightText={t('totalAgencies')}
              headingRightSubText={`${totalElements}`}
            >
              <ExportExcel onclick={() => { this.callApiList(true) }}/>
              <TableList
                tableClasses="floating-table"
                head={(
                  <tr>
                    <th>{t('agency')}</th>
                    <th>
                      Saldo de
                      <br />
                      {t('agency')}
                    </th>
                    <th>
                      {t('transactions')}
                      <br />
                      {t('withoutBatches')}
                    </th>
                    <th>
                      {t('lots')}
                      <br />
                      {t('closed')}
                    </th>
                    <th>
                      {t('amountOf')}
                      <br />
                      {t('closedLots')}
                    </th>
                    <th>
                      {t('commissionAmount')}
                      <br />
                      {t('toReconcile')}
                    </th>
                    <th>{t('actions')}</th>
                  </tr>
                )}
                colNumber={7}
                lenghtData={totalElements !== undefined ? totalElements : 0}
              >
                {list && (list.map((row, index) => {
                    return (
                      <TransactionRow
                        key={index}
                        helper="detailReport"
                      >
                        {/*  <td>{row.number_account}</td> */}
                        {/* <td>{row.alias}</td> */}
                        <td>{row.agency}</td>
                        <td>
                          <AmountFormat
                            name="balance"
                            value={row.balance}
                            displayType="text"
                            allowNegative
                          />
                        </td>
                        <td>{row.transactions_without_lot}</td>
                        <td>{row.close_lot}</td>
                        <td>
                          <AmountFormat
                            name="amount_close_lot"
                            value={row.amount_close_lot}
                            displayType="text"
                            allowNegative
                          />
                        </td>
                        <td>
                          <AmountFormat
                            value={row.amount_gcommission === null ? 0 : row.amount_gcommission}
                            name="amount_commission"
                            displayType="text"
                            allowNegative
                          />
                        </td>

                        <td>

                          <div className="actions">
                            <div
                              className={`dropdown ${collapsed ? '' : (index == indexRow) ? ' is-active' : ''}`}
                              tabIndex={index}
                            >
                              <div className="dropdown-trigger">
                                <button
                                  className="button"
                                  aria-haspopup="true"
                                  aria-controls="dropdown-menu"
                                  onClick={() => this.handleToggle(index)}
                                >
                                  <span>{t('selectAction')}</span>
                                  <span className="icon is-small">
                                  <i className="fas fa-angle-down" aria-hidden="true" />
                                </span>
                                </button>
                              </div>
                              <div className="dropdown-menu" id={`dropdown-menu-${index}`} role="menu">
                                <div className="dropdown-content">
                                  <div className="dropdown-item">
                                    <Link
                                      to={{
                                        pathname: '/movement-agency-list',
                                        state: { id: row.id, agency: row.agency },
                                      }}
                                    >
                                      {t('seeMovements')}
                                    </Link>
                                  </div>

                                  <div className="dropdown-item">
                                    <Link
                                      to={{
                                        pathname: '/lots-info',
                                        state: { id: row.id, agency: row.agency },
                                      }}
                                    >
                                      {t('seeLote')}
                                    </Link>
                                  </div>
                                  <div className="dropdown-item">
                                    <Link
                                      to={{
                                        pathname: '/account-info',
                                        state: { id: row.id, agency: row.agency },
                                      }}
                                    >
                                      {t('seeAccount')}
                                    </Link>
                                  </div>
                                  <div className="dropdown-item">
                                    <Link
                                      to={{
                                        pathname: '/box-agency',
                                        state: { id: row.id, agency: row.agency },
                                      }}
                                    >
                                      {t('seeBox')}
                                    </Link>
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>

                        </td>
                      </TransactionRow>
                    )
                  }
                ))
                }
              </TableList>
            </ContentHeader>
          </div>
        </Fragment>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, users, agency }) => (
  {
    auth,
    users,
    agency,
  }
);

export default compose(connect(mapStateToProps, {
    ...authActions,
    ...usersActions,
    ...agencyActions,
  }),
  withNamespaces())(InfoAgency);
