import { authActions, authTypes } from '../ducks/auth';

const signIn = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== authTypes.SIGN_IN) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload || {};

  dispatch({
    type: authTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/api/login-console',
        data,
      },
      authorization: false,
      onStart: authActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          dispatch(authActions.updateUser(response.data.data));
          dispatch(authActions.updateAccount({ account_number: response.data.data.phone_number }));
          dispatch(authActions.logged());
        }
        callback(response.data);
      },
      onError: (e) => {
        callback(e);
      },
      onEnd: authActions.endFetch,
    },
  });
};

const logout = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== authTypes.LOGOUT) {
    return;
  }

  dispatch(authActions.clear());
};

const getContacts = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== authTypes.GET_CONTACTS) {
    return;
  }

  dispatch({
    type: authTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: 'api/contact-list',
      },
      authorization: true,
      onStart: authActions.startFetch,
      onComplete: (response) => {
        if (response.data.success) {
          dispatch(authActions.updateContacts(response.data.data));
        }
      },
      onEnd: authActions.endFetch,
    },
  });
};

const changePassword = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== authTypes.CHANGE_PASSWORD) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload || {};
  dispatch({
    type: authTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: 'api/change-password',
        data,
      },
      authorization: true,
      onStart: authActions.startFetch,
      onComplete: (response) => {
        callback(response.data);
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: authActions.endFetch,
    },
  });
};

const fetchAcceptUser = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== authTypes.FETCH_ACCEPT_USER) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload || {};
  dispatch({
    type: authTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: 'console/approve-user-account',
        data,
      },
      authorization: true,
      onStart: authActions.startFetch,
      onComplete: (response) => {
        callback(response.data);
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: authActions.endFetch,
    },
  });
};

const fetchRejectUser = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== authTypes.FETCH_REJECT_USER) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload || {};
  dispatch({
    type: authTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: 'console/reject-user-account',
        data,
      },
      authorization: true,
      onStart: authActions.startFetch,
      onComplete: (response) => {
        callback(response.data);
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: authActions.endFetch,
    },
  });
};

const getProvinces = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== authTypes.GET_PROVINCES) {
    return;
  }
  const {
    callback = () => {
    },
  } = action.payload || {};

  dispatch({
    type: authTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: '/api/provinces',
      },
      authorization: true,
      onStart: authActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        dispatch(authActions.updateProvinces({ data }));
        callback({ data, success });
      },
      onError: (response) => {
        console.log(response);
      },
      onEnd: authActions.endFetch,
    },
  });
};

const getLocations = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== authTypes.GET_LOCATIONS) {
    return;
  }

  const {
    province,
    callback = () => {
    },
  } = action.payload || {};

  dispatch({
    type: authTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/api/departments?province=${province}`,
      },
      authorization: true,
      onStart: authActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        dispatch(authActions.updateLocations({ data }));
        callback({ data, success });
      },
      onError: (response) => {
        console.log(response);
      },
      onEnd: authActions.endFetch,
    },
  });
};

const getCities = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== authTypes.GET_CITIES) {
    return;
  }

  const {
    department,
    callback = () => {
    },
  } = action.payload || {};

  dispatch({
    type: authTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/api/cities?department=${department}`,
      },
      authorization: true,
      onStart: authActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        dispatch(authActions.updateCities({ data }));
        callback({ data, success });
      },
      onError: (response) => {
        console.log(response);
      },
      onEnd: authActions.endFetch,
    },
  });
};

const getEntities = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== authTypes.GET_ENTITIES) {
    return;
  }
  const { entity, callback = () => {}, } = action.payload || {};

  let value = entity;
  if (entity == undefined)
    value = '';

  dispatch({
    type: authTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/api/entities?search=${value}`,
      },
      authorization: true,
      onStart: authActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        dispatch(authActions.updateEntities({ data }));
        callback({ data, success });
      },
      onError: (response) => {
        console.log(response);
      },
      onEnd: authActions.endFetch,
    },
  });
};

const getRubros = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== authTypes.GET_RUBROS) {
    return;
  }

  const { rubro, callback = () => {}, } = action.payload || {};
  let value = rubro;
  if (rubro == undefined)
    value = '';


  dispatch({
    type: authTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/api/rubros?search=${value}`,
      },
      authorization: true,
      onStart: authActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        dispatch(authActions.updateRubros({ data }));
        callback({ data, success });
      },
      onError: (response) => {
        console.log(response);
      },
      onEnd: authActions.endFetch,
    },
  });
};

const fetchUnsubscribeUser = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== authTypes.FETCH_UNSUBSCRIBE_USER) {
    return;
  }
  const { data, callback = () => {} } = action.payload || {};
  dispatch({
    type: authTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/console/unsubscribe-user-account',
        data,
      },
      authorization: true,
      onStart: authActions.startFetch,
      onComplete: (response) => {
        callback(response.data);
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: authActions.endFetch,
    },
  });
};

export default [
  signIn,
  logout,
  getContacts,
  changePassword,
  fetchAcceptUser,
  fetchRejectUser,
  getProvinces,
  getLocations,
  getRubros,
  getEntities,
  getCities,
  fetchUnsubscribeUser,
];
