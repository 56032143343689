const CLEAR = 'users/CLEAR';
const CLEAR_USER = 'users/CLEAR_USER';
const START_FETCH = 'users/START_FETCH';
const END_FETCH = 'users/END_FETCH';
const END_ERROR = 'users/END_ERROR';
const API_CALL = 'users/API_CALL';
const FETCH_USERS = 'users/FETCH_USERS';
const FETCH_USERS_DATA = 'users/FETCH_USERS_DATA';
const MESSAGE = 'users/MESSAGE';
const FETCH_USERS_DETAIL = 'users/FETCH_USERS_DETAIL';
const FETCH_USERS_DETAIL_DATA = 'users/FETCH_USERS_DETAIL_DATA';
const FETCH_USER_DATA_FISCAL = 'users/FETCH_USER_DATA_FISCAL';
const GET_USER_DATA_FISCAL = 'users/GET_USER_DATA_FISCAL';
const EDIT_USER_DATA_FISCAL = 'users/EDIT_USER_DATA_FISCAL';
const SET_USER_DATA_FISCAL = 'users/SET_USER_DATA_FISCAL';
const FETCH_STATUS_IVA = 'users/FETCH_STATUS_IVA';
const SET_STATUS_IVA = 'users/SET_STATUS_IVA';
const FETCH_TYPE_PERSON = 'users/FETCH_TYPE_PERSON';
const SET_TYPE_PERSON = 'users/SET_TYPE_PERSON';
const FETCH_LIST_VIRTUAL_ACCOUNTS = 'users/FETCH_LIST_VIRTUAL_ACCOUNTS';
const SET_LIST_VIRTUAL_ACCOUNTS = 'users/SET_LIST_VIRTUAL_ACCOUNTS';
const EDIT_DATA_USER = 'users/EDIT_DATA_USER';
const SET_USER_DATA = 'users/SET_USER_DATA';
const EDIT_DATA_ALIAS_USER = 'users/EDIT_DATA_ALIAS_USER';
const SET_USER_ALIAS_DATA = 'users/SET_USER_ALIAS_DATA';
const FETCH_ALL_USERS = 'users/FETCH_ALL_USERS';
const FETCH_ALL_USERS_DATA = 'users/FETCH_ALL_USERS_DATA';
const FETCH_LIST_ALL_VIRTUAL_ACCOUNTS = 'users/FETCH_LIST_ALL_VIRTUAL_ACCOUNTS';
const SET_LIST_ALL_VIRTUAL_ACCOUNTS = 'users/SET_LIST_ALL_VIRTUAL_ACCOUNTS';
const FETCH_LIST_STATUS_USER = 'users/FETCH_LIST_STATUS_USER';
const FETCH_LIST_USER_EXCEL = 'users/FETCH_LIST_USER_EXCEL';
const UPDATE_LIST_STATUS_USER = 'users/UPDATE_LIST_STATUS_USER';
const FETCH_TRANSACTIONS_PER_USER = 'users/FETCH_TRANSACTIONS_PER_USER';
const UPDATE_TRANSACTIONS_PER_USER = 'users/UPDATE_TRANSACTIONS_PER_USER';
const EDIT_ADDRESS_USER = 'users/EDIT_ADDRESS_USER';
const SET_USER_ADDRESS = 'users/SET_USER_ADDRESS';
const EDIT_ENTITIES_USER = 'users/EDIT_ENTITIES_USER';
const SET_ENTITIES_USER = 'users/SET_ENTITIES_USER'
const FETCH_USERS_OPERATOR = 'users/FETCH_USERS_OPERATOR';
const SET_USERS_OPERATOR = 'users/SET_USERS_OPERATOR';
const NEW_USER_OPERATOR = 'users/NEW_USER_OPERATOR';
const UPDATE_STATUS_USER = 'users/UPDATE_STATUS_USER';
const ASIGNED_REMOVE_DRIVER = 'users/ASIGNED_REMOVE_DRIVER';
const FETCH_LIST_OPERATORS = 'users/FETCH_LIST_OPERATORS';
const UPDATE_LIST_OPERATORS = 'users/UPDATE_LIST_OPERATORS';
const FETCH_ROL_OPERATOR = 'users/FETCH_ROL_OPERATOR';
const UPDATE_ROL_OPERATOR = 'users/UPDATE_ROL_OPERATOR';
const UPDATE_STATE_OPERATOR = 'users/UPDATE_STATE_OPERATOR';
const FETCH_OPERATOR_AGENCY = 'users/FETCH_OPERATOR_AGENCY';
const UPDATE_OPERATOR_AGENCY = 'users/UPDATE_OPERATOR_AGENCY';
const FETCH_STATUS_FILTER = 'users/FETCH_STATUS_FILTER';
const UPDATE_STATUS_FILTER = 'users/UPDATE_STATUS_FILTER';
const FETCH_PROVINCE = 'users/FETCH_PROVINCE';
const UPDATE_PROVINCE = 'users/UPDATE_PROVINCE';
const FETCH_LOCATIONS = 'users/FETCH_LOCATIONS';
const UPDATE_LOCATIONS = 'agency/UPDATE_LOCATIONS';
const FETCH_RESET_PASS_OPERATOR = 'users/FETCH_RESET_PASS_OPERATOR';
const FETCH_UPLOAD_DOCUMENTS = 'users/FETCH_UPLOAD_DOCUMENTS';
const UPDATE_UPLOAD_DOCUMENTS = 'users/UPDATE_UPLOAD_DOCUMENTS';
const FETCH_IMG = 'users/FETCH_IMG';
const FETCH_PDF = 'users/FETCH_PDF';
const FETCH_ACCOUNTS_STATE = 'users/FETCH_ACCOUNTS_STATE';
const UPDATE_ACCOUNTS_STATE = 'users/UPDATE_ACCOUNTS_STATE';

export default {
  CLEAR,
  CLEAR_USER,
  START_FETCH,
  END_FETCH,
  END_ERROR,
  API_CALL,
  FETCH_USERS,
  FETCH_USERS_DATA,
  MESSAGE,
  FETCH_USERS_DETAIL,
  FETCH_USERS_DETAIL_DATA,
  FETCH_USER_DATA_FISCAL,
  GET_USER_DATA_FISCAL,
  EDIT_USER_DATA_FISCAL,
  SET_USER_DATA_FISCAL,
  FETCH_STATUS_IVA,
  SET_STATUS_IVA,
  FETCH_TYPE_PERSON,
  SET_TYPE_PERSON,
  FETCH_LIST_VIRTUAL_ACCOUNTS,
  SET_LIST_VIRTUAL_ACCOUNTS,
  EDIT_DATA_USER,
  SET_USER_DATA,
  EDIT_DATA_ALIAS_USER,
  SET_USER_ALIAS_DATA,
  FETCH_ALL_USERS,
  FETCH_ALL_USERS_DATA,
  FETCH_LIST_ALL_VIRTUAL_ACCOUNTS,
  SET_LIST_ALL_VIRTUAL_ACCOUNTS,
  FETCH_LIST_STATUS_USER,
  UPDATE_LIST_STATUS_USER,
  FETCH_LIST_USER_EXCEL,
  FETCH_TRANSACTIONS_PER_USER,
  UPDATE_TRANSACTIONS_PER_USER,
  EDIT_ADDRESS_USER,
  SET_USER_ADDRESS,
  EDIT_ENTITIES_USER,
  SET_ENTITIES_USER,
  FETCH_USERS_OPERATOR,
  SET_USERS_OPERATOR,
  NEW_USER_OPERATOR,
  UPDATE_STATUS_USER,
  ASIGNED_REMOVE_DRIVER,
  FETCH_LIST_OPERATORS,
  UPDATE_LIST_OPERATORS,
  FETCH_ROL_OPERATOR,
  UPDATE_ROL_OPERATOR,
  UPDATE_STATE_OPERATOR,
  FETCH_OPERATOR_AGENCY,
  UPDATE_OPERATOR_AGENCY,
  FETCH_STATUS_FILTER,
  UPDATE_STATUS_FILTER,
  FETCH_PROVINCE,
  UPDATE_PROVINCE,
  FETCH_LOCATIONS,
  UPDATE_LOCATIONS,
  FETCH_RESET_PASS_OPERATOR,
  FETCH_UPLOAD_DOCUMENTS,
  UPDATE_UPLOAD_DOCUMENTS,
  FETCH_IMG,
  FETCH_PDF,
  FETCH_ACCOUNTS_STATE,
  UPDATE_ACCOUNTS_STATE,
};
