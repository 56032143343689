import React     from 'react';
import PropTypes from 'prop-types';
import './styles.scss'

/**
 * Created by Feedback on 25/10/18.
 * @param name
 * @param placeholder
 * @param value
 * @param onChange
 * @param options array of options for the select
 * @param disabled boolean to disable input
 * @param selectClasses array of classes for the select
 * @param fieldClasses array of classes for the field
 * @param inputClasses array of classes for the input
 * @param icon font awesome class of icon to display
 * @param iconPosition can receive 'is-left' or 'is-right' to position as class
 * @param disabledNull
 * @returns {*}
 * @constructor
 */
const Select = ({
                  name,
                  placeholder,
                  value = '',
                  onChange,
                  options,
                  disabled = false,
                  inputClasses,
                  fieldClasses,
                  columnClasses,
                  icon = null,
                  iconPosition = null,
                  disabledNull,
                }) => {
  return (
    <div className={`field ${fieldClasses}`}>
      <div className={`custom-field column ${columnClasses}`}>
        <div className="control select">
          <select
            name={name}
            className={`input ${inputClasses}`}
            value={value}
            onChange={onChange}
            disabled={disabled}
          >
            {!disabledNull &&
            <option value="">
              {placeholder}
            </option>
            }
            {options && options.map((option, key) => (
              <option
                key={option.value || option.id || key}
                value={option.value || option.id || key}
                disabled={option.disabled}
              >
                {option.text || option.name || option.agency_name || option}
              </option>))
            }
          </select>
        </div>
        {
          !!icon && (
            <span className={`icon ${iconPosition}`}>
            <i className={`fa ${icon}`} />
          </span>
          )
        }
      </div>
    </div>
  )
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  columnClasses: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  disabled: PropTypes.bool,
  disabledNull: PropTypes.bool,
  inputClasses: PropTypes.string,
  fieldClasses: PropTypes.string,
  icon: PropTypes.string,
  iconPosition: PropTypes.string,
};

Select.defaultProps = {
  placeholder: null,
  columnClasses: null,
  value: null,
  disabled: false,
  disabledNull: false,
  inputClasses: '',
  fieldClasses: '',
  icon: null,
  iconPosition: null,
};


export default Select;
