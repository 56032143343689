import React, { Component, Fragment }     from 'react';
import { withNamespaces }                 from 'react-i18next';
import {
  AmountFormat,
  ContentHeader,
  Filter, FormElement, TableList, TransactionRow, ExportExcel
}                                         from '../../components';
import { initTimer, stopTimer }           from '../../util/initTimer';
import {compose}                          from "redux";
import {connect}                          from "react-redux";
import {authActions}                      from "../../state/ducks/auth";
import {transactionsActions}              from "../../state/ducks/transactions";
import {formatDate}                       from "../../util";
import {toast}                            from "react-toastify";
import {TOAST_CONFIG, SIZE_PER_PAGE}                     from "../../config/constants";

class ListCommissions extends Component {
  constructor(props) {
    super(props);
    const date = new Date();
    this.state = {
      status: '',
      dateStart: new Date(date.getFullYear(), date.getMonth(), 1),
      dateEnd: new Date(date.getFullYear(), date.getMonth() + 1, 0),
      codeTransaction: '',
      page: 0,
    };
  }

  componentDidMount() {

    stopTimer();
    initTimer(this.props);

    const {fetchCommissionStatus, fetchCommissionTypes } = this.props;

    fetchCommissionTypes();
    fetchCommissionStatus();

    this.callApiList();

  }

  handleInputChange = (event) => {
  const {
    target: {
      value: targetValue, name,
    },
  } = event;
  this.setState({
    [name]: targetValue,
  });
};

  onChangeDate = (date, name) => {
    stopTimer();
    initTimer(this.props);
    const dateValue = date !== null ? date : '';
    this.setState({
      [name]: dateValue,
    });
  };

  onSubmit = (event) => {
    event.preventDefault();
    //this.onChangePage();
     this.callApiList();
  }

  callApiList = (getExcel = false) => {
    const {dateStart, dateEnd, status, codeTransaction, page} = this.state;
    const {
      t, showMessage, fetchListCommissions,
      transactions: { list_commissions: { count_transactions } },
    } = this.props;

    const data = {
      from: formatDate(dateStart),
      to: formatDate(dateEnd),
      status,
      transaction_code: codeTransaction,
      pageSize: !getExcel ? SIZE_PER_PAGE : count_transactions,
      page: getExcel ? 0 : page,
      export: getExcel,
    };

    fetchListCommissions({
      data,
      callback: (response) => {
        if (!response.success) {
          toast.dismiss();
          showMessage({
            message: t('connectionError'),
            config: TOAST_CONFIG.ERROR,
          });
        }
      },
    });

  }

  onChangePage = (selectPage) => {
    stopTimer();
    initTimer(this.props);
    this.setState(() => ({
      page: selectPage ? selectPage.selected : 0,
    }), () => {
      this.callApiList();
    });
  };

  render () {
    const { t, transactions: {list_commissions: {list, count_transactions}, commission_status,
      commission_types } } = this.props;
    const {
      dateStart, dateEnd, status, codeTransaction, page
    } = this.state;
    return (
      <Fragment>
        <div className='wow animated slideInDown'>
          <div className='wow animated slideInDown'>
            <Filter title={t('feesCollections')} onSubmit={(ev) => {this.onSubmit(ev);}}>
              <FormElement
                name='dateStart'
                onChange={ev => this.onChangeDate(ev, 'dateStart')}
                label={t('dateStart')}
                value={dateStart}
                typeElement="datepicker"
                maxDate={dateEnd || new Date()}
              />
              <FormElement
                name='dateEnd'
                onChange={ev => this.onChangeDate(ev, 'dateEnd')}
                value={dateEnd}
                label={t('dateEnd')}
                typeElement="datepicker"
                maxDate={new Date()}
                minDate={dateStart}
              />
              <FormElement
                name='status'
                onChange={this.handleInputChange}
                value={status}
                label={t('state')}
                 optionsSelect={commission_status}
                placeholder={t('selectState')}
                typeElement="select"
                controlClasses="select"
              />
              <FormElement
                name='codeTransaction'
                onChange={this.handleInputChange}
                value={codeTransaction}
                label={t('codeTransaction')}
              />
            </Filter>
          </div>
          <ContentHeader
            headingRightText={t('totalElements')}
            headingRightSubText={count_transactions}
          >
            <ExportExcel onclick={() => { this.callApiList(true) }}/>
            <TableList
              tableClasses="floating-table"
              totalElements={parseFloat(count_transactions)}
              onChangePage={this.onChangePage}
              forcePage={page}
              head={(<tr>
                <th>{t('date')}</th>
                <th>{t('transportCompany')}</th>
                <th>{t('cuit')}</th>
                <th>{t('codeTx')}</th>
                <th>{t('idSusa')}</th>
                <th>{t('accountPassenger')}</th>
                <th>{t('amount')}</th>
                <th>{t('commission')}</th>
                <th>{t('status')}</th>
              </tr>)}
              colNumber={10}
              lenghtData={count_transactions !== undefined ? count_transactions : 0}
            >
              {list && (list.map(row => (
                <TransactionRow
                key={row.id_account}
                helper={t('detailReport')}
                >
                  <td>{row.date}</td>
                  <td>{row.name}</td>
                  <td>{row.cuit}</td>
                  <td>{row.transaction_code}</td>
                  <td>{row.id_transaction}</td>
                  <td>{row.username_debit}</td>
                  <td>
                    <AmountFormat
                      name="amount"
                      value={row.amount}
                      displayType="text"
                      allowNegative
                    />
                  </td>
                  <td>
                    <AmountFormat
                      name="amount"
                      value={row.commission}
                      displayType="text"
                      allowNegative
                    />
                  </td>
                  <td>{row.statusText}</td>

                </TransactionRow>
              )))}
            </TableList>
          </ContentHeader>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = ({ auth, transactions }) => ({
  auth,
  transactions,

});

export default compose(connect(mapStateToProps, {
   ...authActions,
  ...transactionsActions,
}), withNamespaces())(ListCommissions);
