const START_FETCH = 'transactionsBind/START_FETCH';
const END_FETCH = 'transactionsBind/END_FETCH';
const END_ERROR = 'transactionsBind/END_ERROR';
const API_CALL = 'transactionsBind/API_CALL';
const FETCH_TRANSACTIONS_BIND = 'transactionsBind/FETCH_TRANSACTIONS_BIND';
const UPDATE_TRANSACTIONS_BIND = 'transactionsBind/UPDATE_TRANSACTIONS_BIND';
const MESSAGE = 'transactionsBind/MESSAGE';
const FETCH_TRANSACTIONS_LIST = 'transactionsBind/FETCH_TRANSACTIONS_LIST';
const FETCH_TRANSACTIONS_EXTERNAL_ACCOUNT = 'transactionsBind/FETCH_TRANSACTIONS_EXTERNAL_ACCOUNT';
const UPDATE_TRANSACTIONS_EXTERNAL_ACCOUNT = 'transactionsBind/UPDATE_TRANSACTIONS_EXTERNAL_ACCOUNT';
const FETCH_CAUSE_BIND = 'transactionsBind/FETCH_CAUSE_BIND';
const UPDATE_CAUSE_BIND = 'transactionsBind/UPDATE_CAUSE_BIND';
const FETCH_TRANSACTIONS_ALL_EXCEL = 'transactionsBind/FETCH_TRANSACTIONS_ALL_EXCEL';
const FETCH_CONFIRM_TRANSACTION = 'transactionsBind/FETCH_CONFIRM_TRANSACTION';
const FETCH_REJECTED_TRANSACTION = 'transactionsBind/FETCH_REJECTED_TRANSACTION';
const FETCH_SWITCH_TRANSACTIONS_INBOUND = 'transactionsBind/FETCH_SWITCH_TRANSACTIONS_INBOUND';
const FETCH_TRANSACTIONS_INBOUND = 'transactionsBind/FETCH_TRANSACTIONS_INBOUND';
const FETCH_TRANSACTIONS_OUTGOING = 'transactionsBind/FETCH_TRANSACTIONS_OUTGOING';
const UPDATE_TRANSACTIONS_OUTGOING = 'transactionsBind/UPDATE_TRANSACTIONS_OUTGOING';
const FETCH_TRANSACTIONS_REJECTED = 'transactionsBind/FETCH_TRANSACTIONS_REJECTED';
const UPDATE_TRANSACTIONS_REJECTED = 'transactionsBind/UPDATE_TRANSACTIONS_REJECTED';
const FETCH_TRANSACTIONS_APPROVED = 'transactionsBind/FETCH_TRANSACTIONS_APPROVED';
const UPDATE_TRANSACTIONS_APPROVED = 'transactionsBind/UPDATE_TRANSACTIONS_APPROVED';
const FETCH_TRANSACTIONS_OUT_REJECTED = 'transactionsBind/FETCH_TRANSACTIONS_OUT_REJECTED';
const UPDATE_TRANSACTIONS_OUT_REJECTED = 'transactionsBind/UPDATE_TRANSACTIONS_OUT_REJECTED';
const FETCH_TRANSACTIONS_OUT_APPROVED = 'transactionsBind/FETCH_TRANSACTIONS_OUT_APPROVED';
const UPDATE_TRANSACTIONS_OUT_APPROVED = 'transactionsBind/UPDATE_TRANSACTIONS_OUT_APPROVED';
const FETCH_APPROVED_TRANSACTION_OUT = 'transactionsBind/FETCH_APPROVED_TRANSACTION_OUT';
const FETCH_REJECTED_TRANSACTION_OUT = 'transactionsBind/FETCH_REJECTED_TRANSACTION_OUT';
const FETCH_TYPE_TRANSFERS_BIND = 'transactionsBind/FETCH_TYPE_TRANSFERS_BIND';
const UPDATE_TYPE_TRANSFERS_BIND = 'transactionsBind/UPDATE_TYPE_TRANSFERS_BIND';
const FETCH_STATUS_TRANSFERS_BIND = 'transactionsBind/FETCH_STATUS_TRANSFERS_BIND';
const UPDATE_STATUS_TRANSFERS_BIND = 'transactionsBind/UPDATE_STATUS_TRANSFERS_BIND';

export default {
  START_FETCH,
  END_FETCH,
  END_ERROR,
  API_CALL,
  MESSAGE,
  FETCH_TRANSACTIONS_BIND,
  UPDATE_TRANSACTIONS_BIND,
  FETCH_TRANSACTIONS_LIST,
  FETCH_TRANSACTIONS_EXTERNAL_ACCOUNT,
  UPDATE_TRANSACTIONS_EXTERNAL_ACCOUNT,
  FETCH_CAUSE_BIND,
  UPDATE_CAUSE_BIND,
  FETCH_TRANSACTIONS_ALL_EXCEL,
  FETCH_CONFIRM_TRANSACTION,
  FETCH_REJECTED_TRANSACTION,
  FETCH_SWITCH_TRANSACTIONS_INBOUND,
  FETCH_TRANSACTIONS_INBOUND,
  FETCH_TRANSACTIONS_OUTGOING,
  UPDATE_TRANSACTIONS_OUTGOING,
  FETCH_TRANSACTIONS_REJECTED,
  UPDATE_TRANSACTIONS_REJECTED,
  FETCH_TRANSACTIONS_APPROVED,
  UPDATE_TRANSACTIONS_APPROVED,
  FETCH_TRANSACTIONS_OUT_APPROVED,
  UPDATE_TRANSACTIONS_OUT_APPROVED,
  FETCH_TRANSACTIONS_OUT_REJECTED,
  UPDATE_TRANSACTIONS_OUT_REJECTED,
  FETCH_APPROVED_TRANSACTION_OUT,
  FETCH_REJECTED_TRANSACTION_OUT,
  FETCH_TYPE_TRANSFERS_BIND,
  UPDATE_TYPE_TRANSFERS_BIND,
  FETCH_STATUS_TRANSFERS_BIND,
  UPDATE_STATUS_TRANSFERS_BIND,
};
