import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withNamespaces} from 'react-i18next';
import './styles.scss';

class Dropzone extends Component {
  constructor(props) {
    super(props);
    this.state = {hightlight: false};
    this.fileInputRef = React.createRef();

    this.openFileDialog = this.openFileDialog.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  openFileDialog() {
    if (this.props.disabled) return;
    this.fileInputRef.current.click();
  }

  onFilesAdded(evt) {
    if (this.props.disabled) return;
    const files = evt.target.files;
    this.fileListToArray(files);
  }

  onDragOver(evt) {
    evt.preventDefault();
    if (this.props.disabled) return;
    this.setState({hightlight: true});
  }

  onDragLeave() {
    this.setState({hightlight: false});
  }

  onDrop(event) {
    event.preventDefault();
    if (this.props.disabled) return;
    const files = event.dataTransfer.files;
    this.fileListToArray(files);
    this.setState({hightlight: false});
  }

  fileListToArray(list) {
    const array = [];
    const {accept, onChange} = this.props;
    let format = '';
    for (let i = 0; i < list.length; i++) {
      format = list.item(i)
        .name
        .slice(-4);
      if (format === accept) {
        array.push(list.item(i));
      } else {
        onChange('');
        return;
      }
    }
    onChange(array);
  }

  render() {
    const {
      icon, textInformation, multiple, accept,
    } = this.props;
    return (
      <div
        className={`pt-80px pb-80px dropzone ${this.state.hightlight ? 'Highlight' : ''}`}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        onClick={this.openFileDialog}
        style={{cursor: this.props.disabled ? 'default' : 'pointer'}}
      >
        <input
          ref={this.fileInputRef}
          className="file-input"
          type="file"
          multiple={multiple}
          onChange={this.onFilesAdded}
          accept={accept}
        />
        <i className={`icon ${icon}`} aria-hidden="true"/>
        <span>{textInformation}</span>
      </div>
    );
  }
}

Dropzone.propTypes = {
  multiple: PropTypes.bool,
  textInformation: PropTypes.string,
  icon: PropTypes.string,
  accept: PropTypes.string,
  onChange: PropTypes.func,
};
Dropzone.defaultProps = {
  multiple: false,
  textInformation: '',
  icon: 'far fa-file',
  accept: '.csv',
  onChange: () => {
  },
};
export default withNamespaces()(Dropzone);
