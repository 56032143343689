import { agencyActions, agencyTypes } from '../ducks/agency';
import * as fileSaver                 from "file-saver";

const fetchStatusConciliate = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== agencyTypes.FETCH_STATUS_CONCILIATE) {
    return;
  }
  const { callback } = action.payload || {};
  dispatch({
    type: agencyTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: '/admin/list-status-conciliates',
      },
      authorization: true,
      onStart: agencyActions.startFetch,
      onComplete: ({ data }) => {
        dispatch(agencyActions.setStatusConciliate(data));
        //callback(data);
      },
      onError: (response) => {
        console.log(response);
        //callback(response);
      },
      onEnd: agencyActions.endFetch,
    },
  });
};

const fetchAgencyList = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== agencyTypes.FETCH_AGENCY_LIST) {
    return;
  }
  const { callback } = action.payload || {};
  dispatch({
    type: agencyTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: '/admin/get-agency-list',
      },
      authorization: true,
      onStart: agencyActions.startFetch,
      onComplete: ({ data }) => {
        dispatch(agencyActions.setAgencyList(data));
        //callback(data);
      },
      onError: (response) => {
        console.log(response);
        //callback(response);
      },
      onEnd: agencyActions.endFetch,
    },
  });
};

const fetchInfoAgencies = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== agencyTypes.FETCH_INFO_AGENCIES) {
    return;
  }
  const { data, callback, data: { export: exportExcel } } = action.payload || {};
  dispatch({
    type: agencyTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/admin/agencies-info',
        data,
        responseType: exportExcel ? 'blob' : 'json',
      },
      authorization: true,
      onStart: agencyActions.startFetch,
      onComplete: (responseData) => {
        if (exportExcel) {
          const blob = responseData.data
          const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fileSaver.saveAs(file, 'Información de agencias.xlsx');
        } else {
          const agencies = responseData.data.data;
          dispatch(agencyActions.setInfoAgencies({ data: agencies }));
          callback(responseData.data);
        }
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: agencyActions.endFetch,
    },
  });
};

const fetchTransactionsType = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== agencyTypes.FETCH_TRANSACTION_TYPE) {
    return;
  }
  const { callback } = action.payload || {};
  dispatch({
    type: agencyTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: '/agency/transaction-types',
      },
      authorization: true,
      onStart: agencyActions.startFetch,
      onComplete: ({ data }) => {
        dispatch(agencyActions.setTransactionsTypes(data));
      },
      onError: (response) => {
        console.log(response);
      },
      onEnd: agencyActions.endFetch,
    },
  });
};

const fetchTransactionsTypeAgencies = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== agencyTypes.FETCH_TRANSACTION_TYPE_AGENCIES) {
    return;
  }
  const { callback } = action.payload || {};
  dispatch({
    type: agencyTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: '/agency/transaction-types-account',
      },
      authorization: true,
      onStart: agencyActions.startFetch,
      onComplete: ({ data }) => {
        dispatch(agencyActions.setTransactionsTypesAgencies(data));
      },
      onError: (response) => {
        console.log(response);
      },
      onEnd: agencyActions.endFetch,
    },
  });
};

const fetchMovementAgencies = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== agencyTypes.FETCH_MOVEMENT_AGENCIES) {
    return;
  }
  const { data, callback, data: { export: exportExcel } } = action.payload || {};
  dispatch({
    type: agencyTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: `/admin/agency-transactions`,
        data,
        responseType: exportExcel ? 'blob' : 'json',
      },
      authorization: true,
      onStart: agencyActions.startFetch,
      onComplete: (responseData) => {
        if (exportExcel) {
          const blob = responseData.data
          const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fileSaver.saveAs(file, 'Movimientos de agencias.xlsx');
        } else {
          const movement = responseData.data.data;
          dispatch(agencyActions.setMovementAgencies({ data: movement }));
          callback(responseData.data);
        }
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: agencyActions.endFetch,
    },
  });
};

const fetchTransactionsList = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== agencyTypes.FETCH_TRANSACTIONS_LIST) {
    return;
  }
  const { data, callback, data: { export: exportExcel } } = action.payload || {};
  dispatch({
    type: agencyTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: `/admin/agency-transactions`,
        data,
        responseType: exportExcel ? 'blob' : 'json',
      },
      authorization: true,
      onStart: agencyActions.startFetch,
      onComplete: (responseData) => {
        if (exportExcel) {
          const blob = responseData.data
          const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fileSaver.saveAs(file, 'Cuenta, información de agencia.xlsx');
        } else {
          const account = responseData.data.data;
          dispatch(agencyActions.setTransactionsList({ data: account }));
          callback(responseData.data);
        }
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: agencyActions.endFetch,
    },
  });
};

const fetchBoxAgencyList = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== agencyTypes.FETCH_BOX_AGENCY_LIST) {
    return;
  }
  const {
    data, callback,
    data: { export: exportExcel }
  } = action.payload || {};
  dispatch({
    type: agencyTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: `/console/cash-list`,
        data,
        responseType: exportExcel ? 'blob' : 'json',
      },
      authorization: true,
      onStart: agencyActions.startFetch,
      onComplete: (responseData) => {
        if (exportExcel) {
          const blob = responseData.data
          const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fileSaver.saveAs(file, 'Cajas de la agencia.xlsx');
        } else {
          const boxAgency = responseData.data.data;
          dispatch(agencyActions.setBoxAgencyList({ data: boxAgency }));
          callback(responseData.data);
        }
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: agencyActions.endFetch,
    },
  });
};

const fetchStatusBox = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== agencyTypes.FETCH_STATUS_BOX) {
    return;
  }
  const { callback } = action.payload || {};
  dispatch({
    type: agencyTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: '/console/list-status-cash',
      },
      authorization: true,
      onStart: agencyActions.startFetch,
      onComplete: ({ data }) => {
        dispatch(agencyActions.setStatusBox(data));
      },
      onError: (response) => {
        console.log(response);
      },
      onEnd: agencyActions.endFetch,
    },
  });
};

const fetchLotsInfo = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== agencyTypes.FETCH_LOTSINFO_LIST) {
    return;
  }
  const { data, callback, data: { export: exportExcel } } = action.payload || {};
  dispatch({
    type: agencyTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/admin/agency-lots',
        data,
        responseType: exportExcel ? 'blob' : 'json',
      },
      authorization: true,
      onStart: agencyActions.startFetch,
      onComplete: (responseData) => {
        if (exportExcel) {
          const blob = responseData.data
          const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fileSaver.saveAs(file, 'Lotes, información de agencia.xlsx');
        } else {
          const lotsInfo = responseData.data.data;
          dispatch(agencyActions.setLotsInfo({ data: lotsInfo }));
          callback(responseData.data);
        }
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: agencyActions.endFetch,
    },
  });
};

const fetchDetailBoxAgency = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== agencyTypes.FETCH_DETAIL_BOX_AGENCY) {
    return;
  }
  const { data, callback } = action.payload || {};
  dispatch({
    type: agencyTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/console/info-cash',
        data,
      },
      authorization: true,
      onStart: agencyActions.startFetch,
      onComplete: ({ data }) => {
        dispatch(agencyActions.setDetailBoxAgency(data));
        callback(data);
      },
      onError: (response) => {
        console.log(response);
        //callback(response);
      },
      onEnd: agencyActions.endFetch,
    },
  });
};

const fetchStatusLot = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== agencyTypes.FETCH_STATUS_LOT) {
    return;
  }
  const { callback } = action.payload || {};
  dispatch({
    type: agencyTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: '/agency/list-status-lots',
      },
      authorization: true,
      onStart: agencyActions.startFetch,
      onComplete: ({ data }) => {
        dispatch(agencyActions.setStatusLot(data));
      },
      onError: (response) => {
        console.log(response);
      },
      onEnd: agencyActions.endFetch,
    },
  });
};

const fetchListSimpleRoles = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== agencyTypes.FETCH_STATUS_LOT) {
    return;
  }
  const { callback } = action.payload || {};
  dispatch({
    type: agencyTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: '/admin/list-simple-roles',
      },
      authorization: true,
      onStart: agencyActions.startFetch,
      onComplete: ({ data }) => {
        dispatch(agencyActions.setStatusLot(data));
      },
      onError: (response) => {
        console.log(response);
      },
      onEnd: agencyActions.endFetch,
    },
  });
};

const fetchCompanyTypes = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== agencyTypes.FETCH_COMPANY_TYPES) {
    return;
  }
  dispatch({
    type: agencyTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: '/api/list-person-types?business=1',
      },
      authorization: true,
      onStart: agencyActions.startFetch,
      onComplete: (responseData) => {
        dispatch(agencyActions.updateCompanyTypes(responseData.data));
      },
      onError: (response) => {
        console.log(response);
      },
      onEnd: agencyActions.endFetch,
    },
  });
};

const fetchListSimpleAgencies = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== agencyTypes.FETCH_LIST_SIMPLE_AGENCIES) {
    return;
  }
  const { callback } = action.payload || {};
  dispatch({
    type: agencyTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: '/admin/list-simple-agencies',
      },
      authorization: true,
      onStart: agencyActions.startFetch,
      onComplete: ({ data }) => {
        dispatch(agencyActions.updateListSimpleAgency(data));
      },
      onError: (response) => {
        console.log(response);
      },
      onEnd: agencyActions.endFetch,
    },
  });
};

const fetchCreateAgency = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== agencyTypes.FETCH_CREATE_AGENCY) {
    return;
  }
  const { data, callback } = action.payload || {};
  dispatch({
    type: agencyTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/admin/register-agency',
        data,
      },
      authorization: true,
      onStart: agencyActions.startFetch,
      onComplete: ({ data }) => {
        console.log(data);
        //dispatch(agencyActions.updatecreateAgency(data));
        callback(data);
      },
      onError: (response) => {
        console.log(response);
      },
      onEnd: agencyActions.endFetch,
    },
  });
};

const getAgencyList = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== agencyTypes.GET_AGENCY_LIST) {
    return;
  }
  const { data, callback, data: { export: exportExcel } } = action.payload || {};
  dispatch({
    type: agencyTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/console/get-agency-list',
        data,
        responseType: exportExcel ? 'blob' : 'json',
      },
      authorization: true,
      onStart: agencyActions.startFetch,
      onComplete: (responseData) => {
        if (exportExcel) {
          const blob = responseData.data
          const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fileSaver.saveAs(file, 'Listado de agencias.xlsx');
        } else {
          const listAgency = responseData.data.data;
          dispatch(agencyActions.updateAgencyList({ data: listAgency }));
          callback(responseData.data);
        }
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: agencyActions.endFetch,
    },
  });
};

const updateStateAgency = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== agencyTypes.UPDATE_STATE_AGENCY) {
    return;
  }
  const { data, callback } = action.payload || {};

  dispatch({
    type: agencyTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/console/update-agency-status',
        data,
      },
      authorization: true,
      onStart: agencyActions.startFetch,
      onComplete: (responseData) => {
        callback(responseData.data);
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: agencyActions.endFetch,
    },
  });
};

const fetchCreateOperator = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== agencyTypes.FETCH_CREATE_OPERATOR) {
    return;
  }
  const { data, callback } = action.payload || {};
  dispatch({
    type: agencyTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/admin/register-operator',
        data,
      },
      authorization: true,
      onStart: agencyActions.startFetch,
      onComplete: ({ data }) => {
        console.log(data);
        callback(data);
      },
      onError: (response) => {
        console.log(response);
      },
      onEnd: agencyActions.endFetch,
    },
  });
};

const fetchListRoles = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== agencyTypes.FETCH_LIST_ROLES) {
    return;
  }
  const { callback = () => { } } = action.payload || {};
  dispatch({
    type: agencyTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: '/admin/list-simple-roles'
      },
      authorization: true,
      onStart: agencyActions.startFetch,
      onComplete: ({ data: { data } }) => {
        dispatch(agencyActions.updateListRoles({ data }));
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: agencyActions.endFetch,
    },
  });
}

const fetchCreateTransportCompany = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== agencyTypes.FETCH_CREATE_TRASNPORT_COMPANY) {
    return;
  }
  const { data, callback } = action.payload || {};
  dispatch({
    type: agencyTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/admin/register-transport-company',
        data,
      },
      authorization: true,
      onStart: agencyActions.startFetch,
      onComplete: ({ data, success }) => {
        callback(data, success);
      },
      onError: (response) => {
        console.log(response);
      },
      onEnd: agencyActions.endFetch,
    },
  });
};

const updateExtractionAgency = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== agencyTypes.UPDATE_EXTRACTION_AGENCY) {
    return;
  }
  const { data, callback } = action.payload || {};

  dispatch({
    type: agencyTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/api/update-agency-extraction',
        data,
      },
      authorization: true,
      onStart: agencyActions.startFetch,
      onComplete: (responseData) => {
        callback(responseData.data);
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: agencyActions.endFetch,
    },
  });
};

export default [
  fetchStatusConciliate,
  fetchAgencyList,
  fetchInfoAgencies,
  fetchTransactionsList,
  fetchTransactionsType,
  fetchTransactionsTypeAgencies,
  fetchMovementAgencies,
  fetchBoxAgencyList,
  fetchStatusBox,
  fetchLotsInfo,
  fetchDetailBoxAgency,
  fetchStatusLot,
  fetchListSimpleRoles,
  fetchListSimpleAgencies,
  fetchCompanyTypes,
  fetchCreateAgency,
  getAgencyList,
  updateStateAgency,
  fetchCreateOperator,
  fetchListRoles,
  fetchCreateTransportCompany,
  updateExtractionAgency
];
