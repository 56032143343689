/* eslint-disable no-undef */
import React from 'react';
import './styles.scss';
import {confirmAlert} from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import {BrowserRouter, Link} from 'react-router-dom';
import {PrimaryButton} from '../components';
import {TIMESESSION} from '../config/constants';

let time = () => {};

export const initTimer = (props) => {
  const {t, logout} = props;
  const options = {
    customUI: ({onClose}) => (
      <div className="box-alert">
        <div>
          <p>{t('sessionExpired', {TIMESESSION})}</p>
        </div>
        <div className="btn-accept">
          <BrowserRouter>
            <Link to="/login">
              <PrimaryButton name={t('accept')} onClick={() => {

                onClose();
              }}/>
            </Link>
          </BrowserRouter>
        </div>
      </div>
    ),
    closeOnEscape: false,
    closeOnClickOutside: false,
  };

  time = setTimeout(() => {
    confirmAlert(options);
    logout();
  }, TIMESESSION * 60 * 1000);
  return time;
};

export const stopTimer = () => clearTimeout(time);
