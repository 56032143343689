import types from './types';
import {authTypes} from '../auth';
import {initialState} from '../../../App';

const serviceCharge = (state = {}, action) => {
    switch (action.type) {
        case authTypes.CLEAR: {
            return {
                ...state, ...initialState.serviceCharge,
            };
        }
        case types.START_FETCH: {
            return {
                ...state,
                loading: true,
            };
        }
        case types.END_FETCH: {
            const {error, payload} = action;
            return {
                ...state,
                loading: false,
                error: error ? payload : null,
            };
        }
        case types.SET_SERVICE_CHARGE: {
            const {data} = action.payload;
            return {
                ...state,
                list: data,

            };
        }
        case types.UPDATE_SERVICE_CHARGE: {
            const {payload} = action;
            return {
                ...state
            };
        }
        default:
            return state;
    }
};

export default serviceCharge;
