import types from './types';
import {authTypes} from '../auth';
import {initialState} from '../../../App';

const dashboard = (state = {}, action) => {
  switch (action.type) {
    case authTypes.CLEAR: {
      return {
        ...state, ...initialState.dashboard,
      };
    }
    case types.START_FETCH: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.END_FETCH: {
      const {error, payload} = action;
      return {
        ...state,
        loading: false,
        error: error ? payload : null,
      };
    }
    case types.UPDATE_DASHBOARD: {
      const {payload: {users, balance}} = action;
      return {
        ...state,
          balance,
          users,
      };
    }
    case types.UPDATE_BALANCE_BUSINESS: {
      const { data: { balance } } = action.payload;
      return {
        ...state,
          balanceBusiness: balance,
      };
    }
    default:
      return state;
  }
};

export default dashboard;
