import types            from './types';
import { initialState } from '../../../App';

const transactions = (state = {}, action) => {
  switch (action.type) {
    case types.CLEAR:
    {
      return {
        ...state, ...initialState.transactions,
      };
    }
    case types.START_FETCH:
    {
      return {
        ...state,
        loading: true,
      };
    }
    case types.END_FETCH:
    {
      const { error, payload } = action;
      return {
        ...state,
        loading: false,
        error: error ? payload : null,
      };
    }
    case types.UPDATE_TRANSACTIONS:
    {
      const {
        payload: {
          transactions, count_transactions, amount_total, date_now,
        },
      } = action;
      return {
        ...state,
        account_detail: {
          list: transactions,
          count_transactions,
          amount_total,
        },
      };
    }
    case types.UPDATE_MULTIPLE_TRANSACTION:
    {
      const { payload: { list } } = action;
      return {
        ...state,
        multipleList: list,
      };
    }
    case types.UPDATE_TRANSACTION_LOT:
    {
      const {
        payload: {
          transaction_lot: {
            id_lot_transaction, ok, fail, status, file_name, total_register,
          },
        },
      } = action;
      return {
        ...state,
        lot_process: {
          id_lot_transaction,
          ok,
          fail,
          status,
          fileName: file_name,
          total_transfer: total_register,
        },
      };
    }
    case types.UPDATE_LIST_MULTIPLE_TRANSACTION:
    {
      const {
        payload: {
          aux,
          lots,
          cant_lots,
        },
      } = action;
      const {
        id, ok, fail, status, name_file, created_at, total_register,
      } = aux || '';
      return {
        ...state,
        lot_process: {
          id_lot_transaction: id,
          created_at,
          ok,
          fail,
          status,
          fileName: name_file,
          total_transfer: total_register,
        },
        list_lot_process: {
          list: lots || [],
          count_total: cant_lots || 0,
        },
      };
    }
    case types.UPDATE_ELEMENT_MULTIPLE_TRANSACTION:
    {
      const {
        payload: {
          transactions_fail,
          transfer_processing,
          transactions_ok: { count_transactions: { count }, transactions },
        },
      } = action;
      const error = transactions_fail.length;
      const total = parseInt(count) + parseInt(error);
      const list = transactions_fail.concat(transactions);
      if (transactions.length > 0) {
        return {
          ...state,
          lot_process_detail: {
            count_total: total,
            list,
            error,
            process: count,
          },
        };
      }
    }
    case types.UPDATE_ELEMENT_LIST_MULTIPLE_TRANSACTION:
    {
      const {
        payload: {
          transactions_fail,
          transactions_ok: { count_transactions: { count }, transactions },
        },
      } = action;
      const error = transactions_fail.length;
      const total = parseInt(count) + parseInt(error);
      const list = transactions_fail.concat(transactions);
      return {
        ...state,
        list_lot_process_detail: {
          count_total: total,
          list,
          error,
          process: count,
        },
      };
    }
    case types.UPDATE_CHARGE:
    {
      const { payload: { charge } } = action;
      return {
        ...state,
        charge,
      };
    }
    case types.UPDATE_BALANCE:
    {
      const { payload: { currency, balance } } = action;
      return {
        ...state,
        currency,
        balance,
      };
    }
    case types.UPDATE_BALANCE_FUNDRAISER:
    {
      const { payload: { balance } } = action;
      return {
        ...state,
        balanceAccountFundraiser: balance,
      };
    }
    case types.UPDATE_LIST_PRISMA:
    {
      const { data: { count, transactions } } = action.payload;
      return {
        ...state,
        list_prisma_transaction: {
          list: transactions,
          count_total: count,
        },
      };
    }
    case types.UPDATE_LIST_STATE_PRISMA:
    {
      const { payload } = action;
      return {
        ...state,
        list_state_prisma: payload,
      };
    }
    case types.UPDATE_TRANSACTION_TYPES:
    {
      const { payload } = action;
      return {
        ...state,
        transaction_types: payload,
      };
    }
    case types.UPDATE_LIST_DONGLE:
    {
      const { data: { data } } = action.payload;
      return {
        ...state,
        list_dongle_payment: {
          list: data.transactions || [],
          count_total: data.count_transactions || 0,
        },
      };
    }
    case types.UPDATE_LIST_DONGLE_EXCEL:
    {
      const { headers, title, rows } = action.payload;
      return {
        ...state,
        list_dongle_payment_excel: {
          title,
          headers,
          rows,
        },
      };
    }
    case types.SET_LIST_ALL_PRISMA:
    {
      const { data: { count, transactions } } = action.payload;
      return {
        ...state,
        all_prisma_transaction: transactions,
      };
    }
    case types.SET_TRANSACTIONS_SUSA:{
      const { data: { total, results } } = action.payload;
      return {
        ...state,
        list_susa:{
          count: total,
          list: results,
        }
      }
    }
    case types.SET_LIST_COMMISSIONS:{
      const { data: { total, results } } = action.payload;
      return {
        ...state,
        list_commissions:{
          list: results,
          count_transactions: total,
        }
      }
    }
    case types.SET_COMMISSION_STATUS:
    {
      const { data } = action.payload;
      return {
        ...state,
        commission_status: data,
      };
    }
    case types.SET_COMMISSION_TYPES:{
      const { data } = action.payload;
      return {
        ...state,
        commission_types: data,
      }
    }
    case types.UPDATE_EXTRACTIONS_LIST:{
      const { data: { results, total } } = action.payload;
      return {
        ...state,
        extractions: {
          list: results,
          totalElements: total,
        }
      }
    }
    case types.UPDATE_STATUS_EXTRACTION: {
      const { data } = action.payload;
      return {
        ...state, status_extraction: data
      }
    }
    case types.UPDATE_TYPE_USER_EXTRACTION: {
      const { data } = action.payload;
      return {
        ...state, typeUserExtraction: data
      }
    }
    case types.UPDATE_QR_OPERATIONS_LIST: {
      const { data: { results, total } } = action.payload;
      return {
        ...state,
        paymentsQr: {
          list: results,
          totalElements: total,
        }
      }
    }
    case types.UPDATE_STATUS_QR: {
      const { data } = action.payload;
      return {
        ...state, statusQr: data
      }
    }
    case types.UPDATE_TYPE_USER_QR: {
      const { data } = action.payload;
      return {
        ...state, typeUserQr: data
      }
    }
    default:
      return state;
  }
};

export default transactions;
