// import { MAX_AGE } from '../config/constants';
//
// export const evaluateAgeFromDateBirthdate = (date) => {
//   console.log(date);
//   let fecha = date.format('YYYY-MM-DD')
//                   .split('-');
//   let dia = fecha[2];
//   let mes = fecha[1];
//   let ano = fecha[0];
//   let fecha_hoy = new Date();
//   let ahora_ano = fecha_hoy.getYear();
//   let ahora_mes = fecha_hoy.getMonth() + 1;
//   let ahora_dia = fecha_hoy.getDate();
//   let edad = (
//                ahora_ano + 1900
//              ) - ano;
//   if (ahora_mes < mes) {
//     edad--;
//   }
//   if ((
//         mes == ahora_mes
//       ) && (
//         ahora_dia < dia
//       )) {
//     edad--;
//   }
//   if (edad > 1900) {
//     edad -= 1900;
//   }
//   console.log(edad, MAX_AGE);
//   return edad >= MAX_AGE;
// };

export const putZero = (number) => {
  return number < 10 ? `0${number}` : `${number}`;
};

export const getMonth = (month) => {
  const result = month + 1;

  return putZero(result);
};

export const formatDate = (date) => {
  if (date != undefined && date !== '')
    return `${date.getFullYear()}-${getMonth(date.getMonth())}-${putZero(date.getDate())}`;
  return date;
};

export const fullDateTrunc = (date) => {
  const arrayResult = date.split(' ');
  return arrayResult.length > 0 ? arrayResult[0] : date;
};

// Helper function for insert a node after other
export const insertAfter = (el, referenceNode) => {
  referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
};

export const formatAmountRefactor = (amount) => {

  if (amount !== undefined && amount !== null) {
    const amountStr = amount.toString();
    if (amountStr.indexOf('AR$') > -1) {
      const valueFormat = amountStr.split(' ');
      amount = valueFormat[1].replace('.', ',');
    } else {
      amount = amountStr.replace('.', ',');
    }
  }

  return amount;
};

export const listYears = () => {
  const startYear = 2020;
  const currentYear = (new Date()).getFullYear();
  const years = [];
  for (let i = startYear; i < (5 + (currentYear - startYear) + startYear); i++) {
    years.push(i);
  }
  return years;
}

export const formatDateEn = (date) => {
  var t = new Date(date * 1000)
  var dateF = `${t.getFullYear()}-${getMonth(t.getMonth())}-${putZero(t.getDate())}`;
  return dateF;

};

export const checkPhoneNumber = (phoneNumber) => {
  if (!phoneNumber || phoneNumber === '') {
    return;
  }
  if (phoneNumber.includes('+54')) {
    return phoneNumber;
  } else {
    return `+54${phoneNumber}`;
  }
}