const START_FETCH = 'card/START_FETCH';
const END_FETCH = 'card/END_FETCH';
const API_CALL = 'card/API_CALL';
const MESSAGE = 'card/MESSAGE';
const FETCH_CARD_DATA = 'card/FETCH_CARD_DATA';
const UPDATE_CARD_DATA = 'card/UPDATE_CARD_DATA';
const RECHARGE_CARD = 'card/RECHARGE_CARD';


export default {
  START_FETCH,
  END_FETCH,
  API_CALL,
  MESSAGE,
  FETCH_CARD_DATA,
  UPDATE_CARD_DATA,
  RECHARGE_CARD,
};
