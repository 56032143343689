const es = {

  translations: {
    _transaction: 'Transacción #',
    accept: 'Aceptar',
    acceptTerms: 'Debe aceptar los términos y condiciones',
    account: 'Cuenta',
    accountDestination: 'Cuenta Destino',
    accountPlural: 'Cuentas',
    accountSource: 'Cuenta Origen',
    action: 'Acción',
    activate_button: 'Activado',
    addComment: 'Agregar comentario ...',
    addFileCsv: 'Añadir archivo CSV',
    alias: 'Alias',
    amount: 'Monto',
    amountError: 'El monto mínimo no puede ser mayor al monto máximo',
    amountMoney: 'Monto de dinero',
    amountRange: 'Rango de monto',
    approvedTransfers: 'Transferencias aprobadas',
    april: 'Abril',
    august: 'Agosto',
    available: 'Disponible',
    balance: 'Saldo',
    balanceAvailable: 'Saldo Disponible Cuenta Recaudadora',
    balanceAccountFundraiser: 'Balance en Cuentas Virtuales',
    beneficiaries: 'Beneficiarios',
    beneficiary: 'Beneficiario',
    beneficiaryNoFound: 'Beneficiario no encontrado, verifique el número de cuenta',
    bindCode: 'Código BIND',
    businessAdmin: 'Admin. de Negocio',
    businessName: 'Razón Social',
    calendar: 'Calendario',
    cameCode: 'Código SUSA',
    cbuCvuReceiver: 'CBU/CVU del destinatario',
    cbuOrAlias: 'CBU/CVU o alias',
    changePassword: 'Cambio de contraseña',
    charge: 'Cargo',
    close: 'Cerrar',
    codBind: 'Cód. Came',
    codCame: 'Cód. Bind',
    codeArea: 'cód. área + n˚ teléfono',
    codeofTransaction: 'Código de transacción',
    codeTransactionBind: 'Código de transacción BIND',
    completed: 'Completada',
    concept: 'Concepto',
    confirm: 'Confirmar',
    confirmationCode: 'Código de confirmación',
    confirmInformation: 'Confirmar datos',
    confirmPassword: 'Confirmar contraseña',
    confirmTransfer: 'Confirma tu transacción',
    connectionError: 'Error de conexión',
    continue: 'Continuar',
    cuit: 'CUIT',
    cvu: 'CVU',
    date: 'Fecha',
    dateMonth: 'Movimientos del mes',
    dateRange: 'Rango de fechas',
    debitCreditCommission: 'Comisión por Débito/Crédito BIND',
    december: 'Diciembre',
    deposit: 'Depositar',
    destinationAccount: 'Cuenta destino',
    destinationBank: 'Banco Destino',
    destinationUser: 'Usuario Destino',
    detail: 'Detalle',
    detailAccount: 'Detalle de la cuenta',
    detailMovement: 'Detalle del Movimiento',
    movementList: 'Lista de movimientos',
    detailReport: 'Detalle del reporte',
    detailTransaction: 'Detalle de Transacción',
    detailTransactionsClaim: 'Detalle del Reclamo por transacciones',
    detailUser: 'Detalle del Usuario',
    directory: 'Directorio',
    document: 'Documento',
    email: 'Correo electrónico',
    emptyFields: 'Debes completar todos los campos.',
    enter: 'Ingresar',
    enterCbuCvuUser: 'Ingresa CBU/CVU o alias válido',
    enterCodeSms: 'Verifica e ingresa el código\n que te enviamos vía SMS',
    entityDestination: 'Entidad de Destino',
    entitySource: 'Entidad de Origen',
    equalPassword: 'La contraseña nueva no debe coincidir con la anterior',
    errorTranfer: 'Error al transferir',
    extract: 'Extraer',
    extractAccount: 'Extracción en red Medullaris',
    extractDetail1: 'Código de aprobación generado exitosamente',
    extractDetail2: 'Monto a retirar',
    extractDetail3: 'En',
    extractDetail4: 'Completa la extracción en un punto de nuestra Red',
    extractDetail5: 'Con tu número de telefono',
    extractDetail6: 'Proporciona tu numero de telefono al operador del punto para completar la extraccion iniciada',
    extractDetail7: 'Código QR',
    extractDetail8: 'Preséntate con el siguiente código en el punto de la red',
    failed: 'Fallida',
    february: 'Febrero',
    fileName: 'Nombre del archivo',
    fileSuccess: 'El archivo fue cargado con éxito',
    filters: 'Filtros',
    finalized: 'Finalizado',
    finalizedErrors: 'Finalizado con errores',
    finish: 'Finalizar',
    forgotPassword: '¿Olvidó su clave?',
    fullName: 'Nombre y Apellido',
    nameOrLastName: 'Nombre o Apellido',
    in: 'Entrante',
    out: 'Saliente',
    generalInformation: 'Información General',
    goBack: 'Regresar',
    identifier: 'Identificador',
    import: 'Importe',
    individual: 'Individual',
    insufficientFunds: 'Fondos insuficientes',
    invalidAmount: 'Monto inválido',
    invalidCbu: 'CBU/CVU Inválido',
    invalidShortCbu: 'CBU/CVU debe contener 22 caracteres',
    january: 'Enero',
    july: 'Julio',
    june: 'Junio',
    lastMovements: 'Últimos Movimientos',
    legalRepresentant: 'Datos del Representante Legal',
    passengerData: 'Datos del Pasajero',
    listOperations: 'Lista de Operaciones',
    logout: 'Salir',
    lotList: 'Listado de lotes',
    makeNewTransfer: 'Realizar nueva transferencia',
    makeNewTransfers: 'Realizar nuevas transferencias',
    march: 'Marzo',
    may: 'Mayo',
    month: 'Mes',
    month01: 'Enero',
    month02: 'Febrero',
    month03: 'Marzo',
    month04: 'Abril',
    month05: 'Mayo',
    month06: 'Junio',
    month07: 'Julio',
    month08: 'Agosto',
    month09: 'Septiembre',
    month10: 'Octubre',
    month11: 'Noviembre',
    month12: 'Diciembre',
    movementCollectingAccount: 'Mov. Cta. Recaudadora',
    movementInformation: 'Datos del movimiento',
    multiple: 'Múltiple',
    name: 'Nombres',
    newPassword: 'Nueva contraseña',
    newRecharge: 'Nueva recarga',
    newTransfer: 'Nueva transferencia',
    next: 'Siguiente',
    nextPage: 'Siguiente',
    noBeneficiary: 'Sin beneficiario',
    noCard: 'No posee tarjeta prepagada',
    noData: 'Sin registro',
    none: 'Ninguno',
    nonMatchingPasswords: 'Las contraseñas ingresadas no coinciden',
    notAvailable: 'No Disponible',
    notifications: 'Notificaciones',
    noTransactions: 'Esta tarjeta no posee movimientos',
    november: 'Noviembre',
    october: 'Octubre',
    odBind: 'Cód. Came',
    originAccount: 'Cuenta origen',
    others: 'Otros',
    page: 'Página',
    password: 'Contraseña',
    payment_button: 'Botón de Pago',
    paymentAccount: 'Mov. Cta Recaudadora',
    paymentAccountP: 'Movimientos Cuenta Recaudadora',
    paymentButton: 'Botón de pago',
    paymentsTransfers: 'Pagos y Transferencias',
    personalData: 'Datos personales',
    prepaidCard: 'Tarjeta Prepagada',
    previous: 'Anterior',
    previous_page: 'Anterior',
    printQR: 'Imprimir QR',
    processing: 'Procesando',
    profile: 'Perfil',
    profileUser: 'Perfil de usuario',
    recharge: 'Recargar',
    recipient: 'Destinatario',
    recipientData: 'Datos del Destinatario',
    recoverPassword: 'Recuperar contraseña',
    rejectTransfers: 'Transferencias rechazadas',
    repeatPassword: 'Repita nueva contraseña',
    requiredAmount: 'Monto Requerido',
    requiredCaptcha: 'El captcha es requerido...',
    requiredCbu: 'CBU/CVU Requerido',
    requiredPassword: 'Contraseña no puede estar vacío',
    requiredUser: 'Usuario no puede estar vacío',
    return: 'Volver',
    showDetail: 'Ver detalles',
    showMovement: 'Ver movimientos',
    returnListTransactions: 'Volver al listado de transacciones',
    samePassword: 'La contraseña nueva debe coincidir',
    save: 'Guardar',
    search: 'Buscar',
    select: 'Seleccioná',
    selectMonth: 'Selecciona un mes',
    selectRecipient: 'Seleccionar destinatario',
    senderBank: 'Banco Remitente',
    senderData: 'Datos de Remitente',
    senderUser: 'Usuario Remitente',
    september: 'Septiembre',
    sessionExpired: 'Han transcurrido {{TIMESESSION}} minutos de inactividad,\nse cerró la sesión por seguridad.',
    settings: 'Ajustes',
    showQR: 'Mostrar código QR',
    successfulOperation: 'Operación realizada exitosamente',
    textFirst: 'Te enviaremos un código de confirmación al correo electrónico para cambiar tu contraseña',
    textSecond: 'Ingresa el código de confirmación',
    todayIs: 'Hoy es',
    total: 'Total',
    totalAmount: 'Importe total',
    totalToTransferred: 'Total a Transferir',
    totalTransactions: 'Total de Transacciones',
    totalTransferred: 'Total Transferido',
    totalTransfers: 'Total de transferencias',
    totalUsers: 'Total de usuarios',
    transactionOf: 'Transacciones de',
    transactions: 'Transacciones',
    transactionsBind: 'Transacciones BIND',
    transactionsClaim: 'Reclamo por transacciones',
    transactionsClaimsReport: 'Reporte de reclamos por transacciones',
    transactionType: 'Tipo de transacción',
    transfer: 'Transferir',
    transferCharge: 'Cargo de la operación',
    transferCvuCbu: 'Transferir a CBU/CVU',
    transferCvuCbuOrAlias: 'Transferir a CBU/CVU o Alias',
    transferDetail2: 'Comprobante',
    transferDetail3: 'Enviado a',
    transferDetail4: 'Monto enviado',
    transferDetail5: 'Monto total descontado',
    transferDetail: 'Transferencia Realizada',
    transfers: 'Transferencias',
    state: 'Estado',
    transferType: 'Tipo de transacción',
    type: 'Tipo',
    typeFileInvalid: 'Tipo de archivo inválido',
    updateInformation: 'Actualizar Información',
    user: 'Usuario',
    userAdmin: 'Admin. de Usuarios',
    userDataIncorrect: 'Los datos del usuarios son incorrectos',
    userInformation: 'Datos del usuario',
    users: 'Usuarios',
    userSupport: 'Soporte de Usuario',
    userWithoutAccount: 'Este usuario aun no ha validado su cuenta para acceder a esta opción',
    virtualAccount: 'Cuenta Virtual',
    welcome: 'Bienvenido (a)',
    fiscalData: 'Datos Fiscales',
    fiscalPosition: 'Posición Fiscal',
    invoiceType: 'Tipo de Factura',
    earnings: 'Ganancias',
    category: 'Categoría',
    saveFiscalData: 'Los datos fiscales se han actualizado',
    iva: 'Estado IVA',
    typerPerson: 'Tipo de Persona',
    instructionIva: 'El usuario ha seleccionado "Exento IVA", ¿Desea confirmarlo?',
    confirmIva: 'Cobro de IVA',
    confirmCommission: 'Cobro de Comisión CAME por transferencia o pago con cuenta CAME',
    commissions: 'Reporte Comisiones',
    transactionsCommission: 'Comisiones de las transacciones aprobadas',
    transactionsCommissionReport: 'Reporte de las comisiones de las transacciones aprobadas',
    inProgress: 'En Proceso',
    prismaTransaction: 'Transacciones Prisma',
    dateTransaction: 'Fecha de la transacción',
    createdDate: 'Fecha de alta',
    createdDateFrom: 'Fecha de alta desde',
    createdDateUntil: 'Fecha de alta hasta',
    dateRejected: 'Fecha trans. rechazada',
    clientCvu: 'CVU del Cliente',
    sellerCvu: 'CVU del Vendedor',
    clientDocument: 'Documento del Cliente',
    clientName: 'Nombre del Cliente',
    pending: 'Pendiente',
    payButton: 'Botón de Pago',
    payButtonList: 'Listado de Botón de Pago',
    chargeIva: 'Cargo + IVA',
    charge_in: 'Cargo de Servicio',
    transfer_reversed: 'Reverso de Transferencia',
    donglePayment: 'Dongle Pagos',
    donglePaymentList: 'Listado de pagos Dongle',
    username: 'Usuario',
    chargeTransaction: 'Cargo de la transaccion',
    totalTransaction: 'Total Transaccion',
    ammountTransaction: 'Monto de la transaccion',
    issuerName: 'Nombre del emisor',
    ppa: 'ppa',
    lastName: 'Apellidos',
    virtualAccountBalance: 'Balance Cuentas Virtuales',
    totalVirtualAccount: 'Total Cuentas virtuales',
    documentType: 'Tipo de Documento',
    paymentMethod: 'Método de pago',
    paymentButtonCode: 'Código del botón de pago',
    numberInstallments: 'Número de cuotas',
    identification: 'Identificación',
    senderName: 'Nombre del Remitente',
    brand: 'Marca',
    transactionApprovalNumber: 'Nro. de aprobación',
    documentVendor: 'Documento del vendedor',
    nameVendor: 'Nombre del vendedor',
    lastNameVendor: 'Apellido del vendedor',
    senderDocument: 'Documento del remitente',
    saveUserData: 'Se actualizaron correctamente los datos del usuario',
    saveUserDataError: 'Ocurrio un error al actualizar los datos',
    saveUserAliasData: 'El Alias del usuario se actualizó correctamente',
    saveUserAliasDataError: 'Ocurrio un error actualizando el alias del usuario',
    manualConciliation: 'Conciliación Manual',
    amountTransaction: 'Monto de la transacción',
    typeSale: 'Tipo de Venta',
    approvalNumber: 'Número de aprobación',
    typeCard: 'Tipo de tarjeta',
    buyerName: 'Nombre del comprador',
    referenceNumber: 'Número de referencia',
    channel: 'Canal',
    commissionCame: 'Comisión CAME Pagos',
    chargeFields: 'Introduzca valores que la suma de ambos sea menor o igual al monto de la transacción',
    solveProblems: 'Verificá para poder continuar',
    transactionsPendingToConciliate: 'Transacciones pendientes por conciliar',
    transactionsConciliateSuccess: 'Transacciones conciliadas exitosamente',
    transactionsConciliateFailed: 'Transacciones Fallidas al conciliar',
    detailTransactionsConciliate: 'Detalle de las transacciones conciliadas',
    reason: 'Razón',
    transactionConciliation: 'Transacciones por conciliar',
    transactionConciliate: 'Transacciones conciliadas',
    dateConciliation: 'Fecha Conciliación',
    commissionPrisma: 'Comisión Prisma',
    buyerDocument: 'Documento del Comprador',
    receiverDocument: 'Documento del Receptor',
    chargeService: 'Cargo por Servicios',
    service: 'Servicio',
    chargeTo: 'Cargo a',
    chargeCame: 'Cargo CAME Pago',
    debitCard: 'Tarjeta Debito',
    creditCard: 'Tarjeta Crédito',
    source: 'Remitente',
    receiver: 'Destinatario',
    edit: 'Editar',
    saveChargeService: 'Los cargos por servicio se actualizaron correctamente',
    cvuReceiver: 'CVU Destinatario',
    cvuSource: 'CVU Remitente',
    codNumApprove: 'Código o Nro. Aprobación',
    cvuSeller: 'CVU Vendedor',
    dateStart: 'Fecha Inicio',
    dateEnd: 'Fecha Fin',
    userState: 'Estado del usuario',
    activeUser: 'Usuario activo',
    inactiveUser: 'Usuario inactivo',
    active: 'Activo',
    inactive: 'Inactivo',
    authorization: 'Autorización',
    configurations: 'Configuraciones',
    holidays: 'Días feriados',
    holidaysAdmin: 'Administración de los Días feriados',
    description: 'Descripción',
    delete: 'Eliminar',
    addHoliday: 'Agregar Feriado',
    createHoliday: 'Crear Feriado',
    deleteHoliday: '¿Está seguro de eliminar este feriado?',
    yes: 'Si',
    holidaysTotal: 'Total de días feriados',
    receiverName: 'Nombre del Receptor',
    paymentType: 'Tipo de Pago',
    balanceCvu: 'Balance de las CVU',
    transactionsToConciliate: 'Transacciones por Conciliar',
    conciliate: 'Conciliar',
    amountToCredit: 'Monto a acreditar',
    year: 'Año',
    exportExcel: 'Exportar Excel',
    donglePaymentReport: 'Reporte de Dongle de Pago',
    transactionsExternalAccountsList: 'Listado de transacciones a cuentas externas',
    transactionsPerUserList: 'Listado de transacciones por usuario',
    bindResponse: 'Respuesta BIND',
    bindLog: 'Log BIND',
    causesBind: 'Causas BIND',
    senderBind: 'Enviado a BIND',
    receiverBind: 'Recibido de BIND',
    exento: 'Exento',
    collect: 'Cobrar',
    verificationNewUser: 'Verificación del nuevo usuario',
    verify: 'Verificá',
    reject: 'Rechazá',
    confirmStatusUser: '¿Estás seguro de {{state}} este usuario?',
    withoutValidation: 'Por validar',
    rejected: 'Rechazado',
    obligatoryField: 'Campo obligatorio',
    dateCta: 'Creación Cta',
    cancel: 'Cancelado',
    approved: 'Aprobado',
    pendding: 'Pendiente',
    switch: 'Switch',
    switchInbound: 'Entrantes BIND',
    switchOutbound: 'Salientes BIND',
    switchRejected: 'Entrantes BIND Rechazadas',
    switchApproved: 'Entrantes BIND Aprobadas',
    cbuCvuSender: 'CBU/CVU de origen',
    cvuSender: 'CVU de origen',
    cbuCvuAliasDestination: 'CBU/CVU - Alias destino',
    totalCommissions: 'Total Comisión',
    stateSwitch: 'Estado (Switch)',
    switchTransactionsOutgoingList: 'Listado de transacciones salientes BIND',
    switchTransactionsRejectedList: 'Listado de transacciones rechazadas BIND por web hook',
    switchTransactionsInboundList: 'Listado de transacciones entrantes BIND por web hook',
    switchTransactionsApprovedList: 'Listado de transacciones aprobadas BIND por web hook',
    confirmTransaction: 'Confirma la acreditación de los fondos para la transaccion identificada con {{code}}',
    rejectTransaction: 'Confirma rechazar la transacción de los fondos para la transaccion identicada con {{code}}',
    bank: 'Banco',
    errorfulOperation: 'Ocurrio un error al realizar la operación',
    successfulRejectedOperation: 'Transacción rechazada exitosamente',
    successfulApprovedOperation: 'Transacción aprobada exitosamente',
    dateApproved: 'Fecha trans. aprobada',
    selectTransactionContinue: 'Selecioná una transacción para continuar',
    bindDate: 'Fecha BIND',
    in_progress: 'En Proceso',
    confirmOutTransaction: 'Confirma la transferencia de fondos para la transaccion identificada con {{code}}',
    rejectOutTransaction: 'Confirma rechazar la transacción saliente identicada con {{code}}',
    dateStartBind: 'Fecha de inicio BIND',
    dateEndBind: 'Fecha de fin BIND',
    time: 'Tiempo',
    switchOutRejected: 'Salientes BIND Rechazadas',
    switchOutApproved: 'Salientes BIND Aprobadas',
    switchTransactionsOutRejectedList: 'Listado de transacciones salientes rechazadas BIND',
    switchTransactionsOutApprovedList: 'Listado de transacciones salientes aprobadas BIND',
    detailOutgoingTransaction: 'Detalle de la transacción saliente',
    stataUser: 'Estado del usuario',
    processingCVU: 'Procesando CVU',
    errorCVU: 'Error CVU',
    nearBusinessUser: 'Estado del usuario en Negocio Cercano',
    dateTransactionStart: 'Inicio de las transacciones',
    dateTransactionEnd: 'Fin de las transacciones',
    dateConciliationEnd: 'Fin de las conciliaciones',
    dateConciliationStart: 'Inicio de las conciliaciones',
    userToken: 'Token para Solicitudes de Pago',
    storeId: 'Identificador de la tienda',
    idStore: 'Id tienda',
    clientId: 'Identificador del cliente',
    showCurrentMonth: 'Por defecto se muestran las transacciones del mes actual',
    dateTransactionFrom: 'Fecha de la transacción desde',
    dateTransactionTo: 'Fecha de la transacción hasta',
    showCurrentDay: 'Por defecto se muestran las transacciones de hoy',
    addressInformation: 'Datos de ubicación',
    addressAgencyInformation: 'Datos de ubicación de la agencia',
    addressBusinessInformation: 'Datos de ubicación de la empresa',
    province: 'Provincia',
    location: 'Localidad /Departamento',
    address: 'Dirección',
    zipCode: 'Código Postal',
    phone: 'Telefóno',
    camera: 'Cámara/Integradores',
    rubro: 'Rubro',
    allAddressRequired: 'Todos los campos de la seccion de la ubicación son requeridos',
    detailAddress: 'Datos de ubicación',
    saveUserAddress: 'Se actualizaron correctamente los datos de ubicación',
    saveUserAddressError: 'Ocurrio un error al actualizar los datos de la ubicación',
    city: 'Ciudad',
    transactionsReport: 'Reporte de Transaccciones',
    balanceAccount: 'Balance de Cuentas Virtules',
    rates: 'Tarifas',
    newRate: 'Crear Tarifa',
    ratesTotal: 'Total de tarifas',
    destinyType: 'Tipo de destino',
    detailRate: 'Detalle de la tarifa',
    dateConfig: 'Fecha de configuración',
    allFieldsRequired: 'Todos los campos son requeridos',
    deleteRate: '¿Está seguro de eliminar esta tarifa?',
    userOperator: 'Admin. Operadores Consola',
    newUserOperator: 'Crear usuario',
    nameCuit: 'CUIT/CUIL',
    nameDni: 'DNI',
    samePassword2: 'Las contraseñas no coinciden',
    operatorSuccess: 'Se creó el operador correctamente',
    rateSuccess: 'Se creó la tarifa correctamente',
    rateEditSuccess: 'Se actualizó la tarifa',
    driver: 'Es Conductor',
    activeDriver: 'Usuario activo como conductor',
    inactiveDriver: 'Ususario no conductor',
    confirmChangeDriver: '¿Estás seguro de {{state}} el usuario como conductor?',
    activeDisableUSerSuccess: 'Se {{status}} el usuario correctamente',
    editRate: 'Editar tarifa',
    statusRate: 'Estado de la tarifa',
    activeDisableRate: 'Se {{status}} la tarifa correctamente',
    operatorsTotal: 'Total de operadores',
    balanceAccounts: 'Movimientos de las cuentas',
    conciliation: 'Conciliación',
    conciliations: 'Conciliaciones',
    conciliationList: 'Listado de conciliaciones',
    agencyName: 'Nombre de agencia',
    commission: 'Comisión',
    renderNumber: 'No. de lote',
    elementsTotal: 'Total de elementos',
    agency: 'Agencia',
    checkbooks: 'Talonarios',
    bankAccount: 'Cuenta Bancaria',
    balanceDeposited: 'Saldo Depositado',
    heelNumber: 'Número de talón',
    selectTimeZone: 'Seleccioná zona horaria',
    hour: 'Hora',
    numberBatches: 'Número de lote',
    depositWithdrawalMoney: 'Deposito / Retiro de dinero',
    typeOperation: 'Tipo de operación',
    depositMoney: 'Depósito de Dinero',
    withdrawalMoney: 'Retiro de Dinero',
    makeOperation: 'Realizar Operación',
    dataOperation: 'Datos de la operación',
    fieldRequiered: 'Todos los campos son requeridos',
    confirmOperation: 'Confirmación de los datos de la Operación',
    detailOperation: 'Detalle del ',
    adminRed: 'Admin Red SUSA',
    infoAgency: 'Información de agencias',
    selectDeal: 'Seleccioná Negocio',
    selectAgency: 'Seleccioná Agencia',
    correspondents: 'Corresponsales',
    transactionsType: 'Tipo de transacciones',
    transactionCode: 'Código de la transacción',
    selectAction: 'Seleccione Acción',
    seeMovements: 'Ver Movimientos',
    seeLote: 'Ver Lotes',
    seeBox: 'Ver Caja',
    operationsuccess: 'Operación Realizada Exitosamente',
    acceptConciliationconfirm: '¿Esta seguro de que desea Aceptar la conciliación?',
    rejectConciliationconfirm: '¿Esta seguro de que desea Rechazar la conciliación?',
    acceptConciliation: 'Conciliación exitosa',
    rejectConciliation: 'Conciliación rechazada',
    errorConciliation: 'Error al procesar la solicitud. Intentá más tarde.',
    buttonacceptConciliation: 'Aceptar conciliación',
    buttonrejectConciliation: 'Rechazar conciliación',
    viewReceipt: 'Ver comprobante',
    notAttached: 'Sin adjunto',
    selectAngency: 'Seleccioná agencia...',
    detailReceipt: 'Comprobante de depósito',
    withoutBatches: 'sin lotes',
    lots: 'Lotes',
    closed: 'cerrados',
    amountOf: 'Monto de',
    closedLots: 'lotes cerrados',
    commissionAmount: 'Monto de comisión',
    toReconcile: 'a conciliar',
    actions: 'Acciones',
    download: 'Download',
    see: 'Ver',
    operatingBalance: 'Saldo Operativo',
    statusAccountAgency: 'Estado de cuenta Agencia',
    accountMovements: 'Cuenta y Movimientos',
    balanceAgency: 'Saldo de Agencia',
    boxAgency: 'Cajas de la Agencia',
    DetailBoxAgency: 'Detalle de la Caja de la Agencia',
    DetailBoxAgencyOperator: 'Detalle de la Caja de la Agencia',
    totalBox: 'Total de Cajas ',
    operator: 'Operador',
    income: 'Ingresos',
    expenses: 'Egresos',
    balanceBox: 'Saldo en Caja',
    dateOpen: 'Fecha de Apertura',
    dateClose: 'Fecha de Cierre',
    numOperation: 'Cantidad de Operaciones',
    detailBox: 'Detalle de la Caja',
    seeDetail: 'Ver Detalle',
    selectStateBox: 'Seleccione el estado de la caja',
    stateBox: 'Estado de la caja',
    totalAgencies: 'Número de Agencias',
    infoLots: 'Información de Lotes',
    selectStateLot: 'Seleccione el estado del lote',
    stateLot: 'Estado del lote',
    numberLot: 'Número de lote',
    amountLot: 'Monto del lote',
    comissionLot: 'Comisión del lote',
    totalLot: 'Total del lote',
    totalTransactionsLot: 'Cantidad de TX por lote',
    downloadButton: 'Descargar',
    countofElements: 'Cantidad de elementos',
    selectStatusLot: 'Seleccioná Estado del lote',
    seeAccount: 'Ver Cuenta',
    userType: 'Tipo de usuario',
    dniCuit: 'DNI / CUIT',
    emailSender: 'Correo cuenta remitente',
    emailDestination: 'Correo cuenta destino',
    detailOperationTransaction: 'Detalle de Operación',
    dateHour: 'Fecha y Hora',
    dataTransaction: 'Datos generales de la transacción',
    dataUserSender: 'Datos Usuario Remitente',
    dataUserDestination: 'Datos Usuario Destino',
    summary: 'Resumen',
    typeTransacction: 'Tipo de Transacción',
    status: 'Estatus',
    adminNegocio: 'Admin Negocio',
    adminOfNegocio: 'Admin de Negocio',
    adminGeneral: 'Admin General',
    commissionList: 'Comisiones por pasaje',
    feesCollections: 'Comisiones por cobro de pasaje',
    selectState: 'Seleccioná estado...',
    typeTransaction: 'Tipo de Transacción',
    selectTransaction: 'Seleccioná Transacción...',
    transportCompany: 'Empresa de transporte',
    codeTx: 'Código de la TX',
    accountPassenger: 'Cuenta débito pasaje',
    notFound: 'No disponible',
    depositInformation: 'Información del Lote',
    amountDeposit: 'Monto del depósito',
    amountConciliate: 'Monto a Conciliar',
    amountLiquidate: 'Monto a Liquidar',
    welcomeTo: 'Bienvenido a la',
    consolaAdmin: 'Consola de Administración',
    urbanServices: 'Servicios Urbanos',
    notNumberLot: 'Sin número de lote',
    totalElements: 'Total de registros',
    idSusa: 'Cód. SUSA',
    statusPassenger: 'Estado pasajero',
    refCheckIn: 'Referencia checkin',
    finalAmount: 'Monto final de la operación',
    idDriver: 'Id Conductor',
    registerRedSusa: 'Registro Red SUSA',
    agencyRegistration: 'Registro de agencias',
    companyType: 'Tipo de empresa',
    country: 'País',
    companyData: 'Datos de la empresa',
    transportCompanyData: 'Datos de la empresa de transporte',
    newAgencyForm: 'Formulario de registro para nueva agencia',
    allRequiredFields: 'Todos los campos son obligatorios',
    agencyRolData: 'Seleccione Agencia y Rol del operador',
    listAgency: 'Listado de agencias',
    selectLocation: 'Seleccioná localidad...',
    operatorsList: 'Listado de operadores',
    nameOperator: 'Operador',
    rolOperator: 'Rol operador',
    selectRol: 'Seleccioná el rol',
    assignedAgency: 'Agencia asignada',
    companyList: 'Listado empresas de transporte',
    nameCompany: 'Empresa',
    nameAgency: 'Nombre de agencia',
    activeDisableAgencySuccess: 'Se {{status}} la agencia correctamente',
    selectProvince: 'Seleccioná provincia',
    resetPass: 'Restablecer Contraseña',
    confirmResetPassword: '¿Estás seguro de restablecer la contraseña a este operador?',
    passConfirm: 'Contraseña reestablecida! Por favor, revisar correo!',
    errorResetPasss: 'Error al actualizar contraseña!',
    totalAgencys: 'Total de agencias',
    companyTotal: 'Total de empresas',
    netSusa: 'Red SUSA',
    operatorRegister: 'Registro de operador',
    newOperatorForm: 'Formulario de registro para nuevo operador',
    operatorData: 'Datos del operador',
    agencyData: 'Datos de la agencia',
    editAgencyForm: 'Datos de agencia',
    rol: 'Rol',
    successfulRegister: 'Registro realizado exitosamente',
    companyRegistration: 'Registro de Empresa de Transporte',
    newCompanyForm: 'Formulario de registro para nueva empresa de transporte',
    editOperatorForm: 'Datos de operador',
    agencyRol: 'Agencia y Rol del operador',
    emailInvalid: 'Email invalido',
    statusLot: 'Estado del lote',
    typeTransfer: 'Tipo de Transferencia',
    codeBind: 'Código BIND',
    switchTitle: 'Switch Transferencias Entrantes y Salientes',
    dataGeneralTransaction: 'Datos Generales de la transacción',
    titleTransactionSwitch: 'Transacciones Switch',
    userDocumentation: 'Documentación del usuario',
    photography: 'Fotografía',
    documentation: 'Documentación',
    front: 'Frente',
    back: 'Dorso',
    logOperation: 'Log de operación',
    sendBind: 'Enviado a BIND',
    receiveBind: 'Recibido de BIND',
    titleErrorLog: 'Error en Log',
    errorLogBind: 'NO EXISTE LOG PARA ESTE SWITCH',
    activate: 'Activar',
    deactivate: 'Desactivar',
    codeTransaction: 'Código transacción',
    cvuAccSender: 'Cuenta Remitente',
    confirmUnsubscribeUser: '¿Estás seguro de dar de {{state}} este usuario?',
    unsubscribe: 'Dar de baja',
    balanceAccountSusa: ' Saldo Disponible Cuenta Susa',
    required: 'Obligatorio',
    motive: 'Motivo',
    notMotive: 'Sin motivo',
    listExtractions: 'Listado de Extracciones',
    accSender: 'Cuenta remitente',
    userRequest: 'Usuario solicitante',
    operationsQr: 'Operaciones con QR',
    extractions: 'Extracciones',
    detailExtraction: 'Detalle de Extracción',
    listPaymentsQr: 'Listado de pagos con QR',
    passenger: 'Pasajero',
    actives: 'activas',
    desactives: 'desactivadas',
    activeDisableExtractionSuccess: 'Se {{status}} extracciones correctamente',
    statesAccounts: 'Ver estados de cuentas',
    stateAccountsDeal: 'Estados de cuentas Negocio',
    typeAccount: 'Tipo de cuenta',
    deal: 'Negocio',
    comingSoon: 'Esta funcionalidad estará disponible próximamente',
    authExtraction: 'Autorizar extracción',
    amountDebit: 'Monto a Debitar',
    amountDebited: 'Monto debitado',
    acceptExtraction: '¿Está seguro de realizar esta operación?',
    successOperation: 'Operación realizada exitosamente',
    configChargesIva: 'Configuración de Cargos',
    config: 'Configuraciones',
    configChargeService: 'Configurar cargos del servicio',
    charges: '% Cargos',
    ivas: '% IVA',
    chargeCurrent: 'Cargo actual',
    ivaCurrent: 'Iva actual',
    servicePassenger: 'Servicios Pasajero',
    susa: 'SUSA',
    commissionPercent: '% Comision',
  },
};

export default es;
