import React              from 'react';
import PropTypes          from 'prop-types';
import { withNamespaces } from 'react-i18next';
import './styles.scss';

const NameAgency = (props) => {
  const { t, agency, balance } = props;
  const styleAgency = balance ? 'is-half' : 'is-12';
  return (
    <>
      <div className="columns is-marginless">
        <div className={`column ${styleAgency}`}>
          <div className='content-amount'>
            <div className='subtitle-dt-info'>{t('agency')}</div>
            <p className='amount-account-info'>{agency}</p>
          </div>
        </div>
        <div className="column is-half">
          {balance && (
            <div className='content-amount'>
              <div className='subtitle-dt-info'>{t('operatingBalance')}</div>
              <p className='amount-account-info dark-text'>{balance}</p>
            </div>
          )}
        </div>
      </div>
      <hr className="dots mb-16px" />
    </>
  );
}

NameAgency.propTypes = {
  t: PropTypes.func.isRequired,
  agency: PropTypes.element.isRequired,
  balance: PropTypes.func.isRequired,
};

export default withNamespaces()(NameAgency);
