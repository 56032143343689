import React                   from 'react';
import {Form, FieldDisplay, DefaultButton, Panel, AmountFormat} from "../../../../components";
import {withNamespaces}        from "react-i18next";
import './../../styles.scss'

const DetailDepositWithdrawalMoney = ({t, transaction, title, changeView, currency}) => {
  return (
    <Panel
      headingText={ t('detailOperation') + ' ' + title }
    >
      <div className="p-16px content-xl-center">

          <div className="is-full message-success">
                  <span>
                  {t('operationsuccess')}
                </span>
          </div>

        <Form
          submitText={t('return')}
          onSubmit={(ev) => changeView()}
          nameButton="configButton"
        >


          <div className="columns is-multiline">
              <FieldDisplay
                field={t('date')}
                fieldDetail={transaction.created_at}
                fieldDisplayClasses="column is-6-desktop"
              />
            <FieldDisplay
              field={t('amount') + ' ' + currency}
              fieldDetail={
                <AmountFormat
                  name="balance"
                  value={transaction.transaction_amount}
                  displayType="text"
                  allowNegative
                />
              }
              fieldDisplayClasses="column is-6-desktop"
            />
              <FieldDisplay
                field={t('codeTransaction')}
                fieldDetail={transaction.transaction_code}
                fieldDisplayClasses="column is-6-desktop"
              />
            </div>
          </Form>
        </div>
    </Panel>
  );
};


export default withNamespaces()(DetailDepositWithdrawalMoney);