import types            from './types';
import { authTypes }    from '../auth';
import { initialState } from '../../../App';

const transactionsBind = (state = {}, action) => {
  switch (action.type) {
    case authTypes.CLEAR:
    {
      return {
        ...state, ...initialState.transactionsBind,
      };
    }
    case types.START_FETCH:
    {
      return {
        ...state,
        loading: true,
      };
    }
    case types.END_FETCH:
    {
      const { error, payload } = action;
      return {
        ...state,
        loading: false,
        error: error ? payload : null,
      };
    }
    case types.UPDATE_TRANSACTIONS_BIND: {
      const { data: { data , date_init, countTransactions } } = action.payload;
      return {
      ...state, list: data || [],
        countTransactions : countTransactions || 0,
        date_init : date_init || {},
      }
    }
    case types.UPDATE_CAUSE_BIND:
    {
      const { data } = action.payload;
      return {
        ...state, causesBind: data,
      };
    }
    case types.UPDATE_TRANSACTIONS_EXTERNAL_ACCOUNT:
    {
      const { data: { count_registers, list } } = action.payload;

      return {
        ...state,
        transactionExternalAccount: {
          list,
          totalElements: count_registers,
        },
      };
    }
    case types.FETCH_TRANSACTIONS_ALL_EXCEL:
    {
      const { headers, title, rows } = action.payload;
      return {
        ...state,
        transactionsExcel: {
          title,
          headers,
          rows,
        },
      };
    }
    case types.FETCH_TRANSACTIONS_INBOUND:
    {
      const { data } = action.payload;
      return {
        ...state,
        transactionsInbound: {
          list: data.results ? data.results : [],
          countTransactions: data.total ? data.total.count : 0,
        },

      };
    }
    case types.UPDATE_TRANSACTIONS_OUTGOING:
    {
      const { data } = action.payload;
      return {
        ...state,
        transactionsOutgoing: {
          list: data.results ? data.results : [],
          totalElements: data.total ? data.total.count : 0,
        },

      };
    }
    case types.UPDATE_TRANSACTIONS_REJECTED:
    {
      const { data } = action.payload;
      return {
        ...state,
        transactionsRejected: {
          list: data.results ? data.results : [],
          totalElements: data.total ? data.total.count : 0,
        },

      };
    }
    case types.UPDATE_TRANSACTIONS_APPROVED:
    {
      const { data } = action.payload;
      return {
        ...state,
        transactionsApproved: {
          list: data.results ? data.results : [],
          totalElements: data.total ? data.total.count : 0,
        },

      };
    }
    case types.UPDATE_TRANSACTIONS_OUT_REJECTED:
    {
      const { data } = action.payload;
      return {
        ...state,
        transactionsOutRejected: {
          list: data.results ? data.results : [],
          totalElements: data.total.count ? data.total.count : 0,
        },

      };
    }
    case types.UPDATE_TRANSACTIONS_OUT_APPROVED:
    {
      const { data } = action.payload;
      return {
        ...state,
        transactionsOutApproved: {
          list: data.results ? data.results : [],
          totalElements: data.total ? data.total.count : 0,
        },

      };
    }
    case types.UPDATE_TYPE_TRANSFERS_BIND: {
      const { data } = action.payload;
      return {
        ...state, transfersTypes: data
      };
    }
    case types.UPDATE_STATUS_TRANSFERS_BIND: {
      const { data } = action.payload;
      return {
        ...state, statusBind: data
      };
    }
    default:
      return state;
  }
};

export default transactionsBind;
