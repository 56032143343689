import {dashboardActions, dashboardTypes} from '../ducks/dashboard';

const fetchDashboard = ({dispatch}) => next => (action) => {
  next(action);
  if (action.type !== dashboardTypes.FETCH_DASHBOARD) {
    return;
  }

  dispatch({
    type: dashboardTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: '/api/dashboard-info',
      },
      authorization: true,
      onStart: dashboardActions.startFetch,
      onComplete: ({data: {data}}) => {
        dispatch(dashboardActions.updateDashboard(data));
      },
      onEnd: dashboardActions.endFetch,
    },
  });
};

const fetchBalanceBusiness = ({dispatch}) => next => (action) => {
  next(action);
  if (action.type !== dashboardTypes.FETCH_BALANCE_BUSINESS) {
    return;
  }
    const { callback = () => {} } = action.payload || {};
  dispatch({
    type: dashboardTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: '/api/get-account-balance-business',
      },
      authorization: true,
      onStart: dashboardActions.startFetch,
      onComplete: ({ data: { success, data } }) => {
        console.log('SUSA', data);
        if (success) {
          dispatch(dashboardActions.updateBalanceBusiness({data}));
          callback();
        }
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: dashboardActions.endFetch,
    },
  });
};

export default [
  fetchDashboard,
  fetchBalanceBusiness,
];
