import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { toast } from 'react-toastify';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { authActions } from '../../../state/ducks/auth';
import { transactionsBindActions } from '../../../state/ducks/transactionBind';
import { initTimer, stopTimer } from '../../../util/initTimer';
import { TOAST_CONFIG } from '../../../config/constants';
import { formatDate } from '../../../util';
import { Link } from 'react-router-dom';
import {
  Loading,
  Filter,
  FormElement,
  ContentHeader,
  TableList,
  TransactionRow,
  ExportExcel,
  AmountFormat,
} from '../../../components';
import { confirmAlert } from 'react-confirm-alert';

const SIZE_PER_PAGE = 50;

class TransactionsBind extends Component {
  constructor (props) {
    super (props);
    this.state = {
      page: 0,
      dateStart: '',
      dateEnd: '',
      typeTransfer: '',
      status: '',
      transaction_id: '',
      disable: false,
    }
  }

  componentDidMount() {
    const { fetchTypeTransfersBind, fetchStatusTransferBind } = this.props;
    const { disable } = this.state;
    fetchTypeTransfersBind();
    fetchStatusTransferBind();
    stopTimer();
    initTimer(this.props);
    this.callApiList();
    if (disable === true) {
      return this.showMessageTransfer(this.message());
    }
  }

  showMessageTransfer = (message) => {
    const { t } = this.props;
    const options = {
      customUI: () => (
        <div className="information-alert message columns is-multiline">
          <div className="column is-full has-text-centered text-message">
            {message}
          </div>
          <div className="column is-full has-text-centered">
            <button
              className="button is-outlined"
              onClick={() => window.location.href = '/dashboard'}
            >
              {t('accept')}
            </button>
          </div>
        </div>
      ),
      closeOnEscape: false,
      closeOnClickOutside: false,
    };
    return (confirmAlert(options));
  }

  message = () => {
    const { t } = this.props;
    return (
      <div className='has-text-centered mt-16px'>
        <span>
          <i className='fas fa-chart-line has-text-success' /> {t('comingSoon')} <i className='fas fa-chart-line has-text-success' />
        </span>
      </div>
    );
  };

  callApiList = (getExcel = false) => {
    const {
      t, showMessage, fetchTransactionsBind,
      transactionsBind: { countTransactions, transfersTypes }
     } = this.props;
    const { dateStart, dateEnd, page, status, typeTransfer, transaction_id } = this.state;
    const transfer = transfersTypes.find(item => item.value === typeTransfer);
    fetchTransactionsBind({
      data: {
           pageSize: !getExcel ? SIZE_PER_PAGE : countTransactions,
           page: getExcel ? 0 : page,
           export: getExcel,
           date_from: formatDate(dateStart),
           date_to: formatDate(dateEnd),
           status,
           type: transfer !== undefined ? transfer.value : '',
           transaction_id,
      }, callback: (response) => {
        if (response) {
          if (!response.success) {
            toast.dismiss();
            showMessage({
              message: response.message ?? t('connectionError'),
              config: TOAST_CONFIG.ERROR,
            });
          }
        } else {
          toast.dismiss();
          showMessage({
            message: response.message ?? t('connectionError'),
            config: TOAST_CONFIG.ERROR,
          });
        }
      },
    });
  }

  onChangePage = (selectPage) => {
    stopTimer();
    initTimer(this.props);
    this.setState(() => ({
      page: selectPage ? selectPage.selected : 0,
    }), () => {
      this.callApiList();
    });
  };

  onChangeDate = (date, name) => {
    stopTimer();
    initTimer(this.props);
    const dateValue = date !== null ? date : '';
    this.setState({
      [name]: dateValue,
    });
  };

  handleInputChange = (event) => {
    stopTimer();
    initTimer(this.props);
    const { target: { value: targetValue, name } } = event;
    this.setState({
      [name]: targetValue,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.onChangePage();
    this.callApiList();
  };

  render () {
    const { t,
      transactionsBind: {
        list, countTransactions, loading, transfersTypes, statusBind
      }
    } = this.props;
    const { page, dateStart, dateEnd, typeTransfer, status, transaction_id } = this.state;
    return (
      <div>
        { loading && <Loading /> }
        <Fragment>
          <div className="wow animated slideInDown">
            <div className="wow animated slideInDown">
              <Filter
                title={t('transactionsBind')}
                onSubmit={(ev) => {this.onSubmit(ev);}}
              >
                <FormElement
                  name='dateStart'
                  onChange={ev => this.onChangeDate(ev, 'dateStart')}
                  label={t('createdDateFrom')}
                  value={dateStart}
                  typeElement='datepicker'
                  maxDate={dateEnd || new Date()}
                />
                <FormElement
                  name='dateEnd'
                  onChange={ev => this.onChangeDate(ev, 'dateEnd')}
                  value={dateEnd}
                  label={t('createdDateUntil')}
                  typeElement='datepicker'
                  maxDate={new Date()}
                  minDate={dateStart}
                />
                <FormElement
                  name='typeTransfer'
                  onChange={this.handleInputChange}
                  value={typeTransfer}
                  label={t('typeTransfer')}
                  optionsSelect={transfersTypes}
                  placeholder={t('select')}
                  typeElement="select"
                  controlClasses="select"
                />
                <FormElement
                  name='status'
                  onChange={this.handleInputChange}
                  value={status}
                  label={t('state')}
                  optionsSelect={statusBind}
                  placeholder={t('select')}
                  typeElement="select"
                  controlClasses="select"
                />
                <FormElement
                  name='transaction_id'
                  onChange={this.handleInputChange}
                  value={transaction_id}
                  label={t('codeBind')}
                  typeElement='input'
                />
              </Filter>
              <ContentHeader
                headingRightText={t('totalTransactions')}
                headingRightSubText={`${countTransactions}`}
              >
                <ExportExcel onclick={() => { this.callApiList(true) }} title='Transferencias BIND' />
                <TableList
                  tableClasses="floating-table"
                  totalElements={parseFloat(countTransactions)}
                  onChangePage={this.onChangePage}
                  forcePage={page}
                  head={(
                    <tr>
                      <th>{`${t('date')} / ${t('hour')}`}</th>
                      <th>{t('CUIT/CUIL')}</th>
                      <th>{t('codeTransaction')}</th>
                      <th>{t('codeBind')}</th>
                      <th>{`CVU/${t('cvuAccSender')}`}</th>
                      <th>{`CVU/CBU/${t('accountDestination')}`}</th>
                      <th>{t('amount')}</th>
                      <th>{t('typeTransfer')}</th>
                      <th>{t('state')}</th>
                      <th>{t('actions')}</th>
                    </tr>
                  )}
                  colNumber={9}
                  lenghtData={countTransactions !== undefined ? countTransactions : 0}
                >
                  {list && (list.map((bind, i) => (
                    <TransactionRow key={i}>
                      <td>{bind.date}</td>
                      <td>{bind.doc_user_susa}</td>
                      <td>{bind.susa_code === null ? '-': bind.susa_code}</td>
                      <td>{bind.id}</td>
                      <td>{bind.originData[0].account}</td>
                      <td>{bind.destinationData[0].account}</td>
                      <td>
                        <AmountFormat
                          name="amount"
                          value={bind.amount[0].value}
                          currency={'AR$'}
                          displayType="text"
                          allowNegative
                        />
                      </td>
                      <td>{bind.type}</td>
                      <td>{bind.status}</td>
                      <td>
                        <Link to={{
                          pathname: '/detail-transaction-bind',
                          state: { bind: bind }
                        }}
                        >
                          <button
                            className='button is-outlined is-small'
                            >
                            {t('showDetail')}
                          </button>
                        </Link>
                      </td>
                    </TransactionRow>
                  )))}
                </TableList>
              </ContentHeader>
            </div>
          </div>
        </Fragment>
      </div>
    )
  }
}

TransactionsBind.propTypes = {
  t: PropTypes.func.isRequired,
  showMessage: PropTypes.func,
  fetchTypeTransfersBind: PropTypes.func,
  fetchStatusTransferBind: PropTypes.func,
  fetchTransactionsBind: PropTypes.func.isRequired,
};

TransactionsBind.defaultProps = {
  showMessage: () => {},
  fetchTransactionsBind: () => {},
  fetchTypeTransfersBind: () => {},
  fetchStatusTransferBind: () => {},
};

const mapStateToProps = ({ auth, transactionsBind }) => (
  { auth, transactionsBind }
);

export default compose(connect(
    mapStateToProps, {
      ...authActions,
      ...transactionsBindActions,
  }), withNamespaces())(TransactionsBind);
