import React, { Component }    from 'react';
import { compose }             from 'redux';
import './styles.scss';
import { connect }             from 'react-redux';
import { withNamespaces }      from 'react-i18next';
import { Panel }               from '../../components';
import { transactionsActions } from '../../state/ducks/transactions';
import { cardActions }         from '../../state/ducks/card';
import { authActions }         from '../../state/ducks/auth';
import PropTypes               from 'prop-types';
import {usersActions} from "../../state/ducks/users";

class NotAvailable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      t, auth: { user: { date_now } },
    } = this.props;

    return (
      <div className="columns is-multiline">
        <div className="column is-10 is-offset-1 image is-centered">
          <Panel headingText={t('notAvailable')} dateNow={date_now} viewBalance={false}>
            <div className="columns is-multiline">
              <div className="column is-4 is-offset-4 image is-centered">
                <img src="./logo-xx.png" alt="logo" />
              </div>
              <div className="column is-full is-centered">
                <h2>Esta funcionalidad no está disponible en esta versión.</h2>
              </div>
            </div>
          </Panel>
        </div>
      </div>
    )
  }
}

NotAvailable.propTypes = {
  t: PropTypes.func.isRequired,
  users: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({}),
  ]).isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = ({ users, auth }) => (
  {
    users,
    auth,
  }
);
export default compose(
  connect(mapStateToProps, {
    ...usersActions,
    ...authActions,
  }),
  withNamespaces(),
  )(NotAvailable);

