import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Filter, DefaultButton, Panel } from '../../../components';
import { initTimer, stopTimer } from "../../../util/initTimer";
import { compose } from "redux";
import { connect } from "react-redux";
import { switchActions } from "../../../state/ducks/switch";
import { TOAST_CONFIG } from "../../../config/constants";
import { toast } from "react-toastify";
import './style.scss';

export class DetailTransactionSwitch extends Component {
    constructor (props) {
      super (props);
      this.state = {
        idSwitch: '',
        detalleSwitch: '',
        switch: '',
      }
    }
    componentDidMount() {
      stopTimer();
      initTimer(this.props);
      const { location } = this.props;
      if (location.state !== undefined && location.state.id !== undefined && location.state.detalleSwitch !== undefined) {
      this.setState({ idSwitch: location.state.id, detalleSwitch: location.state.detalleSwitch },
        () => {
            this.callApiList()
        });
      }
    }
    callApiList = (id = 0) => {
      const { t, fetchDetailSwitch, showMessage } = this.props;
      const { idSwitch } = this.state;
      fetchDetailSwitch({
          id: idSwitch,
          callback: (response) => {
            this.setState({ loading: false });
            if (!response.success) {
                toast.dismiss();
            }
          },
      });
    };

  render () {
    const { idSwitch, detalleSwitch } = this.state;
    const { t, switchs: { detailswitch: { receive, send } } } = this.props;
    return (
        <div>
          <Fragment>
            <div className="wow animated slideInDown">
              <div className="wow animated slideInDown">
                <Filter title={t('titleTransactionSwitch')} subtitle={t('logOperation')}/>
                <Link to={{ pathname: '/switch' }}>
                  <DefaultButton name={t('goBack')} />
                </Link>
                <Panel headingText={t('logOperation')}>
                  <div className="column is-full">

                    <div className="column is-full">
                      <h4>{t('dataGeneralTransaction')}</h4>
                      <hr className="dots" />
                    </div>

                    <div className="columns">
                      <div className="column title-left"><span className="title-bold">{t('dateHour')}:</span> { detalleSwitch.created_at }</div>
                      <div className="column title-right"> </div>
                    </div>

                    <div className="columns">
                      <div className="column title-left"><span className="title-bold">{t('codeBind')}:</span> { detalleSwitch.code_bind }</div>
                      <div className="column title-right"><span className="title-bold">{t('cameCode')}:</span> { detalleSwitch.transaction_code }</div>
                    </div>

                    <div className="columns">
                      <div className="column title-left"><span className="title-bold">{t('status')}:</span> { detalleSwitch.status }</div>
                      <div className="column title-right"><span className="title-bold">{t('amount')}:</span> AR$ { detalleSwitch.amount }</div>
                    </div>

                    <div className="column is-full">
                      <h4>{t('dataUserSender')}</h4>
                      <hr className="dots" />
                    </div>

                    <div className="columns">
                      <div className="column title-left"><span className="title-bold">{t('senderUser')}:</span> { detalleSwitch.source }</div>
                      <div className="column title-right"><span className="title-bold">{t('senderBank')}:</span> { detalleSwitch.source_bank }</div>
                    </div>

                    <div className="columns">
                      <div className="column title-left"><span className="title-bold">{t('originAccount')}:</span> { detalleSwitch.account_s }</div>
                      <div className="column title-right"><span className="title-bold">{t('document')}:</span> { detalleSwitch.documento_o }</div>
                    </div>

                      <div className="column is-full">
                        <h4>{t('dataUserDestination')}</h4>
                        <hr className="dots" />
                      </div>

                      <div className="columns">
                        <div className="column title-left"><span className="title-bold">{t('destinationUser')}:</span> { detalleSwitch.destinatary }</div>
                        <div className="column title-right"><span className="title-bold">{t('destinationBank')}:</span> { detalleSwitch.destinatary_bank }</div>
                      </div>

                      <div className="columns">
                        <div className="column title-left"><span className="title-bold">{t('accountDestination')}:</span> { detalleSwitch.account_d }</div>
                        <div className="column title-right"><span className="title-bold">{t('document')}:</span> { detalleSwitch.documento_d }</div>
                      </div>

                      {(send !== undefined) ?
                        <>
                          <div className="column is-full">
                            <h4>{t('Log')}</h4>
                            <hr className="dots" />
                          </div>

                          <div className="title-detail-bind">{ t('sendBind') }</div>
                          {send.map (row => (
                            <div className="detail-bind">{ row }</div>
                          ))}

                          <div className="title-detail-bind">{ t('receiveBind') }</div>
                          {receive.map (row => (
                            <div className="detail-bind">{ row }</div>
                          ))}
                        </>
                        :
                        <div className="column is-full">
                          <h4>{t('titleErrorLog')}</h4>
                          <hr className="dots" />
                          <div className="detail-error">
                              {t('errorLogBind')}
                          </div>
                        </div>
                      }
                  </div>
                </Panel>
              </div>
            </div>
          </Fragment>
        </div>
    );
  }
}

DetailTransactionSwitch.propTypes = {
    t: PropTypes.func.isRequired,
    fetchDetailSwitch: PropTypes.func,
};


const mapStateToProps = ({ switchs }) => (
    { switchs }
);


export default compose(connect(mapStateToProps, {
    ...switchActions, }), withNamespaces())(DetailTransactionSwitch);
