import React, { Component, Fragment } from 'react';
import { withNamespaces }             from 'react-i18next';
import { connect }                    from 'react-redux';
import PropTypes                      from 'prop-types';
import { compose }                    from 'redux';
import { toast }                      from 'react-toastify';
import { confirmAlert }               from 'react-confirm-alert';
import { Link }                       from 'react-router-dom';
import {
  AmountFormat,
  ConfirmAlert,
  ContentHeader,
  DefaultButton,
  Filter,
  Loading,
  PrimaryButton,
  TableList,
  TransactionRow,
  UpdateImage,
}                                     from '../../../components';
import { initTimer, stopTimer }       from '../../../util/initTimer';
import { conciliationActions }        from '../../../state/ducks/conciliation';
import { usersActions }         from '../../../state/ducks/users';
import { TOAST_CONFIG }               from '../../../config/constants';
import './style.scss';
import '../style.scss';

class DetailConciliation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id_render: '',
      statusD: '',
      lotNumber: '',
      agency: '',
      dateStart: '',
      dateEnd: '',
      first: true,
      deposit: '',
    };
    this.viewDeposit = this.viewDeposit.bind(this);
  }

  componentDidMount() {
    const { location } = this.props;
    initTimer(this.props);
    stopTimer();
    if (location.state !== undefined && location.state.id !== undefined) {
      this.setState({
        id_render: location.state.id,
        statusD: location.state.status,
        lotNumber: location.state.lot,
        state: location.state.state,
        agency: location.state.agency,
        dateStart: location.state.dateStart,
        dateEnd: location.state.dateEnd,
        first: location.state.first,

      }, () => {
        this.callApiList();
      });
    }
  }

  callApiList = () => {
    const {
      t, fetchDetailToConciliate, showMessage,
    } = this.props;
    const { id_render } = this.state;

    fetchDetailToConciliate({
      data: {
        id_render,
      },
      callback: (response, success) => {
        if (!success) {
          showMessage({
            message: t('connectionError'),
            config: TOAST_CONFIG.ERROR,
          });
        }
      },
    });
  }

  confirmConciliation = (e) => {
    e.preventDefault();
    ConfirmAlert(
      { ...this.props },
      () => {
        const { t, fetchReconciled, showMessage } = this.props;
        const { id_render } = this.state;
        fetchReconciled({
          data: {
            id_render,
          },
          callback: ({ success, data }) => {
            if (success) {
              this.setState(() => ({
                message: t('acceptConciliation'),
                messageStyle: 'has-text-success',
              }));
              toast.dismiss();
              showMessage({
                message: t('acceptConciliation'),
                config: TOAST_CONFIG.SUCCESS,
              });
            } else {
              this.setState(() => ({
                message: t('errorConciliation'),
                messageStyle: 'has-text-danger',
              }));
              toast.dismiss();
              showMessage({
                message: t('errorConciliation'),
                config: TOAST_CONFIG.ERROR,
              });
            }
          },
        });
        setTimeout(() => {
          window.location.href = '/conciliate';
        }, 3000);
      }, 'acceptConciliationconfirm',
    );
  };

  rejectConciliation = (e) => {
    e.preventDefault();
    ConfirmAlert(
      { ...this.props },
      () => {
        const { t, fetchRejectConciliate, showMessage } = this.props;
        const { id_render } = this.state;
        fetchRejectConciliate({
          data: {
            id_render,
          },
          callback: ({ success, data }) => {
            if (success) {
              this.setState(() => ({
                message: t('rejectConciliation'),
                messageStyle: 'has-text-success',
              }));
              toast.dismiss();
              showMessage({
                message: t('rejectConciliation'),
                config: TOAST_CONFIG.WARNING,
              });
            } else {
              this.setState(() => ({
                message: t('errorConciliation'),
                messageStyle: 'has-text-danger',
              }));
              toast.dismiss();
              showMessage({
                message: t('errorConciliation'),
                config: TOAST_CONFIG.ERROR,
              });
            }
          },
        });
        setTimeout(() => {
          window.location.href = '/conciliate';
        }, 2000);
      }, 'rejectConciliationconfirm',
    );
  };

  getFile = (url, filed) => {
    const { t, fetchImg } = this.props;
    fetchImg({
      data: {
        url_file: url
      },
      callback: (response) => {
        this.setState({
          [filed]: URL.createObjectURL(response),
        }, () =>{
          this.viewDeposit();
        });
      },
    });
  }

  viewDeposit = () => {
    const { t } = this.props;
    const { deposit } = this.state;
    const options = {
      customUI: ({ onClose }) => (
        <div className="box-success columns is-multiline">
          <div className="content-box column is-full has-text-centered">
            <div className="columns is-centered">
              <div className="column is-full has-text-centered title-message">
                {t('detailReceipt')}
              </div>
            </div>
            <img src={deposit} border="0" className="img-view-deposit" />
            {/* <UpdateImage imagePreviewUrl={urlDeposit} /> */}
          </div>
          <div className="btn-accept column">
            {/*
                      <a href={urlDeposit} target="_blank" download onClick={() => this.downloadFile(urlDeposit)}>
                      */}
            <a href={deposit} target="_blank">
              <button className="button button-download">
                {' '}
                {t('see')}
              </button>
            </a>

            <PrimaryButton
              name={t('close')}
              onClick={() => {
                onClose();
              }}
            />
          </div>
        </div>
      ),
      closeOnEscape: false,
      closeOnClickOutside: false,
    };
    const time = setTimeout(() => {
      confirmAlert(options);
    }, 1000);
    return time;
  }

  render() {
    const {
      t, loading,
      conciliation: { conciliationDetail: { list_bank, list_registers, list_lots } },
    } = this.props;
    const {
      statusD, lotNumber, state, agency, dateStart, dateEnd, first,
    } = this.state;
    return (
      <div>
        {loading && <Loading />}
        <Fragment>
          <Filter title={t('conciliation')} />
          <ContentHeader
            headingText={
              <Link to={{
                pathname: '/conciliate',
                state: {
                  state,
                  agency,
                  dateStart,
                  dateEnd,
                  first,
                }
              }}
              >
                <DefaultButton name={t('goBack')} />
              </Link>
            }
          >
            {(list_registers === [] || list_registers !== [] && list_registers.length !== 0)
              ? (list_bank.length === 0)
                ? (
                  <div>
                    <div className="columns content-center">
                      <div className="column col-6 saldo-top">
                        <p className="title-bold">{t('amountLot')}:</p>
                        {list_lots[0] ?
                          <AmountFormat
                            name="balance"
                            value={list_lots[0].lot_balance}
                            name="amount_comission"
                            displayType="text"
                            allowNegative
                            currency={'AR$'}
                          />
                          : '-'}
                      </div>
                      <div className="column col-6 saldo-top"><p
                        className="title-bold">{t('amountLiquidate')}:</p> {list_lots[0] ?
                        <AmountFormat
                          name="balance"
                          value={list_lots[0].lot_commission}
                          name="amount_comission"
                          displayType="text"
                          allowNegative
                          currency={'AR$'}
                        />
                        : '-'}</div>
                    </div>
                    <div className="columns content-center"><p className="title-sub">{t('depositInformation')}</p></div>
                    <TableList
                      tableClasses="floating-table"
                      head={(
                        <tr>
                          <th>{t('dateHour')}</th>
                          <th>{t('agencyName')}</th>
                          <th>{t('numberBatches')}</th>
                          <th>{t('state')}</th>
                        </tr>
                      )}
                      colNumber={4}
                      lenghtData={1}
                    >
                      <TransactionRow>
                        <td>{list_lots[0] ? list_lots[0].lot_date : '-'}</td>
                        <td>{list_lots[0] ? list_lots[0].agency_name : '-'}</td>
                        <td>{(list_lots[0] && list_lots[0].lot_number !== null) ? list_lots[0].lot_number : '-'}</td>
                        <td>{(list_lots[0] && list_lots[0].status !== null) ? list_lots[0].status : '-'}</td>
                      </TransactionRow>
                    </TableList>
                  </div>
                )
                : (
                  <div>
                    <div className="columns content-center">
                      <div className="column col-6 saldo-top">
                        <p className="title-bold">
                          {t('amountDeposit')}
                          :
                        </p>
                        <AmountFormat
                          name="balance"
                          value={list_registers[0] ? list_registers[0].transaction_amount : '-'}
                          currency={list_registers[0] ? list_registers[0].product : '-'}
                          displayType="text"
                        />
                      </div>
                      <div className="column col-6 saldo-top">
                        <p className="title-bold">
                          {t('amountConciliate')}
                          :
                        </p>
                        <AmountFormat
                          name="balance"
                          value={list_registers[0] ? list_registers[0].transaction_total : '-'}
                          currency={list_registers[0] ? list_registers[0].product : '-'}
                          displayType="text"
                        />
                      </div>
                    </div>
                    <div className="columns content-center"><p className="title-sub" /></div>
                  </div>
                ) : ' '}
            {(list_bank.length !== 0)
              ? (
                <TableList
                  tableClasses="floating-table"
                  head={(
                    <tr>
                      <th>{t('dateHour')}</th>
                      <th>{t('agencyName')}</th>
                      <th>{t('bank')}</th>
                      <th>{t('balanceDeposited')}</th>
                      <th>{t('heelNumber')}</th>
                      <th>{t('transferDetail2')}</th>
                    </tr>
                  )}
                  colNumber={4}
                  lenghtData={list_bank !== undefined ? list_bank.length : 0}
                >
                  {list_bank && (list_bank.map((transactions, index) => (
                    <TransactionRow key={index}>
                      <td>{list_lots[0] ? list_lots[0].lot_date : '-'}</td>
                      <td>{list_lots[0] ? list_lots[0].agency_name : '-'}</td>
                      <td>{transactions.bank_name}</td>
                      <td>
                        <AmountFormat
                          name="balance"
                          value={transactions.deposited_balance}
                          currency="AR$"
                          displayType="text"
                          allowNegative
                        />
                      </td>
                      <td>{transactions.bead_number}</td>
                      <td>
                        {transactions.file_url !== null
                          ? (
                            <button
                              // onClick={() => this.viewDeposit(transactions.file_url)}
                              onClick={() => this.getFile(transactions.file_url, 'deposit')}
                              className="button is-outlined is-small"
                            >
                              {t('viewReceipt')}
                            </button>
                          )
                          : t('notAttached')}
                      </td>
                    </TransactionRow>
                  )))}
                </TableList>
              ) : ' '}
          </ContentHeader>
        </Fragment>
        <br />
        {(list_registers === [] || list_registers !== [] && list_registers.length !== 0) ?
          (list_bank.length === 0) ?
            <Fragment>
              <Filter title={t('transactions')} />
              <ContentHeader
                classFirstColumn="is-paddingless">
                <TableList
                  tableClasses="floating-table"
                  head={(
                    <tr>
                      <th>{t('date')} / {t('hour')}</th>
                      <th>{t('transactionType')}</th>
                      <th>{t('amount')}</th>
                      <th>{t('commission')}</th>
                    </tr>
                  )}
                  colNumber={4}
                  lenghtData={list_registers !== undefined ? list_registers.length : 0}
                >
                  {list_registers && (list_registers.map((register, index) => (
                    <TransactionRow key={index}>
                      <td>{register.date}</td>
                      {/* <td>{register.lot_number === null ? 'sin número de lote' : register.lot_number }</td> */}
                      <td>{register.transaction_type}</td>
                      <td>
                        <AmountFormat
                          name="balance"
                          value={register.transaction_amount}
                          currency={register.product}
                          displayType="text"
                          allowNegative
                        />
                      </td>
                      <td>
                        <AmountFormat
                          name="comision"
                          value={register.commission}
                          currency={register.product}
                          displayType="text"
                          allowNegative
                        />
                      </td>
                    </TransactionRow>
                  )))}
                </TableList>
              </ContentHeader>
            </Fragment> : ' ' : ' '}
        {(statusD === 'Aprobada' || statusD === 'Rechazada') ?
          ''
          :
          <div className="columns is-centered mt-20 mb-20">
            <button
              onClick={this.confirmConciliation}
              className="button is-custom-primary mt-20px ml-10px mr-10px"
              type="button"
            >
              {t('buttonacceptConciliation')}
            </button>
            <button
              onClick={this.rejectConciliation}
              className="button btn-danger mt-20px ml-10px mr-10px"
              type="button"
            >
              {t('buttonrejectConciliation')}
            </button>
          </div>
        }
      </div>
    );
  }
}

DetailConciliation.propTypes = {
  t: PropTypes.func.isRequired,
  users: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({}),
  ]).isRequired,
  showMessage: PropTypes.func,
  fetchDetailToConciliate: PropTypes.func,
};

DetailConciliation.defaultProps = {
  showMessage: () => {},
};

const mapStateToProps = ({ users, conciliation }) => (
  {
    users, conciliation,
  }
);

export default compose(
  connect(mapStateToProps, {
    ...conciliationActions,
    ...usersActions,
  }),
  withNamespaces(),
)(DetailConciliation);
