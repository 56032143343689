import React from 'react';
import { withNamespaces } from 'react-i18next';
import { Loading, Panel, Form, FormElement, AmountFormat } from '../../../../components';
import './style.scss';

const FormExtraction = ({
  t, onSubmit, account, amount, reason, disabled,
  onChangeInput, step, onFinish
}) => {
  return (
    <Panel headingText={t('authExtraction')}>
      {step !== 'second' ?
          ''
        :
        <div className='success-text'>
          {t('successOperation')} <i className='fas fa-check-circle text-success' />
        </div>
      }
      <div className='columns has-text-centered'>
        <div className='column'>
          <h3> {t('account')}</h3>
          <div>{account.account}</div>
        </div>
        <div className='column'>
          <h3> {t('deal')}</h3>
          <div>{account.business}</div>
        </div>
        {step === 'second' ?
            ''
          :
          <div className='column'>
            <h3> {t('balance')}</h3>
            <div>{account.amount}</div>
          </div>
        }
      </div>
      <Form
        submitText={step !== 'second' ? t('continue') : t('finish') }
        onSubmit={step !== 'second' ? (e) => onSubmit(e, account.id) : (e) => onFinish(e) }
        className={'form-content'}
        disabled={disabled}
        >
          <FormElement
            name='amount'
            typeElement={step !== 'second' ? 'amountFormat' : 'text' }
            controlClasses='input-extraction'
            value={step !== 'second' ?
              amount
              :
              <AmountFormat
                value={amount}
                currency={'AR$'}
                displayType='text'
              />
            }
            onChange={(e => onChangeInput(e))}
            label={step === 'second' ? t('amountDebited') : t('extractDetail2') }
          />
          <FormElement
            name='reason'
            typeElement={step !== 'second' ? 'input' : 'text' }
            controlClasses='input-reason'
            value={reason}
            onChange={(e => onChangeInput(e))}
            label={t('reason')}
          />
      </Form>
    </Panel>
  );
}

export default withNamespaces()(FormExtraction);
