const START_FETCH = 'userSupport/START_FETCH';
const END_FETCH = 'userSupport/END_FETCH';
const END_ERROR = 'userSupport/END_ERROR';
const API_CALL = 'userSupport/API_CALL';
const MESSAGE = 'userSupport/MESSAGE';
const GET_TRANSACTIONS_CLAIM = 'userSupport/GET_TRANSACTIONS_CLAIM';
const UPDATE_TRANSACTIONS_CLAIM = 'userSupport/UPDATE_TRANSACTIONS_CLAIM';
const UPDATE_TRANSACTIONS_CLAIM_EXCEL = 'userSupport/UPDATE_TRANSACTIONS_CLAIM_EXCEL';
const GET_TRANSACTIONS_COMMISSION = 'userSupport/GET_TRANSACTIONS_COMMISSION';
const UPDATE_TRANSACTIONS_COMMISSION = 'userSupport/UPDATE_TRANSACTIONS_COMMISSION';
const UPDATE_TRANSACTIONS_COMMISSION_EXCEL = 'userSupport/UPDATE_TRANSACTIONS_COMMISSION_EXCEL';
const FETCH_TRANSACTION_TYPES = 'userSupport/GET_TRANSACTION_TYPES';
const UPDATE_TRANSACTION_TYPES = 'userSupport/UPDATE_TRANSACTION_TYPES';

export default {
  START_FETCH,
  END_FETCH,
  END_ERROR,
  API_CALL,
  MESSAGE,
  GET_TRANSACTIONS_CLAIM,
  UPDATE_TRANSACTIONS_CLAIM,
  GET_TRANSACTIONS_COMMISSION,
  UPDATE_TRANSACTIONS_COMMISSION,
  UPDATE_TRANSACTIONS_COMMISSION_EXCEL,
  UPDATE_TRANSACTIONS_CLAIM_EXCEL,
  FETCH_TRANSACTION_TYPES,
  UPDATE_TRANSACTION_TYPES,
};
