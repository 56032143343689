import { createAction } from 'redux-actions';
import types from './types';

const startFetch = createAction(types.START_FETCH);
const endFetch = createAction(types.END_FETCH);
const endError = createAction(types.END_ERROR);
const fetchTransportCompany = createAction(types.FETCH_TRANSPORT_COMPANY);
const updateTransportCompany = createAction(types.UPDATE_TRANSPORT_COMPANY);
const updateStateCompany = createAction(types.UPDATE_STATE_COMPANY);

const showMessage = createAction(types.MESSAGE,
  ({ message }) => ({
    message,
  }),
  ({ config }) => ({
    config,
  }));

  export default {
    startFetch,
    endFetch,
    endError,
    showMessage,
    fetchTransportCompany,
    updateTransportCompany,
    updateStateCompany,
}
