const START_FETCH = 'transactions/START_FETCH';
const END_FETCH = 'transactions/END_FETCH';
const END_ERROR = 'transactions/END_ERROR';
const API_CALL = 'transactions/API_CALL';
const FETCH_TRANSACTIONS = 'transactions/FETCH_TRANSACTIONS';
const FETCH_TRANSACTIONS_CONSOLE = 'transactions/FETCH_TRANSACTIONS_CONSOLE';
const CHECK_CBU = 'transactions/CHECK_CBU';
const UPDATE_CBU = 'transactions/UPDATE_CBU';
const EXTRACT = 'transactions/EXTRACT';
const TRANSFER = 'transactions/TRANSFER';
const UPDATE_TRANSACTIONS = 'transactions/UPDATE_TRANSACTIONS';
const SAVE_INDIVIDUAL_TRANSACTION = 'transactions/SAVE_INDIVIDUAL_TRANSACTION';
const SET_MULTIPLE_TRANSACTION = 'transactions/SET_MULTIPLE_TRANSACTION';
const SAVE_MULTIPLE_TRANSACTION = 'transactions/SAVE_MULTIPLE_TRANSACTION';
const CHECK_MULTIPLE_TRANSACTION = 'transactions/CHECK_MULTIPLE_TRANSACTION';
const UPDATE_MULTIPLE_TRANSACTION = 'transactions/UPDATE_MULTIPLE_TRANSACTION';
const EDIT_MULTIPLE_TRANSACTION = 'transactions/EDIT_MULTIPLE_TRANSACTION';
const RESPONSE_MULTIPLE_TRANSACTION = 'transactions/RESPONSE_MULTIPLE_TRANSACTION';
const UPDATE_TRANSACTION_LOT = 'transactions/UPDATE_TRANSACTION_LOT';
const GET_LIST_MULTIPLE_TRANSACTION = 'transactions/GET_LIST_MULTIPLE_TRANSACTION';
const UPDATE_LIST_MULTIPLE_TRANSACTION = 'transactions/UPDATE_LIST_MULTIPLE_TRANSACTION';
const GET_ELEMENT_MULTIPLE_TRANSACTION = 'transactions/GET_ELEMENT_MULTIPLE_TRANSACTION';
const UPDATE_ELEMENT_MULTIPLE_TRANSACTION = 'transactions/UPDATE_ELEMENT_MULTIPLE_TRANSACTION';
const UPDATE_ELEMENT_LIST_MULTIPLE_TRANSACTION = 'transactions/UPDATE_ELEMENT_LIST_MULTIPLE_TRANSACTION,';
const MESSAGE = 'transactions/MESSAGE';
const CLEAR = 'transactions/CLEAR';
const AUTHORIZE_TRANSACTION = 'transactions/AUTHORIZE_TRANSACTION';
const UPDATE_CHARGE = 'transactions/UPDATE_CHARGE';
const UPDATE_BALANCE = 'transactions/UPDATE_BALANCE';
const CHECK_BALANCE = 'transactions/CHECK_BALANCE';
const GET_ACCOUNT_BALANCE_FUNDRAISER = 'transactions/GET_ACCOUNT_BALANCE_FUNDRAISER';
const UPDATE_BALANCE_FUNDRAISER = 'transactions/UPDATE_BALANCE_FUNDRAISER';
const FETCH_LIST_PRISMA = 'transactions/FETCH_LIST_PRISMA';
const UPDATE_LIST_PRISMA = 'transactions/UPDATE_LIST_PRISMA';
const FETCH_LIST_STATE_PRISMA = 'transactions/FETCH_LIST_STATE_PRISMA';
const UPDATE_LIST_STATE_PRISMA = 'transactions/UPDATE_LIST_STATE_PRISMA';
const FETCH_TRANSACTION_TYPES = 'transactions/FETCH_TRANSACTION_TYPES';
const UPDATE_TRANSACTION_TYPES = 'transactions/UPDATE_TRANSACTION_TYPES';
const FETCH_LIST_DONGLE = 'transactions/FETCH_LIST_DONGLE';
const UPDATE_LIST_DONGLE = 'transactions/UPDATE_LIST_DONGLE';
const UPDATE_LIST_DONGLE_EXCEL = 'transactions/UPDATE_LIST_DONGLE_EXCEL';
const FETCH_LIST_ALL_PRISMA = 'transactions/FETCH_LIST_ALL_PRISMA';
const SET_LIST_ALL_PRISMA = 'transactions/SET_LIST_ALL_PRISMA';
const FETCH_TRANSACTIONS_SUSA = 'transactions/FETCH_TRANSACTIONS_SUSA';
const SET_TRANSACTIONS_SUSA = 'transactions/SET_TRANSACTIONS_SUSA';
const DEPOSIT_BUSINESS = 'transactions/DEPOSIT_BUSINESS';
const WITHDRAWAL_BUSINESS = 'transactions/WITHDRAWAL_BUSINESS';
const FETCH_LIST_COMMISSIONS = 'transactions/FETCH_LIST_COMMISSIONS';
const SET_LIST_COMMISSIONS = 'transactions/SET_LIST_COMMISSIONS';
const FETCH_COMMISSION_STATUS = 'transactions/FETCH_COMMISSION_STATUS';
const SET_COMMISSION_STATUS = 'transactions/FETCH_COMMIISION_STATUS';
const FETCH_COMMISSION_TYPES = 'transactions/FETCH_COMMISSION_TYPES';
const SET_COMMISSION_TYPES = 'transactions/FETCH_COMMIISION_TYPES';
const FETCH_EXTRACTIONS_LIST = 'transactions/FETCH_EXTRACTIONS_LIST';
const UPDATE_EXTRACTIONS_LIST = 'transactions/UPDATE_EXTRACTIONS_LIST';
const FETCH_STATUS_EXTRACTION = 'transactions/FETCH_STATUS_EXTRACTION';
const UPDATE_STATUS_EXTRACTION = 'transactions/UPDATE_STATUS_EXTRACTION';
const FETCH_TYPE_USER_EXTRACTION = 'transactions/FETCH_TYPE_USER_EXTRACTION';
const UPDATE_TYPE_USER_EXTRACTION = 'transactions/UPDATE_TYPE_USER_EXTRACTION';
const FETCH_QR_OPERATIONS_LIST = 'transactions/FETCH_QR_OPERATIONS_LIST';
const UPDATE_QR_OPERATIONS_LIST = 'transactions/UPDATE_QR_OPERATIONS_LIST';
const FETCH_TYPE_USER_QR = 'transactions/FETCH_TYPE_USER_QR';
const UPDATE_TYPE_USER_QR = 'transactions/UPDATE_TYPE_USER_QR';
const FETCH_STATUS_QR = 'transactions/FETCH_STATUS_QR';
const UPDATE_STATUS_QR = 'transactions/UPDATE_STATUS_QR';

export default {
  START_FETCH,
  END_FETCH,
  END_ERROR,
  API_CALL,
  FETCH_TRANSACTIONS,
  FETCH_TRANSACTIONS_CONSOLE,
  CHECK_CBU,
  UPDATE_CBU,
  SAVE_INDIVIDUAL_TRANSACTION,
  SET_MULTIPLE_TRANSACTION,
  SAVE_MULTIPLE_TRANSACTION,
  CHECK_MULTIPLE_TRANSACTION,
  UPDATE_MULTIPLE_TRANSACTION,
  EDIT_MULTIPLE_TRANSACTION,
  RESPONSE_MULTIPLE_TRANSACTION,
  UPDATE_TRANSACTION_LOT,
  GET_LIST_MULTIPLE_TRANSACTION,
  UPDATE_LIST_MULTIPLE_TRANSACTION,
  GET_ELEMENT_MULTIPLE_TRANSACTION,
  UPDATE_ELEMENT_MULTIPLE_TRANSACTION,
  UPDATE_ELEMENT_LIST_MULTIPLE_TRANSACTION,
  EXTRACT,
  TRANSFER,
  UPDATE_TRANSACTIONS,
  MESSAGE,
  CLEAR,
  AUTHORIZE_TRANSACTION,
  CHECK_BALANCE,
  UPDATE_CHARGE,
  UPDATE_BALANCE,
  GET_ACCOUNT_BALANCE_FUNDRAISER,
  UPDATE_BALANCE_FUNDRAISER,
  FETCH_LIST_PRISMA,
  UPDATE_LIST_PRISMA,
  FETCH_LIST_STATE_PRISMA,
  UPDATE_LIST_STATE_PRISMA,
  FETCH_TRANSACTION_TYPES,
  UPDATE_TRANSACTION_TYPES,
  FETCH_LIST_DONGLE,
  UPDATE_LIST_DONGLE,
  UPDATE_LIST_DONGLE_EXCEL,
  FETCH_LIST_ALL_PRISMA,
  SET_LIST_ALL_PRISMA,
  FETCH_TRANSACTIONS_SUSA,
  SET_TRANSACTIONS_SUSA,
  DEPOSIT_BUSINESS,
  WITHDRAWAL_BUSINESS,
  FETCH_LIST_COMMISSIONS,
  SET_LIST_COMMISSIONS,
  FETCH_COMMISSION_STATUS,
  SET_COMMISSION_STATUS,
  FETCH_COMMISSION_TYPES,
  SET_COMMISSION_TYPES,
  FETCH_EXTRACTIONS_LIST,
  UPDATE_EXTRACTIONS_LIST,
  FETCH_STATUS_EXTRACTION,
  UPDATE_STATUS_EXTRACTION,
  FETCH_TYPE_USER_EXTRACTION,
  UPDATE_TYPE_USER_EXTRACTION,
  FETCH_QR_OPERATIONS_LIST,
  UPDATE_QR_OPERATIONS_LIST,
  FETCH_TYPE_USER_QR,
  UPDATE_TYPE_USER_QR,
  FETCH_STATUS_QR,
  UPDATE_STATUS_QR,
};
