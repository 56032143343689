import React, { Component, Fragment } from 'react';
import { withNamespaces }             from 'react-i18next';
import PropTypes                      from 'prop-types';
import { compose }                    from 'redux';
import { connect }                    from 'react-redux';
import { toast }                      from 'react-toastify';
import {initTimer, stopTimer} from "../../../util/initTimer";
import {conciliationActions} from "../../../state/ducks/conciliation";
import {authActions} from "../../../state/ducks/auth";
import {agencyActions} from "../../../state/ducks/agency";
import {
  AmountFormat,
  ContentHeader,
  DefaultButton,
  Filter,
  Loading,
  TableList,
  TransactionRow
} from "../../../components";
import {Link, Route} from "react-router-dom";
import {TOAST_CONFIG} from "../../../config/constants";
import './styles.scss';

class DetailBoxAgency extends  Component{
  constructor(props) {
    super(props);
    this.state = {
      agency: '',
      idAgency: '',
      operator: '',
      idBox: '',
      page:0,
    }
  }

  componentDidMount() {
    stopTimer();
    initTimer(this.props);

    const {location} = this.props;
    if (location!== undefined && location.state !== undefined && location.state.agency !== undefined) {
      this.setState({
          agency: location.state.agency,
          idAgency: location.state.idAgency,
          operator: location.state.operator,
          idBox: location.state.idBox,
        },
        () => {this.callApiList()
      });
    }

  }

  callApiList = () => {
    const {t, fetchDetailBoxAgency, showMessage} = this.props;
    const { idBox, page} = this.state;

    const data = {
      id_cash: idBox,
      page,
    }

    fetchDetailBoxAgency({
      data,
      callback: (response) => {

        if (!response.success) {
          toast.dismiss();
          showMessage({
            message: t('connectionError'),
            config: TOAST_CONFIG.ERROR,
          });
        }
      },
    });
  }

  render() {
    const {t, auth: { currency }, agency:{loading, detailBox } } = this.props;
    const {agency, idAgency, operator} = this.state;

    return (
      <div>
        {loading && <Loading />}
        <Fragment>
          <div className="wow animated slideInDown">
            <Filter title={t('adminRed')}
                    subtitle={t('detailBox')}
            />
            <ContentHeader
              headingText={
                <Link
                  to={{
                    pathname: '/box-agency',
                    state: { id: idAgency,
                      agency: agency,
                    },
                  }}
                >
                  <DefaultButton name={t('goBack')} />
                </Link>
              }
            >
                <div className="columns is-multiline detail-box">
                  <div className="column is-full is-centered">
                    <h4>{t('summary')}</h4>
                  </div>
                  <div className="column is-6-desktop row-detail">
                    <div className='text-label'>{t('agency')}</div>
                    <div className="text-value">{detailBox.agency_name}</div>
                  </div>
                  <div className="column is-6-desktop row-detail">
                    <div className='text-label'>{t('operator')}</div>
                    <div className="text-value">{operator}</div>
                  </div>
                  <div className="column is-6-desktop row-detail">
                    <div className='text-label'>{t('dateOpen')}</div>
                    <div className="text-value">{detailBox.opening_date}</div>
                  </div>
                  <div className="column is-6-desktop row-detail">
                    <div className='text-label'>{t('numOperation')}</div>
                    <div className="text-value">{detailBox.operations}</div>
                  </div>
                  <div className="column is-6-desktop row-detail">
                    <div className='text-label'>{t('balance')}</div>
                    <div className="text-value">
                        <AmountFormat
                        name="balance"
                        value={detailBox.balance}
                        displayType="text"
                        allowNegative
                      />
                    </div>
                  </div>

                </div>
            </ContentHeader>

            <ContentHeader
              headingRightText={t('totalTransactions')}
              headingRightSubText={`${detailBox.transactions !== undefined ? detailBox.transactions.length : 0}`}
            >
              <TableList
                tableClasses="floating-table"
                head={(
                  <tr>
                    <th>{t('date')}</th>
                    <th>{t('codeTransaction')}</th>
                    <th>{t('typeTransacction')}</th>
                    <th>{`${t('amount')} (${currency})`}</th>

                  </tr>
                )}
                colNumber={4}
                lenghtData={ detailBox.transactions !== undefined ? detailBox.transactions.length : 0}>
                {detailBox.transactions && (detailBox.transactions.map((row, index) => (
                  <TransactionRow
                    key={index}
                  >
                    <td>{row.created_at}</td>
                    <td>{row.transaction_code}</td>
                    <td>{row.transaction_type}</td>
                    <td>
                      <AmountFormat
                        name="balance"
                        value={row.transaction_amount}
                        displayType="text"
                        allowNegative
                      />
                    </td>
                  </TransactionRow>
                  ))
                )}
              </TableList>
            </ContentHeader>
          </div>
        </Fragment>
      </div>
    );
  }

}

DetailBoxAgency.propTypes = {
  t: PropTypes.func.isRequired,
  showMessage: PropTypes.func,
};

DetailBoxAgency.defaultProps = {
  showMessage: () => {},

};

const mapStateToProps = ({  auth, agency }) => ({
  auth,
  agency,

});


export default compose(connect(mapStateToProps, {
  ...authActions, ...agencyActions,
}), withNamespaces())(DetailBoxAgency);
