import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TextInput from '../TextInput';

class CbuFormat extends Component {
  handlerInputChanged = (ev) => {
    const {target: {value}} = ev;
    const {validateField, onChange} = this.props;
    if (value.length <= 22) {
      onChange(ev);
      if (/^[0-9]{22}$/gm.exec(value)) {
        validateField('cbu');
      } else if (/^[a-zA-Z0-9\.\-]{6,20}$/gm.exec(value)) {
        validateField('alias');
      } else {
        validateField(null);
      }
    }
  };

  constructor(props) {
    super(props);
    this.handlerInputChanged = this.handlerInputChanged.bind(this);
  }

  render() {
    const {
      value, name, label, controlClasses, displayType, helper,
    } = this.props;
    const newValue = value || '';
    if (displayType == 'input') {
      return (
        <TextInput
          value={newValue}
          name={name}
          label={label}
          onChange={(ev) => {
            this.handlerInputChanged(ev);
          }}
          controlClasses={controlClasses}
          displayType={displayType}
          helper={helper}
        />
      );
    } else {
      return (
        <span>{newValue}</span>
      );
    }
  }
}

CbuFormat.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func,
  validateField: PropTypes.func,
  controlClasses: PropTypes.string,
  displayType: PropTypes.string,
  helper: PropTypes.string,
};
CbuFormat.defaultProps = {
  label: '',
  onChange: () => {
  },
  validateField: () => {
  },
  controlClasses: '',
  helper: '',
  displayType: 'input',
};

export default CbuFormat;
