import React                   from 'react';
import {Form, FieldDisplay, DefaultButton, Panel, AmountFormat} from "../../../../components";
import {withNamespaces}        from "react-i18next";

const ConfirmDepositWithdrawalMoney = ({t, typeOperation, amount, description, onSubmit, onChangeView, currency}) => {
  return (
    <Panel
      titleContentHeader={<DefaultButton name={t('goBack')} onClick={() => onChangeView()}/>}
      headingText={t('confirmOperation')}
    >
     <div className="p-16px content-xl-center">
        <Form
          submitText={t('confirm')}
          onSubmit={(ev) => onSubmit(ev)}
          nameButton="configButton"
        >
          <div className="columns is-multiline">

              <FieldDisplay
                field={t('typeOperation')}
                fieldDetail={typeOperation.value}
                fieldDisplayClasses="column is-6-desktop"
              />

              <FieldDisplay
                field={t('amount')+' '+currency}
                fieldDetail={
                  <AmountFormat
                    name="balance"
                    value={amount}
                    displayType="text"
                    allowNegative
                  />
                }
                fieldDisplayClasses="column is-6-desktop"
              />
              <FieldDisplay
                field={t('description')}
                fieldDetail={description}
                fieldDisplayClasses="column is-6-desktop"
              />
            </div>
        </Form>
    </div>
    </Panel>
  );
};

export default withNamespaces()(ConfirmDepositWithdrawalMoney);