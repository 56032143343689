import React, { Component, Fragment } from 'react';
import { compose }                    from 'redux';
import './styles.scss';
import { connect }                    from 'react-redux';
import { withNamespaces }             from 'react-i18next';
import { BalanceBox, Box }            from '../../components';
import { dashboardActions }           from '../../state/ducks/dashboard';
import { initTimer, stopTimer }       from '../../util/initTimer';
import { authActions }                from '../../state/ducks/auth';
import PropTypes                      from 'prop-types';
import ContentHeader                  from '../../components/ContentHeader';
import { transactionsActions }        from "../../state/ducks/transactions";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const { fetchDashboard, getAccountBalanceFundraiser, fetchBalanceBusiness } = this.props;
    fetchDashboard({
      callback: (data) => {
        //console.log('fetchDashboard', data);
      },
    });
    getAccountBalanceFundraiser();
    fetchBalanceBusiness();
    stopTimer();
    initTimer(this.props);
  }

  dateFormat(date) {
    const { t } = this.props;
    return `${date.day} de ${t(`month${date.month}`)} de ${date.year}`;
  }

  render() {
    const {
            t, auth: { user: { phone_number, cvu, date_now } }, dashboard: { balance, users }
          } = this.props;

    return (
      <Fragment>
        <ContentHeader
          headingRightSubText={this.dateFormat(date_now)}
          classDashboard={'is-width-100'}>
          <div className="columns">
            <div className="column is-4"><Box title={t('users')} content={users ? users.toString() : '0'} />
            </div>
            <div className="column is-8"><BalanceBox /></div>
          </div>
          <div className="columns">
            <div className="column">
              <h2 className="title-dashboard"><span className="title-1">{t('welcomeTo')}</span><br /><span
                className="title-2">{t('consolaAdmin')}</span><br />{t('urbanServices')}</h2>
            </div>
            <div className="column">
              <img src="/dashboard.png" />
            </div>
          </div>
        </ContentHeader>
      </Fragment>
    );
  }
}

Dashboard.propTypes = {
  auth: PropTypes.shape({}).isRequired,
  location: PropTypes.shape({}).isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, dashboard, transactions }) => (
  {
    auth,
    dashboard,
    transactions,
  }
);
export default compose(
  connect(mapStateToProps, { ...authActions, ...dashboardActions, ...transactionsActions }),
  withNamespaces(),
)(Dashboard);
