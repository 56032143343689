import React              from 'react';
import PropTypes          from 'prop-types';
import { withNamespaces } from 'react-i18next';
import './styles.scss';

const UpdateImage = ({
                       t, removeImage, onChange, name, imagePreviewUrl, selectedFile, label,
                     }) => (
  <div className="content-inputs has-text-centered">
    <div className="fields">
      <div className='add-voucher'>
        <input
          type="file"
          onChange={onChange}
          className="input-file input-upload"
          id="file-upload2"
          name={name}
        />
        <div className='upload-label'>
          <span>{t(label)}</span>
        </div>
        <div className='upload-voucher'>
          {imagePreviewUrl ? <>
            <img src={imagePreviewUrl} className="imgA1" alt="Preview" />
            <img className="imgB1" src="/equis.png" onClick={() => removeImage(name)} alt="Close" />
            { imagePreviewUrl.startsWith('data:image') ? '' : <a href={imagePreviewUrl} target="_blank">
              <div className="button is-small">{t('see')}</div>
            </a> }
          </> : <i className="far fa-file-image" />}
        </div>
        <span className="name-file">{selectedFile ? selectedFile.name : ''}</span>
      </div>
    </div>
  </div>
);

UpdateImage.propTypes = {
  t: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  removeImage: PropTypes.func,
  name: PropTypes.string,
  imagePreviewUrl: PropTypes.string,
  selectedFile: PropTypes.shape({}),
  label: PropTypes.func,
};

UpdateImage.defaultProps = {
  onChange: null,
  removeImage: null,
  name: '',
  imagePreviewUrl: '',
  selectedFile: {},
  label: '',
};

export default withNamespaces()(UpdateImage);

