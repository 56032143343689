import React, { Component, Fragment } from 'react';
import { withNamespaces }             from 'react-i18next';
import PropTypes                      from 'prop-types';
import { compose }                    from 'redux';
import { connect }                    from 'react-redux';
import { toast }                      from 'react-toastify';
import createHistory                  from 'history/createBrowserHistory';
import { Link }                       from 'react-router-dom';
import ReactTooltip                   from 'react-tooltip';
import { usersActions }               from '../../state/ducks/users';
import {
  ContentHeader,
  ExportExcel,
  Filter,
  FormElement,
  Loading,
  TableList,
  Toggle,
  TransactionRow,
}                                     from '../../components';
import {
  initTimer,
  stopTimer
}                                     from '../../util/initTimer';
import { TOAST_CONFIG }               from '../../config/constants';
import { authActions }                from '../../state/ducks/auth';
import { ConfirmAlert }               from '../../util/ConfirmAlert';
import { formatDate }                 from '../../util';
import { UserEdit }                   from '../index';
import './styles.scss';

const SIZE_PER_PAGE = 20;

class UsersAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cuit: '',
      cvu: '',
      page: 0,
      stateIva: '',
      dateStart: '',
      dateEnd: '',
      person_type: '',
      first: true,
      statusText: '',
    };
  }

  componentDidMount() {
    const { fetchStatusIva, fetchTypePerson, fetchListStatusUser, getProvinces, getRubros, getEntities } = this.props;
    stopTimer();
    initTimer(this.props);
    this.callApiList();
    fetchStatusIva();
    fetchTypePerson();
    fetchListStatusUser();
    getProvinces();
    getRubros();
    getEntities();
  }

  callApiList = (getExcel = false) => {
    const { t, showMessage, fetchUsers, users: { totalElements, list_status_user } } = this.props;
    const {
      name, cuit, cvu, person_type, stateIva, statusText, dateStart, dateEnd, page, first,
    } = this.state;
    let stateUser = statusText;

    if (statusText === '' && first === true) {
      stateUser = list_status_user.length > 2 ? list_status_user[2].id : 1;

      this.setState({
        first: false,
        statusText: stateUser,
      });
    }
    fetchUsers({
      data: {
        cuit,
        cvu,
        name,
        status_iva: stateIva,
        person_type,
        page: getExcel ? 0 : page,
        pageSize: !getExcel ? SIZE_PER_PAGE : totalElements,
        user_status: stateUser,
        dateStart: formatDate(dateStart),
        dateEnd: formatDate(dateEnd),
        export: getExcel,
      },
      callback: (response) => {
        if (response) {
          if (!response.success) {
            toast.dismiss();
            showMessage({
              message: t('connectionError'),
              config: TOAST_CONFIG.ERROR,
            });
            return false;
          }
        } else {
          toast.dismiss();
          showMessage({
            message: t('connectionError'),
            config: TOAST_CONFIG.ERROR,
          });
        }
      },
    });
  }

  onChangePage = (selectPage) => {
    stopTimer();
    initTimer(this.props);
    const page = selectPage ? selectPage.selected : 0;
    this.setState({ page },
      () => {
        this.callApiList();
      });
  };

  handleInputChange = (event) => {
    stopTimer();
    initTimer(this.props);
    const { target: { value: targetValue, name } } = event;
    this.setState({
      [name]: targetValue,
    });
  };


  onSubmit = (event) => {
    stopTimer();
    initTimer(this.props);
    event.preventDefault();
    this.onChangePage();
  };

  handleDriverChange = (identification, driver) => {
    const { t, assignedRemoveDriver, showMessage } = this.props;
    const label = t('confirmChangeDriver', { state: !driver ? 'remover' : 'asignar' });
    ConfirmAlert(
      { ...this.props },
      () => {
        assignedRemoveDriver({
          data: {
            id_person: identification,
            action: !driver ? 'remove' : 'assign',
          },
          callback: (response) => {
            const { success, data } = response;
            if (success) {
              const history = createHistory();
              toast.dismiss();
              showMessage({
                message: data.message || t('successfulOperation'),
                config: TOAST_CONFIG.SUCCESS,
              });
              setTimeout(() => {
                history.go(0);
              }, 3000);
            }
          },
        });
      },
      label,
    );
  };

  onChangeDate = (date, name) => {
    stopTimer();
    initTimer(this.props);
    const dateValue = date !== null ? date : '';
    this.setState({
      [name]: dateValue,
    });
  };

  render() {
    const {
      t, users: {
        list, totalElements, optionsTypePerson, loading, list_status_user,
      },
      auth: { loading: loadingAuth },
    } = this.props;
    const {
      name, cuit, person_type, page, statusText, dateStart, dateEnd, cvu
    } = this.state;
    return (
      <div>
        {(loading || loadingAuth) && <Loading />}
        <Toggle>
          {({ toggled, onToggle, toggleProps }) => {
            return (
              <Fragment>
                {toggled
                && (
                  <UserEdit
                    identification={toggleProps.identification}
                    dateStart={toggleProps.dateStart}
                    dateEnd={toggleProps.dateEnd}
                    cuit={toggleProps.cuit}
                    name={toggleProps.name}
                    cvu={toggleProps.cvu}
                    personType={person_type}
                    status={toggleProps.statusText}
                    handleReturn={() => {
                      this.callApiList();
                      onToggle();
                    }}
                  />
                )}
                {!toggled && (
                  <Fragment>
                    <div className="wow animated slideInDown">
                      <Filter
                        title={t('userAdmin')}
                        subtitle={t('filters')}
                        onSubmit={(ev) => { this.onSubmit(ev); }}
                      >
                        <FormElement
                          onChange={ev => this.onChangeDate(ev, 'dateStart')}
                          label={t('createdDateFrom')}
                          value={dateStart}
                          typeElement="datepicker"
                          maxDate={dateEnd || new Date()}
                        />
                        <FormElement
                          onChange={ev => this.onChangeDate(ev, 'dateEnd')}
                          value={dateEnd}
                          label={t('createdDateUntil')}
                          typeElement="datepicker"
                          maxDate={new Date()}
                          minDate={dateStart}
                        />
                        <FormElement
                          name="cuit"
                          onChange={this.handleInputChange}
                          value={cuit}
                          label={'CUIT'}
                        />
                        <FormElement
                          name="name"
                          onChange={this.handleInputChange}
                          value={name}
                          label={t('name')}
                          information={`${t('name')} / ${t('lastName')} / ${t('businessName')}`}
                        />
                        <FormElement
                          name="cvu"
                          onChange={this.handleInputChange}
                          value={cvu}
                          label={'CVU'}
                        />
                        <FormElement
                          name="person_type"
                          onChange={this.handleInputChange}
                          value={person_type}
                          label={t('typerPerson')}
                          optionsSelect={optionsTypePerson}
                          placeholder="Seleccione..."
                          typeElement="select"
                          controlClasses="select"
                        />
                        <FormElement
                          name="statusText"
                          onChange={this.handleInputChange}
                          value={statusText}
                          label={t('userState')}
                          optionsSelect={list_status_user}
                          placeholder="Seleccione..."
                          typeElement="select"
                          controlClasses="select"
                        />

                      </Filter>
                    </div>
                    <ContentHeader
                      headingRightText={t('totalUsers')}
                      headingRightSubText={`${totalElements}`}
                    >
                      <ExportExcel
                        onclick={() => { this.callApiList(true) }}
                      />
                      <TableList
                        tableClasses="floating-table"
                        totalElements={totalElements ? parseFloat(totalElements) : 0}
                        pageSize={SIZE_PER_PAGE}
                        onChangePage={this.onChangePage}
                        forcePage={page}
                        head={(
                          <tr>
                            <th>{t('createdDate')}</th>
                            <th>CUIT</th>
                            <th>
                              <span>
                                {t('name')}
                                <i
                                  className="ml-8px fas fa-info-circle has-text-warning"
                                  aria-hidden="true"
                                  data-for="tooltip-name"
                                  data-tip="Test"
                                >
                                  <ReactTooltip
                                    id="tooltip-name"
                                    type="warning"
                                    effect="solid"
                                  >
                                    {`${t('fullName')} / ${t('businessName')}`}
                                  </ReactTooltip>
                                </i>
                              </span>
                            </th>
                            <th>{t('CVU')}</th>
                            <th>{t('typerPerson')}</th>
                            <th>{t('userState')}</th>
                            <th colSpan={2} />
                          </tr>
                        )}
                        colNumber={9}
                        lenghtData={list !== undefined ? list.length : 0}
                      >
                        {(list !== undefined) && (list.map((user, i) => (
                            <TransactionRow
                              key={i}
                              openDetail={async () => {
                                stopTimer();
                                initTimer(this.props);
                                const { detailUser, clearUserDetail } = this.props;
                                const data = { identification: user.identification };
                                await clearUserDetail();
                                await detailUser({
                                  data,
                                  callback: async (response) => {
                                    const { success, data: userDetail } = response;
                                    if (success) {
                                      onToggle(user);
                                    }
                                  },
                                });
                              }}
                              helper={t('showDetail')}
                              typeButtonDetail="text"
                            >
                              <td>{user.date}</td>
                              <td>{user.cuit}</td>
                              <td>{`${user.name}${user.lastName ? ` ${user.lastName}` : ''}`}</td>
                              <td>{user.cvu}</td>
                              <td>{user.person_type}</td>
                              <td>{user.statusText}</td>
                              <td>
                                <Link
                                  to={{
                                    pathname: '/transactions-per-user',
                                    state: { email: user.username, identification: user.identification },
                                  }}
                                >
                                  <button className="button is-outlined is-small">{t('showMovement')}</button>
                                </Link>
                              </td>
                            </TransactionRow>
                          ))
                        )}
                      </TableList>
                    </ContentHeader>
                  </Fragment>
                )}
              </Fragment>
            );
          }}
        </Toggle>
      </div>
    );
  }
}

UsersAdmin.propTypes = {
  t: PropTypes.func.isRequired,
  users: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({}),
  ]).isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = ({ users, auth }) => (
  {
    users,
    auth,
  }
);
export default compose(
  connect(mapStateToProps, {
    ...usersActions,
    ...authActions,
  }),
  withNamespaces(),
)(UsersAdmin);
