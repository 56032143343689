import React, { Component, Fragment }                           from 'react';
import PropTypes                                                from 'prop-types';
import { compose }                                              from 'redux';
import { connect }                                              from 'react-redux';
import { withNamespaces }                                       from 'react-i18next';
import { initTimer, stopTimer }                                 from '../../util/initTimer';
import { Form, Loading, Panel }                                 from '../../components';
import { AddressDetailForm, CompanyDetailForm, UserDetailForm } from '../userDetail';
import { usersActions }                                         from '../../state/ducks/users';
import { authActions }                                          from '../../state/ducks/auth';
import { agencyActions }                                        from '../../state/ducks/agency';
import { TOAST_CONFIG }                                         from "../../config/constants";

class TransportCompanyForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: 'first',
      country: 'AR',
      dni: '',
      cuit: '',
      name: '',
      lastname: '',
      phoneNumber: '',
      cuitCompany: '',
      nameCompany: '',
      usernameCompany: '',
      phoneNumberCompany: '',
      province: '',
      location: '',
      city: '',
      address: '',
      zipCode: '',
      finished: false,
    };
  }

  componentDidMount() {
    const {
      clearUserDetail,
      fetchCompanyTypes,
      getProvinces,
    } = this.props;
    stopTimer();
    initTimer(this.props);
    clearUserDetail();
    fetchCompanyTypes();
    getProvinces();
  }

  callApiList = () => {
    const { fetchCreateTransportCompany } = this.props;
    const {
      dni,
      cuit,
      name,
      lastname,
      cuitCompany,
      nameCompany,
      usernameCompany,
      phoneNumberCompany,
      city: { value: id_city },
      address,
      zipCode,
    } = this.state;

    fetchCreateTransportCompany({
      data: {
        user_type: 'transport_company',
        business_cuit: cuitCompany,
        business_name: nameCompany,
        email: usernameCompany,
        from: 'web',
        phone_number: phoneNumberCompany,

        address,
        id_city,
        zip_code: zipCode,

        name: name,
        lastname: lastname,
        document_type: 'DNI',
        document_number: dni,
        document_number_aux: cuit,

      },
      callback: ({ success }) => {
        if (success) {
          this.setState({
            finished: true
          });
        }
        else{
          this.setState({
            view: 'first',
            finished: false
          });
        }
      }
    });
  }

  handlePhoneChange = (value, name) => {
    stopTimer();
    initTimer(this.props);
    this.setState({
      [name]: value,
    });
  };

  handleInputChange = ({ target: { value: targetValue, name } }) => {
    stopTimer();
    initTimer(this.props);
    this.setState({
      [name]: targetValue,
    });
  };

  handleVerifyCuit = (ev) => {
    this.setState({
      dni: ev.target.value.substr(2, 8)
        .replace(/^0+/, ''),
    });
  };

  onSubmit = (event) => {
    stopTimer();
    initTimer(this.props);
    event.preventDefault();
    this.callApiList()
  };

  handleValidator = () => {
    const { t, showMessage } = this.props;
    const {
      dni,
      cuit,
      name,
      lastname,
      phoneNumber,
      cuitCompany,
      companyType,
      nameCompany,
      usernameCompany,
      phoneNumberCompany,
      province,
      location,
      city,
      address,
      zipCode,
    } = this.state;
    if (dni === ''
      || cuit === ''
      || name === ''
      || lastname === ''
      || phoneNumber === ''
      || cuitCompany === ''
      || companyType === ''
      || nameCompany === ''
      || usernameCompany === ''
      || phoneNumberCompany === ''
      || province === ''
      || location === ''
      || city === ''
      || address === ''
      || zipCode === '') {
      showMessage({
        message: t('allRequiredFields'),
        config: TOAST_CONFIG.ERROR,
      });
      return false;
    }

    const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
    const result = pattern.test(usernameCompany);

    if(result !==true){
      showMessage({
        message: t('emailInvalid'),
        config: TOAST_CONFIG.ERROR,
      });
      return false;
    }

    return true;
  }

  handleChangeView = (event, view = 'first') => {
    event.preventDefault();
    if (this.handleValidator()) {
      this.setState({
        view,
      });
    }
  }

  render() {
    const {
      view,
      dni,
      cuit,
      name,
      lastname,
      phoneNumber,
      cuitCompany,
      companyType,
      nameCompany,
      usernameCompany,
      phoneNumberCompany,
      country,
      province,
      location,
      city,
      address,
      zipCode,
      finished,
    } = this.state;
    const person = {
      dni,
      cuit,
      name,
      lastname,
      phoneNumber,
    };
    const company = {
      cuitCompany,
      companyType,
      nameCompany,
      usernameCompany,
      phoneNumberCompany,
    };
    const fullAddress = {
      country,
      province,
      location,
      city,
      address,
      zipCode,
    };
    const {
      t, users: { loading },
      auth: { loading: loadingAuth, },
      agency: { loading: loadingAgency, },
    } = this.props;

    let { children, submitText } = ''

    if (view === 'first') {
      submitText = t('next');
      children = (
        <Fragment>
          <div className="mb-16px has-text-grey-light has-text-right">{t('allRequiredFields')}</div>
          <CompanyDetailForm
            handleInputChange={this.handleInputChange}
            handlePhoneChange={this.handlePhoneChange}
            company={company}
            titleLabel={'transportCompanyData'}
            editable
            hiddeIva={true}
            hiddeTypeCompany={true}
          />
          <AddressDetailForm
            handleInputChange={this.handleInputChange}
            handlePhoneChange={this.handlePhoneChange}
            address={fullAddress}
            titleLabel={'addressBusinessInformation'}
            editable
          />
          <UserDetailForm
            handleInputChange={this.handleInputChange}
            handlePhoneChange={this.handlePhoneChange}
            handleVerifyCuit={this.handleVerifyCuit}
            person={person}
            editable
            hideEmail={true}
          />
        </Fragment>
      );
    } else if (view === 'second') {
      submitText = t('save');
      children = (
        <Fragment>
          {finished && <h3 className="text-success">{t('successfulRegister')}</h3>}
          <CompanyDetailForm
            handleInputChange={this.handleInputChange}
            handlePhoneChange={this.handlePhoneChange}
            company={company}
            titleLabel={'transportCompanyData'}
            editable={false}
            hiddeIva={true}
            hiddeTypeCompany={true}
          />
          <AddressDetailForm
            handleInputChange={this.handleInputChange}
            handlePhoneChange={this.handlePhoneChange}
            address={fullAddress}
            titleLabel={'addressBusinessInformation'}
            editable={false}
          />
          <UserDetailForm
            handleInputChange={this.handleInputChange}
            handlePhoneChange={this.handlePhoneChange}
            handleVerifyCuit={this.handleVerifyCuit}
            person={person}
            editable={false}
            hideEmail={true}
          />
        </Fragment>
      );
    }

    return (
      <div>
        {(loading || loadingAuth || loadingAgency) && <Loading />}
        <Fragment>
          <Panel titleContentHeader={t('companyRegistration')} headingText={t('newCompanyForm')}>
            <div className="pr-16px pl-16px pt-0 content-xl-center">
              <Form
                submitText={finished ? t('finish') : submitText}
                nameButton="configButton"
                onSubmit={(e) => {
                  if (view === 'first') {
                    this.handleChangeView(e, 'second')
                  } else if (view === 'second' && !finished) {
                    this.onSubmit(e);
                  } else if (finished) {
                    window.location.reload();
                  }
                }}
                returnText={(view === 'second' && !finished) ? t('return') : null}
                handleActionButton={(event) => {
                  if (view === 'second') {
                    this.handleChangeView(event);
                  }
                }}
              >
                {children}
              </Form>
            </div>
          </Panel>
        </Fragment>
      </div>
    );
  }
}

TransportCompanyForm.propTypes = {
  t: PropTypes.func.isRequired,
  users: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({}),
  ]).isRequired,
  logout: PropTypes.func.isRequired,
};


const mapStateToProps = ({ users, auth, agency }) => (
  {
    users,
    auth,
    agency,
  }
);
export default compose(
  connect(mapStateToProps, {
    ...usersActions,
    ...authActions,
    ...agencyActions,
  }),
  withNamespaces(),
)(TransportCompanyForm);
