import {Component} from 'react';
import PropTypes from 'prop-types';

class Toggle extends Component {
  state = {
    toggled: false,
    toggleProps: null,
    toggledDrop: false,
    toggledMenu: false,
  };

  onToggle = (toggleProps = {}) => {
    this.setState(({toggled}) => ({
      toggled: !toggled,
      toggleProps,
    }));
  };

  onToggleDrop = (toggleProps = {}) => {
    this.setState(({toggledDrop}) => ({
      toggledDrop: !toggledDrop,
      toggleProps,
    }));
  };

  onToggleMenu = (toggleProps = {}) => {
    this.setState(({toggledMenu}) => ({
      toggledMenu: !toggledMenu,
      toggleProps,
    }));
  };

  exitToggle = (toggleProps = {}) => {
    this.setState(({toggled}) => ({
      toggled: false,
      toggleProps,
    }));
  };

  exitToggleDrop = (toggleProps = {}) => {
    this.setState(({toggledDrop}) => ({
      toggledDrop: false,
      toggleProps,
    }));
  };


  exitToggleMenu = (toggleProps = {}) => {
    this.setState(({toggledMenu}) => ({
      toggledMenu: false,
      toggleProps,
    }));
  };

  render() {
    const {children} = this.props;
    const {toggled, toggledDrop, toggledMenu, toggleProps} = this.state;

    return children({
      toggled,
      toggledDrop,
      toggledMenu,
      onToggle: this.onToggle,
      exitToggle: this.exitToggle,
      onToggledDrop: this.onToggleDrop,
      exitToggleDrop: this.exitToggleDrop,
      onToggleMenu: this.onToggleMenu,
      exitToggleMenu: this.exitToggleMenu,
      toggleProps,
    });
  }
}

Toggle.propTypes = {
  children: PropTypes.func.isRequired,
};

export default Toggle;
