import {createAction} from "redux-actions";

const START_FETCH = 'conciliation/START_FETCH';
const END_FETCH = 'conciliation/END_FETCH';
const END_ERROR = 'conciliation/END_ERROR';
const API_CALL = 'conciliation/API_CALL';
const FETCH_LIST = 'conciliation/FETCH_LIST';
const UPDATE_LIST = 'conciliation/UPDATE_LIST';
const FETCH_CONCILIATE_DETAIL = 'conciliation/FETCH_CONCILIATE_DETAIL';
const UPDATE_CONCILIATE_DETAIL = 'conciliation/UPDATE_CONCILIATE_DETAIL';
const MESSAGE = 'conciliation/MESSAGE';
const FETCH_RECONCILED = 'conciliation/FETCH_RECONCILED';
const UPDATE_RECONCILED = 'conciliation/UPDATE_RECONCILED';
const FETCH_DETAIL_TO_CONCILIATE = 'conciliation/FETCH_DETAIL_TO_CONCILIATE';
const UPDATE_DETAIL_TO_CONCILIATE = 'conciliation/UPDATE_DETAIL_TO_CONCILIATE';
const FETCH_REJECT_CONCILIATE = 'conciliation/FETCH_REJECT_CONCILIATE';
const UPDATE_REJECT_CONCILIATE = 'conciliation/UPDATE_REJECT_CONCILIATE';
const FETCH_TYPE_TRANSACTION_CONCILIATE = 'conciliation/FETCH_TYPE_TRANSACTION_CONCILIATE';
const UPDATE_TYPE_TRANSACTION_CONCILIATE = 'conciliation/UPDATE_TYPE_TRANSACTION_CONCILIATE';

export default {
  START_FETCH,
  END_FETCH,
  END_ERROR,
  API_CALL,
  MESSAGE,
  FETCH_LIST,
  UPDATE_LIST,
  FETCH_RECONCILED,
  UPDATE_RECONCILED,
  FETCH_CONCILIATE_DETAIL,
  UPDATE_CONCILIATE_DETAIL,
  FETCH_DETAIL_TO_CONCILIATE,
  UPDATE_DETAIL_TO_CONCILIATE,
  FETCH_REJECT_CONCILIATE,
  UPDATE_REJECT_CONCILIATE,
  FETCH_TYPE_TRANSACTION_CONCILIATE,
  UPDATE_TYPE_TRANSACTION_CONCILIATE,
};
