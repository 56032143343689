const START_FETCH = 'configurations/START_FETCH';
const END_FETCH = 'configurations/END_FETCH';
const END_ERROR = 'configurations/END_ERROR';
const API_CALL = 'configurations/API_CALL';
const MESSAGE = 'configurations/MESSAGE';
const FETCH_LIST_HOLIDAYS = 'configurations/FETCH_LIST_HOLIDAYS';
const UPDATE_LIST_HOLIDAYS = 'configurations/UPDATE_LIST_HOLIDAYS';
const FETCH_EDIT_HOLIDAY = 'configurations/FETCH_EDIT_HOLIDAY';
const UPDATE_DETAIL_HOLIDAY = 'configurations/UPDATE_DETAIL_HOLIDAY';
const DELETE_HOLIDAY = 'configurations/DELETE_HOLIDAY';
const CREATE_HOLIDAY = 'configurations/CREATE_HOLIDAY';
const FETCH_CHARGES_BY_SERVICES = 'configurations/FETCH_CHARGES_BY_SERVICES';
const UPDATE_CHARGES_BY_SERVICES = 'configurations/UPDATE_CHARGES_BY_SERVICES';
const EDIT_CHARGES_BY_SERVICES =  'configurations/EDIT_CHARGES_BY_SERVICES';
const SET_EDIT_CHARGES_BY_SERVICES = 'configurations/SET_EDIT_CHARGES_BY_SERVICES';
const FETCH_COMISSION_BY_SERVICES = 'configurations/FETCH_COMISSION_BY_SERVICES';
const UPDATE_COMISSION_BY_SERVICES = 'configurations/UPDATE_COMISSION_BY_SERVICES';
const EDIT_COMISSION_BY_SERVICES =  'configurations/EDIT_COMISSION_BY_SERVICES';
const SET_EDIT_COMISSION_BY_SERVICES = 'configurations/SET_EDIT_COMISSION_BY_SERVICES';

export default {
  START_FETCH,
  END_FETCH,
  END_ERROR,
  API_CALL,
  MESSAGE,
  FETCH_LIST_HOLIDAYS,
  UPDATE_LIST_HOLIDAYS,
  FETCH_EDIT_HOLIDAY,
  UPDATE_DETAIL_HOLIDAY,
  DELETE_HOLIDAY,
  CREATE_HOLIDAY,
  FETCH_CHARGES_BY_SERVICES,
  UPDATE_CHARGES_BY_SERVICES,
  EDIT_CHARGES_BY_SERVICES,
  SET_EDIT_CHARGES_BY_SERVICES,
  FETCH_COMISSION_BY_SERVICES,
  UPDATE_COMISSION_BY_SERVICES,
  EDIT_COMISSION_BY_SERVICES,
  SET_EDIT_COMISSION_BY_SERVICES,
};
