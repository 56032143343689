import axios                      from 'axios';
import { authActions, authTypes } from '../ducks/auth';
import { transactionsTypes }      from '../ducks/transactions';
import { cardTypes }              from '../ducks/card';
import { usersTypes }             from '../ducks/users';
import { dashboardTypes }         from '../ducks/dashboard';
import { transactionsBindTypes }  from '../ducks/transactionBind';
import { userSupportTypes }       from '../ducks/userSupport';
import { conciliationTypes }      from '../ducks/conciliation';
import { serviceChargeTypes }     from '../ducks/serviceCharge';
import { configurationsTypes }    from '../ducks/configurations';
import { ratesTypes }             from '../ducks/rates';
import { agencyTypes }            from '../ducks/agency';
import { companyTypes }           from '../ducks/company';
import { operatorTypes }          from '../ducks/operator';
import switchTypes                from "../ducks/switch/types";

const api = ({ dispatch, getState }) => next => (action) => {
  const types = [
    authTypes.API_CALL,
    transactionsTypes.API_CALL,
    cardTypes.API_CALL,
    usersTypes.API_CALL,
    dashboardTypes.API_CALL,
    transactionsBindTypes.API_CALL,
    userSupportTypes.API_CALL,
    conciliationTypes.API_CALL,
    serviceChargeTypes.API_CALL,
    configurationsTypes.API_CALL,
    ratesTypes.API_CALL,
    agencyTypes.API_CALL,
    companyTypes.API_CALL,
    operatorTypes.API_CALL,
    switchTypes.API_CALL,
  ];

  if (!types.includes(action.type)) {
    return next(action);
  }

  const {
    config: preConfig,
    authorization,
    onStart = () => {
      console.error('onStart not defined');
    },
    onEnd = () => {
      console.error('onEnd not defined');
    },
    onComplete = (response) => {
      /* console.log('onComplete', response); */
    },
    onError = (error) => {
      console.log('onError', error);
    },
  } = action.payload;
  const { auth: { user, expToken } } = getState();
  const config = authorization ? {
    ...preConfig,
    headers: {
      ...preConfig.headers,
      Authorization: `${user.token}`,
      Exptoken: `${expToken}`,
    },
  } : preConfig;
  dispatch(onStart());
  axios(config)
    .then((response) => {
      const { status } = response;
      if (status === 401) {
        dispatch(authActions.logout());
      }
      dispatch(authActions.updateExpToken({ expToken: response.data.expToken }));
      onComplete(response);
      dispatch(onEnd(response));
    })
    .catch((error) => {
      const { response } = error;
      if (response) {
        const { status } = response;
        if (status === 401) {
          dispatch(authActions.logout());
        }
        onError(error);
        dispatch(onEnd(error));
      } else {
        dispatch(authActions.logout());
        onError(error);
        dispatch(onEnd(error));
      }
    });
};
export default [api];
