import { createAction } from 'redux-actions';
import types            from './types';

const startFetch = createAction(types.START_FETCH);
const endFetch = createAction(types.END_FETCH);
const fetchList = createAction(types.FETCH_LIST);
const updateList = createAction(types.UPDATE_LIST);
const fetchReconciled = createAction(types.FETCH_RECONCILED);
const updateReconciled = createAction(types.UPDATE_RECONCILED);
const fetchConciliateDetail = createAction(types.FETCH_CONCILIATE_DETAIL);
const updateConciliateDetail = createAction(types.UPDATE_CONCILIATE_DETAIL);
const fetchDetailToConciliate = createAction(types.FETCH_DETAIL_TO_CONCILIATE);
const updateDetailToConciliate = createAction(types.UPDATE_DETAIL_TO_CONCILIATE);
const fetchRejectConciliate = createAction(types.FETCH_REJECT_CONCILIATE);
const updateRejectConciliate = createAction(types.UPDATE_REJECT_CONCILIATE);
const fetchTypeTransactionConciliate = createAction(types.FETCH_TYPE_TRANSACTION_CONCILIATE);
const updateTypeTransactionConciliate = createAction(types.UPDATE_TYPE_TRANSACTION_CONCILIATE);

const showMessage = createAction(types.MESSAGE,
  ({ message }) => ({
    message,
  }),
  ({ config }) => ({
    config,
  }));

export default {
  startFetch,
  endFetch,
  showMessage,
  fetchList,
  updateList,
  fetchReconciled,
  updateReconciled,
  fetchConciliateDetail,
  updateConciliateDetail,
  fetchDetailToConciliate,
  updateDetailToConciliate,
  fetchRejectConciliate,
  updateRejectConciliate,
  fetchTypeTransactionConciliate,
  updateTypeTransactionConciliate,
};
