import React from 'react';
import './styles.scss';
import {withNamespaces} from 'react-i18next';
import {Form, TextInput} from '../../../../../components';
import Recaptcha from 'react-recaptcha';
import { KEY_CAPTCHA } from "../../../../../config/constants";

const FormLogin = (props) => {
  const {
    t, handleSubmit, options, handleInputChange, requiredUser, requiredPassword,
    verifyCallback, callback, isCaptcha, viewCaptcha,

  } = props;
  return (
    <div className="FormLogin">
      <Form
        className="column"
        submitText={t('enter')}
        onSubmit={handleSubmit}
        buttonClasses="btn-login"
      >
        <TextInput
          tabIndex="1"
          name="username"
          placeholder={t('user')}
          type="text"
          onChange={handleInputChange}
          inputClasses="txt-input"
        />
        <div style={requiredUser} className="required req-user">{t('requiredUser')}</div>
        <TextInput
          tabIndex="2"
          placeholder={t('password')}
          type="password"
          name="password"
          onChange={handleInputChange}
          inputClasses="txt-input mt"
        />
        <div style={requiredPassword} tabIndex="3" className="required req-pass">{t(
          'requiredPassword')}</div>
        <div style={viewCaptcha}>
          <Recaptcha
            sitekey={ KEY_CAPTCHA }
            render="explicit"
            verifyCallback={verifyCallback}
            onloadCallback={callback}
            hl="es"
          />
          <div style={isCaptcha} className="required req-capt">{t('requiredCaptcha')}</div>
        </div>
      </Form>
    </div>
  );
};

export default withNamespaces()(FormLogin);
