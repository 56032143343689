import { createAction } from 'redux-actions';
import types            from './types';

const startFetch = createAction(types.START_FETCH);
const endFetch = createAction(types.END_FETCH);
const endError = createAction(types.END_ERROR);

const showMessage = createAction(types.MESSAGE,
  ({ message }) => ({
    message,
  }),
  ({ config }) => ({
    config,
  }));

const fetchRateList = createAction(types.FETCH_RATE_LIST);
const setRateList = createAction(types.SET_RATE_LIST);
const newRate = createAction(types.NEW_RATE);
const fetchDestinationTypes = createAction(types.FETCH_DESTINATION_TYPES);
const setDestinationTypes = createAction(types.SET_DESTINATION_TYPES);
const updateRate = createAction(types.UPDATE_RATE);
const deleteRate = createAction(types.DELETE_RATE);
const activeDisabledRate = createAction(types.ACTIVE_DISABLED_RATE);

export default {
  startFetch,
  endFetch,
  endError,
  showMessage,
  fetchRateList,
  setRateList,
  newRate,
  fetchDestinationTypes,
  setDestinationTypes,
  updateRate,
  deleteRate,
  activeDisabledRate,
}


