import types from './types';
import { initialState } from '../../../App';

const company = (state = {}, action) => {
  switch (action.type) {
    case types.CLEAR: {
      return {
        ...state, ...initialState.company,
      };
    }
    case types.START_FETCH: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.END_FETCH: {
      const { error, payload } = action;
      return {
        ...state,
        loading: false,
        error: error ? payload : null,
      };
    }
    case types.UPDATE_TRANSPORT_COMPANY: {
      const { data: { company, count_company } } = action.payload;
      return {
        ...state,
        companyTransport: {
          list: company,
          totalElements: count_company,
        },
      }
    }
    default:
      return state;
  }
};

export default company;
