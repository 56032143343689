import React              from 'react';
import { withNamespaces } from 'react-i18next';
import PropTypes          from 'prop-types';
import AriaTabPanel       from 'react-aria-tabpanel';
import './styles.scss';

class Tabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activeTab: 't1' };
    this.setTab = this.setTab.bind(this);
  }

  setTab(newActiveTabId) {
    this.setState({ activeTab: newActiveTabId });
  }

  render() {
    const { activeTab } = this.state;
    const { listTabs, t } = this.props;
    const tabs = listTabs.map((tabDescription, i) => {
      let innerCl = 'Tabs-tabInner';
      if (tabDescription.id === activeTab) innerCl += ' is-active';
      return (
        <li className="Tabs-tablistItem" key={i}>
          <AriaTabPanel.Tab
            id={tabDescription.id}
            className="Tabs-tab"
            active={tabDescription.id === activeTab}
          >
            <div className={innerCl}>
              {t(tabDescription.title)}
            </div>
          </AriaTabPanel.Tab>
        </li>
      );
    });

    const panels = listTabs.map((tabDescription, i) => (
      <AriaTabPanel.TabPanel
        key={i}
        tabId={tabDescription.id}
        active={tabDescription.id === activeTab}
      >
        {tabDescription.content}
      </AriaTabPanel.TabPanel>
    ));

    return (
      <AriaTabPanel.Wrapper
        onChange={this.setTab}
        activeTabId={activeTab}
      >
        <AriaTabPanel.TabList>
          <ul className="Tabs-tablist">
            {tabs}
          </ul>
        </AriaTabPanel.TabList>
        <div className="Tabs-panel">
          {panels}
        </div>
      </AriaTabPanel.Wrapper>
    );
  }
}

Tabs.propTypes = {
  t: PropTypes.func.isRequired,
  listTabs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      content: PropTypes.oneOfType([
        PropTypes.element,
      ]),
    }),
  ).isRequired,
};
export default withNamespaces()(Tabs);
