import React        from 'react';
import ReactTooltip from 'react-tooltip';
import './styles.scss';
import PropTypes    from "prop-types";

const TransactionRow = (props) => {
  const { children, openDetail, helper, id, typeButtonDetail, icon } = props;
  return (
    <tr id={id}>
      {children}
      {
        openDetail
        && (
          <td>
            {typeButtonDetail === 'text' &&
            <button
              type="button"
              className="button is-outlined is-small"
              onClick={openDetail}
            >
              {helper}
            </button>
            }
            {typeButtonDetail === 'button' && <button
              type="button"
              className="button is-small is-white"
              onClick={openDetail}
              data-for="tooltip"
              data-tip=""
            >
              <span className="icon is-small">
                <i className={icon}>
                  {helper && (
                    <ReactTooltip
                      id="tooltip"
                      effect="solid"
                      className='text-toltip'
                    >
                      {helper}
                    </ReactTooltip>
                  )}
                </i>
              </span>
            </button>}
          </td>
        )
      }
    </tr>
  );
};
TransactionRow.propTypes = {
  openDetail: PropTypes.func,
  helper: PropTypes.string,
  icon: PropTypes.string,
  id: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  typeButtonDetail: PropTypes.string,
};

TransactionRow.defaultProps = {
  openDetail: null,
  helper: '',
  id: '',
  icon: 'fas fa-play',
  typeButtonDetail: 'button',

};

export default TransactionRow;
