import {createAction} from "redux-actions";

const START_FETCH = 'operator/START_FETCH';
const END_FETCH = 'operator/END_FETCH';
const END_ERROR = 'operator/END_ERROR';
const API_CALL = 'operator/API_CALL';
const MESSAGE = 'operator/MESSAGE';
const FETCH_LIST_SWITCH = 'switch/FETCH_LIST_SWITCH';
const UPDATE_LIST_SWITCH = 'switch/UPDATE_LIST_SWITCH';
const FETCH_DETAIL_SWITCH = 'switch/FETCH_DETAIL_SWITCH';
const UPDATE_DETAIL_SWITCH = 'switch/UPDATE_DETAIL_SWITCH';
const FETCH_TRANSACTION_TYPE_SWITCH = 'switch/FETCH_TRANSACTION_TYPE_SWITCH';
const UPDATE_TRANSACTION_TYPE_SWITCH = 'switch/UPDATE_TRANSACTION_TYPE_SWITCH';
const FETCH_TRANSACTION_STATUS_SWITCH = 'switch/FETCH_TRANSACTION_STATUS_SWITCH';
const UPDATE_TRANSACTION_STATUS_SWITCH = 'switch/UPDATE_TRANSACTION_STATUS_SWITCH';
const FETCH_TRANSACTIONS_ALL_EXCEL = 'switch/FETCH_TRANSACTIONS_ALL_EXCEL';

export default {
    START_FETCH,
    END_FETCH,
    END_ERROR,
    API_CALL,
    MESSAGE,
    FETCH_LIST_SWITCH,
    UPDATE_LIST_SWITCH,
    FETCH_DETAIL_SWITCH,
    UPDATE_DETAIL_SWITCH,
    FETCH_TRANSACTION_TYPE_SWITCH,
    UPDATE_TRANSACTION_TYPE_SWITCH,
    FETCH_TRANSACTION_STATUS_SWITCH,
    UPDATE_TRANSACTION_STATUS_SWITCH,
    FETCH_TRANSACTIONS_ALL_EXCEL,
}