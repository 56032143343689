import types            from './types';
import { initialState } from '../../../App';

const agency = (state = {}, action) => {
  switch (action.type) {
    case types.CLEAR: {
      return {
        ...state, ...initialState.transactions,
      };
    }
    case types.START_FETCH: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.END_FETCH: {
      const { error, payload } = action;
      return {
        ...state,
        loading: false,
        error: error ? payload : null,
      };
    }
    case types.SET_STATUS_CONCILIATE: {
      const { data } = action.payload;
      return {
        ...state,
        statusConciliate: data
      }
    }
    case types.SET_AGENCY_LIST: {
      const { data } = action.payload;
      const items = data.map(option => ({ id: option.id, name: option.agency_name }));
      const total = data.length;
      return {
        ...state,
        agencyListSelect: items,
        agencyList: {
          list: data,
          totalElements: total,
        },
      }
    }
    case types.SET_MOVEMENT_AGENCIES: {
      const { data: { count_transactions, transactions } } = action.payload;
      return {
        ...state,
        movementAgencyList: {
          list: transactions,
          totalElements: count_transactions,
        },
      }
    }
    case types.SET_TRANSACTIONS_LIST: {
      const { data: { balance, alias, cvu, count_transactions, transactions } } = action.payload;
      return {
        ...state,
        movementAccountList: {
          alias: alias,
          cvu: cvu,
          amount: balance,
          list: transactions,
          totalElements: count_transactions,
        },
      }
    }
    case types.SET_INFO_AGENCIES: {
      const { data } = action.payload;
      const total = data.length;
      return {
        ...state,
        agenciesInfo: {
          list: data,
          totalElements: total,
        },
      }
    }
    case types.SET_TRANSACTION_TYPE: {
      const { data } = action.payload;
      return {
        ...state,
        transactionType: data
      }
    }
    case types.SET_TRANSACTION_TYPE_AGENCIES: {
      const { data } = action.payload;
      return {
        ...state,
        transactionTypeAgencies: data
      }
    }
    case types.SET_BOX_AGENCY_LIST: {
      const { data: { cashes, pagination: { totalCount } } } = action.payload;
      return {
        ...state,
        boxAgency: {
          list: cashes,
          totalElements: totalCount,
        },
      }
    }
    case types.SET_STATUS_BOX: {
      const { data } = action.payload;
      return {
        ...state,
        statusBox: data
      }
    }
    case types.SET_LOTSINFO_LIST: {
      const { data: { lots, pagination: { totalCount } } } = action.payload;
      return {
        ...state,
        lotsInfo: {
          list: lots,
          totalElements: totalCount,
        },
      }
    }
    case types.SET_DETAIL_BOX_AGENCY: {
      const { data } = action.payload;
      return {
        ...state,
        detailBox: data
      }
    }
    case types.SET_STATUS_LOT: {
      const { data } = action.payload;
      return {
        ...state,
        statusLot: data
      }
    }
    case types.UPDATE_COMPANY_TYPES: {
      const { data } = action.payload;
      return {
        ...state,
        companyTypes: data,
      }
    }
    case types.UPDATE_AGENCY_LIST: {
      const { data: { agencies, count_agencies } } = action.payload;
      return {
        ...state,
        getAgencyList: {
          list: agencies,
          totalElements: count_agencies,
        },
      }
    }
    case types.UPDATE_LIST_ROLES: {
      const { data } = action.payload;
      return {
        ...state,
        roles: data,
      }
    }
    case types.UPDATE_LIST_SIMPLE_AGENCY: {
      const { data } = action.payload;
      return {
        ...state,
        agencyListSelect: data,
      }
    }
    default:
      return state;
  }
};

export default agency;
