import { createAction } from 'redux-actions';
import types            from './types';

const startFetch = createAction(types.START_FETCH);
const endFetch = createAction(types.END_FETCH);
const fetchListSwitch = createAction(types.FETCH_LIST_SWITCH);
const updateListSwitch = createAction(types.UPDATE_LIST_SWITCH);
const fetchDetailSwitch = createAction(types.FETCH_DETAIL_SWITCH);
const updateDetailSwitch = createAction(types.UPDATE_DETAIL_SWITCH);
const fetchTransactionTypeSwitch = createAction(types.FETCH_TRANSACTION_TYPE_SWITCH);
const updateTransactionTypeSwitch = createAction(types.UPDATE_TRANSACTION_TYPE_SWITCH);
const fetchTransactionStatusSwitch = createAction(types.FETCH_TRANSACTION_STATUS_SWITCH);
const updateTransactionStatusSwitch = createAction(types.UPDATE_TRANSACTION_STATUS_SWITCH);
const fetchTransactionsAllExcel = createAction(types.FETCH_TRANSACTIONS_ALL_EXCEL);

const showMessage = createAction(types.MESSAGE,
    ({ message }) => ({
        message,
    }),
    ({ config }) => ({
        config,
    }));

export default {
    startFetch,
    endFetch,
    showMessage,
    fetchListSwitch,
    updateListSwitch,
    fetchDetailSwitch,
    updateDetailSwitch,
    fetchTransactionTypeSwitch,
    updateTransactionTypeSwitch,
    fetchTransactionStatusSwitch,
    updateTransactionStatusSwitch,
    fetchTransactionsAllExcel,
}
