import React, { Component }    from 'react';
import { withNamespaces }      from 'react-i18next';
import { compose }             from 'redux';
import { connect }             from 'react-redux';
import { transactionsActions } from '../../state/ducks/transactions';
import AmountFormat            from '../AmountFormat';
import './styles.scss';

class BalanceBox extends Component {
  render() {
    const {
      t, dashboard: { balance, balanceBusiness } , auth: { currency }, transactions: { balanceAccountFundraiser }
    } = this.props;
    const balanceFormat = balance != 0  && balance != undefined ? balance.replace('.', ',') : 0;
    const balanceFundraiserFormat = balanceAccountFundraiser > 0 && balanceAccountFundraiser.toString().search(
      '.') > 0 ? balanceAccountFundraiser.replace('.', ',') : balanceAccountFundraiser;
    return (
      <div className="balanceBox mt-16px mb-16px" style={{ animationDelay: '1s' }}>
        <div className="columns wow animated slideInDown">
          <div className="column is-full test">
            <div className="card">
              <div className="columns is-multiline">

                <div className="column is-half text-balance">
                  <div className="title-balance">{t('balanceAvailable')}</div>
                </div>
                <div className="column is-half row-balance">
                  <div className="balance">
                    <AmountFormat
                        name="balance"
                        value={balanceFundraiserFormat}
                        currency={currency}
                        displayType="text"
                    />
                  </div>
                </div>

                {/* <div className="column is-half text-balance">
                  <div className="title-balance">{t('balanceAccountSusa')}</div>
                </div>
                <div className="column is-half">
                  <div className="balance-susa">
                    <AmountFormat
                        name="balance"
                        value={balanceBusiness}
                        currency={currency}
                        displayType="text"
                        allowNegative={true}
                    />
                  </div>
                </div> */}

                <div className="column is-half text-balance">
                  <div className="title-balance">{t('balanceAccountFundraiser')}</div>
                </div>
                <div className="column is-half">
                  <div className="has-text-danger text-balance">
                    <AmountFormat
                        name="balance"
                        value={balanceFormat}
                        currency={currency}
                        displayType="text"
                        allowNegative={true}
                    />
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ transactions, dashboard, auth }) => (
  {
    transactions,
    dashboard,
    auth,
  }
);
export default compose(
  connect(mapStateToProps, {
    ...transactionsActions,
  }),
  withNamespaces(),
)(BalanceBox);
