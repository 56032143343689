import React, { Fragment } from 'react';
import { withNamespaces }  from 'react-i18next';
import { compose }         from 'redux';
import { connect }         from 'react-redux';
import { FormElement }     from '../../../components';
import { agencyActions }   from '../../../state/ducks/agency';
import { authActions }     from "../../../state/ducks/auth";
import { usersActions }    from "../../../state/ducks/users";
import PropTypes           from "prop-types";

const operatorDetailForm = (props) => {
  const {
    t,
    editable,
    agencyText,
    operator: {
      idAgency,
      rol,
    },
    handleInputChange,
    agency: { agencyListSelect, roles, },
  } = props;

  return (
    <Fragment>
      <div className="columns is-multiline">
        <div className="column is-full">
          <h4>{t('agencyRolData')}</h4>
          <hr className="dots" />
        </div>
        <FormElement
          name='idAgency'
          onChange={(e) => handleInputChange(e)}
          value={idAgency}
          label={t('agency')}
          typeElement={'select'}
          optionsSelect={agencyListSelect}
          longColumn={'is-12'}
          controlClasses="select"
          // disabledNullSelect
          disabled={!editable || agencyText}
          placeholder={t('selectAngency')}
        />
        <FormElement
          name='rol'
          onChange={(e) => handleInputChange(e)}
          value={rol}
          label={t('rol')}
          typeElement={'select'}
          optionsSelect={roles}
          longColumn={'is-12'}
          controlClasses="select"
          // disabledNullSelect
          disabled={!editable}
          placeholder={t('selectRol')}
        />
      </div>
    </Fragment>
  );
};

operatorDetailForm.propTypes = {
  t: PropTypes.func.isRequired,
  editable: PropTypes.bool,
  agencyText: PropTypes.bool,
  operator: PropTypes.shape({
    idAgency: PropTypes.string,
    rol: PropTypes.string,
  }),
  handleInputChange: PropTypes.func,
};

operatorDetailForm.defaultProps = {
  editable: false,
  agencyText: false,
  operator: {},
  handleInputChange: null,
};

const mapStateToProps = ({ agency, auth, users, }) => (
  {
    agency,
    auth,
    users,
  }
);
export default compose(
  connect(mapStateToProps, {
    ...agencyActions,
    ...authActions,
    ...usersActions,
  }),
  withNamespaces(),
)(operatorDetailForm);

