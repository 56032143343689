import React, { Component } from 'react';
import PropTypes            from 'prop-types';

class PrimaryButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wait: false,
    };
  }

  handleLoading(event) {
    const { onClick } = this.props;
    const { wait } = this.state;
    if (onClick !== '') onClick(event);
    if (!wait) {
      this.setState(() => ({
        wait: true,
      }));
    }
  }

  render() {
    const {
            disabled, name, type,
          } = this.props;
    const { wait } = this.state;
    const stateDisabled = wait || disabled;
    return (
      <div className="m-8px">
        <button
          type={type}
          onClick={(event) => {
            this.handleLoading(event);
          }}
          className="button is-custom-primary"
          disabled={disabled}
        >
          {name}
        </button>
      </div>
    );
  }
}

PrimaryButton.propTypes = {
  onClick: PropTypes.any,
  name: PropTypes.string.isRequired,
  type: PropTypes.any,
  disabled: PropTypes.bool,
};
PrimaryButton.defaultProps = {
  type: 'button',
  onClick: '',
  disabled: false,
};

export default PrimaryButton;
