import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {Link, Route} from 'react-router-dom';
import {
    Form,
    FormElement,
    Panel,
    AmountFormat,
    TransactionRow,
    Loading,
    Filter,
    ContentHeader, DefaultButton, TableList, Box, BalanceBox
} from '../../../components';
import { initTimer, stopTimer } from '../../../util/initTimer';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { transactionsActions } from '../../../state/ducks/transactions';
import { authActions } from '../../../state/ducks/auth';
import './styles.scss';

class AccountDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            transaction: '',
        }
    }
    componentDidMount() {
        const { location } = this.props;
        stopTimer();
        initTimer(this.props);
        if (location !== undefined && location.state.transaction !== undefined) {
            this.setState({
                transaction: location.state.transaction,
            });
        }
    }
    render () {
        const { transaction } = this.state;
        const {t} = this.props;
        return (
            <div>
                <Filter title={t('accountMovements')+': '+ t('detailTransaction')} />
                <Link to={{
                    pathname: '/account-info',
                }}
                >
                    <DefaultButton name={t('goBack')}  />
                </Link>

                <Panel headingText={t('detailOperationTransaction')}>

                    <div className="columns">
                        <div className="column is-5 title-left"><span className="title-bold">{t('dateHour')}:</span> {transaction.transaction_date}</div>
                        <div className="column is-7 title-right"><span className="title-bold">{t('codeofTransaction')}:</span> {transaction.transaction_code}</div>
                    </div>
                    <div className="columns">
                        <div className="column is-5 title-left"><span className="title-bold">{t('typeOperation')}:</span> {transaction.transaction_type}</div>
                        <div className="column is-7 title-right"><span className="title-bold">{t('ammountTransaction')}:</span> {transaction.transaction_amount}</div>
                    </div>
                    <div className="columns">
                        <div className="column is-5 title-left"><span className="title-bold">{t('state')}:</span> {transaction.transaction_status}</div>
                        <div className="column is-7"></div>
                    </div>
                    <div className="columns">
                        <div className="bar-bottom"></div>
                    </div>

                </Panel>
            </div>
        )
    }
}
AccountDetails.propTypes = {
    t: PropTypes.func.isRequired,
    list_susa: PropTypes.func,
};
const mapStateToProps = ({ transactions, }) => ({
    transactions,
});
export default compose(connect(mapStateToProps, {
    ...transactionsActions,
}), withNamespaces(),)(AccountDetails);
