import React, { Component, Fragment }  from 'react';
import { withNamespaces }              from 'react-i18next';
import PropTypes                       from 'prop-types';
import { compose }                     from 'redux';
import { connect }                     from 'react-redux';
import { toast }                       from 'react-toastify';
import {
  AmountFormat,
  ContentHeader,
  DefaultButton,
  Filter,
  FormElement,
  Loading,
  NameAgency,
  TableList,
  TransactionRow,
  ExportExcel,
}                                      from '../../../components';
import { initTimer, stopTimer, }       from '../../../util/initTimer';
import './styles.scss';
import { SIZE_PER_PAGE, TOAST_CONFIG } from '../../../config/constants';
import { authActions }                 from '../../../state/ducks/auth';
import { usersActions }                from '../../../state/ducks/users';
import { agencyActions }               from "../../../state/ducks/agency";
import { Link }                        from "react-router-dom";
import { formatDate }                  from "../../../util";

class MovementAgencyList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      transactionTypeAgencies: '',
      page: 0,
      dateStart: new Date(((new Date()).setDate((new Date()).getDate()))),
      dateEnd: new Date(((new Date()).setDate((new Date()).getDate()))),
    };

    this.callApiList = this.callApiList.bind(this);
  }

  componentDidMount() {
    stopTimer();
    initTimer(this.props);

    const { fetchTransactionsTypeAgencies, location } = this.props;

    if (location.state !== undefined && location.state.id !== undefined) {
      this.setState({ id: location.state.id, agency: location.state.agency },
        () => {this.callApiList()});
    }

    fetchTransactionsTypeAgencies();

  }


  onChangePage = (selectPage) => {
    stopTimer();
    initTimer(this.props);
    this.setState(() => ({
      page: selectPage ? selectPage.selected : 0,
    }), () => {
      this.callApiList();
    });
  };

  callApiList = (getExcel = false) => {
    const {
      t,
      fetchMovementAgencies,
      showMessage,
      agency: { transactionTypeAgencies, movementAgencyList: { totalElements } },
    } = this.props;

    const {
      page,
      id,
      typeTransaction,
      dateStart,
      dateEnd
    } = this.state;

    const type = transactionTypeAgencies.find(item => item.value === typeTransaction);

    fetchMovementAgencies({
      data: {
        id: id,
        typeTransaction: type !== undefined ? type.id : '',
        pageSize: !getExcel ? SIZE_PER_PAGE : totalElements,
        export: getExcel,
        page: getExcel ? 0 : page,
        date_from: formatDate(dateStart),
        date_to: formatDate(dateEnd),
      },
      callback: (response) => {
        if (!response.success) {
          toast.dismiss();
          showMessage({
            message: t('connectionError'),
            config: TOAST_CONFIG.ERROR,
          });
        }
      },
    });
  }

  handleInputChange = (event) => {
    stopTimer();
    initTimer(this.props);
    const {
      target: {
        value: targetValue, name,
      },
    } = event;
    this.setState({
      [name]: targetValue,
    });
  };

  onChangeDate = (date, name) => {
    stopTimer();
    initTimer(this.props);
    const dateValue = date !== null ? date : '';
    this.setState({
      [name]: dateValue,
    });
  };

  onSubmit = (event) => {
    event.preventDefault();
    this.onChangePage();
  };

  render() {
    const {
      t,
      auth: { account: { product: currency } },
      agency: {
        movementAgencyList: {
          list,
          totalElements,
        },
        transactionTypeAgencies,
        loading
      }
    } = this.props;
    const {
      typeTransaction, page, agency, dateStart, dateEnd,
    } = this.state;

    return (
      <div>
        {loading && <Loading />}
        <Fragment>
          <div className="wow animated slideInDown">
            <NameAgency agency={agency} />
            <div className="wow animated slideInDown">
              <Filter
                title={t('balanceAccounts')}
                subtitle={t('filters')}
                onSubmit={(ev) => { this.onSubmit(ev); }}
              >
                <FormElement
                  onChange={ev => this.onChangeDate(ev, 'dateStart')}
                  label={t('dateStart')}
                  value={dateStart}
                  typeElement="datepicker"
                  maxDate={dateEnd || new Date()}
                />
                <FormElement
                  onChange={ev => this.onChangeDate(ev, 'dateEnd')}
                  value={dateEnd}
                  label={t('dateEnd')}
                  typeElement="datepicker"
                  maxDate={new Date()}
                  minDate={dateStart}
                />
                <FormElement
                  name="typeTransaction"
                  onChange={this.handleInputChange}
                  value={typeTransaction}
                  label={t('transactionsType')}
                  optionsSelect={transactionTypeAgencies}
                  placeholder="Seleccione..."
                  typeElement="select"
                  controlClasses="select select-movement"
                />
              </Filter>
            </div>
            <ContentHeader
              headingText={
                <Link to={{
                  pathname: '/info-agency',
                  state: { agency }
                }}
                >
                  <DefaultButton name={t('goBack')} />
                </Link>
              }
              headingRightText={t('totalTransactions')}
              headingRightSubText={`${totalElements}`}
            >
              <ExportExcel onclick={() => { this.callApiList(true) }}/>
              <TableList
                tableClasses="floating-table"
                totalElements={parseFloat(totalElements !== undefined ? totalElements : 0)}
                onChangePage={this.onChangePage}
                forcePage={page}
                pageSize={SIZE_PER_PAGE}
                head={(
                  <tr>
                    <th>{t('date')}</th>
                    <th>{t('transactionType')}</th>
                    <th>{t('transactionCode')}</th>
                    <th>{`${t('amount')} (${currency})`}</th>
                    <th>{t('state')}</th>
                    <th />
                  </tr>
                )}
                colNumber={6}
                lenghtData={totalElements !== undefined ? totalElements : 0}

              >
                {list && (list.map(row => (
                  <TransactionRow
                    key={row.id}
                    helper={t('detailReport')}
                  >
                    <td>{row.transaction_date}</td>
                    <td>{row.transaction_type}</td>
                    <td>{row.transaction_code}</td>
                    <td>
                      <AmountFormat
                        name="transactionsAmount"
                        value={row.transaction_amount}
                        displayType="text"
                      />
                    </td>
                    <td>{row.transaction_status}</td>
                    <td />
                  </TransactionRow>
                )))
                }
              </TableList>
            </ContentHeader>
          </div>
        </Fragment>
      </div>
    );
  }
}

MovementAgencyList.propTypes = {
  t: PropTypes.func.isRequired,
  showMessage: PropTypes.func,
  fetchMovementAgencies: PropTypes.func.isRequired,
  fetchTypePerson: PropTypes.func,
};

MovementAgencyList.defaultProps = {
  showMessage: () => {
  },
  fetchTypePerson: () => {
  },
};

const mapStateToProps = ({ agency, auth, users }) => (
  {
    agency,
    auth,
    users,
  }
);
export default compose(
  connect(mapStateToProps, {
    ...agencyActions,
    ...authActions,
    ...usersActions,
  }),
  withNamespaces(),
)(MovementAgencyList);
