import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { toast } from 'react-toastify';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { authActions } from '../../../state/ducks/auth';
import { switchActions } from '../../../state/ducks/switch';
import { initTimer, stopTimer } from '../../../util/initTimer';
import { SIZE_PER_PAGE, TOAST_CONFIG } from '../../../config/constants';
import { formatDate } from '../../../util';
import { Link } from 'react-router-dom';
import {
    Loading,
    Filter,
    FormElement,
    ContentHeader,
    TableList,
    TransactionRow,
    ExportExcel,
    AmountFormat,
} from '../../../components';
import { confirmAlert } from 'react-confirm-alert';

export class Switch extends Component {
    constructor (props) {
      super (props);
      this.state = {
        pageSize: 20,
        dateStart: '',
        dateEnd: '',
        account_s: '',
        account_d: '',
        type: '',
        status: '',
        code_bind: '',
        disable: false,
      }
    }

    componentDidMount() {
      const {fetchTransactionTypeSwitch, fetchTransactionStatusSwitch } = this.props;
      const { disable } = this.state;
      fetchTransactionTypeSwitch();
      fetchTransactionStatusSwitch();
      stopTimer();
      initTimer(this.props);
      this.callApiList();
      if (disable === true) {
        return this.showMessageTransfer(this.message());
      }
    }

    showMessageTransfer = (message) => {
      const { t } = this.props;
      const options = {
        customUI: () => (
          <div className='information-alert message columns is-multiline'>
            <div className='column is-full has-text-centered text-message'>
              {message}
            </div>
            <div className='column is-full has-text-centered'>
              <button
                className='button is-outlined'
                onClick={() => window.location.href = '/dashboard'}
              >
                {t('accept')}
              </button>
            </div>
          </div>
        ),
        closeOnEscape: false,
        closeOnClickOutside: false,
      };
      return (confirmAlert(options));
    }

    message = () => {
      const { t } = this.props;
      return (
        <div className='has-text-centered mt-16px'>
          <span>
            <i className='fas fa-chart-line has-text-success' /> {t('comingSoon')} <i className='fas fa-chart-line has-text-success' />
          </span>
        </div>
      );
    };

    onChangePage = (selectPage) => {
        stopTimer();
        initTimer(this.props);
        this.setState(() => ({
            page: selectPage ? selectPage.selected : 0,
        }), () => {
            this.callApiList();
        });
    };

    callApiList = (getExcel = false) => {
        const { t, fetchListSwitch, showMessage, switchs: { switch: { list, totalElements} } } = this.props;
        const { page, dateStart, dateEnd, status, type, account_s, account_d, code_bind } = this.state;

        fetchListSwitch({
            data: {
                pageSize: !getExcel ? SIZE_PER_PAGE : totalElements,
                page: getExcel ? 0 : page,
                export: getExcel,
                date_from: formatDate(dateStart),
                date_to: formatDate(dateEnd),
                status,
                type,
                account_s,
                account_d,
                code_bind,
            }, callback: (response) => {
                if (response) {
                    if (!response.success) {
                        toast.dismiss();
                        showMessage({
                            message: t('connectionError'),
                            config: TOAST_CONFIG.ERROR,
                        });
                    }
                } else {
                    toast.dismiss();
                    showMessage({
                        message: t('connectionError'),
                        config: TOAST_CONFIG.ERROR,
                    });
                }
            },
        });
    };

    onChangeDate = (date, name) => {
        stopTimer();
        initTimer(this.props);
        const dateValue = date !== null ? date : '';
        this.setState({
            [name]: dateValue,
        });
    };

    handleInputChange = (event) => {
        stopTimer();
        initTimer(this.props);
        const { target: { value: targetValue, name } } = event;
        this.setState({
            [name]: targetValue,
        });
    };

    onSubmit = (event) => {
        event.preventDefault();
        this.callApiList();
    };

    render () {
        const { t, switchs: { switch: { list, totalElements}, switch_types, switch_status }, loading } = this.props;
        const { dateStart, dateEnd, status, type, account_s, account_d, code_bind } = this.state;
        return (
            <div>
                { loading && <Loading /> }
                <Fragment>
                    <div className="wow animated slideInDown">
                        <div className="wow animated slideInDown">
                            <Filter
                                title={t('switchTitle')}
                                onSubmit={(ev) => {this.onSubmit(ev);}}
                            >
                                <FormElement
                                    onChange={ev => this.onChangeDate(ev, 'dateStart')}
                                    label={t('dateStart')}
                                    value={dateStart}
                                    typeElement="datepicker"
                                    maxDate={dateEnd || new Date()}
                                />
                                <FormElement
                                    onChange={ev => this.onChangeDate(ev, 'dateEnd')}
                                    value={dateEnd}
                                    label={t('dateEnd')}
                                    typeElement="datepicker"
                                    maxDate={new Date()}
                                    minDate={dateStart}
                                />
                                <FormElement
                                    name='type'
                                    onChange={this.handleInputChange}
                                    value={type}
                                    label={t('typeTransfer')}
                                    optionsSelect={switch_types}
                                    placeholder={t('select')}
                                    typeElement="select"
                                    controlClasses="select"
                                />
                                <FormElement
                                    name='status'
                                    onChange={this.handleInputChange}
                                    value={status}
                                    label={t('state')}
                                    optionsSelect={switch_status}
                                    placeholder={t('select')}
                                    typeElement="select"
                                    controlClasses="select"
                                />
                                <FormElement
                                    name="account_s"
                                    onChange={this.handleInputChange}
                                    value={account_s}
                                    label={`CVU/${t('cvuAccSender')}`}
                                />
                                <FormElement
                                    name="account_d"
                                    onChange={this.handleInputChange}
                                    value={account_d}
                                    label={`CVU/CBU/${t('accountDestination')}`}
                                />
                                <FormElement
                                    name='code_bind'
                                    onChange={this.handleInputChange}
                                    value={code_bind}
                                    label={t('codeTransactionBind')}
                                />
                            </Filter>
                            </div>
                            <ContentHeader
                                headingRightText={t('totalElements') }
                                headingRightSubText={totalElements}
                            >
                              <ExportExcel onclick={() => { this.callApiList(true) }} />
                                <TableList
                                    tableClasses="floating-table"
                                    totalElements={parseFloat(totalElements)}
                                    onChangePage={this.onChangePage}
                                    head={(
                                        <tr>
                                            <th>{`${t('date')} / ${t('hour')}`}</th>
                                            <th>{t('typeTransaction')}</th>
                                            <th>{t('codeBind')}</th>
                                            <th>{t('cameCode')}</th>
                                            <th>{`CVU/${t('cvuAccSender')}`}</th>
                                            <th>{`CVU/CBU/${t('accountDestination')}`}</th>
                                            <th>{t('amount')}</th>
                                            <th>{t('state')}</th>
                                            <th>{t('actions')}</th>
                                        </tr>)}
                                    colNumber={10}
                                    lenghtData={totalElements !== undefined ? totalElements : 0}
                                >
                                    {(list !== undefined || list !== '') && (list.map(row => (
                                        <TransactionRow
                                            id={row.id}
                                        >
                                            <td>{row.created_at}</td>
                                            <td>{row.type}</td>
                                            <td>{row.code_bind}</td>
                                            <td>{row.transaction_code}</td>
                                            <td>{row.account_s}</td>
                                            <td>{row.account_d}</td>
                                            <td>{row.amount}</td>
                                            <td>{row.status}</td>
                                            <td>
                                                <Link to={{
                                                    pathname: '/detail-switch',
                                                    state: {
                                                        id: row.id,
                                                        detalleSwitch: row
                                                    }
                                                }}
                                                >
                                                    <button
                                                        className='button is-outlined is-small'
                                                    >
                                                        {t('showDetail')}
                                                    </button>
                                                </Link>
                                            </td>
                                        </TransactionRow>
                                    )))}
                                </TableList>
                            </ContentHeader>
                    </div>
                </Fragment>
            </div>
        )
    }
}

Switch.propTypes = {
    t: PropTypes.func.isRequired,
    showMessage: PropTypes.func,
    fetchListSwitch: PropTypes.func,
    fetchTransactionTypeSwitch: PropTypes.func,
    fetchTransactionStatusSwitch: PropTypes.func,
};

Switch.defaultProps = {
    showMessage: () => {},
};

const mapStateToProps = ({ auth, switchs }) => (
    { auth, switchs }
);

export default compose(connect(
    mapStateToProps, {
        ...switchActions,
        ...authActions,
    }), withNamespaces())(Switch);
