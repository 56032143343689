const START_FETCH = 'auth/START_FETCH';
const END_FETCH = 'auth/END_FETCH';
const END_ERROR = 'auth/END_ERROR';
const UPDATE_PROFILE = 'auth/UPDATE_PROFILE';
const UPDATE_USER = 'auth/UPDATE_USER';
const UPDATE_ACCOUNT = 'auth/UPDATE_ACCOUNT';
const UPDATE_CONTACTS = 'auth/UPDATE_CONTACTS';
const GET_CONTACTS = 'auth/GET_CONTACTS';
const SIGN_IN = 'auth/SIGN_IN';
const LOGOUT = 'auth/LOGOUT';
const LOGGED = 'auth/LOGGED';
const API_CALL = 'auth/API_CALL';
const CHANGE_LANGUAGE = 'auth/CHANGE_LANGUAGE';
const MESSAGE = 'auth/MESSAGE';
const CLEAR = 'auth/CLEAR';
const CHANGE_PASSWORD = 'auth/CHANGE_PASSWORD';
const UPDATE_TOKEN_EXP = 'auth/UPDATE_TOKEN_EXP';
const FETCH_ACCEPT_USER = 'auth/FETCH_ACCEPT_USER';
const FETCH_REJECT_USER = 'auth/FETCH_REJECT_USER';
const GET_PROVINCES = 'auth/GET_PROVINCES';
const UPDATE_PROVINCES = 'auth/UPDATE_PROVINCES'
const GET_LOCATIONS = 'auth/GET_LOCATIONS';
const UPDATE_LOCATIONS = 'auth/UPDATE_LOCATIONS';
const GET_CITIES = 'auth/GET_CITIES';
const UPDATE_CITIES = 'auth/UPDATE_CITIES';
const GET_ENTITIES = 'auth/GET_ENTITIES';
const UPDATE_ENTITIES = 'auth/UPDATE_ENTITIES';
const GET_RUBROS = 'auth/GET_RUBROS';
const UPDATE_RUBROS = 'auth/UPDATE_RUBROS';
const FETCH_UNSUBSCRIBE_USER = 'auth/FETCH_UNSUBSCRIBE_USER';

export default {
  START_FETCH,
  END_FETCH,
  END_ERROR,
  UPDATE_PROFILE,
  GET_CONTACTS,
  LOGGED,
  LOGOUT,
  SIGN_IN,
  UPDATE_USER,
  UPDATE_CONTACTS,
  API_CALL,
  CHANGE_LANGUAGE,
  MESSAGE,
  CLEAR,
  UPDATE_ACCOUNT,
  CHANGE_PASSWORD,
  UPDATE_TOKEN_EXP,
  FETCH_ACCEPT_USER,
  FETCH_REJECT_USER,
  GET_PROVINCES,
  UPDATE_PROVINCES,
  GET_LOCATIONS,
  UPDATE_LOCATIONS,
  GET_RUBROS,
  UPDATE_RUBROS,
  GET_ENTITIES,
  UPDATE_ENTITIES,
  GET_CITIES,
  UPDATE_CITIES,
  FETCH_UNSUBSCRIBE_USER,
};
