import React, { Component, Fragment } from 'react';
import PropTypes                      from 'prop-types';
import { withNamespaces }             from 'react-i18next';
import { connect }                    from 'react-redux';
import { toast }                      from 'react-toastify';
import { compose }                    from 'redux';
import {
  ContentHeader,
  ExportExcel,
  Filter,
  FormElement,
  Loading,
  Switch,
  TableList,
  Toggle,
  TransactionRow
}                                     from '../../components';
import { agencyActions }              from '../../state/ducks/agency';
import { authActions }                from '../../state/ducks/auth';
import { usersActions }               from '../../state/ducks/users';
import {
  initTimer,
  stopTimer
}                                     from '../../util/initTimer';
import {
  SIZE_PER_PAGE,
  TOAST_CONFIG
}                                     from '../../config/constants';
import { formatDate }                 from '../../util';
import UserEdit                       from '../userEdit';
import ReactTooltip                    from "react-tooltip";
import './style.scss';

class AgencyList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      dateStart: '',
      dateEnd: '',
      cuit: '',
      emailAgency: '',
      nameAgency: '',
      status: '',
      province: '',
      locationAgency: '',
      switchList: [],
      id_province: '',
      loading: true,
      listTabs: [],
      first: true,
    }
  }

  componentDidMount() {
    const { fetchStatusFilter, fetchProvince } = this.props;
    fetchStatusFilter();
    fetchProvince();
    stopTimer();
    initTimer(this.props);
    this.callApiList();
  }

  callApiList = (getExcel = false) => {
    const {
      t, showMessage, getAgencyList, fetchLocations, users: { provinces, locations, totalElements }
    } = this.props;
    const {
      page, dateStart, dateEnd, cuit, emailAgency, nameAgency, status, province, locationAgency,
      first, id_province,
    } = this.state;

    let proAgency = province;
    if (province === '' && first === true) {
      proAgency = provinces.length > 0 ? provinces[13].id : 1;
      this.setState({
        first: false,
        province: proAgency,
      });
    }

    getAgencyList({
      data: {
        pageSize: !getExcel ? SIZE_PER_PAGE : totalElements,
        page: getExcel ? 0 : page,
        export: getExcel,
        date_from: formatDate(dateStart),
        date_to: formatDate(dateEnd),
        cuit,
        email: emailAgency,
        name: nameAgency,
        status,
        province: proAgency,
        location: locationAgency,
      }, callback: (response) => {
        this.setState({ loading: false });
        if (response) {
          if (!response.success) {
            toast.dismiss();
            showMessage({
              message: t('connectionError'),
              config: TOAST_CONFIG.ERROR,
            });
          }
        } else {
          toast.dismiss();
          showMessage({
            message: t('connectionError'),
            config: TOAST_CONFIG.ERROR,
          });
        }
      },
    });

    fetchLocations({
      data: {
        id: proAgency,
      }, callback: (success, data) => {
        if (!success) {
          toast.dismiss();
          showMessage({
            message: t('connectionError'),
            config: TOAST_CONFIG.ERROR,
          });
        }
      },
    });
  }

  onChangeDate = (date, name) => {
    stopTimer();
    initTimer(this.props);
    const dateValue = date !== null ? date : '';
    this.setState({
      [name]: dateValue,
    });
  };

  onChangePage = (selectPage) => {
    stopTimer();
    initTimer(this.props);
    this.setState(() => ({
      page: selectPage ? selectPage.selected : 0,
    }), () => {
      this.callApiList();
    });
  };

  handleInputChange = (event) => {
    stopTimer();
    initTimer(this.props);
    const { target: { value: targetValue, name } } = event;
    this.setState({
      [name]: targetValue,
    });
  };

  handleActiveDisable = (event, i, agency_id) => {
    const item = event.target.name;
    const isChecked = event.target.checked;
    const { switchList } = this.state;
    switchList[i] = isChecked;
    this.setState({ switchList });
    const { t, showMessage, updateStateAgency } = this.props;
    const data = {
      id_agency: agency_id,
      new_status: !isChecked ? 0 : 1,
    };
    const status = isChecked ? 'activó' : 'desactivó';
    updateStateAgency({
      data,
      callback: (response) => {
        if (response.success) {
          toast.dismiss();
          showMessage({
            message: t('activeDisableAgencySuccess', { status }),
            config: status === 'activó' ? TOAST_CONFIG.SUCCESS : TOAST_CONFIG.INFO,
          });
        } else {
          toast.dismiss();
          showMessage({
            message: t('saveUserDataError'),
            config: TOAST_CONFIG.ERROR,
          });
        }
      },
    });
  }

  detailUser = async (id) => {
    stopTimer();
    initTimer(this.props);
    await this.setState(() => ({
      loading: true,
    }));
    const { detailUser, clearUserDetail } = this.props;
    const data = { identification: id };
    await clearUserDetail();
    await detailUser({
      data,
      callback: async (response) => {
        const { success, data: userDetail } = response;
      },
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.onChangePage();
    this.callApiList();
  };

  render() {
    const {
      t, agency: { loading, getAgencyList: { list, totalElements } },
      users: { statusFilter, provinces, locations },
    } = this.props;
    const {
      page, dateStart, dateEnd, cuit, emailAgency, nameAgency, status, province,
      locationAgency, switchList,
    } = this.state;
    return (
      <div>
        {loading && <Loading />}
        <Toggle>
          {({ toggled, onToggle, toggleProps }) => {
            return (
              <Fragment>
                {toggled
                && (
                  <UserEdit
                    identification={toggleProps.identification}
                    handleReturn={() => {
                      this.callApiList();
                      onToggle();
                    }}
                  />
                )}
                {!toggled && (
                  <Fragment>
                    <div className='wow animated slideInDown'>
                      <div className='wow animated slideInDown'>
                        <Filter title={t('listAgency')} onSubmit={e => this.onSubmit(e)}>
                          <FormElement
                            name='dateStart'
                            onChange={ev => this.onChangeDate(ev, 'dateStart')}
                            label={t('createdDateFrom')}
                            value={dateStart}
                            typeElement='datepicker'
                            maxDate={dateEnd || new Date()}
                          />
                          <FormElement
                            name='dateEnd'
                            onChange={ev => this.onChangeDate(ev, 'dateEnd')}
                            value={dateEnd}
                            label={t('createdDateUntil')}
                            typeElement='datepicker'
                            maxDate={new Date()}
                            minDate={dateStart}
                          />
                          <FormElement
                            name='cuit'
                            onChange={this.handleInputChange}
                            value={cuit}
                            label={'CUIT'}
                            typeElement='input'
                          />
                          <FormElement
                            name='emailAgency'
                            onChange={this.handleInputChange}
                            value={emailAgency}
                            label={t('emailSender')}
                            typeElement='input'
                          />
                          <FormElement
                            name='nameAgency'
                            onChange={this.handleInputChange}
                            value={nameAgency}
                            label={t('nameAgency')}
                            typeElement='input'
                          />
                          <FormElement
                            name='status'
                            onChange={this.handleInputChange}
                            value={status}
                            label={t('state')}
                            optionsSelect={statusFilter}
                            placeholder={t('selectState')}
                            typeElement="select"
                            controlClasses="select"
                          />
                          <FormElement
                            name='province'
                            onChange={this.handleInputChange}
                            value={province}
                            label={t('selectProvince')}
                            optionsSelect={provinces}
                            placeholder={t('selectState')}
                            typeElement="select"
                            controlClasses="select"
                            disabled
                          />
                          <FormElement
                            name='locationAgency'
                            onChange={this.handleInputChange}
                            value={locationAgency}
                            label={t('location')}
                            optionsSelect={locations}
                            placeholder={t('selectLocation')}
                            typeElement="select"
                            controlClasses="select"
                          />
                        </Filter>
                        <ContentHeader
                          headingRightText={t('totalAgencys')}
                          headingRightSubText={`${totalElements}`}
                        >
                          <ExportExcel onclick={() => { this.callApiList(true) }} />
                          <TableList
                            tableClasses="floating-table"
                            totalElements={parseFloat(totalElements)}
                            onChangePage={this.onChangePage}
                            forcePage={page}
                            head={(
                              <tr>
                                <th>{`${t('date')} / ${t('hour')}`}</th>
                                <th>{t('CUIT')}</th>
                                <th>{t('nameAgency')}</th>
                                <th>{t('email')}</th>
                                <th>{t('CVU')}</th>
                                <th>{t('location')}</th>
                                <th>{t('status')}</th>
                                <th colSpan={"2"}>{t('actions')}</th>
                              </tr>)}
                            colNumber={9}
                            lenghtData={totalElements !== undefined ? totalElements : 0}
                          >
                            {list && (list.map((agency, i) => (
                              <TransactionRow
                                key={i}
                                icon={'far fa-edit icons-actions'}
                                helper={t('edit')}
                                openDetail={async () => {
                                  stopTimer();
                                  initTimer(this.props);
                                  const { detailUser, clearUserDetail } = this.props;
                                  const data = { identification: agency.identification };
                                  await clearUserDetail();
                                  await detailUser({
                                    data,
                                    callback: async (response) => {
                                      const { success, data: userDetail } = response;
                                      if (success) {
                                        onToggle(agency);
                                      }
                                    },
                                  });
                                }}
                              >
                                <td>{agency.date}</td>
                                <td>{agency.cuit}</td>
                                <td>{agency.name}</td>
                                <td>{agency.email}</td>
                                <td>{agency.cvu === undefined ? `${t('notAvailable')}` : agency.cvu}</td>
                                <td>{agency.location}</td>
                                <td>{switchList[i] !== undefined ? (switchList[i] === true ? t('active') : t('inactive')) : (agency.status === '1' ? t('active') : t('inactive'))}</td>
                                <td className="is-paddingless"
                                  aria-hidden="true"
                                  data-for={`switch-orange-${i}`}
                                  data-tip="Test"
                                  >
                                  <Switch
                                    name={`switch-${i}`}
                                    id={`switch-orange-${i}`}
                                    value={switchList[i] !== undefined ? switchList[i] : (agency.status === '1')}
                                    onChange={(event) => {this.handleActiveDisable(event, i, agency.agency_id)}}
                                    checked={switchList[i] !== undefined ? switchList[i] : (agency.status === '1')}
                                    htmlFor={`switch-orange-${i}`}
                                  />
                                  <ReactTooltip
                                    id={`switch-orange-${i}`}
                                    effect="solid"
                                    className='text-toltip'
                                  >
                                    {switchList[i] !== undefined ? (switchList[i] === true ? t('deactivate') : t('activate')) : (agency.status === '1' ? t('deactivate') : t('activate'))}
                                  </ReactTooltip>
                                </td>
                              </TransactionRow>
                            )))}
                          </TableList>
                        </ContentHeader>
                      </div>
                    </div>
                  </Fragment>
                )}
              </Fragment>
            );
          }}
        </Toggle>

      </div>
    );
  }
}

AgencyList.propTypes = {
  t: PropTypes.func.isRequired,
  showMessage: PropTypes.func,
  fetchStatusFilter: PropTypes.func,
  fetchProvince: PropTypes.func,
  getAgencyList: PropTypes.func.isRequired,
};

AgencyList.defaultProps = {
  showMessage: () => {
  },
  fetchStatusFilter: () => {},
  fetchProvince: () => {},
};

const mapStateToProps = ({ auth, agency, users }) => (
  { auth, agency, users }
);

export default compose(connect(mapStateToProps, {
    ...usersActions,
    ...authActions,
    ...agencyActions,
  }),
  withNamespaces())(AgencyList);
