import types            from './types';
import { initialState } from '../../../App';

const users = (state = {}, action) => {
  switch (action.type) {
    case types.CLEAR: {
      return {
        ...state, ...initialState.users,
      };
    }
    case types.CLEAR_USER: {
      return {
        ...state,
        userDetail: { ...initialState.users.userDetail },
      };
    }
    case types.START_FETCH: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.END_FETCH: {
      const { error, payload } = action;
      return {
        ...state,
        loading: false,
        error: error ? payload : null,
      };
    }
    case types.FETCH_USERS_DATA: {
      const { payload: { data } } = action;
      state.list = data.data;
      state.totalElements = data.total;
      return {
        ...state,
      };
    }
    case types.FETCH_USERS_DETAIL_DATA: {
      const { payload: { data } } = action;
      state.userDetail = data;
      return {
        ...state,
      };
    }
    case types.TEMP_AGENCY_DATA_PERSON: {
      const { payload: { data } } = action;
      return {
        ...state,
        createAgency: {
          ...state,
          person: data
        },
      };
    }
    case types.GET_USER_DATA_FISCAL: {
      const {
        payload: {
          data: {
            fiscal_position, invoice, gain, category, commission, iva,
          },
        },
      } = action;
      const fiscalData = {
        fiscalPosition: Array.isArray(fiscal_position) ? fiscal_position.map(element => ({ text: element.value, value: element.id })) : fiscal_position,
        invoiceType: Array.isArray(invoice) ? invoice.map(element => ({ text: element.value, value: element.id })) : invoice,
        earnings: Array.isArray(gain) ? gain.map(element => ({ text: element.value, value: element.id })) : gain,
        category: Array.isArray(category) ? category.map(element => ({ text: element.value, value: element.id })) : category,
      };
      const arraySelectFiscalPosition = Array.isArray(fiscal_position) ? fiscal_position.filter(element => element.selected === true) : [];
      const fiscalPositionValue = arraySelectFiscalPosition.length > 0 ? arraySelectFiscalPosition[0].id : '';
      const arraySelectInvoiceType = Array.isArray(invoice) ? invoice.filter(element => element.selected === true) : [];
      const invoiceTypeValue = arraySelectInvoiceType.length > 0 ? arraySelectInvoiceType[0].id : '';
      const arraySelectEarnings = Array.isArray(gain) ? gain.filter(element => element.selected === true) : [];
      const earningsValue = arraySelectEarnings.length > 0 ? arraySelectEarnings[0].id : '';
      const arraySelectCategory = Array.isArray(category) ? category.filter(element => element.selected === true) : [];
      const categoryValue = arraySelectCategory.length > 0 ? arraySelectCategory[0].id : '';
      return {
        ...state,
        fiscalData,
        fiscalPositionValue,
        invoiceTypeValue,
        earningsValue,
        categoryValue,
        ivaValue: iva ? 1 : 0,
        commissionValue: commission ? 1 : 0,
      };
    }
    case types.SET_USER_DATA_FISCAL: {
      const { payload } = action;
      return {
        ...state,
      };
    }
    case types.SET_STATUS_IVA: {
      const { payload: { success, data } } = action;
      const optionsStatusIva = success ? Object.values(data).map(element => ({ value: element, text: element })) : [];
      return {
        ...state,
        optionsStatusIva,
      };
    }
    case types.SET_TYPE_PERSON: {
      const { payload: { success, data } } = action;
      const optionsTypePerson = success ? data.map(element => ({ value: element.id, text: element.name })) : [];
      return {
        ...state,
        optionsTypePerson,
      };
    }

    case types.SET_LIST_VIRTUAL_ACCOUNTS: {
      const { payload: { data: { totalCount, results } } } = action;

      return {
        ...state,
        listVirtualAcounts: {
          list: results,
          totalElements: totalCount,
        },
      };
    }
    case types.SET_USER_DATA: {
      const { payload } = action;
      return {
        ...state,
      };
    }
    case types.SET_USER_ALIAS_DATA: {
      const { payload } = action;
      return {
        ...state,
      };
    }
    case types.UPDATE_TRANSACTIONS_PER_USER: {
      const { count_transactions, transactions, balance } = action.payload;
      return {
        ...state,
        transactionPerUser: {
          list: transactions,
          totalElements: count_transactions,
          balance: balance,
        },
      };
    }
    case types.FETCH_ALL_USERS_DATA: {
      const { payload: { data } } = action;
      return {
        ...state,
        allUsers: data[0],
      };
    }
    case types.SET_LIST_ALL_VIRTUAL_ACCOUNTS: {
      const { payload: { data: { results } } } = action;
      return {
        ...state,
        allVirtualAcounts: results,
      };
    }
    case types.UPDATE_LIST_STATUS_USER: {
      const { payload: { data } } = action;
      return {
        ...state,
        list_status_user: data,
      };
    }
    case types.SET_USER_ADDRESS: {
      const { payload } = action;
      return {
        ...state,
      };
    }
    case types.SET_ENTITIES_USER: {
      const { payload } = action;
      return {
        ...state,
      };
    }
    case types.SET_USERS_OPERATOR: {
      const { payload: { data } } = action;
      return {
        ...state,
        list_users_operator: {
          list: data,
          totalElements: data.length,
        },
      };
    }
    case types.UPDATE_LIST_OPERATORS: {
      const { data: { operators, count_operator } } = action.payload;
      return {
        ...state,
        listOperators: {
          list: operators,
          totalElements: count_operator,
        },
      }
    }
    case types.UPDATE_ROL_OPERATOR: {
      const { data } = action.payload;
      return {
        ...state,
        rolsOperator: data,
      }
    }
    case types.UPDATE_OPERATOR_AGENCY: {
      const { data } = action.payload;
      return {
        ...state,
        operatorAgency: data,
      }
    }
    case types.UPDATE_STATUS_FILTER: {
      const { data } = action.payload;
      return {
        ...state,
        statusFilter: data,
      }
    }
    case types.UPDATE_PROVINCE: {
      const { data } = action.payload;
      return {
        ...state,
        provinces: data
      }
    }
    case types.UPDATE_LOCATIONS: {
      const { data } = action.payload;
      return {
        ...state,
        locations: data
      }
    }
    case types.UPDATE_ACCOUNTS_STATE: {
      const { data: { account, total } } = action.payload;
      return {
        ...state,
        statesAccounts: {
          list: account,
          totalElements: total,
        },
      }
    }
    default:
      return state;
  }
};

export default users;
