import React, { Component, Fragment }  from 'react';
import { withNamespaces }              from 'react-i18next';
import { compose }                     from 'redux';
import { Link }                        from 'react-router-dom';
import {
  AmountFormat,
  ContentHeader,
  DefaultButton,
  Filter,
  FormElement,
  Loading,
  NameAgency,
  TableList,
  TransactionRow,
  ExportExcel,

}                                      from '../../../components';
import { initTimer, stopTimer }        from '../../../util/initTimer';
import { toast }                       from 'react-toastify';
import { SIZE_PER_PAGE, TOAST_CONFIG } from '../../../config/constants';
import { connect }                     from 'react-redux';
import { authActions }                 from '../../../state/ducks/auth';
import { usersActions }                from '../../../state/ducks/users';
import { agencyActions }               from '../../../state/ducks/agency';
import './styles.scss';
import PropTypes                       from 'prop-types';
import { formatDate }                  from '../../../util';

class LotsInfo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      lots: '',
      agency: '',
      idAgency: '',
      page: 0,
      status: '',
      dateStart: new Date(((new Date()).setDate((new Date()).getDate()))),
      dateEnd: new Date(((new Date()).setDate((new Date()).getDate()))),

    }
  }

  componentDidMount() {
    stopTimer();
    initTimer(this.props);

    const { location, fetchStatusLot } = this.props;

    if (location.state !== undefined && location.state.agency !== undefined) {
      this.setState({ agency: location.state.agency, idAgency: location.state.id },
        () => {
          this.callApiList()
        });
    }

    fetchStatusLot();
  }

  callApiList = (getExcel = false) => {
    const {
      t, showMessage, fetchLotsInfo,
      agency: { lotsInfo: { totalElements } }
    } = this.props;
    const { idAgency, page, status, dateStart, dateEnd } = this.state;
    const data = {
      id: idAgency,
      pageSize: !getExcel ? SIZE_PER_PAGE : totalElements,
      page: getExcel ? 0 : page,
      export: getExcel,
      status,
      date_from: formatDate(dateStart),
      date_to: formatDate(dateEnd),
    };

    fetchLotsInfo({
      data,
      callback: (response) => {
        if (response) {
          if (!response.success) {
            toast.dismiss();
            showMessage({
              message: t('connectionError'),
              config: TOAST_CONFIG.ERROR,
            });
          }
        } else {
          toast.dismiss();
          showMessage({
            message: t('connectionError'),
            config: TOAST_CONFIG.ERROR,
          });
        }
      },
    });
  }

  handleInputChange = (event) => {
    stopTimer();
    initTimer(this.props);
    const {
      target: {
        value: targetValue, name,
      },
    } = event;
    this.setState({
      [name]: targetValue,
    });
  };

  onChangeDate = (date, name) => {
    stopTimer();
    initTimer(this.props);
    const dateValue = date !== null ? date : '';
    this.setState({
      [name]: dateValue,
    });
  };

  onChangePage = (selectPage) => {
    stopTimer();
    initTimer(this.props);
    this.setState(() => ({
      page: selectPage ? selectPage.selected : 0,
    }), () => {
      this.callApiList();
    });
  };

  onSubmit = (event) => {
    event.preventDefault();
    //this.onChangePage();
    this.callApiList();
  };

  getPdf = (url) => {
    const {
      t, showMessage, fetchPdf,
    } = this.props;
    fetchPdf({
      data: {
        url_file: url,
      }, callback: (success) => {
        if (!success) {
          toast.dismiss();
          showMessage({
            message: t('connectionError'),
            config: TOAST_CONFIG.ERROR,
          });
        }
      },
    });
  }

  render() {
    const { t, agency: { loading, lotsInfo: { list, totalElements }, statusLot }, } = this.props;
    const { agency, status, page, dateStart, dateEnd } = this.state;

    return (
      <div className="agency-list">
        {loading && <Loading />}
        <Fragment>
          <div className='wow animated slideInDown'>
            <NameAgency agency={agency} />
            <div className='wow animated slideInDown'>
              <Filter
                title={t('adminRed')}
                subtitle={t('infoLots')}
                onSubmit={(ev) => { this.onSubmit(ev);}}
              >
                <FormElement
                  onChange={ev => this.onChangeDate(ev, 'dateStart')}
                  label={t('dateStart')}
                  value={dateStart}
                  typeElement="datepicker"
                  maxDate={dateEnd || new Date()}
                />
                <FormElement
                  onChange={ev => this.onChangeDate(ev, 'dateEnd')}
                  value={dateEnd}
                  label={t('dateEnd')}
                  typeElement="datepicker"
                  maxDate={new Date()}
                  minDate={dateStart}
                />

                <FormElement
                  name="status"
                  onChange={this.handleInputChange}
                  value={status}
                  optionsSelect={statusLot}
                  label={t('stateLot')}
                  placeholder={t('selectStateLot')}
                  typeElement="select"
                  controlClasses="select"
                  fieldClasses='select-lot'
                />
              </Filter>
            </div>
            <ContentHeader
              headingText={
                <Link to={{
                  pathname: '/info-agency',
                  state: { agency }
                }}
                >
                  <DefaultButton name={t('goBack')} />
                </Link>
              }
              headingRightText={t('countofElements')}
              headingRightSubText={totalElements}
            >
              <ExportExcel onclick={() => { this.callApiList(true) }}/>
              <TableList
                tableClasses="floating-table"
                totalElements={parseFloat(totalElements)}
                onChangePage={this.onChangePage}
                forcePage={page}
                head={(
                  <tr>
                    <th>{t('date')}</th>
                    <th>{t('numberLot')}</th>
                    <th>{t('amountLot')}</th>
                    <th>{t('comissionLot')}</th>
                    <th>{t('totalTransactionsLot')}</th>
                    <th>{t('statusLot')}</th>
                    <th>{t('transferDetail2')}</th>
                  </tr>
                )}
                colNumber={8}
                lenghtData={totalElements !== undefined ? totalElements : 0}
              >
                {list && (list.map(row => (
                  <TransactionRow
                    //key={index}
                    //helper='detailReport'
                    id={row.id}
                  >
                    <td>{row.lot_date}</td>
                    <td>{row.lot_number === null ? `${t('notNumberLot')}` : row.lot_number}</td>
                    <td>
                      <AmountFormat
                        name="amount_close_lot"
                        value={row.lot_balance}
                        displayType="text"
                        allowNegative
                      />
                    </td>
                    <td>
                      <AmountFormat
                        value={row.lot_commission}
                        name="amount_comission"
                        displayType="text"
                        allowNegative
                      />
                    </td>
                    <td>{row.operations}</td>
                    <td>{row.status}</td>
                    <td>
                      {(row.lot_file_url !== undefined && row.lot_file_url !== null) ?
                        <button
                          className="button is-outlined is-small"
                            onClick={() => this.getPdf(row.lot_file_url)}
                          >
                          {t('downloadButton')}
                        </button>
                      :
                        t('notFound')
                      }
                    </td>
                  </TransactionRow>
                )))
                }
              </TableList>
            </ContentHeader>
          </div>
        </Fragment>
      </div>
    )
  }
}

LotsInfo.propTypes = {
  t: PropTypes.func.isRequired,
  showMessage: PropTypes.func,
  fetchLotsInfo: PropTypes.func,
};
LotsInfo.defaultProps = {
  showMessage: () => {
  },
  fetchLotsInfo: () => {},
};
const mapStateToProps = ({ auth, users, agency }) => (
  {
    auth,
    users,
    agency,
  }
);
export default compose(connect(mapStateToProps, {
    ...authActions,
    ...usersActions,
    ...agencyActions,
  }),
  withNamespaces())(LotsInfo);
