import { createAction } from 'redux-actions';
import types            from './types';

const startFetch = createAction(types.START_FETCH);
const endFetch = createAction(types.END_FETCH);
const endError = createAction(types.END_ERROR);
const updateProfile = createAction(types.UPDATE_PROFILE);
const getContacts = createAction(types.GET_CONTACTS);
const updateUser = createAction(types.UPDATE_USER);
const updateAccount = createAction(types.UPDATE_ACCOUNT);
const updateContacts = createAction(types.UPDATE_CONTACTS);
const signIn = createAction(types.SIGN_IN);
const logout = createAction(types.LOGOUT);
const logged = createAction(types.LOGGED);
const changeLanguage = createAction(types.CHANGE_LANGUAGE);
const clear = createAction(types.CLEAR);
const changePassword = createAction(types.CHANGE_PASSWORD);
const updateExpToken = createAction(types.UPDATE_TOKEN_EXP);
const fetchAcceptUser = createAction(types.FETCH_ACCEPT_USER);
const fetchRejectUser = createAction(types.FETCH_REJECT_USER);
const getProvinces = createAction(types.GET_PROVINCES);
const updateProvinces = createAction(types.UPDATE_PROVINCES);
const getLocations = createAction(types.GET_LOCATIONS);
const updateLocations = createAction(types.UPDATE_LOCATIONS);
const getCities = createAction(types.GET_CITIES);
const updateCities = createAction(types.UPDATE_CITIES);
const getEntities = createAction(types.GET_ENTITIES);
const updateEntities = createAction(types.UPDATE_ENTITIES);
const getRubros = createAction(types.GET_RUBROS);
const updateRubros = createAction(types.UPDATE_RUBROS);
const fetchUnsubscribeUser = createAction(types.FETCH_UNSUBSCRIBE_USER);


const showMessage = createAction(types.MESSAGE,
  ({ message }) => ({
    message,
  }),
  ({ config }) => ({
    config,
  }));

export default {
  startFetch,
  endFetch,
  endError,
  updateProfile,
  getContacts,
  updateUser,
  updateContacts,
  signIn,
  logout,
  logged,
  showMessage,
  changeLanguage,
  clear,
  updateAccount,
  changePassword,
  updateExpToken,
  fetchAcceptUser,
  fetchRejectUser,
  getProvinces,
  updateProvinces,
  getLocations,
  updateLocations,
  getEntities,
  updateEntities,
  getRubros,
  updateRubros,
  getCities,
  updateCities,
  fetchUnsubscribeUser,
};
