import { createAction } from 'redux-actions';
import types            from './types';

const startFetch = createAction(types.START_FETCH);
const endFetch = createAction(types.END_FETCH);
const fetchTransactionsBind = createAction(types.FETCH_TRANSACTIONS_BIND);
const updateTransactionsBind = createAction(types.UPDATE_TRANSACTIONS_BIND);
const fetchTransactionsList = createAction(types.FETCH_TRANSACTIONS_LIST);
const fetchTransactionsExternalAccount = createAction(types.FETCH_TRANSACTIONS_EXTERNAL_ACCOUNT);
const updateTransactionsExternalAccount = createAction(types.UPDATE_TRANSACTIONS_EXTERNAL_ACCOUNT);
const fetchCauseBind = createAction(types.FETCH_CAUSE_BIND);
const updateCauseBind = createAction(types.UPDATE_CAUSE_BIND);
const fetchTransactionsAllExcel = createAction(types.FETCH_TRANSACTIONS_ALL_EXCEL);
const fetchConfirmTransaction = createAction(types.FETCH_CONFIRM_TRANSACTION);
const fetchRejectedTransaction = createAction(types.FETCH_REJECTED_TRANSACTION);
const fetchSwitchTransacctionsInbound = createAction(types.FETCH_SWITCH_TRANSACTIONS_INBOUND);
const updateSwitchTransacctionsInbound = createAction(types.FETCH_TRANSACTIONS_INBOUND);
const fetchTransactionsOutgoing = createAction(types.FETCH_TRANSACTIONS_OUTGOING);
const updateTransactionsOutgoing = createAction(types.UPDATE_TRANSACTIONS_OUTGOING);
const fetchTransactionsRejected = createAction(types.FETCH_TRANSACTIONS_REJECTED);
const updateTransactionsRejected = createAction(types.UPDATE_TRANSACTIONS_REJECTED);
const fetchTransactionsApproved = createAction(types.FETCH_TRANSACTIONS_APPROVED);
const updateTransactionsApproved = createAction(types.UPDATE_TRANSACTIONS_APPROVED);
const fetchTransactionsOutRejected = createAction(types.FETCH_TRANSACTIONS_OUT_REJECTED);
const updateTransactionsOutRejected = createAction(types.UPDATE_TRANSACTIONS_OUT_REJECTED);
const fetchTransactionsOutApproved = createAction(types.FETCH_TRANSACTIONS_OUT_APPROVED);
const updateTransactionsOutApproved = createAction(types.UPDATE_TRANSACTIONS_OUT_APPROVED);
const fetchApprovedTransactionOut = createAction(types.FETCH_APPROVED_TRANSACTION_OUT);
const fetchRejectedTransactionOut = createAction(types.FETCH_REJECTED_TRANSACTION_OUT);
const fetchTypeTransfersBind = createAction(types.FETCH_TYPE_TRANSFERS_BIND);
const updateTypeTransfersBind = createAction(types.UPDATE_TYPE_TRANSFERS_BIND);
const fetchStatusTransferBind = createAction(types.FETCH_STATUS_TRANSFERS_BIND);
const updateStatusTransferBind = createAction(types.UPDATE_STATUS_TRANSFERS_BIND);

const showMessage = createAction(types.MESSAGE,
  ({ message }) => ({
    message,
  }),
  ({ config }) => ({
    config,
  }));

export default {
  startFetch,
  endFetch,
  showMessage,
  fetchTransactionsBind,
  updateTransactionsBind,
  fetchTransactionsList,
  fetchTransactionsExternalAccount,
  updateTransactionsExternalAccount,
  fetchCauseBind,
  updateCauseBind,
  fetchTransactionsAllExcel,
  fetchConfirmTransaction,
  fetchRejectedTransaction,
  fetchSwitchTransacctionsInbound,
  updateSwitchTransacctionsInbound,
  fetchTransactionsOutgoing,
  updateTransactionsOutgoing,
  fetchTransactionsRejected,
  updateTransactionsRejected,
  fetchTransactionsApproved,
  updateTransactionsApproved,
  fetchTransactionsOutApproved,
  updateTransactionsOutApproved,
  fetchTransactionsOutRejected,
  updateTransactionsOutRejected,
  fetchApprovedTransactionOut,
  fetchRejectedTransactionOut,
  fetchTypeTransfersBind,
  updateTypeTransfersBind,
  fetchStatusTransferBind,
  updateStatusTransferBind,
};
