import axios                                 from 'axios';
import { toast }                             from 'react-toastify';
import { API_VERSION, ENDPOINTS, ENV, TIME } from './env';

import logo             from '../resources/images/auth/feedback.png';
import bgGeneral        from '../resources/images/bg-general.png';
import avatar           from '../resources/images/assets/avatar.png';
import logoMedullaris   from '../resources/images/assets/logo.png';
import logoMain         from '../resources/images/assets/logo-xx.png';
import arrowDownOutline from '../resources/images/assets/arrowDownOutline.png';
import list             from '../resources/images/assets/list.png';
import transfers        from '../resources/images/assets/update.png';
import directory        from '../resources/images/assets/listPresentation.png';
import user             from '../resources/images/assets/user.png';
import userGroup        from '../resources/images/assets/userGroup.png';
import btnClose         from '../resources/images/assets/btn-close.png';
import btnPlus          from '../resources/images/assets/btn-plus.png';
import thinkerCloud     from '../resources/images/assets/thinker-cloud.png';
import wallet           from '../resources/images/assets/wallet.png';
import calendar         from '../resources/images/assets/calendar.png';
import clear            from '../resources/images/assets/clear.png';

// Se usa para manejar una version, del estado de la aplicación,
// cuando se cambia, setea el storage del cliente por el estado por defecto.
export const STATE_VERSION = 0.26;

// Constante para definir la edad minima del usuario para usar la app
// export const MAX_AGE = 18;

// Colores base de la aplicación
export const COLORS = {};

// Imagenes de toda la aplicacion separadas por categorias
export const IMG = {
  logo,
  logoMain,
  // bgNavResponsive,
  avatar,
  bgGeneral,
  logoMedullaris,
  arrowDownOutline,
  list,
  transfers,
  directory,
  user,
  userGroup,
  btnClose,
  btnPlus,
  thinkerCloud,
  wallet,
  calendar,
  clear,
};

export const TOAST_CONFIG = {
  SUCCESS: {
    type: toast.TYPE.SUCCESS,
    autoClose: 10000,
    position: toast.POSITION.TOP_CENTER,
  },
  ERROR: {
    type: toast.TYPE.ERROR,
    autoClose: 10000,
    position: toast.POSITION.TOP_CENTER,
  },
  WARNING: {
    type: toast.TYPE.WARNING,
    autoClose: 10000,
    position: toast.POSITION.TOP_CENTER,
  },
  INFO: {
    type: toast.TYPE.INFO,
    autoClose: 10000,
    position: toast.POSITION.TOP_CENTER,
  },
};

// Se genera la URL base de acuerdo a los endpoints del entorno
// Opcionalmente se puede usar versionamiento de la API
const { API } = ENDPOINTS;
export const URL = API[ENV];
export const API_URL = `${URL}/${API_VERSION}/`;

export const SIZE_PER_PAGE = 10;

export const stateTransaction = {
  P: 'processing',
  F: 'finalized',
  E: 'finalizedErrors',
};

export const LIST_MONTH = [
  {
    value: '1',
    nameTranslate: 'january',
  },
  {
    value: '2',
    nameTranslate: 'february',
  },
  {
    value: '3',
    nameTranslate: 'march',
  },
  {
    value: '4',
    nameTranslate: 'april',
  },
  {
    value: '5',
    nameTranslate: 'may',
  },
  {
    value: '6',
    nameTranslate: 'june',
  },
  {
    value: '7',
    nameTranslate: 'july',
  },
  {
    value: '8',
    nameTranslate: 'august',
  },
  {
    value: '9',
    nameTranslate: 'september',
  },
  {
    value: '10',
    nameTranslate: 'october',
  },
  {
    value: '11',
    nameTranslate: 'november',
  },
  {
    value: '12',
    nameTranslate: 'december',
  },
];

export const STATE_TRANSACTION = [
  {
    id: 'approved',
    text: 'Aprobado',
  },
  {
    id: 'rejected',
    text: 'Rechazado',
  },
  {
    id: 'cancel',
    text: 'Cancelado',
  },
  {
    id: 'pending',
    text: 'Pendiente',
  }
];

export const STATE_USER = ['inactive', 'active', 'withoutValidation', 'rejected'];

// Headers fetch
export const HEADERS = ({ token, expToken, contentType = null }) => {
  const headers = new Headers();
  headers.append('cache-control', 'no-cache');
  headers.append('Access-Control-Allow-Origin', '*');
  headers.append('Access-Control-Allow-Credentials', true);
  headers.append('Access-Control-Allow-Methods', 'GET,HEAD,OPTIONS,POST,PUT,DELETE');
  headers.append('Access-Control-Allow-Headers',
    'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers');
  if (contentType) {
    headers.append('Content-Type', contentType);
  }
  headers.append('Authorization', token);
  headers.append('Exptoken', expToken);
  return headers;
};
// En dado caso que se use axios
axios.defaults.baseURL = API_URL;

// Duración de la sesión en minutos
export const TIMESESSION = TIME;

export const KEY_CAPTCHA = '6LdzwmQUAAAAANfyPA1K-G1J-OkeMS-K0ef_kfCN';
