const START_FETCH = 'company/START_FETCH';
const END_FETCH = 'company/END_FETCH';
const END_ERROR = 'company/END_ERROR';
const API_CALL = 'company/API_CALL';
const MESSAGE = 'company/MESSAGE';
const FETCH_TRANSPORT_COMPANY = 'company/FETCH_TRANSPORT_COMPANY';
const UPDATE_TRANSPORT_COMPANY = 'company/UPDATE_TRANSPORT_COMPANY';
const UPDATE_STATE_COMPANY = 'agency/UPDATE_STATE_COMPANY';

export default {
  START_FETCH,
  END_FETCH,
  END_ERROR,
  API_CALL,
  MESSAGE,
  FETCH_TRANSPORT_COMPANY,
  UPDATE_TRANSPORT_COMPANY,
  UPDATE_STATE_COMPANY,
}
