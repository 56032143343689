import React, { Component }                  from 'react';
import PropTypes                             from 'prop-types';
import { compose }                           from 'redux';
import { connect }                           from 'react-redux';
import { withNamespaces }                    from 'react-i18next';
import { initTimer, stopTimer }              from '../../../../util/initTimer';
import { Form }                              from '../../../../components';
import { CompanyDetailForm, UserDetailForm } from '../../../userDetail';
import { usersActions }                      from '../../../../state/ducks/users';
import { authActions }                       from '../../../../state/ducks/auth';
import { agencyActions }                     from '../../../../state/ducks/agency';
import { TOAST_CONFIG }                      from "../../../../config/constants";

class UserData extends Component {
  constructor(props) {
    super(props);
    const {
      users: {
        userDetail: {
          cuit,
          dni,
          IVA: iva,
          phone_number: phoneNumber,
          name,
          lastname,
          username,
          id_type_person,
          responsible: {
            name: nameResponsible,
            lastname: lastNameResponsible,
            document_number: dniResponsible,
            document_number_aux: cuitResponsible,
            phone_number_resp: phoneResponsible,
          }
        }
      }
    } = props;
    this.state = {
      country: 'AR',
      dni: dniResponsible || dni,
      cuit: cuitResponsible || cuit,
      name: nameResponsible || name,
      lastname: lastNameResponsible || lastname,
      phoneNumber: phoneResponsible || phoneNumber,
      cuitCompany: cuit,
      companyType: id_type_person,
      nameCompany: name,
      iva,
      usernameCompany: username,
      phoneNumberCompany: phoneNumber,
    };
  }

  componentDidMount() {
    const {
      fetchCompanyTypes,
    } = this.props;
    stopTimer();
    initTimer(this.props);
    fetchCompanyTypes();
  }

  callApiList = async (e) => {
    e.preventDefault();
    /*const {
      name,
      lastname,
      phoneNumber,
      username,
      companyType,
      nameCompany,
      iva,
      usernameCompany,
      phoneNumberCompany,
      agency
    } = this.state;
    const { showMessage, editDataUser } = this.props;

    let country = '+';
    let phone = '';

    if (phoneNumber !== undefined && phoneNumber !== '' && phoneNumber !== 'undefined' && isValidPhoneNumber(phoneNumber)) {
      const phoneN = parsePhoneNumber(phoneNumber);
      country = country + phoneN.countryCallingCode;
      phone = phoneN.nationalNumber;
    }
    const data = {
      name,
      last_name: lastname,
      email: username,
      person: identification,
    };

    if (!props.userDetail.responsible) {
      data.country_code = country;
      data.phone_number = phone;
    } else {
      data.phone_number_business = phone;
      data.country_code_business = country;
    }

    if (isValidPhoneNumber(phoneNumberResp) && phoneNumberResp !== undefined && phoneNumberResp !== '') {
      let country = '+';
      const phoneNumber = parsePhoneNumber(phoneNumber);
      country = country + phoneNumber.countryCallingCode;
      const phone = phoneNumber.nationalNumber;
      data.phone_number = phone;
      data.country_code = country;
    }

    editDataUser({
      data,
      callback: (response) => {
        if (response.success) {
          toast.dismiss();
          showMessage({
            message: t('saveUserData'),
            config: TOAST_CONFIG.SUCCESS,
          });
        } else {
          toast.dismiss();
          showMessage({
            message: t('saveUserDataError'),
            config: TOAST_CONFIG.ERROR,
          });
        }
      },
    });*/
  };

  handlePhoneChange = (value, name) => {
    stopTimer();
    initTimer(this.props);
    this.setState({
      [name]: value,
    });
  };

  handleInputChange = ({ target: { value: targetValue, name } }) => {
    stopTimer();
    initTimer(this.props);
    this.setState({
      [name]: targetValue,
    });
  };

  handleVerifyCuit = (ev) => {
    this.setState({
      dni: ev.target.value.substr(2, 8)
        .replace(/^0+/, ''),
    });
  };

  onSubmit = (event) => {
    stopTimer();
    initTimer(this.props);
    event.preventDefault();
    this.callApiList()
  };

  handleValidator = () => {
    const { t, showMessage } = this.props;
    const {
      dni,
      cuit,
      name,
      lastname,
      phoneNumber,
      cuitCompany,
      companyType,
      nameCompany,
      iva,
      usernameCompany,
      phoneNumberCompany,
    } = this.state;
    if (dni === ''
      || cuit === ''
      || name === ''
      || lastname === ''
      || phoneNumber === ''
      || cuitCompany === ''
      || companyType === ''
      || nameCompany === ''
      || iva === ''
      || usernameCompany === ''
      || phoneNumberCompany === '') {
      showMessage({
        message: t('allRequiredFields'),
        config: TOAST_CONFIG.ERROR,
      });
      return false;
    }
    return true;
  }

  render() {
    const {
      dni,
      cuit,
      name,
      lastname,
      phoneNumber,
      cuitCompany,
      companyType,
      nameCompany,
      iva,
      usernameCompany,
      phoneNumberCompany,
    } = this.state;
    const person = {
      dni,
      cuit,
      name,
      lastname,
      phoneNumber,
    };
    const company = {
      cuitCompany,
      companyType,
      nameCompany,
      iva,
      usernameCompany,
      phoneNumberCompany,
    };

    const { t } = this.props;

    return (
      <div className="pr-16px pl-16px pt-16px content-xl-center">
        <Form
          submitText={t('save')}
          nameButton="configButton"
          onSubmit={(e) => {this.onSaveUserData(e)}}
        >
          <CompanyDetailForm
            handleInputChange={this.handleInputChange}
            handlePhoneChange={this.handlePhoneChange}
            company={company}
            titleLabel={'agencyData'}
            cuitText
            editable
            hiddeIva
            hiddeTypeCompany
          />
          <UserDetailForm
            handleInputChange={this.handleInputChange}
            handlePhoneChange={this.handlePhoneChange}
            handleVerifyCuit={this.handleVerifyCuit}
            person={person}
            cuitText
            dniText
            hideEmail
            editable
          />
        </Form>
      </div>
    );
  }
}

UserData.propTypes = {
  t: PropTypes.func.isRequired,
  users: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({}),
  ]).isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = ({ users, auth, agency }) => (
  {
    users,
    auth,
    agency,
  }
);
export default compose(
  connect(mapStateToProps, {
    ...usersActions,
    ...authActions,
    ...agencyActions,
  }),
  withNamespaces(),
)(UserData);
