import React, { Component } from 'react';
import PropTypes                      from 'prop-types';
import { connect }                    from 'react-redux';
import { toast }                      from 'react-toastify';
import { withNamespaces }             from 'react-i18next';
import { compose }                    from 'redux';
import { initTimer, stopTimer }       from '../../util/initTimer';
import {SIZE_PER_PAGE, TOAST_CONFIG} from '../../config/constants';
import {
  AmountFormat, Box,
  ContentHeader,
  DefaultButton,
  Filter, Form,
  FormElement,
  Loading,
  Panel, PrimaryButton, Switch,
  TableList,
  Toggle,
  TransactionRow,
} from '../../components';
import './styles.scss';
import { usersActions }               from '../../state/ducks/users';
import { authActions }                from '../../state/ducks/auth';
import {formatDate, formatDateEn} from "../../util";
import {ratesActions} from "../../state/ducks/rates";
import ReactTooltip from "react-tooltip";
import FormRate from "./components/FormRate";
import {ConfirmAlert} from "../../util/ConfirmAlert";

class Rates extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dateConfig: new  Date(),
      description: '',
      amount: '',
      destinyType: '',
      view: '',
      loading: false,
      type: '',
      switchList: [],
    };

    this.callApiList = this.callApiList.bind(this);

  }

  componentDidMount() {
    initTimer(this.props);
    this.callApiList();
  }

  componentWillUnmount() {
    stopTimer();
  }

  callApiList = () => {
    this.setState({loading: true});
    const {t, showMessage, fetchRateList, } = this.props;
    const {type} = this.state;

    fetchRateList({
      type,
      callback: (response) => {
        this.setState({loading: false});
        if (response) {
          if (!response.success) {
            toast.dismiss();
            showMessage({
              message: t('connectionError'),
              config: TOAST_CONFIG.ERROR,
            });
          }
        } else {
          toast.dismiss();
          showMessage({
            message: t('connectionError'),
            config: TOAST_CONFIG.ERROR,
          });
        }
      },
    });
  }


  handleChangeView = (view = '') => {
    this.setState({ view });
    if (view === '') {
      this.callApiList();
    }
  };

  handleEdit = (id, dateConfig, description, destinyType, amount  ) => {
    this.setState({
      id,
      dateConfig,
      description,
      destinyType,
      amount,
      view: 'edit',
    });
  };

  handleDelete = (id) => {
    ConfirmAlert(
      { ...this.props },
      () => {
        const { deleteRate, showMessage } = this.props;
        const data = {
          date: formatDate(new Date()),
          description: 'Desactivada por consola',
        };

        deleteRate({
          id,
          data,
          callback: ({ data, success }) => {
            if (data.message) {
              this.callApiList();
              toast.dismiss();
              showMessage({
                message: data.message,
                config: success ? TOAST_CONFIG.SUCCESS : TOAST_CONFIG.INFO,
              });
            }

          },
        });
      },
      'deleteRate',
    );
  };

  handleActiveDisable = (event, i, id) => {

    const item = event.target.name;
    const isChecked = event.target.checked;

    const {switchList} = this.state;

    let listSwitch= switchList;
    listSwitch[i] = isChecked;

    const {t, showMessage, activeDisabledRate} = this.props;
    const data = {
      id : id,
      checked: isChecked ? 'active' : 'deactive',
    };
    const status = isChecked ? 'activó' : 'desactivó';

    activeDisabledRate({
      data,
      callback: (response) => {
        const {success, data} = response;

        if (data) {
          listSwitch[i] = success ? isChecked : !isChecked;
          this.setState({switchList: listSwitch});

          toast.dismiss();
          showMessage({
            message: success ? t('activeDisableRate', {status}) : data.message,
            config: TOAST_CONFIG.INFO,
          });
        } else {
          toast.dismiss();
          showMessage({
            message: t('saveUserDataError'),
            config: TOAST_CONFIG.ERROR,
          });
        }
      },
    });
  }

  render() {
    const {t, rates :{list, totalElements, destination_types}, auth: { account: { product: currency } }, } = this.props;
    const {view, id, dateConfig, description, amount, destinyType, loading, switchList} = this.state

    if (view === 'create') {
      return (<FormRate handleChangeView={this.handleChangeView} currency={currency} />);
    }
    if (view === 'edit') {
      return (<FormRate handleChangeView={this.handleChangeView} id={id} dateConfig={dateConfig} description={description} destinyType={destinyType} amount={amount} currency={currency} />);
    }
    return (
      <div className="rates">
        { loading && <Loading />}
          <Filter title={t('rates')} />

        <ContentHeader
          headingRightText={t('ratesTotal')}
          headingRightSubText={`${totalElements}`}
          headingText={(
            <PrimaryButton
              name={t('newRate')}
              onClick={() => { this.handleChangeView('create'); }}
            />
          )}
          classFirstColumn="is-paddingless"
        >
          <TableList
            tableClasses="floating-table"
            totalElements={totalElements}
            onChangePage={this.onChangePage}
            head={(
              <tr>
                <th>{t('dateConfig')}</th>
                <th>{t('destinyType')}</th>
                <th>{`${t('amount')} (${currency})`}</th>
                <th>{t('description')}</th>
                <th>{t('statusRate')}</th>
                <th />
                <th />
              </tr>
            )}
            colNumber={6}
            lenghtData={totalElements}
          >
            {list && (list.map((row, i) => (
              <TransactionRow
                key={row.id}
                helper={t('detailRate')}
              >
                <td>{row.date}</td>
                <td>{row.destination}</td>
                <td>
                  <AmountFormat
                    name="amount"
                    value={row.amount}
                    displayType="text"
                  />
                </td>
                <td>{row.description}</td>
                <td>{switchList[i] !== undefined ? (switchList[i] === true ? t('active'): t('inactive')) : (row.active === '1' ? t('active'): t('inactive'))}</td>
                <td>
                  <button
                    type="button"
                    className="button is-info is-inverted"
                    onClick={() => { this.handleEdit(row.id, row.created_at, row.description, row.id_destination, row.amount); }}
                    data-for="tooltip-edit"
                    data-tip=""
                  >
                      <span className="icon">
                        <i className="far fa-edit">
                          <ReactTooltip
                            id="tooltip-edit"
                            type="info"
                            effect="solid"
                          >
                            {t('edit')}
                          </ReactTooltip>
                        </i>
                      </span>
                  </button>
                  <button
                    type="button"
                    className="button is-danger is-inverted"
                    onClick={() => { this.handleDelete(row.id); }}
                    data-for="tooltip-delete"
                    data-tip=""
                  >
                      <span className="icon">
                        <i className="far fa-trash-alt">
                          <ReactTooltip
                            id="tooltip-delete"
                            type="error"
                            effect="solid"
                          >
                            {t('delete')}
                          </ReactTooltip>
                        </i>
                      </span>
                  </button>
                </td>
                <td>
                  <Switch name={`switch-${i}`}
                          id={`switch-orange-${i}`}
                          value={switchList[i] !== undefined ? switchList[i] : (row.active === '1' ? true : false)}
                          onChange={(event) =>{this.handleActiveDisable(event, i, row.id)} }
                          checked={switchList[i] !== undefined ? switchList[i] : (row.active === '1' ? true : false)}
                          htmlFor={`switch-orange-${i}`}
                  />

                </td>
              </TransactionRow>
            )))
            }
          </TableList>
        </ContentHeader>
      </div>
    );
  }
}


Rates.propTypes = {
  t: PropTypes.func.isRequired,
  showMessage: PropTypes.func,
  fetchRateList: PropTypes.func,
  deleteRate: PropTypes.func,
  rates: PropTypes.object,
};

Rates.defaultProps = {
  showMessage: () => {},
  deleteRate: () => {},
  fetchRateList: () => {},
  rates: {},
};


const mapStateToProps = ({ auth, users, rates }) => (
  {
    auth,
    users,
    rates,
  }
);

export default compose(
  connect(mapStateToProps, {
    ...authActions,
    ...usersActions,
    ...ratesActions,
  }),
  withNamespaces(),
)(Rates);
