import { conciliationActions, conciliationTypes } from '../ducks/conciliation';
import * as fileSaver               from "file-saver";

const fetchList = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== conciliationTypes.FETCH_LIST) {
    return;
  }
  const { callback, data: dataSend, data: { export: exportExcel } } = action.payload || {};
  dispatch({
    type: conciliationTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: `/admin/get-render`,
        data: dataSend,
        responseType: exportExcel ? 'blob' : 'json',
      },
      authorization: true,
      onStart: conciliationActions.startFetch,
      onComplete: (responseData) => {
        if (exportExcel) {
          const blob = responseData.data
          const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fileSaver.saveAs(file, 'Conciliaciones.xlsx');
        } else {
          const conciliations = responseData.data.data;
          dispatch(conciliationActions.updateList({ data: conciliations }));
          callback(responseData.data);
        }
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: conciliationActions.endFetch,
    },
  });
};

const fetchConciliateDetail = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== conciliationTypes.FETCH_CONCILIATE_DETAIL) {
    return;
  }
  const { callback, pageSize, page } = action.payload || {};
  dispatch({
    type: conciliationTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/console/payments-pending-reconcile?pageSize=${pageSize}&page=${page}`,
      },
      authorization: true,
      onStart: conciliationActions.startFetch,
      onComplete: (responseData) => {
        const { data } = responseData;
        if (data.success) {
          dispatch(conciliationActions.updateConciliateDetail({ data }));
        }
        callback(data);
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: conciliationActions.endFetch,
    },
  });
};

const fetchTypeTransactionConciliate = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== conciliationTypes.FETCH_TYPE_TRANSACTION_CONCILIATE) {
    return;
  }
  dispatch({
    type: conciliationTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/admin/type-transactions-renders`,
      },
      authorization: true,
      onStart: conciliationActions.startFetch,
      onComplete: (responseData) => {
        dispatch(conciliationActions.updateTypeTransactionConciliate(responseData.data));
      },
      onError: (response) => {
        console.log(response);
      },
      onEnd: conciliationActions.endFetch,
    },
  });
};

const fetchReconciled = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== conciliationTypes.FETCH_RECONCILED) {
    return;
  }
  const { data, callback } = action.payload || {};

  dispatch({
    type: conciliationTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: `/admin/reconcile`,
        data,
      },
      authorization: true,
      onStart: conciliationActions.startFetch,
      onComplete: (responseData) => {
        const { data } = responseData;
        if (data.success) {
          dispatch(conciliationActions.updateReconciled({ data }));
        }
        callback(data);
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: conciliationActions.endFetch,
    },
  });
};

const fetchRejectConciliate = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== conciliationTypes.FETCH_REJECT_CONCILIATE) {
    return;
  }
  const { data, callback } = action.payload || {};

  dispatch({
    type: conciliationTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: `/admin/reject-conciliate`,
        data,
      },
      authorization: true,
      onStart: conciliationActions.startFetch,
      onComplete: ({ data: { data } }) => {
        if (data.success) {
          dispatch(conciliationActions.updateRejectConciliate({ data }));
        }
        callback(data);
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: conciliationActions.endFetch,
    },
  });
};

const fetchDetailToConciliate = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== conciliationTypes.FETCH_DETAIL_TO_CONCILIATE) {
    return;
  }
  const { data, callback } = action.payload || {};
  dispatch({
    type: conciliationTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/admin/get-transactions-by-render',
        data,
      },
      authorization: true,
      onStart: conciliationActions.startFetch,
      onComplete: ({ data: { data, success } }) => {
        dispatch(conciliationActions.updateDetailToConciliate({ data }));
        callback(data, success);
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: conciliationActions.endFetch,
    },
  });
};

export default [
  fetchList,
  fetchReconciled,
  fetchConciliateDetail,
  fetchDetailToConciliate,
  fetchRejectConciliate,
  fetchTypeTransactionConciliate,
];
