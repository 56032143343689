import {createAction} from 'redux-actions';
import types from './types';

const startFetch = createAction(types.START_FETCH);
const endFetch = createAction(types.END_FETCH);
const fetchServiceCharge = createAction(types.FETCH_SERVICE_CHARGE);
const setServiceCharge = createAction(types.SET_SERVICE_CHARGE);
const editServiceCharge = createAction(types.EDIT_SERVICE_CHARGE);
const updateServiceCharge = createAction(types.UPDATE_SERVICE_CHARGE);


const showMessage = createAction(types.MESSAGE,
    ({message}) => ({
        message,
    }),
    ({config}) => ({
        config,
    }));


export default {
    startFetch,
    endFetch,
    showMessage,
    fetchServiceCharge,
    setServiceCharge,
    editServiceCharge,
    updateServiceCharge,
}
