import React, { Fragment } from 'react';
import PropTypes           from 'prop-types';
//import './styles.scss';

/**
 * Created by Feedback Software on 30/09/19.
 * @param value
 * @param onChange function to handle the state in the father component
 * @param onBlur function to handle the state in the father component
 * @param helpText
 * @param label if empty doesn't show
 * @param disabled boolean to disable input
 * @returns {*}
 * @constructor
 */
const Checkbox = ({
  name,
  label,
  value,
  defaultValue,
  onChange,
  onBlur,
  helpText,
  disabled,
  helper,
}) => (
  <Fragment>
    <input type="checkbox" name={name} value={defaultValue} onChange={onChange} onBlur={onBlur} checked={value} disabled={disabled} /> { label && label.toString() }
  </Fragment>
);

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.bool.isRequired,
  defaultValue: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.bool,
  ]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  helpText: PropTypes.string,
  disabled: PropTypes.bool,
  helper: PropTypes.string,
};

Checkbox.defaultProps = {
  value: false,
  defaultValue: true,
  onChange: () => {},
  onBlur: () => {},
  helpText: null,
  label: null,
  disabled: false,
  helper: '',
};

export default Checkbox;
