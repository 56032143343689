import React from 'react';
import {withNamespaces} from 'react-i18next';
import PropTypes from 'prop-types';
import './styles.scss';

const InformationBox = (props) => {
  const {
    t, icon, title, content, customClass, boxClass,
  } = props;
  let bodyInformation = {};
  if (typeof content !== 'object') {
    bodyInformation = <h3 className="text-white has-text-right">{content}</h3>;
  } else {
    bodyInformation = (
      content.map(value => (
        <div className="box-information">
          <div className="has-text-right">
            <small className="text-white title">{t(value.title)}</small>
          </div>
          <div className="has-text-right value">
            <strong className="text-white">{value.value}</strong>
          </div>
        </div>
      ))
    );
  }
  return (
    <div className={`information-box column ${ customClass }`}>
      <div className={`card ${ boxClass }`}>
        <div className="card-header">
          <div className="card-header-icon"><i className={icon}/></div>
          <div className="card-header-title">{t(title)}</div>
        </div>
        <article className="card-content">
          {bodyInformation}
        </article>
      </div>
    </div>
  );
};

InformationBox.propTypes = {
  t: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  customClass: PropTypes.string,
  boxClass: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]).isRequired,
};
InformationBox.defaultProps = {
  customClass: '',
  boxClass: '',
};
export default withNamespaces()(InformationBox);
