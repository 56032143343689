import React from 'react';
import PropTypes from 'prop-types';

const DefaultButton = ({ name, type, onClick, icon }) => (
  <div className="m-8px">
    <button
      type={type}
      onClick={onClick}
      className="button is-custom-back"
    >
      {icon && (<i className={`mr-8px ${icon}`} />)}
      {name}
    </button>
  </div>
);
DefaultButton.propTypes = {
  onClick: PropTypes.func,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  icon: PropTypes.string,
};
DefaultButton.defaultProps = {
  type: 'button',
  onClick: null,
  icon: 'fas fa-chevron-left',
};

export default DefaultButton;
