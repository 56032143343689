const START_FETCH = 'operator/START_FETCH';
const END_FETCH = 'operator/END_FETCH';
const END_ERROR = 'operator/END_ERROR';
const API_CALL = 'operator/API_CALL';
const MESSAGE = 'operator/MESSAGE';
const FETCH_LIST_SIMPLE_ROL = 'operator/FETCH_LIST_SIMPLE_ROL';
const UPDATE_LIST_SIMPLE_ROL = 'operator/UPDATE_LIST_SIMPLE_ROL';


export default {
  START_FETCH,
  END_FETCH,
  END_ERROR,
  API_CALL,
  MESSAGE,
  FETCH_LIST_SIMPLE_ROL,
  UPDATE_LIST_SIMPLE_ROL,
}
