import React, { Component }     from 'react';
import PropTypes                from 'prop-types';
import { compose }              from 'redux';
import { connect }              from 'react-redux';
import { withNamespaces }       from 'react-i18next';
import { initTimer, stopTimer } from '../../../../util/initTimer';
import { Form }                 from '../../../../components';
import { usersActions }         from '../../../../state/ducks/users';
import { authActions }          from '../../../../state/ducks/auth';
import { agencyActions }        from '../../../../state/ducks/agency';
import { TOAST_CONFIG }         from '../../../../config/constants';
import { AddressDetailForm }    from '../../../userDetail';
import { toast }                from 'react-toastify';

class AddressData extends Component {
  constructor(props) {
    super(props);
    const {
      users: {
        userDetail: {
          province,
          city,
          department,
          address,
          zip_code: zipCode,
        }
      }
    } = props;
    this.state = {
      country: 'AR',
      province,
      location: department,
      city,
      address,
      zipCode,
      agency: '',
      listTabs: [],
      cities: [],
      locations: [],
    };
  }

  componentDidMount() {

    const { province, location, city } = this.state;
    stopTimer();
    initTimer(this.props);
    this.getProvinces(province);
    this.getLocationsByProvince(province, location);
    this.getCitiesByDepartment(location, city);
  }

  getProvinces = async (prov) => {
    const {
      getProvinces, auth: { provinces },
      users: {
        userDetail: {
          province,
        }
      }
    } = this.props;
    await getProvinces();
    if (prov) {
      const provV = provinces.find(item => item.value === prov);
      this.setState({
        province: provV
      });
    }
  };

  getLocationsByProvince = async (prov, searchLoc) => {
    const {
      getLocations,
      t,
      showMessage,
      users: {
        userDetail: {
          department,
        }
      }
    } = this.props;
    let loc = '';

    if (prov !== null && prov !== '' && prov !== undefined) {
      await getLocations({
        province: prov,
        callback: (data) => {
          if (!data.success) {
            toast.dismiss();
            showMessage({
              message: t('connectionError'),
              config: TOAST_CONFIG.ERROR,
            });
            return false;
          }
          loc = [];
          data.data.map((row) => {
            const l = { label: row.name, value: row.id };
            loc.push(l);
          });
          this.setState({
            locations: loc
          });

          if (searchLoc) {
            const locV = loc.find(item => item.value == department);
            this.setState({
              location: locV
            });
          }
        },
      });
    } else {

    }
    return loc;
  }

  getCitiesByDepartment = async (location, searchCity) => {
    const {
      getCities,
      t,
      showMessage,
      users: {
        userDetail: {
          city,
        }
      }
    } = this.props;
    const fCity = [];
    if (location !== null && location !== '' && location !== undefined) {
      await getCities({
        department: location,
        callback: (data) => {
          if (!data.success) {
            toast.dismiss();
            showMessage({
              message: t('connectionError'),
              config: TOAST_CONFIG.ERROR,
            });
            return false;
          }
          data.data.map((row) => {
            const l = { label: row.name, value: row.id };
            fCity.push(l);
          });

          this.setState({
            cities: fCity
          });

          if (searchCity) {
            const fCityV = fCity.find(item => item.value === city);
            this.setState({
              city: fCityV
            });
          }
        },
      });
    } else {

    }
    return fCity;
  }

  callApiList = async (e) => {
    e.preventDefault();
    const { showMessage, t, editAddressUser, identification } = this.props;
    const {
      province,
      location,
      city,
      address,
      zipCode,
    } = this.state;

    if (address === '' || province === undefined || province === '' || location === undefined || location === '' || zipCode === '') {
      showMessage({
        message: t('allAddressRequired'),
        config: TOAST_CONFIG.ERROR,
      });
      return;
    }

    const data = {
      person: identification,
      address,
      city: city !== undefined ? city.value : '',
      zip_code: zipCode,
    };

    editAddressUser({
      data,
      callback: (response) => {
        if (response.success) {
          toast.dismiss();
          showMessage({
            message: t('saveUserAddress'),
            config: TOAST_CONFIG.SUCCESS,
          });
        } else {
          toast.dismiss();
          showMessage({
            message: t('saveUserAddressError'),
            config: TOAST_CONFIG.ERROR,
          });
        }
      },
    });
  };

  handlePhoneChange = (value, name) => {
    stopTimer();
    initTimer(this.props);
    this.setState({
      [name]: value,
    });
  };

  handleInputChange = ({ target: { value: targetValue, name } }) => {
    stopTimer();
    initTimer(this.props);
    this.setState({
      [name]: targetValue,
    });
  };

  onSubmit = (event) => {
    stopTimer();
    initTimer(this.props);
    event.preventDefault();
    this.callApiList()
  };

  handleValidator = () => {
    const { t, showMessage } = this.props;
    const {
      dni,
      cuit,
      name,
      lastname,
      phoneNumber,
      cuitCompany,
      companyType,
      nameCompany,
      iva,
      usernameCompany,
      phoneNumberCompany,
    } = this.state;
    if (dni === ''
      || cuit === ''
      || name === ''
      || lastname === ''
      || phoneNumber === ''
      || cuitCompany === ''
      || companyType === ''
      || nameCompany === ''
      || iva === ''
      || usernameCompany === ''
      || phoneNumberCompany === '') {
      showMessage({
        message: t('allRequiredFields'),
        config: TOAST_CONFIG.ERROR,
      });
      return false;
    }
    return true;
  }

  render() {
    const {
      country,
      province,
      location,
      city,
      address,
      zipCode,
      cities,
      locations,
    } = this.state;

    const fullAddress = {
      country,
      province,
      location,
      city,
      address,
      zipCode,
    };

    const {
      t, users: { loading },
      auth: { loading: loadingAuth, },
      agency: { loading: loadingAgency, },
    } = this.props;

    return (
      <div className="pr-16px pl-16px pt-16px content-xl-center">
        <Form
          submitText={t('save')}
          nameButton="configButton"
          onSubmit={(e) => {this.callApiList(e)}}
        >
          <AddressDetailForm
            handleInputChange={this.handleInputChange}
            handlePhoneChange={this.handlePhoneChange}
            address={fullAddress}
            titleLabel={'addressAgencyInformation'}
            editable
          />
        </Form>
      </div>
    );
  }
}

AddressData.propTypes = {
  t: PropTypes.func.isRequired,
  users: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({}),
  ]).isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = ({ users, auth, agency }) => (
  {
    users,
    auth,
    agency,
  }
);
export default compose(
  connect(mapStateToProps, {
    ...usersActions,
    ...authActions,
    ...agencyActions,
  }),
  withNamespaces(),
)(AddressData);
