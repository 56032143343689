import {cardActions, cardTypes} from '../ducks/card';
import {TOAST_CONFIG} from '../../config/constants';

const cardData = ({dispatch}) => next => (action) => {
  next(action);

  if (action.type !== cardTypes.FETCH_CARD_DATA) {
    return;
  }

  dispatch({
    type: cardTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: 'api/get-country',
      },
      authorization: true,
      onStart: cardActions.startFetch,
      onComplete: ({data: responseData}) => {
        const {success, data} = responseData;
        if (success) {
          const {
            card, person, address, transactions,
          } = data;

          dispatch(cardActions.updateCardData({
            card,
            person,
            address,
            transactions,
          }));
        }
      },
      onEnd: cardActions.endFetch,
    },
  });
};

const recharge = ({dispatch}) => next => (action) => {
  next(action);

  if (action.type !== cardTypes.RECHARGE_CARD) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload;

  dispatch({
    type: cardTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: 'prepaid/recharge-card',
        data,
      },
      authorization: true,
      onStart: cardActions.startFetch,
      onComplete: ({data: responseData}) => {
        const {success} = responseData;
        if (success) {
          callback();
          dispatch(cardActions.showMessage({
            message: 'El saldo de su tarjeta ha sido recargado exitosamente',
            config: TOAST_CONFIG.INFO,
          }));
        } else {
          dispatch(cardActions.showMessage({
            message: data.data.message,
            config: TOAST_CONFIG.INFO,
          }));
        }
      },
      onEnd: cardActions.endFetch,
    },
  });
};

export default [cardData, recharge];
