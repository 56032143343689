import React, { Fragment } from 'react';
import { withNamespaces }  from 'react-i18next';
import { compose }         from 'redux';
import { connect }         from 'react-redux';
import { FormElement, AmountFormat }     from '../../../../components';
import { agencyActions }   from '../../../../state/ducks/agency';
import PropTypes           from 'prop-types';

const ServiceForm = (props) => {
  const {
    t,
    editable,
    config: {
      charge,
      iva,
    },
    handleInputChange,
    title,
    chargeCurrent, ivaCurrent
  } = props;

  return (
    <Fragment>
      <div className='columns is-multiline'>
        <div className='column is-full'>
          <h4>{t(title)}</h4>
          <hr className='dots' />
        </div>
        <FormElement
          name='charge'
          onChange={(e) => handleInputChange(e)}
          value={charge}
          label={t('charges')}
          typeElement={!editable ? 'text' : 'amountFormat'}
        />
        <FormElement
          name='iva'
          onChange={(e) => handleInputChange(e)}
          value={iva}
          label={t('ivas')}
          typeElement={!editable ? 'text' : 'amountFormat'}
        />
        <FormElement
          name='charge'
          value={
            <AmountFormat
              value={chargeCurrent}
              currency={'%'}
              displayType='text'
            />
          }
          label={t('chargeCurrent')}
          typeElement='text'
        />
        <FormElement
          name='iva'
          value={
            <AmountFormat
              value={ivaCurrent}
              currency={'%'}
              displayType='text'
            />
          }
          label={t('ivaCurrent')}
          typeElement='text'
        />
      </div>
    </Fragment>
  );
};

ServiceForm.propTypes = {
  t: PropTypes.func.isRequired,
  editable: PropTypes.bool,
  title: PropTypes.string,
  config: PropTypes.shape({
    charge: PropTypes.string.isRequired,
    iva: PropTypes.string.isRequired,
  }),
  handleInputChange: PropTypes.func,
};

ServiceForm.defaultProps = {
  editable: false,
  config: {},
  title: '',
  handleInputChange: null,
};
const mapStateToProps = ({ agency }) => (
  { agency }
);
export default compose(
  connect(mapStateToProps, {
    ...agencyActions,
  }),
  withNamespaces(),
)(ServiceForm);
