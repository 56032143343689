import React                   from 'react';
import {
  DefaultButton, Form, Panel,
  SelectSearchable,
  TextInput
} from "../../../../components";
import AmountFormat            from "../../../../components/AmountFormat";
import {withNamespaces} from "react-i18next";

const FormDepositWithdrawalMoney = ({t, typeOperation, typeOperations, amount, description, onChangeInput, onChangeSelect, onSubmit, currency}) => {
  return (
    <Panel
      headingText={t('makeOperation')}
    >
      <div className="p-16px content-xl-center">
        <Form
          submitText={t('save')}
          onSubmit={(ev) => onSubmit(ev)}
          nameButton="configButton"
        >
          <div className="columns is-multiline">
            <SelectSearchable
              label={t('typeOperation')}
              name="typeOperation"
              options={typeOperations}
              onChange={(event) => {
                onChangeSelect(event, 'typeOperation')
              }}
              value={typeOperation}
              fieldClasses="column is-6 content-select"
              labelClasses="label-select"
              controlClasses="pt-8px"
              inputClasses={'input-border-select'}
            />
            <div className="column is-6">
              <div className="pt-8px">
                <span className="pr-8px">
                  {t('amount')+' '+ currency}:
                </span>
                <span>
                  <AmountFormat
                    name="amount"
                    label={ t('amount') }
                    value={ amount }
                    fieldClasses="txt-input"
                    onChange={(e) => { onChangeInput(e); }}
                  />

                </span>
              </div>
            </div>

            <div className="column is-6">
              <div className="pt-8px">
                <span className="pr-8px">
                  {t('description')}:
                </span>
                <span>
                  <TextInput
                    name="description"
                    value={description}
                    type="text"
                    inputClasses="txt-input"
                    onChange={(e) => { onChangeInput(e); }}
                    maxLength="60"
                  />
                </span>
              </div>
            </div>

          </div>
        </Form>
      </div>
    </Panel>
  )
};


export default withNamespaces()(FormDepositWithdrawalMoney);