import React, { Component, Fragment } from 'react';
import { withNamespaces }             from 'react-i18next';
import { compose }                    from 'redux';
import { Link }                       from 'react-router-dom';
import { toast }                      from 'react-toastify';
import { connect }                    from 'react-redux';
import {
  AmountFormat,
  ContentHeader,
  DefaultButton,
  Filter,
  FormElement,
  Loading,
  NameAgency,
  TableList,
  TransactionRow,
  ExportExcel
}                                     from '../../../components';
import { initTimer, stopTimer }       from '../../../util/initTimer';
import { TOAST_CONFIG, SIZE_PER_PAGE }               from '../../../config/constants';
import { authActions }                from '../../../state/ducks/auth';
import { transactionsActions }        from '../../../state/ducks/transactions';
import { agencyActions }              from '../../../state/ducks/agency';
import { formatDate }                 from '../../../util';
import './styles.scss';


class AccountInfo extends Component {

  constructor(props) {
    super(props);
    this.state = {
      idAgency: '',
      transactionTypeAgencies: '',
      page: 0,
      dateStart: '',
      dateEnd: '',
      agency: '',
    };
    this.callApiLis = this.callApiList.bind(this);
  }

  componentDidMount() {
    stopTimer();
    initTimer(this.props);
    const { location, fetchTransactionsTypeAgencies } = this.props;

    if (location !== undefined && location.state !== undefined && location.state.id !== undefined) {
      this.setState({
        idAgency: location.state.id,
        agency: location.state.agency,
      }, () => {this.callApiList()});
    }

    fetchTransactionsTypeAgencies();
  }

  handleInputChange = (event) => {
    const {
      target: {
        value: targetValue, name,
      },
    } = event;
    this.setState({
      [name]: targetValue,
    });
  };


  onChangePage = (selectPage) => {
    stopTimer();
    initTimer(this.props);
    this.setState(() => ({
      page: selectPage ? selectPage.selected : 0,
    }), () => {
      this.callApiList();
    });
  };

  onChangeDate = (date, name) => {
    stopTimer();
    initTimer(this.props);
    const dateValue = date !== null ? date : '';
    this.setState({
      [name]: dateValue,
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.onChangePage();
  };

  callApiList = (getExcel = false) => {
    const {
      t,
      fetchTransactionsList,
      showMessage,
      agency: { transactionTypeAgencies, movementAccountList: { totalElements } },
    } = this.props;

    const { idAgency, typeTransaction, dateStart, dateEnd, page } = this.state;

    const type = transactionTypeAgencies.find(item => item.value === typeTransaction);

    fetchTransactionsList({
      data: {
        id: idAgency,
        typeTransaction: type !== undefined ? type.id : '',
        date_from: formatDate(dateStart),
        date_to: formatDate(dateEnd),
        pageSize: !getExcel ? SIZE_PER_PAGE : totalElements,
        page: getExcel ? 0 : page,
        export: getExcel,
      },
      callback: (response) => {
        if (!response.success) {
          toast.dismiss();
          showMessage({
            message: t('connectionError'),
            config: TOAST_CONFIG.ERROR,
          });
        }
      },
    });
  }

  render() {
    const {
      t, agency: { loading, transactionTypeAgencies, movementAccountList: { alias, cvu, amount, list, totalElements } },
      transactions: {
        currency,
      }
    } = this.props;
    const { page, typeTransaction, dateStart, dateEnd, agency } = this.state;

    return (
      <div>{loading && <Loading />}

        <Fragment>
          <div className="columns is-12">
              <div className='content-amount'>
                <p className='subtitle-dt-info'>{t('alias')}</p>
                <p className='amount-account-info'>{alias ? alias : 'Sin Alias'}</p>
            </div>
            <div className='content-amount'>
              <p className='subtitle-dt-info'>{t('operatingBalance')}</p>
              <p className='amount-account-info'>{amount}</p>
            </div>
            <div className='content-amount'>
              <p className='subtitle-dt-info'>{t('CVU')}</p>
              <p className='amount-account-info'>{cvu}</p>
            </div>
          </div>
          <Filter
            title={t('adminRed')}
            subtitle={t('statusAccountAgency') + ': ' + t('accountMovements')}
            onSubmit={(e) => this.onSubmit(e)}>
            <FormElement
              name='dateStart'
              onChange={ev => this.onChangeDate(ev, 'dateStart')}
              label={t('dateStart')}
              value={dateStart}
              typeElement="datepicker"
              maxDate={dateEnd || new Date()}
            />
            <FormElement
              name='dateEnd'
              onChange={ev => this.onChangeDate(ev, 'dateEnd')}
              value={dateEnd}
              label={t('dateEnd')}
              typeElement="datepicker"
              maxDate={new Date()}
              minDate={dateStart}
            />
            <FormElement
              name="typeTransaction"
              onChange={this.handleInputChange}
              value={typeTransaction}
              label={t('transactionsType')}
              optionsSelect={transactionTypeAgencies}
              placeholder="Seleccione..."
              typeElement="select"
              controlClasses="select select-movement"
            />
          </Filter>
          <ContentHeader
            headingText={
              <Link to={{
                pathname: '/info-agency',
                state: { agency }
              }}
              >
                <DefaultButton name={t('goBack')} />
              </Link>
            }
            headingRightText={t('elementsTotal')}
            headingRightSubText={`${totalElements}`}
          >
            <ExportExcel onclick={() => { this.callApiList(true) }}/>
            <TableList
              tableClasses="floating-table"
              totalElements={parseFloat(totalElements)}
              onChangePage={this.onChangePage}
              forcePage={page}
              head={(
                <tr>
                  <th>{t('date')} / {t('hour')}</th>
                  <th>{t('transactionType')}</th>
                  <th>{t('amount')}</th>
                  <th> {t('state')}</th>
                  <th>{t('actions')}</th>
                </tr>
              )}
              colNumber={5}
              lenghtData={totalElements || 0}
            >
              {list && (list.map(operation => (
                <TransactionRow key={operation.id}>
                  <td>{operation.transaction_date}</td>
                  <td>{operation.transaction_type}</td>
                  <td>
                    <AmountFormat
                      name='balance'
                      value={operation.transaction_amount}
                      currency={operation.product}
                      displayType='text'
                      allowNegative
                    />
                  </td>
                  <td>{operation.transaction_status}</td>
                  <td>
                    <Link to={{
                      pathname: '/account-details',
                      state: { transaction: operation }
                    }}
                    >
                      <button
                        className="button is-outlined is-small">{t('showDetail')}</button>
                    </Link>
                  </td>
                </TransactionRow>
              )))
              }
            </TableList>
          </ContentHeader>
        </Fragment>
      </div>
    )
  }
}

const mapStateToProps = ({ auth, agency, transactions }) => ({
  auth, agency, transactions
});
export default compose(connect(mapStateToProps, {
  ...authActions,
  ...agencyActions,
  ...transactionsActions
}), withNamespaces())(AccountInfo);
