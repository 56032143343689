import React, { Component, Fragment }  from 'react';
import { withNamespaces }              from 'react-i18next';
import { connect }                     from 'react-redux';
import { toast }                       from 'react-toastify';
import PropTypes                       from 'prop-types';
import { compose }                     from 'redux';
import {
  ConfirmAlert,
  ContentHeader,
  ExportExcel,
  Filter,
  FormElement,
  Loading,
  Switch,
  TableList,
  Toggle,
  TransactionRow
}                                      from '../../components';
import { authActions }                 from '../../state/ducks/auth';
import { usersActions }                from '../../state/ducks/users';
import { initTimer, stopTimer }        from '../../util/initTimer';
import { SIZE_PER_PAGE, TOAST_CONFIG } from '../../config/constants';
import { formatDate }                  from '../../util';
import './style.scss';
import UserEdit                        from "../userEdit";
import ReactTooltip                    from "react-tooltip";

class OperatorsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      dateStart: '',
      dateEnd: '',
      cuit: '',
      emailOperator: '',
      nameOperator: '',
      rolOperator: '',
      agency: '',
      status: '',
      switchList: []
    }
  }

  componentDidMount() {
    const {
      fetchRolOperator, fetchOperatorAgency, fetchStatusFilter
    } = this.props;
    fetchRolOperator();
    fetchOperatorAgency();
    fetchStatusFilter();
    stopTimer();
    initTimer(this.props);
    this.callApiList();
  }

  callApiList = (getExcel = false) => {
    const {
      t, showMessage, fetchListOperators,
      users: { rolsOperator, operatorAgency, listOperators: { totalElements }, }
    } = this.props;
    const {
      page, dateStart, dateEnd, cuit, emailOperator, nameOperator, rolOperator, agency, status
    } = this.state;
    const rol = rolsOperator.find(item => item.value === rolOperator);
    const agencyName = operatorAgency.find(item => item.value === agency);
    fetchListOperators({
      data: {
        pageSize: !getExcel ? SIZE_PER_PAGE : totalElements,
        page: getExcel ? 0 : page,
        export: getExcel,
        date_from: formatDate(dateStart),
        date_to: formatDate(dateEnd),
        cuit,
        email: emailOperator,
        name: nameOperator,
        rol: rol !== undefined ? rol.id : '',
        agency: agencyName !== undefined ? agencyName.id : '',
        status,
      }, callback: (response) => {
        this.setState({ loading: false });
        if (response) {
          if (!response.success) {
            toast.dismiss();
            showMessage({
              message: t('connectionError'),
              config: TOAST_CONFIG.ERROR,
            });
          }
        } else {
          toast.dismiss();
          showMessage({
            message: t('connectionError'),
            config: TOAST_CONFIG.ERROR,
          });
        }
      },
    });
  }

  onChangeDate = (date, name) => {
    stopTimer();
    initTimer(this.props);
    const dateValue = date !== null ? date : '';
    this.setState({
      [name]: dateValue,
    });
  };

  handleInputChange = (event) => {
    stopTimer();
    initTimer(this.props);
    const { target: { value: targetValue, name } } = event;
    this.setState({
      [name]: targetValue,
    });
  };

  onChangePage = (selectPage) => {
    stopTimer();
    initTimer(this.props);
    this.setState(() => ({
      page: selectPage ? selectPage.selected : 0,
    }), () => {
      this.callApiList();
    });
  };

  handleActiveDisable = (event, i, id_user) => {
    const item = event.target.name;
    const isChecked = event.target.checked;
    const { switchList } = this.state;
    switchList[i] = isChecked;
    this.setState({ switchList });
    const { t, showMessage, updateStateOperator } = this.props;
    const data = {
      id_user: id_user,
      new_status: !isChecked ? 0 : 1,
    };
    const status = isChecked ? 'activó' : 'desactivó';
    updateStateOperator({
      data,
      callback: (response) => {
        if (response.success) {
          toast.dismiss();
          showMessage({
            message: t('activeDisableUSerSuccess', { status }),
            config: status === 'activó' ? TOAST_CONFIG.SUCCESS : TOAST_CONFIG.INFO,
          });
        } else {
          toast.dismiss();
          showMessage({
            message: t('saveUserDataError'),
            config: TOAST_CONFIG.ERROR,
          });
        }
      },
    });
  }

  resetPassword = (e, i, user_type, email) => {
    e.preventDefault();
    ConfirmAlert(
      { ...this.props },
      () => {
        const { t, fetchResetPassOperator, showMessage } = this.props;
        const { emailOperator } = this.state;
        fetchResetPassOperator({
          data: {
            email,
            user_type,
          }, callback: ({ success, data }) => {
            if (success) {
              this.setState(() => ({
                message: t('passConfirm'),
                messageStyle: 'has-text-success',
              }));
              toast.dismiss();
              showMessage({
                message: t('passConfirm'),
                config: TOAST_CONFIG.SUCCESS,
              });
            } else {
              this.setState(() => ({
                message: t('errorResetPasss'),
                messageStyle: 'has-text-danger',
              }));
              toast.dismiss();
              showMessage({
                message: t('errorResetPasss'),
                config: TOAST_CONFIG.ERROR,
              });
            }
          },
        });
      }, 'confirmResetPassword',
    );
  };

  detailUser = async (id) => {
    stopTimer();
    initTimer(this.props);
    await this.setState(() => ({
      loading: true,
    }));
    const { detailUser, dataFiscal } = this.props;
    const data = { identification: id };
    await detailUser({
      data,
      callback: async (response) => {
        const { success, data: userDetail } = response;
      },
    });
  };

  onSubmit = (e) => {
    e.preventDefault();
    this.onChangePage();
    this.callApiList();
  };

  render() {
    const {
      t, users: { loading, listOperators: { list, totalElements }, rolsOperator, operatorAgency, statusFilter },
    } = this.props;
    const {
      page, dateStart, dateEnd, cuit, emailOperator, nameOperator, rolOperator, agency, status,
      switchList
    } = this.state;
    return (
      <div>
        {loading && <Loading />}
        <Toggle>
          {({ toggled, onToggle, toggleProps }) => {
            return (
              <Fragment>
                {toggled && (
                  <UserEdit
                    identification={toggleProps.identification}
                    personType={toggleProps.type_person}
                    handleReturn={() => {
                      this.callApiList();
                      onToggle();
                    }}
                  />
                )}
                {!toggled && (
                  <Fragment>
                    <div className='wow animated slideInDown'>
                      <div className='wow animated slideInDown'>
                        <Filter title={t('operatorsList')} onSubmit={e => this.onSubmit(e)}>
                          <FormElement
                            name='dateStart'
                            onChange={ev => this.onChangeDate(ev, 'dateStart')}
                            label={t('createdDateFrom')}
                            value={dateStart}
                            typeElement='datepicker'
                            maxDate={dateEnd || new Date()}
                          />
                          <FormElement
                            name='dateEnd'
                            onChange={ev => this.onChangeDate(ev, 'dateEnd')}
                            value={dateEnd}
                            label={t('createdDateUntil')}
                            typeElement='datepicker'
                            maxDate={new Date()}
                            minDate={dateStart}
                          />
                          <FormElement
                            name='cuit'
                            onChange={this.handleInputChange}
                            value={cuit}
                            label={'CUIT'}
                            typeElement='input'
                          />
                          <FormElement
                            name='emailOperator'
                            onChange={this.handleInputChange}
                            value={emailOperator}
                            label={t('email')}
                            typeElement='input'
                          />
                          <FormElement
                            name='nameOperator'
                            onChange={this.handleInputChange}
                            value={nameOperator}
                            label={t('nameOperator')}
                            typeElement='input'
                          />
                          <FormElement
                            name='rolOperator'
                            onChange={this.handleInputChange}
                            value={rolOperator}
                            label={t('rolOperator')}
                            optionsSelect={rolsOperator}
                            placeholder={t('selectRol')}
                            typeElement="select"
                            controlClasses="select"
                          />
                          <FormElement
                            name='agency'
                            onChange={this.handleInputChange}
                            value={agency}
                            label={t('agency')}
                            optionsSelect={operatorAgency}
                            placeholder={t('selectAgency')}
                            typeElement="select"
                            controlClasses="select"
                          />
                          <FormElement
                            name='status'
                            onChange={this.handleInputChange}
                            value={status}
                            label={t('state')}
                            optionsSelect={statusFilter}
                            placeholder={t('selectState')}
                            typeElement="select"
                            controlClasses="select"
                          />
                        </Filter>
                        <ContentHeader
                          headingRightText={t('operatorsTotal')}
                          headingRightSubText={`${totalElements}`}
                        >
                          <ExportExcel onclick={() => { this.callApiList(true) }} />
                          <TableList
                            tableClasses="floating-table"
                            totalElements={parseFloat(totalElements)}
                            onChangePage={this.onChangePage}
                            forcePage={page}
                            head={(
                              <tr>
                                <th>{`${t('date')} / ${t('hour')}`}</th>
                                <th>{t('CUIT')}</th>
                                <th>{t('nameOperator')}</th>
                                <th>{t('email')}</th>
                                <th>{t('assignedAgency')}</th>
                                <th>{t('rolOperator')}</th>
                                <th>{t('status')}</th>
                                <th colSpan="2">{t('actions')}</th>
                              </tr>)}
                            colNumber={10}
                            lenghtData={totalElements !== undefined ? totalElements : 0}
                          >
                            {list && (list.map((operator, i) => (
                              <TransactionRow
                                key={i}
                                icon={'far fa-edit icons-actions'}
                                helper={t('edit')}
                                openDetail={async () => {
                                  stopTimer();
                                  initTimer(this.props);
                                  const { detailUser, clearUserDetail } = this.props;
                                  const data = { identification: operator.identification };
                                  await clearUserDetail();
                                  await detailUser({
                                    data,
                                    callback: async (response) => {
                                      const { success, data: userDetail } = response;
                                      if (success) {
                                        onToggle(operator);
                                      }
                                    },
                                  });
                                }}
                              >
                                <td>{operator.date}</td>
                                <td>{operator.cuit}</td>
                                <td>{operator.name}</td>
                                <td>{operator.email}</td>
                                <td>{operator.agency}</td>
                                <td>{operator.rol}</td>
                                <td>{switchList[i] !== undefined ? (switchList[i] === true ? t('active') : t('inactive')) : (operator.status === '1' ? t('active') : t('inactive'))}</td>
                                <td>
                                  <div className='columns' /*group-actions*/>
                                    <div className='column'
                                      aria-hidden="true"
                                      data-for={`switch-orange-${i}`}
                                      data-tip="Test"
                                      >
                                      <Switch
                                        name={`switch-${i}`}
                                        id={`switch-orange-${i}`}
                                        value={switchList[i] !== undefined ? switchList[i] : (operator.status === '1')}
                                        onChange={(event) => {this.handleActiveDisable(event, i, operator.id_user)}}
                                        checked={switchList[i] !== undefined ? switchList[i] : (operator.status === '1')}
                                        htmlFor={`switch-orange-${i}`}
                                      />
                                      <ReactTooltip
                                        id={`switch-orange-${i}`}
                                        effect="solid"
                                        className='text-toltip'
                                      >
                                        {switchList[i] !== undefined ? (switchList[i] === true ? t('deactivate') : t('activate')) : (operator.status === '1' ? t('deactivate') : t('activate'))}
                                      </ReactTooltip>
                                    </div>
                                    <div className='column' /*change-password*/>
                                      <i
                                        className='fas fa-key icons-actions'
                                        onClick={e => this.resetPassword(e, i, operator.user_type, operator.email)}
                                        aria-hidden="true"
                                        data-for={`resetPass-${i}`}
                                        data-tip="Test"
                                      >
                                        <ReactTooltip
                                          id={`resetPass-${i}`}
                                          effect="solid"
                                        >
                                          {t('resetPass')}
                                        </ReactTooltip>
                                      </i>
                                    </div>
                                  </div>
                                </td>
                              </TransactionRow>
                            )))}
                          </TableList>
                        </ContentHeader>
                      </div>
                    </div>
                  </Fragment>
                )}
              </Fragment>
            );
          }}
        </Toggle>
      </div>
    );
  }
}

OperatorsList.propTypes = {
  t: PropTypes.func.isRequired,
  showMessage: PropTypes.func,
  fetchRolOperator: PropTypes.func,
  fetchOperatorAgency: PropTypes.func,
  fetchStatusFilter: PropTypes.func,
  fetchListOperators: PropTypes.func.isRequired,
};

OperatorsList.defaultProps = {
  showMessage: () => {},
  fetchRolOperator: () => {},
  fetchOperatorAgency: () => {},
  fetchStatusFilter: () => {},
};

const mapStateToProps = ({ auth, users }) => (
  { auth, users }
);

export default compose(connect(mapStateToProps, {
    ...usersActions,
    ...authActions,
  }),
  withNamespaces())(OperatorsList);
