import React, { Component }     from 'react';
import PropTypes                from 'prop-types';
import { compose }              from 'redux';
import { connect }              from 'react-redux';
import { withNamespaces }       from 'react-i18next';
import { initTimer, stopTimer } from '../../../../util/initTimer';
import { Form }                 from '../../../../components';
import { usersActions }         from '../../../../state/ducks/users';
import { authActions }          from '../../../../state/ducks/auth';
import { agencyActions }        from '../../../../state/ducks/agency';
import { TOAST_CONFIG }         from '../../../../config/constants';
import { AccountDetailForm }    from '../../../userDetail';
import { toast }                from 'react-toastify';

class AddressData extends Component {
  constructor(props) {
    super(props);
    const {
      users: {
        userDetail: {
          number_account: cvu,
          alias,
        }
      }
    } = props;
    this.state = {
      cvu,
      alias,
    };
  }

  componentDidMount() {
    stopTimer();
    initTimer(this.props);
  }

  callApiList = async (e) => {
    e.preventDefault();
    const { showMessage, t, editDataAliasUser, identification } = this.props;
    const { alias } = this.state;
    const data = {
      person: identification,
      alias,
    };

    editDataAliasUser({
      data,
      callback: (response) => {
        if (response.success) {
          toast.dismiss();
          showMessage({
            message: t('saveUserAliasData'),
            config: TOAST_CONFIG.SUCCESS,
          });
        } else {
          toast.dismiss();
          showMessage({
            message: t('saveUserAliasDataError'),
            config: TOAST_CONFIG.ERROR,
          });
        }
      },
    });
  };

  handleInputChange = ({ target: { value: targetValue, name } }) => {
    stopTimer();
    initTimer(this.props);
    this.setState({
      [name]: targetValue,
    });
  };

  onSubmit = (event) => {
    stopTimer();
    initTimer(this.props);
    event.preventDefault();
    if (this.handleValidator()) {
      this.callApiList()
    }
  };

  handleValidator = () => {
    const { t, showMessage } = this.props;
    const { alias } = this.state;
    if (alias === '') {
      showMessage({
        message: t('allRequiredFields'),
        config: TOAST_CONFIG.ERROR,
      });
      return false;
    }
    return true;
  }

  render() {
    const {
      cvu,
      alias,
    } = this.state;
    const account = {
      cvu,
      alias,
    };
    const { t } = this.props;

    return (
      <div className="pr-16px pl-16px pt-16px content-xl-center">
        <Form
          submitText={t('save')}
          nameButton="configButton"
          onSubmit={(e) => {this.callApiList(e)}}
        >
          <AccountDetailForm
            handleInputChange={this.handleInputChange}
            account={account}
            editable
          />
        </Form>
      </div>
    );
  }
}

AddressData.propTypes = {
  t: PropTypes.func.isRequired,
  users: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({}),
  ]).isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = ({ users, auth, agency }) => (
  {
    users,
    auth,
    agency,
  }
);
export default compose(
  connect(mapStateToProps, {
    ...usersActions,
    ...authActions,
    ...agencyActions,
  }),
  withNamespaces(),
)(AddressData);
