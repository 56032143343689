import React              from 'react';
import PropTypes          from 'prop-types';
import { Link }           from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import SubMenu            from '../SubMenu';

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayMenu: false,
    };
    this.showDropdownMenu = this.showDropdownMenu.bind(this);
    this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
    this.handlerOnClick = this.handlerOnClick.bind(this);
  }

  render() {
    const {
      t, pathname, url, icon, name, submenu, disabled,
    } = this.props;
    let activeSubmenu = false;
    let iconMenu = '';
    if (submenu !== undefined) {
      iconMenu = <i className="icon fas fa-angle-down" aria-hidden="true" />;
      const cantSubMenu = submenu.items.length;
      for (let i = 0; i < cantSubMenu; i++) {
        if (pathname === submenu.items[i].url) {
          activeSubmenu = true;
        }
      }
    }
    return (
      (!disabled ?
        <li
          className={
            `border-bar ${pathname === url || activeSubmenu ? 'active activeIcon' : ''} ${submenu ? (this.state.displayMenu || activeSubmenu ? 'arrow-down' : 'arrow') : ''}`
          }

          onClick={(ev) => {
            this.showDropdownMenu(ev);
            this.handlerOnClick(pathname, url);
          }}
        >
          <Link to={url}>
            <div className="iconList">
              <i className={icon} aria-hidden="true" />
            </div>
            <span>{t(name)}</span>
          </Link>
          {submenu && (
            <SubMenu
              items={submenu.items}
              open={activeSubmenu || this.state.displayMenu}
              pathname={pathname}
              onClick={this.handlerOnClick}
            />
          )}
        </li>
        : null)
    );
  }

  handlerOnClick(path, url) {
    if (path === url) {
      window.location.reload();
    }
  }

  hideDropdownMenu() {
    this.setState({ displayMenu: false }, () => {
      document.removeEventListener('click', this.hideDropdownMenu);
    });
  }

  showDropdownMenu(event) {
    event.preventDefault();
    this.setState({ displayMenu: true }, () => {
      document.addEventListener('click', this.hideDropdownMenu);
    });
  }
}

Menu.propTypes = {
  t: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  submenu: PropTypes.shape({}),
  disabled: PropTypes.bool,
};

export default withNamespaces()(Menu);
