const START_FETCH = 'agency/START_FETCH';
const END_FETCH = 'agency/END_FETCH';
const END_ERROR = 'agency/END_ERROR';
const API_CALL = 'agency/API_CALL';
const MESSAGE = 'agency/MESSAGE';
const FETCH_STATUS_CONCILIATE = 'agency/FETCH_STATUS_CONCILIATE';
const SET_STATUS_CONCILIATE = 'agency/SET_STATUS_CONCILIATE';
const FETCH_AGENCY_LIST = 'agency/FETCH_AGENCY_LIST';
const SET_AGENCY_LIST = 'agency/SET_AGENCY_LIST';
const FETCH_INFO_AGENCIES = 'agency/FETCH_INFO_AGENCY';
const SET_INFO_AGENCIES = 'agency/SET_INFO_AGENCY';
const FETCH_MOVEMENT_AGENCIES = 'agency/FETCH_MOVEMENT_AGENCIES';
const SET_MOVEMENT_AGENCIES = 'agency/SET_MOVEMENT_AGENCIES';
const FETCH_TRANSACTIONS_LIST = 'agency/FETCH_TRANSACTIONS_LIST';
const SET_TRANSACTIONS_LIST = 'agency/SET_TRANSACTIONS_LIST';
const FETCH_TRANSACTION_TYPE = 'agency/FETCH_TRANSACTION_TYPE';
const SET_TRANSACTION_TYPE = 'agency/SET_TRANSACTION_TYPE';
const FETCH_TRANSACTION_TYPE_AGENCIES = 'agency/FETCH_TRANSACTION_TYPE_AGENCIES';
const SET_TRANSACTION_TYPE_AGENCIES = 'agency/SET_TRANSACTION_TYPE_AGENCIES';
const FETCH_BOX_AGENCY_LIST = 'agency/FETCH_BOX_AGENCY_LIST';
const SET_BOX_AGENCY_LIST = 'agency/SET_BOX_AGENCY_LIST';
const FETCH_DETAIL_BOX_AGENCY = 'agency/FETCH_DETAIL_BOX_AGENCY';
const SET_DETAIL_BOX_AGENCY = 'agency/SET_DETAIL_BOX_AGENCY';
const FETCH_STATUS_BOX = 'agency/FETCH_STATUS_BOX';
const SET_STATUS_BOX = 'agency/SET_STATUS_BOX';
const FETCH_LOTSINFO_LIST = 'agency/FETCH_LOTSINFO_LIST';
const SET_LOTSINFO_LIST = 'agency/SET_LOTSINFO_LIST';
const FETCH_STATUS_LOT = 'agency/FETCH_STATUS_LOT';
const SET_STATUS_LOT = 'agency/SET_STATUS_LOT';
const FETCH_COMPANY_TYPES = 'agency/FETCH_COMPANY_TYPES';
const UPDATE_COMPANY_TYPES = 'agency/UPDATE_COMPANY_TYPES';
const FETCH_CREATE_AGENCY = 'agency/FETCH_CREATE_AGENCY';
const UPDATE_CREATE_AGENCY = 'agency/UPDATE_CREATE_AGENCY';
const GET_AGENCY_LIST = 'agency/GET_AGENCY_LIST';
const UPDATE_AGENCY_LIST = 'agency/UPDATE_AGENCY_LIST';
const UPDATE_STATE_AGENCY = 'agency/UPDATE_STATE_AGENCY';
const FETCH_CREATE_OPERATOR = 'agency/FETCH_CREATE_OPERATOR';
const FETCH_LIST_ROLES = 'agency/FETCH_LIST_ROLES';
const UPDATE_LIST_ROLES = 'agency/UPDATE_LIST_ROLES';
const FETCH_CREATE_TRASNPORT_COMPANY = 'agency/FETCH_CREATE_TRASNPORT_COMPANY';
const FETCH_LIST_SIMPLE_AGENCIES = 'agency/FETCH_LIST_SIMPLE_AGENCIES';
const UPDATE_LIST_SIMPLE_AGENCY = 'agency/UPDATE_LIST_SIMPLE_AGENCY';
const UPDATE_EXTRACTION_AGENCY = 'agency/UPDATE_EXTRACTION_AGENCY';

export default {
  START_FETCH,
  END_FETCH,
  END_ERROR,
  API_CALL,
  MESSAGE,
  FETCH_STATUS_CONCILIATE,
  SET_STATUS_CONCILIATE,
  FETCH_AGENCY_LIST,
  SET_AGENCY_LIST,
  FETCH_INFO_AGENCIES,
  SET_INFO_AGENCIES,
  FETCH_MOVEMENT_AGENCIES,
  SET_MOVEMENT_AGENCIES,
  FETCH_TRANSACTIONS_LIST,
  SET_TRANSACTIONS_LIST,
  FETCH_TRANSACTION_TYPE,
  SET_TRANSACTION_TYPE,
  FETCH_TRANSACTION_TYPE_AGENCIES,
  SET_TRANSACTION_TYPE_AGENCIES,
  FETCH_BOX_AGENCY_LIST,
  SET_BOX_AGENCY_LIST,
  FETCH_DETAIL_BOX_AGENCY,
  SET_DETAIL_BOX_AGENCY,
  FETCH_STATUS_BOX,
  SET_STATUS_BOX,
  FETCH_LOTSINFO_LIST,
  SET_LOTSINFO_LIST,
  FETCH_STATUS_LOT,
  SET_STATUS_LOT,
  FETCH_COMPANY_TYPES,
  UPDATE_COMPANY_TYPES,
  FETCH_CREATE_AGENCY,
  UPDATE_CREATE_AGENCY,
  GET_AGENCY_LIST,
  UPDATE_AGENCY_LIST,
  UPDATE_STATE_AGENCY,
  FETCH_CREATE_OPERATOR,
  FETCH_LIST_ROLES,
  UPDATE_LIST_ROLES,
  FETCH_CREATE_TRASNPORT_COMPANY,
  FETCH_LIST_SIMPLE_AGENCIES,
  UPDATE_LIST_SIMPLE_AGENCY,
  UPDATE_EXTRACTION_AGENCY,
};
