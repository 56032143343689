/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import './styles.scss';
import {Link} from 'react-router-dom';
import {withNamespaces} from 'react-i18next';

const OptionLogout = (props) => {
  const {t, name, logout, toggled, tclass} = props;

  return (
    <div className={`boxSettings animated fadeInUp ${ tclass } ${ (toggled) && 'open' }`}>
      <div className="box">
        <ul className="sort-click">
          <li>
            <div className="user-info">
              <div className="user-name">{name}</div>
              <div className="user-position">{t('available')}</div>
            </div>
          </li>
          <li className="box-icon">
            <Link
              to="/profile"
            >
              <i className="icon fas fa-user" aria-hidden="true"/>
              {t('profile')}
            </Link>
          </li>
          <li className="box-icon">
            <Link
              to="/login"
              onClick={logout}
            >
              <i className="icon fas fa-power-off" aria-hidden="true"/>
              {t('logout')}
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default withNamespaces()(OptionLogout);
