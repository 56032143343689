import { toast }                 from 'react-toastify';
import { authTypes }             from '../ducks/auth';
import { TOAST_CONFIG }          from '../../config/constants';
import { transactionsTypes }     from '../ducks/transactions';
import { cardTypes }             from '../ducks/card';
import { usersTypes }            from '../ducks/users';
import { userSupportTypes }      from '../ducks/userSupport';
import { transactionsBindTypes } from '../ducks/transactionBind';
import { conciliationTypes }     from '../ducks/conciliation';
import { configurationsTypes }   from '../ducks/configurations';
import { ratesTypes }            from '../ducks/rates';
import { agencyTypes }           from "../ducks/agency";
import { companyTypes }          from "../ducks/company";
import operatorTypes             from "../ducks/operator/types";
import { switchTypes }           from "../ducks/switch";

const endFetch = ({ dispatch }) => (next) => (action) => {
  next(action);

  const types = [
    authTypes.END_FETCH,
    transactionsBindTypes.END_FETCH,
    transactionsTypes.END_FETCH,
    cardTypes.END_FETCH,
    usersTypes.END_FETCH,
    userSupportTypes.END_FETCH,
    conciliationTypes.END_FETCH,
    configurationsTypes.END_FETCH,
    ratesTypes.END_FETCH,
    agencyTypes.END_FETCH,
    companyTypes.END_FETCH,
    operatorTypes.END_FETCH,
    switchTypes.END_FETCH,
  ];

  if (!types.includes(action.type) || !action.payload) {
    return;
  }

  const { payload, error } = action;

  /*if (error) {
    dispatch({
      type: authTypes.MESSAGE,
      payload: {
        message: payload.message,
      },
      meta: {
        config: TOAST_CONFIG.ERROR,
      },
    });
  }*/

  if (payload.hasOwnProperty('data')
    && payload.data.hasOwnProperty('success') && !payload.data.success) {

    dispatch({
      type: authTypes.MESSAGE,
      payload: {
        message: payload.data.data.message.message || payload.data.data.message || payload.data.data.message_error || '',
      },
      meta: {
        config: TOAST_CONFIG.INFO,
      },
    });
  }
};

const messages = () => next => (action) => {
  const types = [
    authTypes.MESSAGE,
    transactionsTypes.MESSAGE,
    cardTypes.MESSAGE,
    usersTypes.MESSAGE,
    userSupportTypes.MESSAGE,
    transactionsBindTypes.MESSAGE,
    conciliationTypes.MESSAGE,
    configurationsTypes.MESSAGE,
    ratesTypes.MESSAGE,
    agencyTypes.MESSAGE,
    companyTypes.MESSAGE,
    operatorTypes.MESSAGE,
    switchTypes.MESSAGE,
  ];

  if (!types.includes(action.type)) {
    return next(action);
  }
  toast.dismiss();
  const { message = 'THERE IS NO MESSAGE' } = action.payload;
  const { config = TOAST_CONFIG.INFO } = action.meta;
  toast(message, config);
};

export default [endFetch, messages];
