import {serviceChargeActions, serviceChargeTypes} from '../ducks/serviceCharge';

const fetchServiceCharge = ({dispatch}) => next => (action) => {
    next(action);

    if (action.type !== serviceChargeTypes.FETCH_SERVICE_CHARGE) {
        return;
    }
    const {callback} = action.payload || {};

    dispatch({
        type: serviceChargeTypes.API_CALL,
        payload: {
            config: {
                method: 'GET',
                url: '/console/charges-by-service',
            },
            authorization: true,
            onStart: serviceChargeActions.startFetch,
            onComplete: ({data}) => {
                dispatch(serviceChargeActions.setServiceCharge(data));
                callback(data);
            },
            onError: (response) => {
                console.log(response)
                //  callback(response);

            },
            onEnd: serviceChargeActions.endFetch,
        },
    });
};

const editServiceCharge = ({dispatch}) => next => (action) => {
    next(action);

    if (action.type !== serviceChargeTypes.EDIT_SERVICE_CHARGE) {
        return;
    }
    const {data, callback} = action.payload || {};
    dispatch({
        type: serviceChargeTypes.API_CALL,
        payload: {
            config: {
                method: 'POST',
                url: '/console/update-configurations',
                data,
            },
            authorization: true,
            onStart: serviceChargeActions.startFetch,
            onComplete: ({data}) => {
                dispatch(serviceChargeActions.updateServiceCharge(data.data));
                callback(data);
            },
            onError: (response) => {
                callback(response);
                //console.log(response)
            },
            onEnd: serviceChargeActions.endFetch,
        },
    });
};

export default [
    fetchServiceCharge,
    editServiceCharge,
];
