import React, { Component }     from 'react';
import { toast }                from 'react-toastify';
import PropTypes                from 'prop-types';
import { compose }              from 'redux';
import { withNamespaces }       from 'react-i18next';
import connect                  from 'react-redux/lib/connect/connect';
import UpdateImage              from '../../../../components/UpdateImage';
import { initTimer, stopTimer } from '../../../../util/initTimer';
import { Form }                 from '../../../../components';
import { usersActions }         from '../../../../state/ducks/users';
import { TOAST_CONFIG }         from '../../../../config/constants';
import { authActions }          from '../../../../state/ducks/auth';
import { agencyActions }        from '../../../../state/ducks/agency';

class PicturesData extends Component {
  constructor(props) {
    super(props);
    const {
      users: {
        userDetail: {
          dorso,
          frente,
          selfie
        }
      }
    } = props;
    this.state = {
      photoPreview: selfie,
      documentFrontPreview: frente,
      documentBackPreview: dorso,
      photoSelectedFile: selfie,
      documentFrontSelectedFile: frente,
      documentBackSelectedFile: dorso,
      selfie: '',
    };
  }

  componentDidMount() {
    stopTimer();
    initTimer(this.props);
    const {
      photoPreview,
      documentFrontPreview,
      documentBackPreview,
    } = this.state;

    this.getFile(photoPreview, 'photoPreview');
    this.getFile(documentFrontPreview, 'documentFrontPreview');
    this.getFile(documentBackPreview, 'documentBackPreview');
  }

  getFile = (url, filed) => {
    const { t, fetchImg } = this.props;

    fetchImg({
      data: {
        url_file: url
      },
      callback: (response) => {
          this.setState({
            [filed]: URL.createObjectURL(response),
          });
      },
    });
  }


  callApiList = async (e) => {
    e.preventDefault();
    const { showMessage, t, editAddressUser, identification } = this.props;
    const { alias } = this.state;
    const data = {
      person: identification,
      alias,
    };

    editAddressUser({
      data,
      callback: (response) => {
        if (response.success) {
          toast.dismiss();
          showMessage({
            message: t('saveUserAddress'),
            config: TOAST_CONFIG.SUCCESS,
          });
        } else {
          toast.dismiss();
          showMessage({
            message: t('saveUserAddressError'),
            config: TOAST_CONFIG.ERROR,
          });
        }
      },
    });
  };

  handleInputChange = ({ target: { value: targetValue, name } }) => {
    stopTimer();
    initTimer(this.props);
    this.setState({
      [name]: targetValue,
    });
  };

  onSubmit = (event) => {
    stopTimer();
    initTimer(this.props);
    event.preventDefault();
    if (this.handleValidator()) {
      this.callApiSave()
    }
  };

  handleChangeUploadFile = (event) => {
    const reader = new FileReader();
    const { files, name } = event.target;
    const file = files[0];
    reader.onloadend = () => {
      this.setState({
        [`${name}SelectedFile`]: file,
        [`${name}Preview`]: reader.result
      });
    }
    reader.readAsDataURL(file)
  };

  handleRemoveFile = (state) => {
    this.setState({
      [`${state}SelectedFile`]: 'eliminar',
      [`${state}Preview`]: null
    });
  };

  callApiSave = (e) => {
    e.preventDefault();
    const { fetchUploadDocuments, auth: { expToken, user: { token } }, showMessage, t, identification } = this.props;
    const { photoSelectedFile, documentFrontSelectedFile, documentBackSelectedFile } = this.state;
    const data = new FormData();
    data.append('id_person', identification);
    if (photoSelectedFile !== null) {
      data.append('selfie', photoSelectedFile);
    }
    console.log(photoSelectedFile);
    if (documentFrontSelectedFile !== null) {
      data.append('frente', documentFrontSelectedFile);
    }
    console.log(documentFrontSelectedFile);
    if (documentBackSelectedFile !== null) {
      data.append('dorso', documentBackSelectedFile);
    }
    console.log(documentBackSelectedFile);
    if (photoSelectedFile !== null && documentFrontSelectedFile !== null && documentBackSelectedFile !== null) {
      fetchUploadDocuments({
        data, token, expToken,
        callback: async (response) => {
          const { success, data } = response;
          if (success) {
            toast.dismiss();
            showMessage({
              message: t('successfulOperation'),
              config: TOAST_CONFIG.SUCCESS,
            });
          } else if (!success) {
            toast.dismiss();
            showMessage({
              message: data.message,
              config: TOAST_CONFIG.ERROR,
            });
          }
        }
      });
    }
  };

  handleValidator = () => {
    const { t, showMessage } = this.props;
    const { alias } = this.state;
    if (alias === '') {
      showMessage({
        message: t('allRequiredFields'),
        config: TOAST_CONFIG.ERROR,
      });
      return false;
    }
    return true;
  }

  render() {
    const {
      photoPreview,
      documentFrontPreview,
      documentBackPreview,
      photoSelectedFile,
      documentFrontSelectedFile,
      documentBackSelectedFile,
    } = this.state;
    const { t } = this.props;
    console.log('photoPreview', photoPreview);
    console.log('documentFrontPreview', documentFrontPreview);
    console.log('documentBackPreview', documentBackPreview);
    return (
      <div className="content-xl-center">
        <Form
          submitText={t('save')}
          nameButton="configButton"
          className="space"
          onSubmit={(e) => {this.callApiSave(e)}}
        >
          <div className="columns">
            <div className="column is-4 has-text-centered">
              <h4>{t('photography')}</h4>

              <UpdateImage
                onChange={this.handleChangeUploadFile}
                label={t('selfie')}
                imagePreviewUrl={photoPreview}
                selectedFile={photoSelectedFile}
                name={'photo'}
                removeImage={this.handleRemoveFile}
              />
            </div>
            <div className="column is-8 has-text-centered">
              <h4>{t('documentation')}</h4>
              <UpdateImage
                onChange={this.handleChangeUploadFile}
                label={t('front')}
                imagePreviewUrl={documentFrontPreview}
                selectedFile={documentFrontSelectedFile}
                name={'documentFront'}
                removeImage={this.handleRemoveFile}
              />
              <UpdateImage
                onChange={this.handleChangeUploadFile}
                label={t('back')}
                imagePreviewUrl={documentBackPreview}
                selectedFile={documentBackSelectedFile}
                name={'documentBack'}
                removeImage={this.handleRemoveFile}
              />
              <div className="space"> </div>
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

PicturesData.propTypes = {
  t: PropTypes.func.isRequired,
  users: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({}),
  ]).isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = ({ users, auth, agency }) => (
  {
    users,
    auth,
    agency,
  }
);
export default compose(
  connect(mapStateToProps, {
    ...usersActions,
    ...authActions,
    ...agencyActions,
  }),
  withNamespaces(),
)(PicturesData);
