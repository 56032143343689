import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { ToastContainer, toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { authActions } from '../../../state/ducks/auth';
import { Loading } from '../../../components';
import FormLogin from './components/FormLogin';
import './styles.scss';
import { stopTimer } from '../../../util/initTimer';
import { TOAST_CONFIG } from '../../../config/constants';
import { detect } from 'detect-browser';

class SignIn extends Component {
  verifyCallback = (recaptchaToken) => {
    // Here you will get the final recaptchaToken!!!
    this.setState({
      isVerified: true,
    });
  };

  callback = () => {
  };

  handleInputChange = (event) => {
    const {
            target: {
              type, checked, value: targetValue, name,
            },
          } = event;
    const value = type === 'checkbox' ? checked : targetValue;
    this.setState({
      [name]: value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (this.state.username === '') {
      this.setState({ requiredUser: true });
    } else if (this.state.password === '') {
      this.setState({ requiredPassword: true });
    } else if (this.state.isVerified !== true && process.env.NODE_ENV !== 'development') {
      this.setState({ isCaptcha: true });
    } else {
      this.setState(() => ({
        loading: true,
      }));
      const { signIn, showMessage, t } = this.props;
      const { ...data } = this.state;
      signIn({
        data,
        callback: (response) => {
          this.setState(() => ({
            loading: false,
          }));
          if (response.error) {
            toast.dismiss();
            showMessage({
              message: t('userDataIncorrect'),
              config: TOAST_CONFIG.INFO,
            });
          } else if (!('success' in response)) {
            toast.dismiss();
            showMessage({
              message: t('connectionError'),
              config: TOAST_CONFIG.ERROR,
            });
          }
        },
      });
    }
  };

  constructor(props, context) {
    super(props, context);

    this.verifyCallback = this.verifyCallback.bind(this);
    this.callback = this.callback.bind(this);
    const browser = detect();

    this.state = {
      user_type: 'user_business',
      password: '',
      username: '',
      token_firebase: 'firebasefbacks',
      device_name: browser.name,
      device_number: browser.version,
      rememberData: false,
      requiredUser: false,
      requiredPassword: false,
      loading: false,
      isVerified: false,
      isCaptcha: false,
    };
  }

  componentDidMount() {
    stopTimer();
  }

  render() {
    const requiredUser = {
      display: this.state.requiredUser ? 'block' : 'none',
    };
    const requiredPassword = {
      display: this.state.requiredPassword ? 'block' : 'none',
    };
    const isCaptcha = {
      display: this.state.isCaptcha ? 'block' : 'none',
    };
    const viewCaptcha = {
      display: (process.env.NODE_ENV === 'development') ? 'none' : 'block',
    };
    const { auth: { logged }, t } = this.props;
    const { loading } = this.state;
    if (logged === true) {
      return <Redirect to="/dashboard" />;
    }
    return (
      <div className="SignIn">
        <ToastContainer />
        {loading && <Loading />}
        <section className="hero is-info is-fullheight">
          <div className="hero-body">
            <div>
              <div className="panel fadeInUp animated">
                <figure className="image is-centered">
                  <img src="./logo-xx.png" alt="logo" />
                </figure>
                <FormLogin
                  handleSubmit={this.handleSubmit}
                  handleInputChange={this.handleInputChange}
                  requiredUser={requiredUser}
                  requiredPassword={requiredPassword}
                  isCaptcha={isCaptcha}
                  viewCaptcha={viewCaptcha}
                  verifyCallback={this.verifyCallback}
                  callback={this.callback}
                />
                {/* <div className="forgot-password">
                  <Link to="/recover-password">{t('forgotPassword')}</Link>
                </div> */}
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => (
  {
    auth,
  }
);

export default compose(
  connect(mapStateToProps, {
    ...authActions,
  }),
  withNamespaces(),
)(SignIn);
