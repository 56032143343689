import types            from './types';
import { initialState } from '../../../App';

const configurations = (state = {}, action) => {
  switch (action.type) {
    case types.CLEAR:
    {
      return {
        ...state, ...initialState.configurations,
      };
    }
    case types.START_FETCH:
    {
      return {
        ...state,
        loading: true,
      };
    }
    case types.END_FETCH:
    {
      const { error, payload } = action;
      return {
        ...state,
        loading: false,
        error: error ? payload : null,
      };
    }
    case types.UPDATE_LIST_HOLIDAYS:
    {
      const { data } = action.payload;
      return {
        ...state,
        listHolidays: {
          list: data,
          totalElements: data.length,
        }
      };
    }
    case types.UPDATE_DETAIL_HOLIDAY:
    {
      const { data } = action.payload;
      return {
        ...state,
        listHolidays: {
          list: data,
          totalElements: data.length,
        }
      };
    }
    case types.UPDATE_CHARGES_BY_SERVICES: {
      const { data } = action.payload;
      return {
        ...state,
        charges: data,
      };
    }
    case types.SET_EDIT_CHARGES_BY_SERVICES: {
      const { payload } = action;
      return {
        ...state,
        charges: payload,
      };
    }
    case types.UPDATE_COMISSION_BY_SERVICES: {
      const { data } = action.payload;
      return {
        ...state,
        ivaCharge: data,
      };
    }
    case types.SET_EDIT_COMISSION_BY_SERVICES: {
      const { payload } = action;
      return {
        ...state,
        ivaCharge: payload,
      };
    }
    default:
      return state;
  }
};

export default configurations;
