import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const Loading = (props) => {
  const {
    // Default value for close function
    close,
  } = props;

  return (
    <div className="loading">
      <div
        role="Presentation"
        className="holder"
        onClick={close}
      >
        <div className="preloader">
          <div/>
          <div/>
          <div/>
          <div/>
          <div/>
          <div/>
          <div/>
          <div/>
          <div/>
          <div/>
        </div>
      </div>
    </div>
  );
};

Loading.propTypes = {
  close: PropTypes.func,
};

Loading.defaultProps = {
  close: (...args) => {
    //console.log('Close', args);
  },
};

export default Loading;
