/**
 * Created by Feedback Software on 25/10/18.
 * @param children contain the body of this table, it's important use th or td inside tr
 * @param totalPages if is pass by params it means that must be added the pagination nav
 * @param title it is used by pass by params to the Panel for the title of this
 * @param head contain the head of the table, it's important pass th inside tr
 * @param currentPage manage de actual page in pagination
 * @param onChangePage manage the change of the page in the pagination, must change currentPage
 * @returns {*}
 */

import React              from 'react';
import './styles.scss';
import { withNamespaces } from 'react-i18next';
import PropTypes          from 'prop-types';
import Pagination         from '../Pagination';

const TableList = (props) => {
  const {
    t,
    children,
    title,
    head,
    tableClasses,
    totalElements,
    onChangePage,
    month,
    lenghtData,
    colNumber,
    pageSize,
    hidePagination,
    forcePage,
  } = props;
  const tElements = parseInt(totalElements);
  return (
    <div className="columns List">
      <div className="column is-full center-table">
        <h1>{title}</h1>
        <div className="table-responsive">
          <table className={`table ${tableClasses}`}>
            {!!head && (
              <thead>
              {head}
              </thead>
            )}
            <tbody>
            {children}
            {(lenghtData === 0 || lenghtData === '0') && (
              <tr>
                <td colSpan={colNumber}>{t('noData')}</td>
              </tr>
            )}
            </tbody>
          </table>
        </div>
        {!hidePagination &&
        <Pagination
          totalElements={tElements}
          onChangePage={onChangePage}
          pageSize={pageSize}
          forcePage={forcePage}
        />
        }
      </div>
    </div>
  );
};

TableList.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
  ]).isRequired,
  title: PropTypes.string,
  tableClasses: PropTypes.string,
  head: PropTypes.element,
  totalElements: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  onChangePage: PropTypes.func,
  hidePagination: PropTypes.bool,
  lenghtData: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  colNumber: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

TableList.defaultProps = {
  totalElements: 0,
  lenghtData: 0,
  forcePage: 0,
  colNumber: 0,
  hidePagination: false,
  title: '',
  head: null,
  tableClasses: '',
  onChangePage: () => {
  },
};

export default withNamespaces()(TableList);
