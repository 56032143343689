import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { toast } from 'react-toastify';
import { withNamespaces } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert';
import { initTimer, stopTimer } from '../../util/initTimer';
import { authActions } from '../../state/ducks/auth';
import { transactionsActions } from '../../state/ducks/transactions';
import { Loading, ConfirmAlert, PrimaryButton } from '../../components';
import { FormExtraction, DetailExtraction } from './components';
import { TOAST_CONFIG } from '../../config/constants';
import './styles.scss';

class AuthExtractions extends Component {
  constructor (props) {
    super (props);
    this.state = {
      step: 'first',
      account: [],
      amount: 0,
      reason: '',
    }
  }

  componentDidMount () {
    const { location } = this.props;
    initTimer(this.props);
    stopTimer();
    if (location.state !== undefined && location.state.accounts !== undefined) {
      this.setState({
        account: location.state.accounts,
      });
    }
  }

  validateEmptyFields = () => {
    const { step, reason } = this.state;
    if (step === 'first') {
      return (reason === '');
    }
    return false;
  };

  handleInputChange = (e) => {
    const { target: { value: targetValue, name } } = e;
    this.setState({ [name]: targetValue });
  };

  handleChangeView = (step = 'first') => {
    this.setState({ step });
  };

  confirmExtraction = (e, id) => {
    e.preventDefault();
    this.showMessageTransfer(this.message());
    const { t, showMessage, withdrawalBusiness } = this.props;
    const { amount, reason } = this.state;
    withdrawalBusiness({
      data: {
        id_transport_company: id,
        amount,
        description: reason,
      }, callback: (response) => {
        if (!response.success) {
          toast.dismiss();
          showMessage({
            message: t('connectionError'),
            config: TOAST_CONFIG.ERROR,
          })
        }
      }
    });
  }

  acceptOperation = () => {
    this.handleChangeView('second');
  }

  showMessageTransfer = (message) => {
    const { t } = this.props;
    const options = {
      customUI: ({ onClose }) => (
        <div className='information-alert message columns is-multiline'>
          <div className='column is-full has-text-centered text-message'>
            {message}
          </div>
          <div className='column is-6 has-text-centered'>
            <button className='button is-outlined' onClick={() => { onClose() }}>
              {t('no')}
            </button>
          </div>
          <div className='column is-6 has-text-centered'>
            <button className='button is-primary' onClick={() => {
              this.acceptOperation();
              onClose();
            }}>
              {t('yes')}
            </button>
          </div>
        </div>
      ),
      closeOnEscape: false,
      closeOnClickOutside: false,
    };
    return (confirmAlert(options));
  }

  message = () => {
    const { t } = this.props;
    const { amount, account, reason } = this.state;
    return (
      <div className='has-text-centered mt-16px'>
        <span>
          <i className='fas fa-exclamation-circle icon-alert' />
        </span>
        <div className='mt-20px'>{t('amountDebit')}: {`AR$ ${amount}`}</div>
        <div className='mt-20px'>{t('account')}: {account.account}</div>
        <div className='mt-20px'>{t('deal')}: {account.business}</div>
        <div className='mt-20px'>{t('acceptExtraction')}</div>
      </div>
    );
  };

  resetView = (e) => {
    e.preventDefault();
    window.location.href = '/state-accounts'
  }

  render () {
    const { step, account, amount, reason } = this.state;
    const { t, auth: { loading } } = this.props;
    const disabledForm = this.validateEmptyFields();

    if (step === 'first') {
      stopTimer();
      initTimer(this.props);
      return (
        <section>
          { loading && <Loading /> }
          <FormExtraction
            account={account}
            amount={amount}
            reason={reason}
            disabled={disabledForm}
            onChangeInput={this.handleInputChange}
            onSubmit={this.confirmExtraction}
          />
        </section>
      );
    }

    if (step === 'second') {
      stopTimer();
      initTimer(this.props);
      return (
        <section>
          { loading && <Loading /> }
          <FormExtraction
            step={step}
            account={account}
            amount={amount}
            reason={reason}
            onSubmit={this.confirmExtraction}
            onFinish={this.resetView}
          />
        </section>
      );
    }

  }
}

AuthExtractions.propTypes = {
  t: PropTypes.func.isRequired,
  showMessage: PropTypes.func,
  auth: PropTypes.shape({}).isRequired,
};

AuthExtractions.defaultProps = {
    showMessage: null,
};

const mapStateToProps = ({ auth, transactions }) => ({
  auth, transactions
});

export default compose(connect(mapStateToProps, {
  ...authActions,
  ...transactionsActions
}), withNamespaces())(AuthExtractions);;
