import {createAction} from 'redux-actions';
import types from './types';

const startFetch = createAction(types.START_FETCH);
const endFetch = createAction(types.END_FETCH);
const endError = createAction(types.END_ERROR);
const fetchDashboard = createAction(types.FETCH_DASHBOARD);
const updateDashboard = createAction(types.UPDATE_DASHBOARD);
const fetchBalanceBusiness = createAction(types.FETCH_BALANCE_BUSINESS);
const updateBalanceBusiness = createAction(types.UPDATE_BALANCE_BUSINESS);

const showMessage = createAction(types.MESSAGE,
  ({message}) => ({
    message,
  }),
  ({config}) => ({
    config,
  }));

export default {
  startFetch,
  endFetch,
  endError,
  showMessage,
  fetchDashboard,
  updateDashboard,
  fetchBalanceBusiness,
  updateBalanceBusiness,
};
