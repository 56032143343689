import { switchActions, switchTypes } from "../ducks/switch";
import * as fileSaver from 'file-saver';

const fetchListSwitch = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== switchTypes.FETCH_LIST_SWITCH) {
    return;
  }
  const { data, callback, data: { export: exportExcel } } = action.payload || {};
  dispatch({
    type: switchTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: `/console/list-transactions-switch`,
        data,
        responseType: exportExcel ? 'blob' : 'json',
      },
      authorization: true,
      onStart: switchActions.startFetch,
      onComplete: (responseData) => {
        if (exportExcel) {
          const blob = responseData.data
          const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fileSaver.saveAs(file, 'listado-switchs.xlsx');
        } else {
          const listswitchs = responseData.data.data;
          dispatch(switchActions.updateListSwitch({ data: listswitchs }));
          callback(responseData.data);
        }
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: switchActions.endFetch,
    },
  });
};

const fetchDetailSwitch = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== switchTypes.FETCH_DETAIL_SWITCH) {
    return;
  }
  const { id } = action.payload || {};

  dispatch({
    type: switchTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/console/detail-switch-error?switch=${id}`,
      },
      authorization: true,
      onStart: switchActions.startFetch,
      onComplete: (responseData) => {
        dispatch(switchActions.updateDetailSwitch(responseData.data));
      },
      onError: (response) => {
        console.log(response);
      },
      onEnd: switchActions.endFetch,
    },
  });
};


const fetchTransactionTypeSwitch = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== switchTypes.FETCH_TRANSACTION_TYPE_SWITCH) {
    return;
  }
  dispatch({
    type: switchTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: '/console/transaction-type-switch',
      },
      authorization: true,
      onStart: switchActions.startFetch,
      onComplete: ({ data }) => {
        dispatch(switchActions.updateTransactionTypeSwitch(data.data));
      },
      onError: (response) => {
      },
      onEnd: switchActions.endFetch,
    },
  });
};
const fetchTransactionStatusSwitch = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== switchTypes.FETCH_TRANSACTION_STATUS_SWITCH) {
    return;
  }
  dispatch({
    type: switchTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: '/console/transaction-status-switch',
      },
      authorization: true,
      onStart: switchActions.startFetch,
      onComplete: ({ data }) => {
        dispatch(switchActions.updateTransactionStatusSwitch(data.data));
      },
      onError: (response) => {
      },
      onEnd: switchActions.endFetch,
    },
  });
};

export default [
  fetchListSwitch,
  fetchDetailSwitch,
  fetchTransactionTypeSwitch,
  fetchTransactionStatusSwitch,
];