const START_FETCH = 'serviceCharge/START_FETCH';
const END_FETCH = 'serviceCharge/END_FETCH';
const END_ERROR = 'serviceCharge/END_ERROR';
const API_CALL = 'serviceCharge/API_CALL';
const MESSAGE = 'serviceCharge/MESSAGE';
const FETCH_SERVICE_CHARGE = 'serviceCharge/FETCH_SERVICE_CHARGE';
const SET_SERVICE_CHARGE = 'serviceCharge/SET_SERVICE_CHARGE';
const EDIT_SERVICE_CHARGE = "serviceCharge/EDIT_SERVICE_CHARGE";
const UPDATE_SERVICE_CHARGE = "serviceCharge/UPDATE_SERVICE_CHARGE";

export default {
    START_FETCH,
    END_FETCH,
    END_ERROR,
    API_CALL,
    MESSAGE,
    FETCH_SERVICE_CHARGE,
    SET_SERVICE_CHARGE,
    EDIT_SERVICE_CHARGE,
    UPDATE_SERVICE_CHARGE,

}
