import { createAction } from 'redux-actions';
import types            from './types';

const startFetch = createAction(types.START_FETCH);
const endFetch = createAction(types.END_FETCH);
const fetchListSimpleRol = createAction(types.FETCH_LIST_SIMPLE_ROL);
const updateListSimpleRol = createAction(types.UPDATE_LIST_SIMPLE_ROL);

const showMessage = createAction(types.MESSAGE,
  ({ message }) => ({
    message,
  }),
  ({ config }) => ({
    config,
  }));


export default {
  startFetch,
  endFetch,
  showMessage,
  fetchListSimpleRol,
  updateListSimpleRol,
}
