import types            from './types';
import { initialState } from '../../../App';

const rates = (state = {}, action) => {
  switch (action.type) {
    case types.CLEAR:
    {
      return {
        ...state, ...initialState.transactions,
      };
    }
    case types.START_FETCH:
    {
      return {
        ...state,
        loading: true,
      };
    }
    case types.END_FETCH:
    {
      const { error, payload } = action;
      return {
        ...state,
        loading: false,
        error: error ? payload : null,
      };
    }
    case types.SET_RATE_LIST:
    {
        const { data } = action.payload;
        const total = data.length;
        return {
          ...state,
          totalElements: total,
          list: data,
        }
    }
    case types.SET_DESTINATION_TYPES:
    {
      const { data } = action.payload;
      return {
        ...state,
        destination_types: data,
      }
    }
    default:
      return state;
  }
};

export default rates;
