import React     from "react";
import Select    from 'react-select';
import PropTypes from "prop-types";
import './styles.scss';

const SelectSearchable = ({
  t,
  name,
  label,
  placeholder,
  value = '',
  onChange,
  options,
  disabled,
  selectClasses,
  inputClasses,
  controlClasses,
  fieldClasses,
  isSearchable,
  multi,
  clearable,
  labelClasses
}) =>
{
  return (
    <div className={`custom-field ${fieldClasses}`}>
      {label && <label htmlFor={name} className={`label text-completed ${labelClasses}`}>{label}</label>}
      <div className={`control ${controlClasses}`}>
        <div className={`select ${selectClasses}`}>
          <Select
            autocomplete={'on'}
            menuPlacement="auto"
            defaultValue={value}
            isMulti={multi}
            name={name}
            options={options}
            className={`select-searchable ${inputClasses}`}
            classNamePrefix="select"
            placeholder={placeholder}
            isSearchable={isSearchable}
            onChange={onChange}
            isDisabled={disabled}
            isClearable={clearable}
            value={value}
          />
        </div>
      </div>
    </div>
  );

}

SelectSearchable.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  disabled: PropTypes.bool,
  selectClasses: PropTypes.string,
  inputClasses: PropTypes.string,
  controlClasses: PropTypes.string,
  fieldClasses: PropTypes.string,
  clearable: PropTypes.bool,
  isSearchable: PropTypes.bool,
  multi: PropTypes.bool,
  labelClasses: PropTypes.string,

};

SelectSearchable.defaultProps = {
  label: null,
  placeholder: null,
  value: null,
  disabled: false,
  isSearchable: true,
  multi: false,
  clearable: false,
  selectClasses: '',
  inputClasses: '',
  controlClasses: '',
  fieldClasses: '',
  labelClasses: '',
};


export default SelectSearchable;