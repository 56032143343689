import React,  { Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { compose } from 'redux';
import { Loading, Filter, Panel, TableList, TransactionRow } from '../../components';
import { authActions } from '../../state/ducks/auth';
import { usersActions } from '../../state/ducks/users';
import { initTimer, stopTimer } from '../../util/initTimer';
import { SIZE_PER_PAGE, TOAST_CONFIG } from '../../config/constants';

class StateAccounts extends Component {
  constructor (props) {
    super (props);
    this.state = {
      page: 0
    }
  }

  componentDidMount() {
    stopTimer();
    initTimer(this.props);
    this.callApiList();
  }

  callApiList = () => {
    const { t, showMessage, fetchAccountsState } = this.props;
    const { page } = this.state;
    fetchAccountsState({
      data: { page, pageSize: SIZE_PER_PAGE },
      callback: (response) => {
        if (response) {
          if (!response.success) {
            toast.dismiss();
            showMessage({
              message: t('connectionError'),
              config: TOAST_CONFIG.ERROR,
            });
          }
        }
      },
    });
  }

  onChangePage = (selectPage) => {
    stopTimer();
    initTimer(this.props);
    this.setState(() => ({
      page: selectPage ? selectPage.selected : 0,
    }), () => {
      this.callApiList();
    });
  };

  render () {
    const { t, users: { loading, statesAccounts: { list, totalElements } } } = this.props;
    const { page } = this.state;
    return (
      <div>
        { loading && <Loading /> }
        <Filter title={t('adminOfNegocio')} />
        <Panel headingText={t('stateAccountsDeal')}>
          <TableList
            tableClasses='floating-table'
            totalElements={parseFloat(totalElements)}
            onChangePage={this.onChangePage}
            forcePage={page}
            head={(
              <tr>
                <th>{t('typeAccount')}</th>
                <th>{t('deal')}</th>
                <th>{`${t('balance')} AR$`}</th>
                <th>{t('actions')}</th>
              </tr>
            )}
            colNumber={4}
            lenghtData={totalElements !== undefined ? totalElements : 0}
            >
              {list && (list.map(accounts => (
                <TransactionRow key={accounts.id}>
                  <td>{accounts.account}</td>
                  <td>{accounts.business}</td>
                  <td>{accounts.amount}</td>
                  <td>
                    {accounts.business === 'EMPRESA DE TRANSPORTE' ?
                        <Link to={{
                          pathname: '/extraction-accounts',
                          state: { accounts: accounts }
                        }}>
                          <button className='button is-outlined is-small'>
                            {t('authExtraction')}
                          </button>
                        </Link>
                      :
                        '-'
                    }
                  </td>
                </TransactionRow>
            )))}
          </TableList>
        </Panel>
      </div>
    );
  }
}

StateAccounts.propTypes = {
  t: PropTypes.func.isRequired,
  showMessage: PropTypes.func,
  fetchAccountsState: PropTypes.func.isRequired,
};

StateAccounts.defaultProps = {
  showMessage: () => {},
};

const mapStateToProps = ({ auth, users }) => (
  { auth, users }
);

export default compose(connect(mapStateToProps, {
    ...authActions,
    ...usersActions,
  }),
  withNamespaces())(StateAccounts);
