import React, { Fragment } from 'react';
import { withNamespaces }  from 'react-i18next';
import { compose }         from 'redux';
import { connect }         from 'react-redux';
import { FormElement }     from '../../../components';
import { agencyActions }   from '../../../state/ducks/agency';
import { authActions }     from '../../../state/ducks/auth';
import PropTypes           from 'prop-types';

const listCountry = [{ id: 'AR', name: 'Argentina' }];

const addressDetailForm = (props) => {
  const {
    t,
    editable,
    address: {
      country,
      province,
      location,
      city,
      address,
      zipCode,
    },
    handleInputChange,
    auth: {
      provinces,
      locations,
      cities
    },
    getLocations,
    getCities,
    titleLabel,
  } = props;

  return (
    <Fragment>
      <div className="columns is-multiline">
        <div className="column is-full">
          <h4>{t(titleLabel)}</h4>
          <hr className="dots" />
        </div>
        <FormElement
          name='country'
          onChange={(e) => handleInputChange(e)}
          value={country}
          label={t('country')}
          typeElement={'select'}
          optionsSelect={listCountry}
          longColumn={'is-12'}
          controlClasses="select"
          disabledNullSelect
          disabled={!editable}
        />
        <FormElement
          name='province'
          onChange={(e) => {
            getLocations({ province: e.value });
            handleInputChange({ target: { name: 'province', value: e } });
            handleInputChange({ target: { name: 'location', value: '' } });
            handleInputChange({ target: { name: 'city', value: '' } });
          }}
          value={province || ''}
          label={t('province')}
          typeElement={'selectSearchable'}
          longColumn={'is-12'}
          optionsSelect={provinces}
          disabled={country === '' || !editable}
        />
        <FormElement
          name='location'
          onChange={(e) => {
            getCities({ department: e.value, });
            handleInputChange({ target: { name: 'location', value: e } });
            handleInputChange({ target: { name: 'city', value: '' } });
          }}
          value={location || ''}
          label={t('location')}
          typeElement={'selectSearchable'}
          longColumn={'is-12'}
          optionsSelect={locations}
          disabled={province === '' || !editable}
        />
        <FormElement
          name='city'
          onChange={(e) => handleInputChange({ target: { name: 'city', value: e } })}
          value={city || ''}
          label={t('city')}
          typeElement={'selectSearchable'}
          longColumn={'is-12'}
          optionsSelect={cities}
          disabled={location === '' || !editable}
        />
        <FormElement
          name='address'
          onChange={(e) => handleInputChange(e)}
          value={address}
          label={t('address')}
          typeElement={!editable ? 'text' : 'input'}
        />
        <FormElement
          name='zipCode'
          onChange={(e) => handleInputChange(e)}
          value={zipCode}
          label={t('zipCode')}
          typeElement={!editable ? 'text' : 'input'}
        />
      </div>
    </Fragment>
  );
};

addressDetailForm.propTypes = {
  t: PropTypes.func.isRequired,
  editable: PropTypes.bool,
  handleInputChange: PropTypes.func,
  handlePhoneChange: PropTypes.func,
  titleLabel: PropTypes.string,
};

addressDetailForm.defaultProps = {
  editable: false,
  handleInputChange: null,
  handlePhoneChange: null,
  titleLabel: 'addressInformation',
};

const mapStateToProps = ({ agency, auth }) => (
  {
    agency,
    auth,
  }
);
export default compose(
  connect(mapStateToProps, {
    ...agencyActions,
    ...authActions,
  }),
  withNamespaces(),
)(addressDetailForm);