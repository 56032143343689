import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  Loading,
  Filter,
  DefaultButton,
  Panel,
  AmountFormat,
} from '../../../components';
import './style.scss';
import { initTimer, stopTimer } from '../../../util/initTimer';

class DetailTransactionBind extends Component {
  constructor (props) {
    super (props);
    this.state = {
      transactionBind: '',
    }
  }

  componentDidMount() {
      const { location } = this.props;
    stopTimer();
    initTimer(this.props);
        if (location !== undefined && location.state.bind !== undefined) {
        this.setState({ transactionBind: location.state.bind });
    }
  }

  render () {
    const { t, loading } = this.props;
    const { transactionBind } = this.state;
    if(!transactionBind) return null;
    return (
      <div>
        <Filter title={t('transactionsBind')} subtitle={t('detailTransaction')}/>
        <Link to={{ pathname: '/transactions-bind' }}>
          <DefaultButton name={t('goBack')} />
        </Link>
        <Panel headingText={t('detailOperationTransaction')}>
          <div className="column is-full">
            <div className="column is-full">
              <h4>{t('dataGeneralTransaction')}</h4>
              <hr className="dots" />
            </div>

            <div className="columns">
              <div className="column title-left"><span className="title-bold">{t('dateHour')}: </span>
                {transactionBind.date}
              </div>
              <div className="column title-right"> </div>
            </div>
            <div className="columns">
              <div className="column title-left"><span className="title-bold">{t('codeTransaction')}: </span>
                {transactionBind.susa_code === null ? '-' : transactionBind.susa_code}
              </div>
              <div className="column title-right"><span className="title-bold">{t('codeBind')}: </span>
                {transactionBind.id}
              </div>
            </div>
            <div className="columns">
              <div className="column title-left"><span className="title-bold">{t('amount')}: </span>
                <AmountFormat
                  name="amount"
                  value={transactionBind.amount[0].value === undefined ? '-' : transactionBind.amount[0].value}
                  currency={'AR$'}
                  displayType="text"
                  allowNegative
                />
              </div>
              <div className="column title-right"><span className="title-bold">{t('chargeIva')}: </span>
                <AmountFormat
                  name="iva"
                  value={transactionBind.iva === undefined ? '-' : transactionBind.iva}
                  currency={'AR$'}
                  displayType="text"
                  allowNegative
                />
              </div>
            </div>
            <div className="columns">
              <div className="column title-left"><span className="title-bold">{t('total')}: </span>
                <AmountFormat
                  name="total"
                  value={transactionBind.total === undefined ? '-' : transactionBind.total}
                  currency={'AR$'}
                  displayType="text"
                  allowNegative
                />
              </div>
              <div className="column title-right"> </div>
            </div>
            <div className="column is-full">
              <h4>{t('senderData')}</h4>
              <hr className="dots" />
            </div>
            <div className="columns">
              <div className="column title-left"><span className="title-bold">{t('senderUser')}: </span>
                {transactionBind.originData[0].name === undefined ? '-' : transactionBind.originData[0].name}
              </div>
              <div className="column title-right"><span className="title-bold">{t('senderBank')}: </span>
                {transactionBind.originData[0].bank === undefined ? '-' : transactionBind.originData[0].bank}
              </div>
            </div>
            <div className="columns">
              <div className="column title-left"><span className="title-bold">{t('originAccount')}: </span>
                {transactionBind.originData[0].account === undefined ? '-' : transactionBind.originData[0].account}
              </div>
              <div className="column title-right"><span className="title-bold">{t('document')}: </span>
                {transactionBind.originData[0].document === undefined ? '-' : transactionBind.originData[0].document}
              </div>
            </div>
            <div className="column is-full">
              <h4>{t('recipientData')}</h4>
              <hr className="dots" />
            </div>
            <div className="columns">
              <div className="column title-left"><span className="title-bold">{t('destinationUser')}: </span>
                {transactionBind.destinationData[0].name === undefined ? '-' : transactionBind.destinationData[0].name}
              </div>
              <div className="column title-right"><span className="title-bold">{t('destinationBank')}: </span>
                {transactionBind.destinationData[0].bank === undefined ? '-' : transactionBind.destinationData[0].bank}
              </div>
            </div>
            <div className="columns">
              <div className="column title-left"><span className="title-bold">{t('accountDestination')}: </span>
                {transactionBind.destinationData[0].account === undefined ? '-' : transactionBind.destinationData[0].account}
              </div>
              <div className="column title-right"><span className="title-bold">{t('document')}: </span>
                {transactionBind.destinationData[0].document === undefined ? '-' : transactionBind.destinationData[0].document}
              </div>
            </div>
            <div className="columns">
              <div className="bar-bottom"></div>
            </div>
          </div>
        </Panel>
      </div>
    )
  }
}

DetailTransactionBind.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withNamespaces()(DetailTransactionBind);
