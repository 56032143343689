import { ratesActions, ratesTypes }               from '../ducks/rates';

const fetchRateList = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== ratesTypes.FETCH_RATE_LIST) {
    return;
  }
  const { type, callback } = action.payload || {};
  dispatch({
    type: ratesTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/fares?type=${type}`,
      },
      authorization: true,
      onStart: ratesActions.startFetch,
      onComplete: ({ data }) => {
        dispatch(ratesActions.setRateList(data));
        callback(data);
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: ratesActions.endFetch,
    },
  });
};

const newRate = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== ratesTypes.NEW_RATE) {
    return;
  }
  const { data, callback } = action.payload || {};
  dispatch({
    type: ratesTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/fares',
        data,
      },
      authorization: true,
      onStart: ratesActions.startFetch,
      onComplete: ({ data }) => {
        callback( data );
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: ratesActions.endFetch,
    },
  });
};

const fetchDestinationTypes = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== ratesTypes.FETCH_DESTINATION_TYPES) {
    return;
  }
  const { callback } = action.payload || {};
  dispatch({
    type: ratesTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/fares/destination-types`,
      },
      authorization: true,
      onStart: ratesActions.startFetch,
      onComplete: ({ data }) => {
        dispatch(ratesActions.setDestinationTypes(data));
        callback(data);
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: ratesActions.endFetch,
    },
  });
}


const updateRate = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== ratesTypes.UPDATE_RATE) {
    return;
  }
  const {  data, callback } = action.payload || {};

  dispatch({
    type: ratesTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: `fares/update`,
        data,
      },
      authorization: true,
      onStart: ratesActions.startFetch,
      onComplete: ({ data }) => {
        callback({ data });
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: ratesActions.endFetch,
    },
  });
};

const deleteRate = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== ratesTypes.DELETE_RATE) {
    return;
  }
  const { id, data, callback } = action.payload || {};
  dispatch({
    type: ratesTypes.API_CALL,
    payload: {
      config: {
        method: 'DELETE',
        url: `/fares/${id}`,
        data,
      },
      authorization: true,
      onStart: ratesActions.startFetch,
      onComplete: (responseData) => {
        const { data, success } = responseData.data;
        callback({ data, success });
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: ratesActions.endFetch,
    },
  });
};

const activeDisabledRate = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== ratesTypes.ACTIVE_DISABLED_RATE) {
    return;
  }
  const {data : { id, checked},  callback } = action.payload || {};

  dispatch({
    type: ratesTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/fares/${id}/${checked}`,
      },
      authorization: true,
      onStart: ratesActions.startFetch,
      onComplete: ({ data }) => {
        dispatch(ratesActions.setDestinationTypes(data));
        callback(data);
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: ratesActions.endFetch,
    },
  });
}

export default [
  fetchRateList,
  newRate,
  fetchDestinationTypes,
  updateRate,
  deleteRate,
  activeDisabledRate,
];