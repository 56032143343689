import React             from 'react';
import PropTypes         from 'prop-types';
import { ContentHeader } from '../index';
import './styles.scss';

/**
 * Created by Feedback Software on 25/10/18.
 * @param headingText is the title of the panel
 * @param children contain the elements inside the panel body
 * @returns {*}
 * @constructor
 */
const Panel = ({
  titleContentHeader,
  headingText,
  headingSubText,
  children,
  viewBalance,
  buttonReturn,
  headingRightText,
  headingRightSubText,
  classFirstColumn,
}) => (
  <ContentHeader
    headingText={titleContentHeader}
    headingSubText={headingSubText}
    headingRightText={headingRightText}
    headingRightSubText={headingRightSubText}
    viewBalance={viewBalance}
    classFirstColumn={classFirstColumn}
  >
    {buttonReturn.label
    && (
      <button onClick={buttonReturn.event} className="button is-info is-outlined mb-16px">
        <span className="icon is-small">
          <i className="fas fa-chevron-left-alt" />
        </span>
        <span>{buttonReturn.label}</span>
      </button>
    )}
    <div className="Panel">
      <div
        className="columns is-centered"
        style={{ animationDelay: '1s' }}
      >
        <div className="column is-full wow animated flipInX">
          <div className="panel panel-default panel-table shadas">
            {headingText !== ''
            && (
              <div className="title panel-header bg-panel text-white mb-0">
                {headingText}
              </div>
            )}
            <div className="panel-body">
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  </ContentHeader>
);

Panel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
  ]).isRequired,
  buttonReturn: PropTypes.shape({
    event: PropTypes.func,
    label: PropTypes.string.isRequired,
  }),
  headingText: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
  headingSubText: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
  headingRightText: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
  headingRightSubText: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
  titleContentHeader: PropTypes.any,
  viewBalance: PropTypes.bool,
  classFirstColumn: PropTypes.string,
};

Panel.defaultProps = {
  headingText: '',
  headingSubText: '',
  headingRightText: '',
  headingRightSubText: '',
  classFirstColumn: '',
  titleContentHeader: '',
  viewBalance: true,
  buttonReturn: {
    event: () => {
    },
  },
};

export default Panel;
