import { companyActions, companyTypes } from '../ducks/company';
import * as fileSaver from 'file-saver';

const fetchTransportCompany = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== companyTypes.FETCH_TRANSPORT_COMPANY) {
    return;
  }
  const { data, callback, data: { export: exportExcel } } = action.payload || {};
  dispatch({
    type: companyTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/console/list-transport-company',
        data,
        responseType: exportExcel ? 'blob' : 'json',
      },
      authorization: true,
      onStart: companyActions.startFetch,
      onComplete: (responseData) => {
        if (exportExcel) {
          const blob = responseData.data
          const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fileSaver.saveAs(file, 'Listado de empresas de transporte.xlsx');
        } else {
          const company = responseData.data.data;
          dispatch(companyActions.updateTransportCompany({ data: company }));
          callback(responseData.data);
        }
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: companyActions.endFetch,
    },
  });
};

const updateStateCompany = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== companyTypes.UPDATE_STATE_COMPANY) {
    return;
  }
  const { data, callback } = action.payload || {};

  dispatch({
    type: companyTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/console/update-company-status',
        data,
      },
      authorization: true,
      onStart: companyActions.startFetch,
      onComplete: (responseData) => {
        callback(responseData.data);
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: companyActions.endFetch,
    },
  });
};

export default [
  fetchTransportCompany,
  updateStateCompany,
];
