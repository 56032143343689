import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

/**
 * Created by Feedback Software on 25/10/18.
 * @param name
 * @param placeholder
 * @param value
 * @param onChange function to handle the state in the father component
 * @param type
 * @param helpText
 * @param label if empty doesn't show
 * @param disabled boolean to disable input
 * @param fieldClasses array of classes for the field
 * @param inputClasses array of classes for the input
 * @param controlClasses array of classes for the control
 * @param icon font awesome class of icon to display
 * @param iconPosition can receive 'is-left' or 'is-right' to position as class
 * @param min minimal amount used when the input is number
 * @param step incremental amount
 * @returns {*}
 * @constructor
 */
const LinearTextInput = ({
                           name,
                           placeholder,
                           value,
                           onChange,
                           type = 'text',
                           helpText,
                           label,
                           disabled = false,
                           fieldClasses,
                           inputClasses,
                           controlClasses,
                           icon = null,
                           iconPosition = null,
                           min = null,
                           step = null,
                           maxLength = null,
                         }) => (
  <div className={`column ${fieldClasses}`}>
    <div className="field is-horizontal input-linear">
      {label
      && (
        <div className="field-label is-normal">
          <label className="label">{label}</label>
        </div>
      )
      }
      <div className="field-body">
        <div className="field">
          <div className={`control ${controlClasses}`}>
            <input
              className={`input ${inputClasses}`}
              name={name}
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              type={type}
              disabled={disabled}
              min={min}
              step={step}
              maxLength={maxLength}
            />
            {
              !!icon && (
                <span className={`icon ${iconPosition}`}>
                  <i className={`fa ${icon}`}/>
                </span>
              )
            }
          </div>
          <p className="help">{helpText}</p>
        </div>
      </div>
    </div>
  </div>
);

LinearTextInput.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  onChange: PropTypes.func,
  type: PropTypes.string,
  helpText: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  fieldClasses: PropTypes.string,
  inputClasses: PropTypes.string,
  controlClasses: PropTypes.string,
  icon: PropTypes.string,
  iconPosition: PropTypes.string,
  min: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  step: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  maxLength: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
};

LinearTextInput.defaultProps = {
  placeholder: null,
  value: undefined,
  type: 'text',
  onChange: () => {
  },
  helpText: null,
  label: null,
  disabled: false,
  fieldClasses: '',
  inputClasses: '',
  controlClasses: '',
  icon: null,
  iconPosition: null,
  min: null,
  step: null,
  maxLength: null,
};

export default LinearTextInput;
