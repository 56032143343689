import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { initTimer, stopTimer } from '../../../util/initTimer';
import { transactionsActions } from '../../../state/ducks/transactions';
import {
  Filter, Panel, AmountFormat, DefaultButton
} from '../../../components';

class DetailRetreats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      extraction: '',
    }
  }

  componentDidMount() {
    const { location } = this.props;
    stopTimer();
    initTimer(this.props);
    if (location !== undefined && location.state.extraction !== undefined) {
      this.setState({ extraction: location.state.extraction });
    }
  }

  render () {
    const { extraction } = this.state;
    const { t } = this.props;

    return (
      <Fragment>
        <Filter title={t('detailExtraction')} /><br/>
        <Link to={{
          pathname: '/list-extractions',
          state: { extraction: extraction }
        }}
        >
          <DefaultButton name={t('goBack')}  />
        </Link>
          <Panel headingText={t('detailOperationTransaction')}>
            <div className='column is-full' key={extraction.id}>

              <div className='column is-full'>
                <h4>{t('dataOperation')}</h4>
                <hr className='dots' />
              </div>

              <div className='columns'>
                <div className='column title-left'>
                  <span className='title-bold'>{t('dateHour')}:</span> {extraction.date}
                </div>
                <div className='column title-right'>
                  <span className='title-bold'>{t('CUIL/CUIT')}:</span> {extraction.cuit_destinatary}
                </div>
              </div>

              <div className='columns'>
                <div className='column title-left'>
                  <span className='title-bold'>{t('agencyName')}:</span> {extraction.user_destinatary}
                </div>
                <div className='column title-right'>
                  <span className='title-bold'>{t('codeofTransaction')}:</span> {extraction.transaction_code}
                </div>
              </div>

              <div className='columns'>
                <div className='column title-left'>
                  <span className='title-bold'>{t('amount')}: </span>
                  <AmountFormat
                    name='amount'
                    value={extraction.amount}
                    currency={'AR$'}
                    displayType='text'
                  />
                </div>
                <div className='column title-right'>
                  <span className='title-bold'>{t('state')}:</span> {extraction.statusTxt}
                </div>
              </div>

              <div className='columns'>
                <div className='column title-left'>
                  <span className='title-bold'>{t('transferCharge')}: </span>
                  <AmountFormat
                    name='commission'
                    value={extraction.commission}
                    currency={'AR$'}
                    displayType='text'
                  />
                </div>
                <div className='column title-right'>
                  <span className='title-bold'> </span>
                </div>
              </div>
              {/*DATOS DEL USUARIO*/}
              <div className='column is-full'>
                <h4>{t('userRequest')}</h4>
                <hr className='dots' />
              </div>

              <div className='columns'>
                <div className='column title-left'>
                  <span className='title-bold'>{t('fullName')}:</span> {extraction.user_source}
                </div>
                <div className='column title-right'>
                  <span className='title-bold'>{t('userType')}:</span> {extraction.type_user_source}
                </div>
              </div>

              <div className='columns'>
                <div className='column title-left'>
                  <span className='title-bold'>{t('email')}:</span> {extraction.email_source}
                </div>
                <div className='column title-right'>
                  <span className='title-bold'>{t('CUIL/CUIT')}:</span> {extraction.cuit_source}
                </div>
              </div>

            </div>
          </Panel>
      </Fragment>
    );
  }
}

DetailRetreats.propTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = ({ transactions, }) => ({
  transactions,
});
export default compose(connect(mapStateToProps, {
  ...transactionsActions,
}), withNamespaces())(DetailRetreats);
