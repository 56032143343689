import types            from './types';
import { initialState } from '../../../App';

const switchs = (state = {}, action) => {
  switch (action.type) {
    case types.CLEAR: {
      return {
        ...state, ...initialState.operator,
      };
    }
    case types.START_FETCH: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.UPDATE_LIST_SWITCH: {
      const { data: { results, total } } = action.payload;
      return {
        ...state,
        switch: {
          list: results,
          totalElements: total,
        },
      }
    }
    case types.UPDATE_DETAIL_SWITCH: {
      const { data: { receive_bind, send_bind } } = action.payload;
      return {
        ...state,
        detailswitch: {
          receive: receive_bind,
          send: send_bind,

        }
      }
    }
    case types.UPDATE_TRANSACTION_TYPE_SWITCH:
    {
      const { payload } = action;
      return {
        ...state,
        switch_types: payload,
      };
    }
    case types.UPDATE_TRANSACTION_STATUS_SWITCH:
    {
      const { payload } = action;
      return {
        ...state,
        switch_status: payload,
      };
    }
    case types.UPDATE_LIST_DONGLE_EXCEL:
    {
      const { headers, title, rows } = action.payload;
      return {
        ...state,
        transactionsExcel: {
          title,
          headers,
          rows,
        },
      };
    }
    default:
      return state;
  }
};

export default switchs;
