import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { authActions } from '../../../state/ducks/auth';
import { transactionsActions } from '../../../state/ducks/transactions';
import { initTimer, stopTimer } from '../../../util/initTimer';
import { formatDate } from '../../../util';
import { SIZE_PER_PAGE, TOAST_CONFIG } from '../../../config/constants';
import {
  Loading, Filter, FormElement, ContentHeader, ExportExcel,
  TableList, TransactionRow, AmountFormat
} from '../../../components';

class RetreatsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      dateStart: '',
      dateEnd: '',
      transactionCode: '',
      cuit: '',
      agencyName: '',
      userType: '',
      accSender: '',
      accDestiny: '',
      status: '',
      first: true,
    }
  }

  componentDidMount() {
    const { fetchStatusExtractions, fetchTypeUserExtractions } = this.props;
    fetchStatusExtractions();
    fetchTypeUserExtractions();
    stopTimer();
    initTimer(this.props);
    this.callApiList();
  }

  callApiList = (getExcel = false) => {
    const {
      t, showMessage, fetchExtractionsList,
      transactions: { extractions: { totalElements }, typeUserExtraction, status_extraction } ,
    } = this.props;
    const {
      page, dateStart, dateEnd, transactionCode, cuit, agency, status, userType,
      first
    } = this.state;
    const userExtraction = typeUserExtraction.find(item => item.id === userType);
    let stateExtrac = status;
    if (status === '' && first === true) {
      stateExtrac = status_extraction.length > 0 ? status_extraction[2].id : 1;
      this.setState({
        first: false,
        status: stateExtrac,
      });
    }
    fetchExtractionsList({
      data: {
        pageSize: !getExcel ? SIZE_PER_PAGE : totalElements,
        page: getExcel ? 0 : page,
        export: getExcel,
        from: formatDate(dateStart),
        to: formatDate(dateEnd),
        cuit,
        code: transactionCode,
        status: stateExtrac,
        type_user: userExtraction !== undefined ? userExtraction.id : '',
      }, callback: (response) => {
        this.setState({ loading: false });
        if (response) {
          if (!response.success) {
            toast.dismiss();
            showMessage({
              message: t('connectionError'),
              config: TOAST_CONFIG.ERROR,
            });
          }
        }
      },
    });
  }

  handleInputChange = (e) => {
    stopTimer();
    initTimer(this.props);
    const { target: { value: targetValue, name } } = e;
    this.setState({ [name]: targetValue });
  };

  onChangeDate = (date, name) => {
    stopTimer();
    initTimer(this.props);
    const dateValue = date !== null ? date : '';
    this.setState({ [name]: dateValue });
  };

  onChangePage = (selectPage) => {
    stopTimer();
    initTimer(this.props);
    this.setState(() => ({
      page: selectPage ? selectPage.selected : 0,
    }), () => {
      this.callApiList();
    });
  };

  onSubmit = (event) => {
    event.preventDefault();
    this.onChangePage();
  };

  render () {
    const {
      page, dateStart, dateEnd, transactionCode, cuit,agencyName, accSender, accDestiny, status,
      userType
    } = this.state;
    const { t, auth: { loading },
      transactions: { extractions: { list, totalElements }, status_extraction, typeUserExtraction }
    } = this.props;
    return (
      <div>
        { loading && <Loading /> }
        <Fragment>
          <div className='wow animated slideInDown'>
            <div className='wow animated slideInDown'>
              <Filter title={t('extractions')} onSubmit={e => this.onSubmit(e)}>
                <FormElement
                  name='dateStart'
                  onChange={ev => this.onChangeDate(ev, 'dateStart')}
                  label={t('createdDateFrom')}
                  value={dateStart}
                  typeElement='datepicker'
                  maxDate={dateEnd || new Date()}
                />
                <FormElement
                  name='dateEnd'
                  onChange={ev => this.onChangeDate(ev, 'dateEnd')}
                  value={dateEnd}
                  label={t('createdDateUntil')}
                  typeElement='datepicker'
                  maxDate={new Date()}
                  minDate={dateStart}
                />
                <FormElement
                    name='transactionCode'
                    onChange={this.handleInputChange}
                    value={transactionCode}
                    label={t('codeTransaction')}
                />
                <FormElement
                  name='cuit'
                  onChange={this.handleInputChange}
                  value={cuit}
                  label={'CUIT'}
                  typeElement='input'
                />
                <FormElement
                    name='userType'
                    onChange={this.handleInputChange}
                    label={t('userType')}
                    value={userType}
                    optionsSelect={typeUserExtraction}
                    placeholder="Seleccione..."
                    typeElement="select"
                    controlClasses="select"
                />
                <FormElement
                  name='agencyName'
                  onChange={this.handleInputChange}
                  value={agencyName}
                  label={t('agencyName')}
                  typeElement='input'
                />
                <FormElement
                  name='accSender'
                  onChange={this.handleInputChange}
                  value={accSender}
                  label={t('userRequest')}
                  typeElement='input'
                />
                <FormElement
                    name='status'
                    onChange={this.handleInputChange}
                    label={t('state')}
                    value={status}
                    optionsSelect={status_extraction}
                    placeholder="Seleccione..."
                    typeElement="select"
                    controlClasses="select"
                />
              </Filter>
              <ContentHeader
                headingRightText={t('Total operaciones')}
                headingRightSubText={`${totalElements}`}
              >
                <ExportExcel onclick={() => { this.callApiList(true) }} />
                <TableList
                  tableClasses="floating-table"
                  totalElements={parseFloat(totalElements)}
                  onChangePage={this.onChangePage}
                  forcePage={page}
                  head={(
                    <tr>
                      <th>{`${t('date')} / ${t('hour')}`}</th>
                      <th>{t('userType')}</th>
                      <th>{t('CUIT/CUIL')}</th>
                      <th>{t('agencyName')}</th>
                      <th>{t('codeofTransaction')}</th>
                      <th>{t('userRequest')}</th>
                      <th>{t('amount')}</th>
                      <th>{t('state')}</th>
                      <th>{t('actions')}</th>
                    </tr>)}
                  colNumber={9}
                  lenghtData={totalElements !== undefined ? totalElements : 0}
                >
                  {list && (list.map((extraction, i) => (
                    <TransactionRow key={i}>
                      <td>{extraction.date}</td>
                      <td>{extraction.type_user_source}</td>
                      <td>{extraction.cuit_source}</td>
                      <td>{extraction.user_destinatary === ' ' ? '-' : extraction.user_destinatary}</td>
                      <td>{extraction.transaction_code}</td>
                      <td>{extraction.user_source}</td>
                      <td>
                        <AmountFormat
                          name='amount'
                          value={extraction.amount}
                          currency={'AR$'}
                          displayType='text'
                        />
                      </td>
                      <td>{extraction.statusTxt}</td>
                      <td>
                        <Link
                          to={{
                            pathname: '/detail-extraction',
                            state: { extraction: extraction },
                          }}
                        >
                          <button className='button is-outlined is-small'>{t('seeDetail')}</button>
                        </Link>
                      </td>
                    </TransactionRow>
                  )))}
                </TableList>
              </ContentHeader>
            </div>
          </div>
        </Fragment>
      </div>
    );
  }
}

RetreatsList.propTypes = {
  t: PropTypes.func.isRequired,
  showMessage: PropTypes.func,
  fetchExtractionsList: PropTypes.func.isRequired,
};

RetreatsList.defaultProps = {
  showMessage: () => {},
  fetchExtractionsList: () => {},
};

const mapStateToProps = ({ auth, transactions }) => (
  { auth, transactions }
);

export default compose(connect(mapStateToProps, {
    ...transactionsActions,
    ...authActions,
  }),
  withNamespaces())(RetreatsList);
