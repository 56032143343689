import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { initTimer, stopTimer } from '../../util/initTimer';
import { ContentHeader, DefaultButton, Loading, Tabs } from '../../components';
import { usersActions } from '../../state/ducks/users';
import { authActions } from '../../state/ducks/auth';
import { ServiceData } from './components';
import { Link } from 'react-router-dom';

class SettingsEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listTabs: [],
      id: '',
      service: []
    };
  }

  componentDidMount() {
    const { location } = this.props;
    stopTimer();
    initTimer(this.props);
    if (location.state !== undefined
      && location.state.id !== undefined
      && location.state.service !== undefined
    ) {
      this.setState({
        id: location.state.id,
        service: location.state.service
      }, () => {
        this.handleCreateTabs();
      });
    }
  }

  handleCreateTabs = () => {
    const { id, service } = this.state;
    const listTabs = [
      {
        title: 'service',
        id: 't1',
        content: (
          <ServiceData
            identification={id}
            service={service}
          />
        ),
      },
    ];


    this.setState({
      listTabs
    });
  }

  render() {
    const { listTabs } = this.state;
    const { t,
    users: { loading }, auth: { loading: loadingAuth } } = this.props;

    return (
      <div>
        {(loading || loadingAuth) && <Loading />}
        <ContentHeader
          headingText={(
            <Link to='/settings-charges-iva'>
              <DefaultButton name={t('goBack')} />
            </Link>
          )}
        >
          <Tabs listTabs={listTabs} />
        </ContentHeader>
      </div>
    );
  }
}

SettingsEdit.propTypes = {
  t: PropTypes.func.isRequired,
  users: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({}),
  ]).isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = ({ users, auth }) => (
  { users, auth }
);
export default compose(
  connect(mapStateToProps, {
    ...authActions,
    ...usersActions,
  }),
  withNamespaces(),
)(SettingsEdit);
