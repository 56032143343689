import React, { Component, Fragment } from 'react';
import { withNamespaces }             from 'react-i18next';
import PropTypes                      from 'prop-types';
import { compose }                    from 'redux';
import { connect }                    from 'react-redux';
import { toast }                      from 'react-toastify';
import { initTimer, stopTimer }       from "../../../util/initTimer";
import { authActions }                from "../../../state/ducks/auth";
import { agencyActions }              from "../../../state/ducks/agency";
import {
  AmountFormat,
  ContentHeader,
  DefaultButton,
  Filter,
  FormElement,
  Loading,
  NameAgency,
  TableList,
  TransactionRow,
  ExportExcel
}                                     from "../../../components";
import { Link }                       from "react-router-dom";
import { TOAST_CONFIG, SIZE_PER_PAGE }               from "../../../config/constants";
import { formatDate }                 from "../../../util";

class BoxAgencyList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stateBox: '',
      agency: '',
      idAgency: '',
      page: 0,
      dateStart: new Date(((new Date()).setDate((new Date()).getDate()))),
      dateEnd: new Date(((new Date()).setDate((new Date()).getDate()))),

    };

    this.callApiLis = this.callApiList.bind(this);
  }

  componentDidMount() {

    const { location, fetchStatusBox } = this.props;
    stopTimer();
    initTimer(this.props);

    fetchStatusBox();

    if (location !== undefined && location.state !== undefined && location.state.agency !== undefined) {
      this.setState({ agency: location.state.agency, idAgency: location.state.id }, () => {this.callApiList()});
    }

  }


  callApiList = (getExcel = false) => {
    const {
      t, fetchBoxAgencyList, showMessage,
      agency: { boxAgency: { totalElements } }
    } = this.props;
    const { stateBox, agency, idAgency, page, dateStart, dateEnd } = this.state;

    const data = {
      status: stateBox,
      id_agency: idAgency,
      pageSize: !getExcel ? SIZE_PER_PAGE : totalElements,
      page: getExcel ? 0 : page,
      export: getExcel,
      date_from: formatDate(dateStart),
      date_to: formatDate(dateEnd),
    }

    fetchBoxAgencyList({
      data,
      callback: (response) => {
        if (!response.success) {
          toast.dismiss();
          showMessage({
            message: t('connectionError'),
            config: TOAST_CONFIG.ERROR,
          });
        }
      },
    });
  }

  handleInputChange = (event) => {
    stopTimer();
    initTimer(this.props);
    const {
      target: {
        value: targetValue, name,
      },
    } = event;
    this.setState({
      [name]: targetValue,
    });
  };

  onChangeDate = (date, name) => {
    stopTimer();
    initTimer(this.props);
    const dateValue = date !== null ? date : '';
    this.setState({
      [name]: dateValue,
    });
  };

  onChangePage = (selectPage) => {
    stopTimer();
    initTimer(this.props);
    this.setState(() => ({
      page: selectPage ? selectPage.selected : 0,
    }), () => {
      this.callApiList();
    });
  };


  onSubmit = (event) => {
    event.preventDefault();
    this.callApiList();
  };

  render() {
    const { stateBox, agency, idAgency, page, dateStart, dateEnd } = this.state;
    const { t, agency: { loading, boxAgency: { list, totalElements }, statusBox } } = this.props;
    return (
      <div>
        {loading && <Loading />}
        <Fragment>
          <NameAgency agency={agency} />
          <div className="wow animated slideInDown">
            <Filter
              title={t('adminRed')}
              subtitle={t('boxAgency')}
              onSubmit={(ev) => {
                this.onSubmit(ev);
              }}
            >
              <FormElement
                onChange={ev => this.onChangeDate(ev, 'dateStart')}
                label={t('dateStart')}
                value={dateStart}
                typeElement="datepicker"
                maxDate={dateEnd || new Date()}
              />
              <FormElement
                onChange={ev => this.onChangeDate(ev, 'dateEnd')}
                value={dateEnd}
                label={t('dateEnd')}
                typeElement="datepicker"
                maxDate={new Date()}
                minDate={dateStart}
              />
              <FormElement
                name="stateBox"
                onChange={this.handleInputChange}
                value={stateBox}
                label={t('stateBox')}
                optionsSelect={statusBox}
                placeholder={t('selectStateBox')}
                typeElement="select"
                controlClasses="select select-agency"
              />

            </Filter>
            <ContentHeader
              headingText={
                <Link to={{
                  pathname: '/info-agency',
                  state: { agency }
                }}
                >
                  <DefaultButton name={t('goBack')} />
                </Link>
              }
              headingRightText={t('totalBox')}
              headingRightSubText={`${totalElements}`}
            >
              <ExportExcel onclick={() => { this.callApiList(true) }}/>
              <TableList
                tableClasses="floating-table"
                totalElements={parseFloat(totalElements)}
                onChangePage={this.onChangePage}
                forcePage={page}
                head={(
                  <tr>
                    <th>{t('operator')}</th>
                    <th>{t('income')}</th>
                    <th>{t('expenses')}</th>
                    <th>{t('balanceBox')}</th>
                    <th>{t('dateOpen')}</th>
                    <th>{t('dateClose')}</th>
                    <th>{t('numOperation')}</th>
                    <th>{t('detailBox')}</th>
                  </tr>
                )}
                colNumber={8}
                lenghtData={totalElements !== undefined ? totalElements : 0}>
                {list && (list.map((row, index) => (
                    <TransactionRow
                      key={index}
                    >

                      <td>{row.operator}</td>
                      <td>{row.income}</td>
                      <td>{row.expenses}</td>
                      <td>
                        <AmountFormat
                          name="balance"
                          value={row.balance}
                          displayType="text"
                          allowNegative
                        />
                      </td>
                      <td>{row.created_at}</td>
                      <td>{row.closed_at}</td>
                      <td>{row.operations}</td>
                      <td>
                        <Link
                          to={{
                            pathname: '/detail-box-agency',
                            state: {
                              agency: agency,
                              operator: row.operator,
                              idAgency: idAgency,
                              idBox: row.id,
                            },
                          }}
                        >
                          <button className="button is-outlined is-small"> {t('seeDetail')}</button>

                        </Link>
                      </td>

                    </TransactionRow>
                  ))
                )}

              </TableList>
            </ContentHeader>
          </div>
        </Fragment>
      </div>
    );
  }

}

BoxAgencyList.propTypes = {
  t: PropTypes.func.isRequired,
  showMessage: PropTypes.func,
};

BoxAgencyList.defaultProps = {
  showMessage: () => {},

};

const mapStateToProps = ({ auth, agency }) => ({
  auth,
  agency,

});


export default compose(connect(mapStateToProps, {
  ...authActions,
  ...agencyActions,
}), withNamespaces())(BoxAgencyList);
