import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { initTimer, stopTimer } from '../../../util/initTimer';
import {
  Filter, Panel, AmountFormat, DefaultButton
} from '../../../components';

class DetailQr extends Component {
  constructor(props) {
    super(props);
    this.state = {
      qr: '',
    }
  }

  componentDidMount() {
    const { location } = this.props;
    stopTimer();
    initTimer(this.props);
    if (location !== undefined && location.state.qr !== undefined) {
      this.setState({ qr: location.state.qr });
    }
  }

  render () {
    const { t } = this.props;
    const { qr } = this.state;

    return (
      <Fragment>
        <Filter title={t('detailExtraction')} /><br/>
        <Link to={{
          pathname: '/operations-qr',
          state: { extraction: qr }
        }}
        >
          <DefaultButton name={t('goBack')}  />
        </Link>
          <Panel headingText={t('detailOperationTransaction')}>
            <div className='column is-full' key={qr.id}>

              <div className='column is-full'>
                <h4>{t('dataOperation')}</h4>
                <hr className='dots' />
              </div>

              <div className='columns'>
                <div className='column title-left'>
                  <span className='title-bold'>{t('dateHour')}:</span> {qr.date}
                </div>
                <div className='column title-right'>
                  <span className='title-bold'>{t('transactionCode')}: </span> {qr.transaction_code}
                </div>
              </div>

              <div className='columns'>
                <div className='column title-left'>
                  <span className='title-bold'>{t('amount')}: </span>
                  <AmountFormat
                    name='amount'
                    value={qr.amount}
                    currency={'AR$'}
                    displayType='text'
                  />
                </div>
                <div className='column title-right'>
                  <span className='title-bold'>{t('status')}: </span> {qr.statusTxt}
                </div>
              </div>
              {/*USUARIO SOLICITANTE*/}
              <div className='column is-full'>
                <h4>{t('accSender')}</h4>
                <hr className='dots' />
              </div>

              <div className='columns'>
                <div className='column title-left'>
                  <span className='title-bold'>{t('fullName')}:</span> {qr.user_source}
                </div>
                <div className='column title-right'>
                  <span className='title-bold'>{t('userType')}:</span> {qr.type_user_source}
                </div>
              </div>

              <div className='columns'>
                <div className='column title-left'>
                  <span className='title-bold'>{t('email')}:</span> {qr.email_source}
                </div>
                <div className='column title-right'>
                  <span className='title-bold'>{t('CUIL/CUIT')}:</span> {qr.cuit_source}
                </div>
              </div>

              <div className='columns'>
                <div className='column title-left'>
                  <span className='title-bold'>{t('account')}:</span> {qr.account_source}
                </div>
                <div className='column title-right'>
                  <span className='title-bold'> </span>
                </div>
              </div>
              {/*USUARIO DESTINO*/}
              <div className='column is-full'>
                <h4>{t('accountDestination')}</h4>
                <hr className='dots' />
              </div>

              <div className='columns'>
                <div className='column title-left'>
                  <span className='title-bold'>{t('fullName')}:</span> {qr.user_destinatary}
                </div>
                <div className='column title-right'>
                  <span className='title-bold'>{t('userType')}: </span>
                  {qr.type_user_destinatary === '' ? '-' :  qr.type_user_destinatary }
                </div>
              </div>

              <div className='columns'>
                <div className='column title-left'>
                  <span className='title-bold'>{t('email')}:</span> {qr.email_destinatary}
                </div>
                <div className='column title-right'>
                  <span className='title-bold'>{t('CUIL/CUIT')}:</span> {qr.cuit_destinatary}
                </div>
              </div>

              <div className='columns'>
                <div className='column title-left'>
                  <span className='title-bold'>{t('account')}:</span> {qr.account_destinatary}
                </div>
                <div className='column title-right'>
                  <span className='title-bold'> </span>
                </div>
              </div>

            </div>
          </Panel>
      </Fragment>
    );
  }
}

export default withNamespaces()(DetailQr);
