import React                          from 'react';
import PropTypes                      from 'prop-types';
import { Switch }                     from 'react-router-dom';
import { ConnectedRouter }            from 'connected-react-router';
import { PersistGate }                from 'redux-persist/integration/react';
import { Provider }                   from 'react-redux';
import routes, { RouteWithSubRoutes } from './config/routes';
import configureStore                 from './state/store';
import './styles/index.scss';

const date = new Date();


export const initialState = {
  auth: {
    logged: false,
    language: '',
    local: 'es-AR',
    currency: 'AR$',
    user: {},
    profile: {},
    contacts: [],
    account: {
      id: 1,
      name: 'Cuenta Came',
      account_number: 'XXXXXXXXXXX',
      balance: '$ 0,00',
      product: 'AR$',
    },
    loading: false,
    error: null,
    expToken: '',
    provinces: [],
    locations: [],
    entities: [],
    rubros: [],
  },
  transactions: {
    balance: 0.00,
    currency: '',
    balanceAccountFundraiser: 0.00,
    account_detail: {
      list: [],
      count_transactions: 0,
      amount_total: 0.00,
      date_now: new Date(),
    },
    loading: false,
    error: null,
    multipleList: [],
    lot_process: {
      id_lot_transaction: '',
      ok: 0,
      fail: 0,
      status: '',
      fileName: '',
      total_transfer: 0,
    },
    lot_process_detail: {
      count_total: 0,
      list: [],
      error: 0,
      process: 0,
    },
    list_lot_process: {
      list: [],
      count_total: 0,
    },
    list_lot_process_detail: {
      count_total: 0,
      list: [],
      error: 0,
      process: 0,
    },
    list_state_prisma: [],
    list_prisma_transaction: {
      list: [],
      count_total: 0,
    },
    transaction_types: [],
    list_dongle_payment: {
      list: [],
      count_total: 0,
    },
    list_dongle_payment_excel: {
      title: '',
      headers: [],
      rows: [],
    },
    list_susa: {
      list: [],
      count: 0,
    },
    list_commissions: {
      list: [],
      count_transactions: 0,
    },
    commission_status: [],
    commission_types: [],
    extractions: {
      list: [],
      totalElements: 0,
    },
    status_extraction: [],
    typeUserExtraction: [],
    paymentsQr: {
      list: [],
      totalElements: 0,
    },
    statusQr: [],
    typeUserQr: [],
  },
  card: {
    person: null,
    address: null,
    card: null,
    transactions: {
      account_detail: {
        amount_total: 0.00,
      },
      transactions: [],
    },
    loading: false,
    error: null,
  },
  users: {
    list: [],
    allUsers: [],
    totalElements: 0,
    userDetail: {
      username: '',
      name: '',
      lastname: '',
      alias: '',
      cbu: '',
      cvu: '',
      cuit: '',
      near_business: null,
      active: null,
      statusText: '',
      responsible: {
        name: '',
        lastname: '',
        document_number: '',
        document_number_aux: '',
        phone_number_resp: '',
      }
    },
    fiscalData: {
      fiscalPosition: [],
      invoiceType: [],
      earnings: [],
      category: [],
      fiscalPositionValue: 0,
      invoiceTypeValue: 0,
      earningsValue: 0,
      categoryValue: 0,
      ivaValue: 0,
      commissionValue: 0
    },
    optionsStatusIva: [],
    optionsTypePerson: [],
    list_status_user: [],
    listVirtualAcounts: {
      list: [],
      totalElements: 0,
    },
    allVirtualAcounts: [],
    list_users_excel: {
      title: '',
      headers: [],
      rows: [],
    },
    transactionPerUser: {
      list: [],
      totalElements: 0,
      balance: 0,
    },
    list_users_operator: {
      list: [],
      totalElements: 0,
    },
    listOperators: {
      list: [],
      totalElements: 0,
    },
    statusFilter: [],
    rolsOperator: [],
    operatorAgency: [],
    provinces: [],
    locations: [],
    statesAccounts: {
      list: [],
      totalElements: 0
    },
  },
  dashboard: {
    balance: 0,
    users: 0,
    balanceBusiness: 0,
  },
  transactionsBind: {
    list: [],
    countTransactions: 0,
    amount_total: 0,
    date_init: {
      day: date.getDay(),
      month: date.getMonth(),
      year: date.getFullYear()
    },
    transfersTypes: [],
    statusBind: [],
    transactionsExcel: {
      title: '',
      headers: [],
      rows: [],
    },
    causesBind: [],
    transactionExternalAccount: {
      list: [],
      totalElements: 0,
    },
    transactionsInbound: {
      list: [],
      countTransactions: 0,
    },
    transactionsOutgoing: {
      list: [],
      totalElements: 0,
    },
    transactionsRejected: {
      list: [],
      totalElements: 0,
    },
    transactionsApproved: {
      list: [],
      totalElements: 0,
    },
    transactionsOutRejected: {
      list: [],
      totalElements: 0,
    },
    transactionsOutApproved: {
      list: [],
      totalElements: 0,
    },
  },
  userSupport: {
    transactionsClaim: {
      currency: '',
      totalElements: 0,
      list: [],
    },
    transactionsClaimExcel: {
      title: '',
      headers: [],
      rows: [],
    },
    transactionsCommission: {
      currency: '',
      totalElements: 0,
      list: [],
    },
    transactionsCommissionExcel: {
      title: '',
      headers: [],
      rows: [],
    },
    transactionsTypes:[],
  },
  conciliation: {
    pendingPerConciliate: {
      list: [],
      totalElements: 0,
    },
    conciliateList: {
      list: [],
      totalElements: 0,
    },
    conciliationDetail: {
      list_bank: [],
      list_registers: [],
      list_lots: [],
    },
    transactionTypeConciliate: 0,
  },
  serviceCharge: {
    list: [],
  },
  configurations: {
    listHolidays: {
      totalElements: 0,
      list: [],
    },
    detailHoliday: {},
    charges: [],
    ivaCharge: []
  },
  rates: {
    list: [],
    totalElements: 0,
    destination_types: [],
  },
  agency: {
    companyTypes: [],
    transactionsList: [],
    lotsInfo: {
      list: [],
      totalElements: 0,
    },
    statusConciliate: [],
    agencyList: {
      list: [],
      totalElements: 0,
    },
    getAgencyList: {
      list: [],
      totalElements: 0,
    },
    movementAgencyList: {
      list: [],
      totalElements: 0,
    },
    agencyListSelect: [],
    movementAccountList: {
      alias: '',
      cvu: '',
      amount: 0,
      list: [],
      totalElements: 0,
    },
    agenciesInfo: {
      list: [],
      totalElements: 0,
    },
    transactionType: [],
    transactionTypeAgencies: [],
    boxAgency: {
      list: [],
      totalElements: 0,
    },
    detailBox: {},
    statusBox: [],
    statusLot: [],
    roles: [],
  },
  switchs: {
    switch: {
      list: [],
      totalElements: 0,
    },
    detailswitch: {
      receive: [],
      send: [],

    },
    transactionsExcel: {
      title: '',
      headers: [],
      rows: [],
    },
    switch_types: [],
    switch_status: [],
  },
  company: {
    loading: false,
    companyTransport: {
      list: [],
      page: 0,
      totalElements: 0,
    }
  },
  operator: {},
};

const App = () => {

  const { store, persistor, history } = configureStore(initialState);
  // This is useful when state gets corrupted and persisted
  // persistor.purge();

  return (
    <Provider store={store}>
      <PersistGate
        loading={null}
        persistor={persistor}
      >
        <ConnectedRouter history={history}>
          <div>
            <Switch>
              {routes.map(
                route => <RouteWithSubRoutes key={`${route.path}`} {...route} />,
              )}
            </Switch>
          </div>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
};

App.propTypes = {
  location: PropTypes.shape({}),
};

export default App;
