const START_FETCH = 'rates/START_FETCH';
const END_FETCH = 'rates/END_FETCH';
const END_ERROR = 'rates/END_ERROR';
const API_CALL = 'rates/API_CALL';
const MESSAGE = 'rates/MESSAGE';
const FETCH_RATE_LIST = 'rates/FETCH_RATE_LIST';
const SET_RATE_LIST = 'rates/SET_RATE_LIST';
const NEW_RATE = 'rates/NEW_RATE';
const FETCH_DESTINATION_TYPES = 'rates/FETCH_DESTINATION_TYPES';
const SET_DESTINATION_TYPES = 'rates/SET_DESTINATION_TYPES';
const UPDATE_RATE = 'rates/UPDATE_RATE';
const DELETE_RATE ='rates/DELETE_RATE';
const ACTIVE_DISABLED_RATE = 'rates/ACTIVE_DISABLED_RATE';

export default {
  START_FETCH,
  END_FETCH,
  END_ERROR,
  API_CALL,
  MESSAGE,
  FETCH_RATE_LIST,
  SET_RATE_LIST,
  NEW_RATE,
  FETCH_DESTINATION_TYPES,
  SET_DESTINATION_TYPES,
  UPDATE_RATE,
  DELETE_RATE,
  ACTIVE_DISABLED_RATE,

};