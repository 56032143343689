import React     from 'react';
import PropTypes from 'prop-types';

import './styles.scss';

/**
 * @field field Title for the field
 * @field fieldDetail Value for the Title
 * @field fieldClasses Classes to customize field
 * @field fieldDetailClasses Classes to customize fieldClasses
 * @field fieldDisplayClasses Classes to customize field-container
 * */

function FieldDisplay({ field, fieldDetail, fieldClasses, fieldDetailClasses, fieldDisplayClasses }) {

  return (
    !fieldDetail ||
    (
      <div className={`field-container ${fieldDisplayClasses}`}>
        <div className={`${fieldClasses} field-default`}>
          {field}
        </div>
        <div className={`${fieldDetailClasses} field-detail-default`}>
          {fieldDetail}
        </div>
      </div>
    )
  );
}

FieldDisplay.propTypes = {
  field: PropTypes.string.isRequired,
  fieldDetail: PropTypes.string,
  fieldClasses: PropTypes.string,
  fieldDetailClasses: PropTypes.string,
  fieldDisplayClasses: PropTypes.string,
};

FieldDisplay.defaultProps = {
  fieldClasses: '',
  fieldDetail: '',
  fieldDetailClasses: '',
  fieldDisplayClasses: '',
};

export default FieldDisplay;
