import { createAction } from 'redux-actions';
import types            from './types';

const startFetch = createAction(types.START_FETCH);
const endFetch = createAction(types.END_FETCH);
const endError = createAction(types.END_ERROR);
const fetchStatusConciliate = createAction(types.FETCH_STATUS_CONCILIATE);
const setStatusConciliate = createAction(types.SET_STATUS_CONCILIATE);
const fetchAgencyList = createAction(types.FETCH_AGENCY_LIST);
const setAgencyList = createAction(types.SET_AGENCY_LIST);
const fetchInfoAgencies = createAction(types.FETCH_INFO_AGENCIES);
const setInfoAgencies = createAction(types.SET_INFO_AGENCIES);
const fetchMovementAgencies = createAction(types.FETCH_MOVEMENT_AGENCIES);
const setMovementAgencies = createAction(types.SET_MOVEMENT_AGENCIES);
const fetchTransactionsList = createAction(types.FETCH_TRANSACTIONS_LIST);
const setTransactionsList = createAction(types.SET_TRANSACTIONS_LIST);
const fetchTransactionsType = createAction(types.FETCH_TRANSACTION_TYPE);
const setTransactionsTypes = createAction(types.SET_TRANSACTION_TYPE);
const fetchTransactionsTypeAgencies = createAction(types.FETCH_TRANSACTION_TYPE_AGENCIES);
const setTransactionsTypesAgencies = createAction(types.SET_TRANSACTION_TYPE_AGENCIES);
const fetchBoxAgencyList = createAction(types.FETCH_BOX_AGENCY_LIST);
const setBoxAgencyList = createAction(types.SET_BOX_AGENCY_LIST);
const fetchDetailBoxAgency = createAction(types.FETCH_DETAIL_BOX_AGENCY);
const setDetailBoxAgency = createAction(types.SET_DETAIL_BOX_AGENCY);
const fetchStatusBox = createAction(types.FETCH_STATUS_BOX);
const setStatusBox = createAction(types.SET_STATUS_BOX);
const fetchLotsInfo = createAction(types.FETCH_LOTSINFO_LIST);
const setLotsInfo = createAction(types.SET_LOTSINFO_LIST);
const fetchStatusLot = createAction(types.FETCH_STATUS_LOT);
const setStatusLot = createAction(types.SET_STATUS_LOT);
const fetchCompanyTypes = createAction(types.FETCH_COMPANY_TYPES);
const updateCompanyTypes = createAction(types.UPDATE_COMPANY_TYPES);
const fetchCreateAgency = createAction(types.FETCH_CREATE_AGENCY);
const updateCreateAgency = createAction(types.UPDATE_CREATE_AGENCY);
const getAgencyList = createAction(types.GET_AGENCY_LIST);
const updateAgencyList = createAction(types.UPDATE_AGENCY_LIST);
const updateStateAgency = createAction(types.UPDATE_STATE_AGENCY);
const fetchCreateOperator = createAction(types.FETCH_CREATE_OPERATOR);
const fetchListRoles = createAction(types.FETCH_LIST_ROLES);
const updateListRoles = createAction(types.UPDATE_LIST_ROLES);
const fetchCreateTransportCompany = createAction(types.FETCH_CREATE_TRASNPORT_COMPANY);
const fetchListSimpleAgencies = createAction(types.FETCH_LIST_SIMPLE_AGENCIES);
const updateListSimpleAgency = createAction(types.UPDATE_LIST_SIMPLE_AGENCY);
const updateExtractionAgency = createAction(types.UPDATE_EXTRACTION_AGENCY);

const showMessage = createAction(types.MESSAGE,
  ({ message }) => ({
    message,
  }),
  ({ config }) => ({
    config,
  }));

export default {
  startFetch,
  endFetch,
  endError,
  showMessage,
  fetchStatusConciliate,
  setStatusConciliate,
  fetchAgencyList,
  setAgencyList,
  fetchInfoAgencies,
  setInfoAgencies,
  fetchMovementAgencies,
  setMovementAgencies,
  fetchTransactionsList,
  setTransactionsList,
  fetchTransactionsType,
  setTransactionsTypes,
  fetchTransactionsTypeAgencies,
  setTransactionsTypesAgencies,
  fetchBoxAgencyList,
  setBoxAgencyList,
  fetchDetailBoxAgency,
  setDetailBoxAgency,
  fetchStatusBox,
  setStatusBox,
  fetchLotsInfo,
  setLotsInfo,
  fetchStatusLot,
  setStatusLot,
  getAgencyList,
  updateAgencyList,
  updateStateAgency,
  fetchCompanyTypes,
  updateCompanyTypes,
  fetchCreateAgency,
  updateCreateAgency,
  fetchCreateOperator,
  fetchListRoles,
  updateListRoles,
  fetchCreateTransportCompany,
  fetchListSimpleAgencies,
  updateListSimpleAgency,
  updateExtractionAgency,
};
