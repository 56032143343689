import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { transactionsActions } from '../../state/ducks/transactions';
import AmountFormat from '../AmountFormat';
import './styles.scss';
import PropTypes from "prop-types";

const Box = ({ title, content }) => {
  /*const {
    t, transactions: {balance, currency},
  } = this.props;*/
  //const balanceFormat = balance > 0 ? balance.replace('.', ',') : balance;
  return (
    <div className="balanceBox mt-16px mb-16px" style={{ animationDelay: '1s' }}>
      <div className="columns wow animated slideInDown">
        <div className="column is-full test">
          <div className="card">
            <div className="columns">
              <div className="column is-half">
                <div className="title-balance">{title}</div>
              </div>
              <div className="column is-half">
                <div className="balance">
                  {content}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

Box.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

Box.defaultProps = {};

export default Box;
