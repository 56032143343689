import React, {Component, Fragment} from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {withNamespaces} from 'react-i18next';
import PropTypes from 'prop-types';
import { toast }             from 'react-toastify';
import {authActions} from '../../state/ducks/auth';
import {initTimer, stopTimer} from '../../util/initTimer';
import {Form, Loading, Panel, TableList, Tabs, TextInput,} from '../../components';
import './style.scss';
import {TOAST_CONFIG} from '../../config/constants';

class Profile extends Component {
  validateFields = () => {
    const {
      old_password, new_password_repeat, new_password,
    } = this.state;
    const empty = (old_password === '' || new_password_repeat === '' || new_password === '');
    const same_password = (new_password !== new_password_repeat);
    const diff_password = (old_password === new_password);
    return (empty || same_password || diff_password);
  };
  handleInputChange = (event) => {
    const {
      target: {
        value: targetValue, name,
      },
    } = event;
    this.setState({
      [name]: targetValue,
    });
  };
  onFinish = () => {
    this.setState(() => ({
      old_password: '',
      new_password_repeat: '',
      new_password: '',
      isToggle: false,
    }));
  };
  showChangePassword = () => {
    this.setState({isToggle: !this.state.isToggle});
  };
  samePassword = () => {
    const {
      old_password, new_password_repeat, new_password,
    } = this.state;
    const empty = (old_password === '' || new_password_repeat === '' || new_password === '');
    const same_password = (new_password !== new_password_repeat);
    const diff_password = (old_password === new_password);
    if (empty) {
      this.setState({
        same: true,
        message: 'emptyFields',
      });
    } else if (same_password) {
      this.setState({
        same: true,
        message: 'samePassword',
      });
    } else if (diff_password) {
      this.setState({
        same: true,
        message: 'equalPassword',
      });
    } else {
      this.setState({
        same: false,
        message: '',
      });
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      old_password: '',
      new_password_repeat: '',
      new_password: '',
      loading: false,
      isToggle: false,
      same: true,
      message: '',
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showChangePassword = this.showChangePassword.bind(this);
    this.samePassword = this.samePassword.bind(this);
  }

  componentDidMount() {
    initTimer(this.props);
  }

  componentWillUnmount() {
    stopTimer();
  }

  handleSubmit(event) {
    event.preventDefault();
    const {changePassword, t, showMessage} = this.props;
    const {old_password, new_password} = this.state;
    const data = {
      current_password: old_password,
      new_password,
    };
    this.setState({
      loading: true,
    });
    changePassword({
      data,
      callback: (response) => {
        this.setState({
          loading: false,
        });
        this.onFinish();
        if (response.success) {
          toast.dismiss();
          showMessage({
            message: response.data.message,
            config: TOAST_CONFIG.SUCCESS,
          });
        } else if (!('success' in response)) {
          toast.dismiss();
          showMessage({
            message: t('connectionError'),
            config: TOAST_CONFIG.ERROR,
          });
        } else if (!response.data.message) {
          response.data.message = response.data.message
            ? response.data.message
            : response.data.data.message;
          if (!response.data.message) {
            response.data.message = t('connectionError');
          }
        }
      },
    });
  }

  render() {
    const {t, auth} = this.props;
    const disabled = this.validateFields();
    const {
      loading, message, old_password, new_password_repeat, new_password, same,
    } = this.state;
    const listTabs = [
      {
        title: 'userInformation',
        id: 't1',
        content: (
          <TableList>
            <tr key="name">
              <th className="detail-text">{t('fullName')}</th>
              <td className="detail-value">{`${auth.user.name} ${auth.user.lastname}`}</td>
            </tr>
            <tr key="name">
              <th className="detail-text">{t('email')}</th>
              <td className="detail-value">{`${auth.user.email}`}</td>
            </tr>
            <tr/>
          </TableList>
        ),
      },
      {
        title: 'changePassword',
        id: 't2',
        content: (
          <Fragment>
            <Form
              submitText={t('save')}
              onSubmit={(event) => {
                event.preventDefault();
                this.handleSubmit(event);
              }}
              disabled={disabled}
              className="column"
            >
              <TableList tableClasses="mb-0">
                <tr key="name">
                  <th className="detail-text">{t('password')}</th>
                  <td className="detail-value">
                    <TextInput
                      name="old_password"
                      placeholder="********"
                      type="password"
                      fieldClasses="pb-0 pt-0"
                      value={old_password}
                      onChange={this.handleInputChange}
                      onBlur={this.samePassword}
                    />
                  </td>
                </tr>
                <tr key="name">
                  <th className="detail-text">{t('newPassword')}</th>
                  <td className="detail-value">
                    <TextInput
                      name="new_password_repeat"
                      placeholder="********"
                      type="password"
                      fieldClasses="pb-0 pt-0"
                      value={new_password_repeat}
                      onChange={this.handleInputChange}
                      onBlur={this.samePassword}
                    />
                  </td>
                </tr>
                <tr key="name">
                  <th className="detail-text">{t('repeatPassword')}</th>
                  <td className="detail-value">
                    <TextInput
                      name="new_password"
                      placeholder="********"
                      type="password"
                      value={new_password}
                      fieldClasses="pb-0 pt-0"
                      onChange={this.handleInputChange}
                      onBlur={this.samePassword}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <div className={`has-text-danger is-small has-text-centered ${same ? 'is-block' : 'is-hidden'}`}>
                      {t(message)}
                    </div>
                  </td>
                </tr>
              </TableList>
            </Form>
          </Fragment>
        ),
      },
    ];
    return (
      <Fragment>
        {loading && <Loading/>}
        <Panel headingText={t('profileUser')} viewBalance={false}>
          <Tabs listTabs={listTabs}/>
        </Panel>
      </Fragment>
    );
  }
}

Profile.propTypes = {
  t: PropTypes.func.isRequired,
  auth: PropTypes.shape({}).isRequired,
};

const mapStateToProps = ({transactions, auth}) => (
  {
    transactions,
    auth,
  }
);

export default compose(
  connect(mapStateToProps, {
    ...authActions,
  }),
  withNamespaces(),
)(Profile);
