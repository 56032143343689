import React              from 'react';
import PropTypes          from 'prop-types';
import { PrimaryButton }  from '../index';
import { withNamespaces } from 'react-i18next';
import './styles.scss';

const Filter = ({
                  t, title, subtitle, className, onSubmit, children, nameButton
                }) => (
  <div className={`filter  ${className}`}>
    <div className="title">{title}</div>
    <div className="subtitle">{subtitle}</div>
    <div className="content-elements">
      <div className="columns is-multiline">
        {children}
        {onSubmit && (
          <div className="column is-full is-paddingless">
            <div className="fields is-right">
              <PrimaryButton
                name={t(nameButton)}
                onClick={(ev) => { onSubmit(ev); }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
    <hr className="dots mt-16px" />
  </div>
);

Filter.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element, PropTypes.array,
  ]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  className: PropTypes.string,
  nameButton: PropTypes.string,
  onSubmit: PropTypes.func,
};

Filter.defaultProps = {
  className: '',
  title: '',
  subtitle: '',
  onSubmit: null,
  children: [],
  nameButton: 'search',
};

export default withNamespaces()(Filter);

