import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { compose } from 'redux';
import { authActions } from '../../../../state/ducks/auth';
import { configurationsActions } from '../../../../state/ducks/configurations';
import { initTimer, stopTimer } from '../../../../util/initTimer';
import { TOAST_CONFIG } from '../../../../config/constants';
import SettingsEdit from '../../../settingsEdit';
import {
  Loading, TableList, TransactionRow
} from '../../../../components';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';

class List extends Component {
  constructor (props) {
    super (props);
  }

  render () {
    const { t, auth: { loading }, list, commission } = this.props;
    return (
      <Fragment>
        { loading && <Loading /> }
        <TableList
          tableClasses='floating-table'
          colNumber={3}
          lenghtData={1}
          head={(
            <tr>
              <th>{t('detail')}</th>
              <th>{ commission ?? t('charges')}</th>
              <th>{t('ivas')}</th>
              <th>{t('actions')}</th>
            </tr>)}
          >
            {list && (list.map((data, i) => (
              <TransactionRow key={i}>
                <td
                  className='is-paddingless'
                  aria-hidden='true'
                  data-for={`switch-orange-${data.detail}`}
                  data-tip='Test'
                  >
                  {`${data.detail.slice(0, 16)}`}
                  <ReactTooltip
                    id={`switch-orange-${data.detail}`}
                    effect='solid'
                    className='text-toltip'
                  >
                    {data.detail}
                  </ReactTooltip>
                </td>
                <td>{`${data.charge.slice(0,4)}`}</td>
                <td>{`${data.iva.slice(0,4)}`}</td>
                <td>
                  <Link to={{
                    pathname: '/edit-charge',
                    state: { id: i, service: data }
                  }}
                    >
                    <i className='far fa-edit icons-actions' />
                  </Link>
                </td>
              </TransactionRow>
            )))}
        </TableList>
      </Fragment>
    );
  }
}

List.propTypes = {
  t: PropTypes.func.isRequired,
  showMessage: PropTypes.func,
};

List.defaultProps = {
  showMessage: () => {},
};

const mapStateToProps = ({ auth, configurations }) => (
  { auth, configurations }
);

export default compose(connect(mapStateToProps, {
    ...configurationsActions,
    ...authActions,
  }),
  withNamespaces())(List);
