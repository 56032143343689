import React, { Fragment } from 'react';
import { withNamespaces }  from 'react-i18next';
import { compose }         from 'redux';
import { connect }         from 'react-redux';
import { FormElement }     from '../../../components';
import { agencyActions }   from '../../../state/ducks/agency';
import { authActions }     from '../../../state/ducks/auth';
import PropTypes           from 'prop-types';
import './style.scss';

const accountDetailForm = (props) => {
  const {
    t,
    editable,
    account: {
      cvu,
      alias,
    },
    handleInputChange,
  } = props;

  return (
    <Fragment>
      <div className="columns is-multiline">

        { cvu !== 'No Disponible' ? <>
        <div className="column is-full">
          <h4>{t('detailAccount')}</h4>
          <hr className="dots" />
        </div><FormElement
                name='cvu'
                onChange={(e) => handleInputChange(e)}
                value={cvu}
                label={t('cvu')}
                typeElement={'text'}
            />
            <FormElement
                name='alias'
                onChange={(e) => handleInputChange(e)}
                value={alias}
                label={t('alias')}
                typeElement={!editable ? 'text' : 'input'}
            /></> : <><div className="column is-full">
          <i className="fas fa-exclamation-triangle"></i>
          <div className="message">{t('userWithoutAccount')}</div>
          <div className="disablebuttton"> </div>
        </div> </> }
      </div>
    </Fragment>
  );
};

accountDetailForm.propTypes = {
  t: PropTypes.func.isRequired,
  editable: PropTypes.bool,
  handleInputChange: PropTypes.func,
  handlePhoneChange: PropTypes.func,
  titleLabel: PropTypes.string,
};

accountDetailForm.defaultProps = {
  editable: false,
  handleInputChange: null,
  handlePhoneChange: null,
  titleLabel: 'addressInformation',
};

const mapStateToProps = ({ agency, auth }) => (
  {
    agency,
    auth,
  }
);
export default compose(
  connect(mapStateToProps, {
    ...agencyActions,
    ...authActions,
  }),
  withNamespaces(),
)(accountDetailForm);