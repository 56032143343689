import React, { Component, Fragment } from 'react';
import { withNamespaces }             from 'react-i18next';
import PropTypes                      from 'prop-types';
import { compose }                    from 'redux';
import { connect }                    from 'react-redux';
import { toast }                      from 'react-toastify';
import ReactTooltip                   from 'react-tooltip';
import { ConfirmAlert }               from '../../util/ConfirmAlert';
import { configurationsActions }      from '../../state/ducks/configurations';
import {
  initTimer,
  stopTimer
}                                     from '../../util/initTimer';
import { TOAST_CONFIG }               from '../../config/constants';
import {transactionsActions} from "../../state/ducks/transactions";
import {Filter }                      from '../../components';
import './styles.scss';
import {FormDepositWithdrawalMoney,
  ConfirmDepositWithdrawalMoney,
  DetailDepositWithdrawalMoney}      from "./components";

class DepositWithdrawalMoney extends Component {

  constructor(props) {
    super(props);
    this.state = {
      view: 'first',
      typeOperation: null,
      typeOperations: [],
      amount: 0,
      description: '',
      transaction: {},
      title:'',
    };

  }

  componentDidMount() {
    initTimer(this.props);
    const {t} = this.props;

    const types = [
      { value: t('depositMoney'), label: t('depositMoney') },
      { value: t('withdrawalMoney'), label: t('withdrawalMoney') },
    ];
    this.setState({
      typeOperations: types,
    });

  }

  handleInputChange = (event) => {
    stopTimer();
    initTimer(this.props);
    const { target: { value: targetValue, name } } = event;
    this.setState({
      [name]: targetValue,
    });
  };

  handleInputSelectChange = (event, name) => {

    this.setState({
      [name]: event,
    });
  }

  handleChangeView = (view = 'first') => {
    if(view== 'first'){
      this.setState({
        transaction: {},
        title: '',
        typeOperation: null,
        amount: 0,
        description: '',
        view
      });

    }
    else
      this.setState({ view });

  };

  handleValidateTransaction = (event) => {
    event.preventDefault();
    const {typeOperation, amount, description} = this.state;
    const {t, showMessage} = this.props;

    if(typeOperation === '' || amount === 0 || description ===''){
      toast.dismiss();
      showMessage({
        message: t('fieldRequiered'),
        config: TOAST_CONFIG.INFO,
      });
      return;
    }
    else
      this.handleChangeView('second')

  }

  handleDetailTransacion = (transaction, title) => {

    const {t} = this.props;
    this.setState({
      transaction,
      title: t(title),
      view: 'thrid',
    });

  }

  handleSubmit = (event) => {
    event.preventDefault();

    const {typeOperation, amount, description} = this.state;
    const {t, showMessage, depositBusiness, withdrawalBusiness} = this.props;
    const formatAmount = amount.replace(/\./g, '').replace(',', '.');
    const data = {
      amount: formatAmount,
      description
    }
    if(typeOperation.value === t('depositMoney')) {
      depositBusiness({
        data,
        callback: (response) => {
          const {success, data} = response;
          if (success) {
            this.handleDetailTransacion(data.transaction_business, 'depositMoney');

          } else {
            this.handleChangeView('first');

          }
        },
      });
    }
    else{
      withdrawalBusiness({
        data,
        callback: (response) => {
          const {success, data} = response;
          if (success) {
            this.handleDetailTransacion(data.transaction_business, 'withdrawalMoney');

          } else {
            this.handleChangeView('first');

          }
        },
      });
    }



  }

  render() {
    const {view, typeOperation, typeOperations, amount, description, transaction, title} = this.state;
    const {t,  auth: { currency },} = this.props;
     let content = '';

     if(view == 'first'){
       content = <FormDepositWithdrawalMoney
         typeOperation={typeOperation}
         typeOperations={typeOperations}
         amount={amount}
         description={description}
         onSubmit={this.handleValidateTransaction}
         onChangeInput={this.handleInputChange}
         onChangeSelect={this.handleInputSelectChange}
         onChangeView={this.handleChangeView}
         currency={currency}
       />
     }
     else if(view== 'second') {
       content = <ConfirmDepositWithdrawalMoney
         typeOperation={typeOperation}
         typeOperations={typeOperations}
         amount={amount}
         description={description}
         onSubmit={this.handleSubmit}
         currency={currency}
         onChangeView={this.handleChangeView}
       />
     }
     else {
       content = <DetailDepositWithdrawalMoney
          transaction={transaction}
          changeView ={this.handleChangeView}
          title={title}
          currency={currency}
       />
     }

    return (
      <div className="input-withdrawal-money">

          <div className="wow animated slideInDown">
          <Filter
            title={t('depositWithdrawalMoney')}
          />
            {content}

          </div>
      </div>
    );
  }


}


DepositWithdrawalMoney.propTypes = {
  t: PropTypes.func.isRequired
};

const mapStateToProps = ({ transactions, auth }) => ({
  transactions,
  auth,
});

export default compose(connect(mapStateToProps, {
  ...transactionsActions,
}), withNamespaces())(DepositWithdrawalMoney);