import { configurationsActions, configurationsTypes } from '../ducks/configurations';

const fetchListHolidays = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== configurationsTypes.FETCH_LIST_HOLIDAYS) {
    return;
  }
  const { year, callback } = action.payload || {};
  dispatch({
    type: configurationsTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/holidays?year=${year}`,
      },
      authorization: true,
      onStart: configurationsActions.startFetch,
      onComplete: (responseData) => {
        const { data, success } = responseData.data;
        if (success) {
          dispatch(configurationsActions.updateListHolidays({ data }));
        }
        callback({ data, success });
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: configurationsActions.endFetch,
    },
  });
};

const fetchEditHoliday = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== configurationsTypes.FETCH_EDIT_HOLIDAY) {
    return;
  }
  const { data: sendData, callback } = action.payload || {};
  dispatch({
    type: configurationsTypes.API_CALL,
    payload: {
      config: {
        method: 'PUT',
        url: '/holidays',
        data: sendData,
      },
      authorization: true,
      onStart: configurationsActions.startFetch,
      onComplete: (responseData) => {
        const { data } = responseData;
        if (data.success) {
          dispatch(configurationsActions.fetchTransactionsList({ data }));
        }
        callback(data);
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: configurationsActions.endFetch,
    },
  });
};

const deleteHoliday = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== configurationsTypes.DELETE_HOLIDAY) {
    return;
  }
  const { id, callback } = action.payload || {};
  dispatch({
    type: configurationsTypes.API_CALL,
    payload: {
      config: {
        method: 'DELETE',
        url: `/holidays/${id}`,
      },
      authorization: true,
      onStart: configurationsActions.startFetch,
      onComplete: (responseData) => {
        const { data, success } = responseData.data;
        callback({ data, success });
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: configurationsActions.endFetch,
    },
  });
};

const createHoliday = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== configurationsTypes.CREATE_HOLIDAY) {
    return;
  }
  const { data: sendData, callback } = action.payload || {};
  dispatch({
    type: configurationsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/holidays',
        data: sendData,
      },
      authorization: true,
      onStart: configurationsActions.startFetch,
      onComplete: (responseData) => {
        const { data, success } = responseData.data;
        callback({ data, success });
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: configurationsActions.endFetch,
    },
  });
};

const fetchChargesByServices = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== configurationsTypes.FETCH_CHARGES_BY_SERVICES) {
    return;
  }
  const { callback = () => {} } = action.payload || {};
  dispatch({
    type: configurationsTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: '/console/charges-by-service',
      },
      authorization: true,
      onStart: configurationsActions.startFetch,
      onComplete: ({ data }) => {
        dispatch(configurationsActions.updateChargesByServices(data));
        callback(data);
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: configurationsActions.endFetch,
    },
  });
};

const editChargesByServices = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== configurationsTypes.EDIT_CHARGES_BY_SERVICES) {
    return;
  }
  const { data, callback } = action.payload || {};
  dispatch({
    type: configurationsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/console/update-configurations',
        data,
      },
      authorization: true,
      onStart: configurationsActions.startFetch,
      onComplete: (responseData) => {
        const { data } = responseData;
        if (data.success) {
          dispatch(configurationsActions.setEditChargesByServices({ data }));
        }
        callback(data);
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: configurationsActions.endFetch,
    },
  });
};

const fetchComissionByServices = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== configurationsTypes.FETCH_COMISSION_BY_SERVICES) {
    return;
  }
  const { callback = () => {} } = action.payload || {};
  dispatch({
    type: configurationsTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: '/console/commission-by-service',
      },
      authorization: true,
      onStart: configurationsActions.startFetch,
      onComplete: ({ data }) => {
        dispatch(configurationsActions.updateComissionByServices(data));
        callback(data);
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: configurationsActions.endFetch,
    },
  });
};

const editComissionByServices = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== configurationsTypes.EDIT_COMISSION_BY_SERVICES) {
    return;
  }
  const { data, callback } = action.payload || {};
  dispatch({
    type: configurationsTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/console/update-configurations-commission',
        data,
      },
      authorization: true,
      onStart: configurationsActions.startFetch,
      onComplete: (responseData) => {
        const { data } = responseData;
        if (data.success) {
          dispatch(configurationsActions.setEditComissionByServices({ data }));
        }
        callback(data);
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: configurationsActions.endFetch,
    },
  });
};

export default [
  fetchListHolidays,
  fetchEditHoliday,
  deleteHoliday,
  createHoliday,
  fetchChargesByServices,
  editChargesByServices,
  fetchComissionByServices,
  editComissionByServices,
];
