const START_FETCH = 'dashboard/START_FETCH';
const END_FETCH = 'dashboard/END_FETCH';
const END_ERROR = 'dashboard/END_ERROR';
const API_CALL = 'dashboard/API_CALL';
const FETCH_DASHBOARD = ' dashboard/FETCH_DASHBOARD';
const MESSAGE = 'dashboard/MESSAGE';
const UPDATE_DASHBOARD = ' dashboard/UPDATE_DASHBOARD';
const FETCH_BALANCE_BUSINESS = ' dashboard/FETCH_BALANCE_BUSINESS';
const UPDATE_BALANCE_BUSINESS = ' dashboard/UPDATE_BALANCE_BUSINESS';

export default {
  START_FETCH,
  END_FETCH,
  END_ERROR,
  API_CALL,
  FETCH_DASHBOARD,
  MESSAGE,
  UPDATE_DASHBOARD,
  FETCH_BALANCE_BUSINESS,
  UPDATE_BALANCE_BUSINESS,
};
