import React, { Component } from 'react';
import './styles.scss';
import { withNamespaces }   from 'react-i18next';
import PropTypes            from 'prop-types';
import ReactPaginate        from 'react-paginate';

class Pagination extends Component {
  state = {
    totalPages: 1,
    currentPage: 0,
  };

  componentWillUpdate() {
    this.setElements();
  }

  setElements = () => {
    const { totalElements, pageSize } = this.props;
    const { totalPages } = this.state;
    const ceil = Math.ceil(totalElements / pageSize);
    if (totalPages !== ceil) {
      this.setState({
        totalPages: ceil,
      });
    }
  };

  render() {
    const { t, onChangePage, forcePage } = this.props;
    this.setElements();
    const { totalPages } = this.state;
    return (
      <div>
        {totalPages > 0 && (
          <ReactPaginate
            previousLabel={t('previous')}
            nextLabel={t('next')}
            breakLabel="..."
            breakClassName="break-me"
            pageCount={totalPages}
            pageNum={4}
            forcePage={forcePage}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={onChangePage}
            containerClassName="pagination"
            subContainerClassName="pages pagination"
            activeClassName="active"
          />
        )}
      </div>
    );
  }
}

Pagination.propTypes = {
  totalElements: PropTypes.number,
  onChangePage: PropTypes.func,
  pageSize: PropTypes.number,
  forcePage: PropTypes.number,
};

Pagination.defaultProps = {
  totalElements: 0,
  forcePage: 0,
  pageSize: 10,
  onChangePage: () => {
  },
};

export default withNamespaces()(Pagination);
