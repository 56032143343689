import types            from './types';
import { initialState } from '../../../App';

const conciliation = (state = {}, action) => {
  switch (action.type) {
    case types.CLEAR: {
      return {
        ...state, ...initialState.conciliation,
      };
    }
    case types.START_FETCH: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.END_FETCH: {
      const { error, payload } = action;
      return {
        ...state,
        loading: false,
        error: error ? payload : null,
      };
    }
    case types.UPDATE_LIST: {
      const { data: { agencies, conciliates, paginate:{totalCount} } } = action.payload;
      return {
        ...state,
        agencies,
        conciliateList: {
          list: conciliates,
          totalElements: totalCount,
        },
      };
    }
    case types.UPDATE_RECONCILED: {
      const { data: { data: { transactions, count_transactions } } } = action.payload;
      return {
        ...state,
        listReconciled:
          {
            list: transactions,
            totalElements: count_transactions,
          },
      };
    }
    case types.UPDATE_REJECT_CONCILIATE: {
      const { data: { data: { transactions, count_transactions } } } = action.payload;
      return {
        ...state,
        listReconciled:
          {
            list: transactions,
            totalElements: count_transactions,
          },
      };
    }
    case types.UPDATE_DETAIL_TO_CONCILIATE: {
      const { data: { bank_data, lots, registers } } = action.payload;
      return {
        ...state,
        conciliationDetail: {
          list_bank: bank_data,
          list_registers: registers,
          list_lots: lots,
        },
      }
    }
    case types.UPDATE_TYPE_TRANSACTION_CONCILIATE:
    {
      const { payload: { data } } = action;
      return {
        ...state,
        transactionTypeConciliate: data,
      };
    }
    default:
      return state;
  }
};

export default conciliation;
