import React, {Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {withNamespaces} from 'react-i18next';
import {toast} from 'react-toastify';
import './styles.scss';
import {
  AmountFormat,
  ContentHeader,
  ExportExcel,
  Filter,
  FormElement, Loading,
  TableList,
  TransactionRow,
} from '../../components';
import ReactTooltip from "react-tooltip";
import {authActions} from '../../state/ducks/auth';
import {initTimer, stopTimer} from "../../util/initTimer";
import transactions, {transactionsActions} from "../../state/ducks/transactions";
import {SIZE_PER_PAGE, TOAST_CONFIG} from '../../config/constants';
import {formatDate} from '../../util';
import {Link} from "react-router-dom";
import {usersActions} from "../../state/ducks/users";

class TransactiosnReport extends Component{
  constructor(props) {
    super(props);
    const date = new Date();
    const limit_month = ['31', '28', '31', '30', '31', '30', '31', '31', '30', '31', '30', '31'];
    this.state = {
      dateStart: new Date(date.getFullYear(), date.getMonth(), '01'),
      dateEnd: new Date(date.getFullYear(), date.getMonth(), limit_month[date.getMonth()]),
      transaction_type: '',
      transaction_code: '',
      document: '',
      user_type: '',
      source_email: '',
      destinatary_email: '',
      page: 0,
      loading: false,
    };

  }

  componentDidMount() {
    stopTimer();
    initTimer(this.props);
    const { fetchTransactionTypes, fetchTypePerson } = this.props;
    fetchTransactionTypes();
    fetchTypePerson();
    this.callApiList();
  }

  onChangePage = (selectPage) => {
    stopTimer();
    initTimer(this.props);
    this.setState(() => ({
        page: selectPage ? selectPage.selected : 0,
      }),
      () => {this.callApiList();}
    );
  };

  onChangeDate = (date, name) => {
    stopTimer();
    initTimer(this.props);
    const dateValue = date !== null ? date : '';
    this.setState({
      [name]: dateValue,
    });
  };

  callApiList = (getExcel = false) => {

    const {t, showMessage, fetchTransactionsSusa, transactions: { list_susa: {list, count} }, } = this.props;
    const { dateStart, dateEnd, transaction_type, transaction_code, document, user_type, source_email, destinatary_email, page} = this.state;

   fetchTransactionsSusa({
     data : {
       from: formatDate(dateStart),
       to: formatDate(dateEnd),
       transaction_type,
       transaction_code,
       document,
       user_type,
       source_email,
       destinatary_email,
       page: getExcel ? 0 : page,
       pageSize: !getExcel ? SIZE_PER_PAGE : count,
       export: getExcel,
     },
     callback: (responseData) => {
       const { success, data } = responseData;
       if (!success) {
         toast.dismiss();
         showMessage({
           message: t('connectionError'),
           config: TOAST_CONFIG.ERROR,
         });
       }
     },
   });

  }
  handleInputChange = (event) => {
    stopTimer();
    initTimer(this.props);
    const { target: { value: targetValue, name } } = event;
    this.setState({
      [name]: targetValue,
    });
  };

  onSubmit = (event) => {
    event.preventDefault();
    this.onChangePage();
  };


  render() {
    const {t, users: { optionsTypePerson }, transactions: { list_susa: {list, count}, loading, transaction_types }, auth: { account: { product: currency } }} = this.props
    const { dateStart, dateEnd, page, transaction_type, transaction_code, document, source_email, destinatary_email } = this.state;
    return(
      <div>
        {loading && <Loading />}
        <Fragment>
          <div className="wow animated slideInDown">
            <Filter
              title={t('transactions')}
              subtitle={t('filters')}
              onSubmit={(ev) => {
                this.onSubmit(ev);
              }}
            >
              <FormElement
                onChange={ev => this.onChangeDate(ev, 'dateStart')}
                label={t('dateStart')}
                value={dateStart}
                typeElement="datepicker"
                maxDate={dateEnd || new Date()}
              />
              <FormElement
                onChange={ev => this.onChangeDate(ev, 'dateEnd')}
                value={dateEnd}
                label={t('dateEnd')}
                typeElement="datepicker"
                maxDate={new Date()}
                minDate={dateStart}
              />
              <FormElement
                  name="transaction_type"
                  onChange={this.handleInputChange}
                  label={t('transactionType')}
                  value={transaction_type}
                  optionsSelect={transaction_types}
                  placeholder="Seleccione..."
                  typeElement="select"
                  controlClasses="select"
              />

              <FormElement
                  name="transaction_code"
                  onChange={this.handleInputChange}
                  value={transaction_code}
                  label={t('codeTransaction')}
              />
              <FormElement
                  name="document"
                  onChange={this.handleInputChange}
                  value={document}
                  label={t('dniCuit')}
              />
              <FormElement
                  name="user_type"
                  onChange={this.handleInputChange}
                  label={t('userType')}
                  optionsSelect={optionsTypePerson}
                  placeholder="Seleccione..."
                  typeElement="select"
                  controlClasses="select"
              />
              <FormElement
                  name="source_email"
                  onChange={this.handleInputChange}
                  value={source_email}
                  label={t('emailSender')}
              />
              <FormElement
                  name="destinatary_email"
                  onChange={this.handleInputChange}
                  value={destinatary_email}
                  label={t('emailDestination')}
              />


            </Filter>
          </div>
          <ContentHeader
            headingRightText={t('totalTransactions')}
            headingRightSubText={` ${count}`}
            headingText={t('showCurrentMonth')}
          >
            <ExportExcel
              onclick={() => {
                this.callApiList(true);
              }}
            />
            <TableList
              tableClasses="floating-table"
              totalElements={count}
              onChangePage={this.onChangePage}
              forcePage={page}
              head={(
                <tr>
                  <th>{t('dateTransaction')}</th>
                  <th>{t('userType')}</th>
                  <th>{t('dniCuit')}</th>
                  <th>{t('codeTransaction')}</th>
                  <th>{t('transactionType')}</th>
                  <th>{t('senderUser')}</th>
                  <th>{t('destinationUser')}</th>
                  <th>{t('amount')}<div className="text-small">{`(${currency})`}</div></th>
                  <th>{t('state')}</th>
                  <th>{t('showDetail')}</th>
                </tr>
              )}
              colNumber={10}
              lenghtData={count}
            >
              {(list !== undefined || list !== '') && (list.map(transactions => (

                <TransactionRow
                    id={transactions.id}
                >
                  <td>{transactions.date}</td>
                  <td>{transactions.source_type_user}</td>
                  <td>{transactions.source_document}</td>
                  <td>{transactions.transaction_code}</td>
                  <td>{transactions.transaction_type}</td>
                  <td>
                    <div className={"name-user"}>
                      {transactions.source}
                      {transactions.source_role === 'Conductor' ?
                        <span className="icon">
                              <i className="fas fa-bus-alt">
                                <ReactTooltip
                                  id="tooltip-edit"
                                  type="info"
                                  effect="solid"
                                >
                                  {t('edit')}
                                </ReactTooltip>
                              </i>
                            </span>
                        : null
                      }
                    </div>
                  </td>
                  <td>
                    <div className={"name-user"}>
                        {transactions.destinatary}
                        {transactions.destinatary_role === 'Conductor' ?
                          <span className="icon">
                              <i className="fas fa-bus-alt">
                                <ReactTooltip
                                  id="tooltip-edit"
                                  type="info"
                                  effect="solid"
                                >
                                  {t('edit')}
                                </ReactTooltip>
                              </i>
                            </span> : null }
                    </div>
                  </td>
                  <td>
                    <AmountFormat
                      name="total"
                      value={transactions.amount}
                      displayType="text"
                      allowNegative
                    />
                  </td>
                  <td>{transactions.status}</td>
                  <td><Link to={{
                    pathname: '/detail-transaction',
                    state: { transaction: transactions
                        }
                  }}
                  >
                    <button
                        className="button is-outlined is-small">{t('showDetail')}</button>
                  </Link></td>
                </TransactionRow>
              )))
              }

            </TableList>
          </ContentHeader>
        </Fragment>
      </div>
    );
  }

}

const mapStateToProps = ({auth, users, transactions}) => (
  {
    auth, users, transactions,
  }
);
export default compose(
  connect(mapStateToProps, {
    ...authActions,
    ...usersActions,
    ...transactionsActions,
  }),withNamespaces())(TransactiosnReport);
