import React                            from 'react';
import PropTypes                        from 'prop-types';
import { DefaultButton, PrimaryButton } from '../index';
import './styles.scss';

const Form = ({
                className, onSubmit, children, submitText, buttonClasses, disabled, returnText, handleActionButton
              }) => (
  <div className={`Form  ${className}`}>
    <form
      action=""
      onSubmit={onSubmit}
    >
      {children}
      {(returnText || submitText) && (
        <div className="field is-grouped is-grouped-centered">
          {returnText && (
            <DefaultButton name={returnText} disabled={disabled} type="button" onClick={handleActionButton} />
          )}
          {submitText && (
            <PrimaryButton name={submitText} disabled={disabled} type="submit" />
          )}
        </div>
      )}
    </form>
  </div>
);

Form.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.array,
  ]).isRequired,
  className: PropTypes.string,
  returnText: PropTypes.string,
  handleActionButton: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  submitText: PropTypes.string.isRequired,
  buttonClasses: PropTypes.string,
  disabled: PropTypes.bool,
};

Form.defaultProps = {
  className: '',
  buttonClasses: '',
  disabled: false,
  returnText: '',
  handleActionButton: null,
};

export default Form;
