import React, { Component, Fragment } from 'react';
import PropTypes                      from 'prop-types';
import { connect }                    from 'react-redux';
import { toast }                      from 'react-toastify';
import { withNamespaces }             from 'react-i18next';
import { compose }                    from 'redux';
import './styles.scss';
import {
  ContentHeader,
  DefaultButton,
  Filter,
  Form,
  SelectSearchable,
  TextInput
} from "../../../../components";
import {formatDate, formatDateEn} from "../../../../util";
import {TOAST_CONFIG} from "../../../../config/constants";
import {ratesActions} from "../../../../state/ducks/rates";
import {initTimer, stopTimer} from "../../../../util/initTimer";
import AmountFormat from "../../../../components/AmountFormat";

class FormRate extends Component {

  constructor(props) {
    super(props);
    const { id, dateConfig, description, destinyType, amount, currency } = this.props;
    this.state = {
      id: id || '',
      dateConfig: dateConfig ? formatDateEn(dateConfig) : formatDate(new Date()),
      description: description || '',
      typeD: destinyType || '',
      destinyType: '',
      amount: amount || '',
      destinationType: [{ value: '', label: 'Select' }],
    };

  }

  componentDidMount() {
    stopTimer();
    initTimer(this.props);
    this.getDestinyTypes();
  }

  getDestinyTypes = () => {
    const {t, showMessage, fetchDestinationTypes, } = this.props;

    fetchDestinationTypes({
      callback: (data) => {
        if (!data.success) {
          toast.dismiss();
          showMessage({
            message: t('connectionError'),
            config: TOAST_CONFIG.ERROR,
          });
          return false;
        }
        else {
          this.setDestinationTypes(data.data)
        }
      },
    });
  }

  setDestinationTypes = (data) => {
    const types = [];
    const {typeD} = this.state;

    Object.keys(data).forEach((e) => {
      const dT = { label: data[e], value: e }
      types.push(dT);

    });

    this.setState({
      destinationType: types,
    });

    if(typeD !== ''){
      const typeValue = types.find(item => item.value === typeD);
      this.setState({
        destinyType: typeValue,
      });
    }

  }

  handleInputChange = (event) => {
    stopTimer();
    initTimer(this.props);
    const { target: { value: targetValue, name } } = event;
    this.setState({
      [name]: targetValue,
    });
  };

  onChangeDate = (date) => {
    this.setState({
      dateConfig: date,
    });
  };

  handleSelectChange = (event, name) => {

      this.setState({
        [name]: event,
      });

  };

  handleFieldEmpty = () => {
    const { description, destinyType, amount } = this.state;
    return (description === '' || destinyType === '' || amount === '');
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const {t, showMessage, newRate, updateRate, handleChangeView } = this.props;
    const {id, description, amount, destinyType} = this.state;

    const data =  {
      description,
        amount: amount.replace(',', '.'),
        id_destination: destinyType.value,
        active: true,
    };

    if( id === '') {
      newRate({
        data,
        callback: (response) => {
          const {success, data} = response;
          if (success) {
            handleChangeView();
            toast.dismiss();
            showMessage({
              message: t('rateSuccess'),
              config: TOAST_CONFIG.SUCCESS,
            });
          } else {
            toast.dismiss();
            showMessage({
              message: data.message ?? t('connectionError'),
              config: data.message ? TOAST_CONFIG.INFO : TOAST_CONFIG.ERROR,
            });
          }
        },
      });
    }
    else{
      data.id = id;
      updateRate({
        data,
        callback: (responseData) => {
          const {success, data} = responseData.data;
          if (data) {
            if(success) {
              handleChangeView();
            }
            toast.dismiss();
            showMessage({
              message: success ? t('rateEditSuccess') : data.message,
              config: success ? TOAST_CONFIG.SUCCESS : TOAST_CONFIG.INFO,
            });
          } else {
            toast.dismiss();
            showMessage({
              message: t('connectionError'),
              config: TOAST_CONFIG.ERROR,
            });
          }
        },
      });
    }

  };

  render() {
    const {t, handleChangeView, currency } = this.props;
    const {dateConfig, description, amount, destinyType, destinationType, id} = this.state
    const disabled = this.handleFieldEmpty();

    return(
      <div className="content-new-rate">
        <div className="wow animated slideInDown">
          <Filter title={t('rates')} />
        </div>
        <ContentHeader
          headingText={
            <DefaultButton name={t('goBack')} onClick={() => { handleChangeView(); }} />
          }
          classFirstColumn="is-paddingless"
        >
          <div className="Tabs-panel">
            <div className="p-16px content-xl-center">
            <Form
              submitText={t('save')}
              onSubmit={(ev) => {
                this.handleSubmit(ev);
              }}
              nameButton="save"
              disabled={disabled}
            >
              <div className="columns is-multiline">
                <div className="column is-full">
                  <h4>{id==='' ? t('newRate') : t('editRate')}</h4>
                  <hr className="dots" />
                </div>
                <SelectSearchable
                  label={t('destinyType')}
                  name="destinyType"
                  options={destinationType}
                  onChange={(event) => this.handleSelectChange(event, 'destinyType')}
                  value={destinyType || ''}
                  fieldClasses="column is-6 content-select"
                  labelClasses="label-select"
                  controlClasses="pt-8px"
                  inputClasses={'input-border-select'}
                />

                <div className="column is-6">
                  <div className="pt-8px">
                      <span className="pr-8px">
                        {t('description')}
                        :
                      </span>
                    <span>
                        <TextInput
                          name="description"
                          value={description}
                          type="text"
                          inputClasses="txt-input"
                          onChange={(e) => { this.handleInputChange(e); }}
                          maxLength="60"
                        />
                      </span>
                  </div>
                </div>
                <div className="column is-6">
                  <div className="pt-8px">
                        <span className="pr-8px">
                          {t('dateConfig')}
                          :
                        </span>
                    <span>
                      <TextInput
                        name="dateConfig"
                        inputClasses="txt-input"
                        type="date"
                        onChange={(e) => { this.handleInputChange(e); }}
                        value={dateConfig}
                        disabled={true}
                      />
                    </span>
                  </div>
                </div>

                <div className="column is-6">
                  <div className="pt-8px">
                      <span className="pr-8px">
                        {t('amount')}
                        :
                      </span>
                    <span>
                        <AmountFormat
                          name="amount"
                          label={ t('amount') }
                          value={ amount }
                          fieldClasses="txt-input"
                          onChange={(e) => { this.handleInputChange(e); }}
                        />

                      </span>
                  </div>
                </div>

              </div>
            </Form>
            </div>
          </div>
        </ContentHeader>
      </div>
    )
  }

}

FormRate.propTypes = {
  t: PropTypes.func.isRequired,
  showMessage: PropTypes.func,
  handleChangeView: PropTypes.func,
};

FormRate.defaultProps = {
  showMessage: () => {},
  handleChangeView: () => {},
  auth: {},
};

const
  mapStateToProps = ({ rates }) => (
    {
      rates,
    }
  );
export default compose(connect(mapStateToProps, {
    ...ratesActions,
  }),
  withNamespaces())(FormRate);