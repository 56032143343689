import React, { Component, Fragment } from 'react';
import { withNamespaces }             from 'react-i18next';
import PropTypes                      from 'prop-types';
import { compose }                    from 'redux';
import { connect }                    from 'react-redux';
import { toast }                      from 'react-toastify';
import { Link }                       from 'react-router-dom';
import {
  AmountFormat,
  ContentHeader,
  Filter,
  FormElement,
  Loading,
  TableList,
  TransactionRow,
  ExportExcel
}                                     from '../../../components';
import {
  initTimer,
  stopTimer
}                                     from '../../../util/initTimer';
import { authActions }                from '../../../state/ducks/auth';
import { conciliationActions }        from '../../../state/ducks/conciliation';
import { TOAST_CONFIG, SIZE_PER_PAGE }               from '../../../config/constants';
import './styles.scss';
import { agencyActions }              from "../../../state/ducks/agency";
import { formatDate }                 from "../../../util";

class conciliationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 0,
      state: '',
      agency: '',
      dateStart: '',//new Date(),
      dateEnd: '',//new Date(((new Date()).setDate((new Date()).getDate() + 1))),
      first: true,
      type_render: 0,
    };
  }

  componentDidMount() {

    stopTimer();
    initTimer(this.props);

    const {
      fetchStatusConciliate, fetchAgencyList, fetchTypeTransactionConciliate, location
    } = this.props;

    fetchTypeTransactionConciliate();
    fetchStatusConciliate();
    fetchAgencyList();

    if (location.state !== undefined && location.state.state !== undefined) {
      this.setState({
        state: location.state.state,
        agency: location.state.agency,
        dateStart: location.state.dateStart,
        dateEnd: location.state.dateEnd,
        first: location.state.first,
      }, () => {
        this.callApiList()
      });
    } else {
      this.callApiList();
    }

  }

  onChangePage = (selectPage) => {
    stopTimer();
    initTimer(this.props);
    this.setState(() => ({
      page: selectPage ? selectPage.selected : 0,
    }), () => {
      this.callApiList();
    });
  };

  callApiList = (getExcel = false) => {
    const {
      t, fetchList, showMessage, agency: { statusConciliate },
      conciliation: { conciliateList: { totalElements } }
    } = this.props;
    const {
      page,
      state,
      agency,
      dateStart,
      dateEnd,
      first,
      type_render,
    } = this.state;

    var stateFilter = state;

    if (state === '' && first === true) {
      stateFilter = statusConciliate.length > 0 ? statusConciliate[0].id : 1;

      this.setState({
        first: false,
        state: stateFilter,
      });
    }

    fetchList({
      data: {
        pageSize: !getExcel ? SIZE_PER_PAGE : totalElements,
        page: getExcel ? 0 : page,
        state_filter: stateFilter,
        id_agency: agency,
        conciliated: true,
        type_render: type_render,
        date_from: formatDate(dateStart),
        date_to: formatDate(dateEnd),
        export: getExcel,
      }, callback: (response) => {
        if (!response.success) {
          toast.dismiss();
          showMessage({
            message: t('connectionError'),
            config: TOAST_CONFIG.ERROR,
          });
        }
      },
    });
  };

  handleInputChange = (event) => {
    stopTimer();
    initTimer(this.props);
    const {
      target: {
        value: targetValue, name,
      },
    } = event;
    this.setState({
      [name]: targetValue,
    });
  };

  onChangeDate = (date, name) => {
    stopTimer();
    initTimer(this.props);
    const dateValue = date !== null ? date : '';
    this.setState({
      [name]: dateValue,
    });
  };

  onSubmit = (event) => {
    event.preventDefault();
    // this.onChangePage();
    this.callApiList();
  };

  render() {
    const {
      t,
      conciliation: { loading, transactionTypeConciliate, conciliateList: { list, totalElements } },
      auth: { currency },
      agency: { statusConciliate, agencyListSelect }
    } = this.props;
    const { page, state, agency, dateStart, dateEnd, first, type_render } = this.state;
    return (<div>
      {loading && <Loading />}
      <Fragment>
        <div className="wow animated slideInDown">
          <div className="wow animated slideInDown">
            <Filter
              title={t('conciliations')}
              subtitle={t('filters')}
              onSubmit={(ev) => {
                this.onSubmit(ev);
              }}
            >
              <FormElement
                name='dateStart'
                onChange={ev => this.onChangeDate(ev, 'dateStart')}
                label={t('dateStart')}
                value={dateStart}
                typeElement="datepicker"
                maxDate={dateEnd || new Date()}
              />
              <FormElement
                name='dateEnd'
                onChange={ev => this.onChangeDate(ev, 'dateEnd')}
                value={dateEnd}
                label={t('dateEnd')}
                typeElement="datepicker"
                maxDate={new Date()}
                minDate={dateStart}
              />
              <FormElement
                name="state"
                onChange={this.handleInputChange}
                value={state}
                label={t('state')}
                optionsSelect={statusConciliate}
                placeholder="Seleccione..."
                typeElement="select"
                controlClasses="select"
              />
              <FormElement
                name="agency"
                onChange={this.handleInputChange}
                value={agency}
                label={t('agency')}
                optionsSelect={agencyListSelect}
                placeholder={t('selectAngency')}
                typeElement="select"
                controlClasses="select"
              />
              <FormElement
                name="type_render"
                onChange={this.handleInputChange}
                value={type_render}
                label={t('typeTransaction')}
                optionsSelect={transactionTypeConciliate}
                placeholder={t('selectTransaction')}
                typeElement="select"
                controlClasses="select"
              />
            </Filter>
          </div>
          <ContentHeader
            headingRightText={t('elementsTotal')}
            headingRightSubText={`${totalElements}`}
          >
            <ExportExcel onclick={() => { this.callApiList(true) }}/>
            <TableList
              tableClasses="floating-table"
              totalElements={parseFloat(totalElements)}
              onChangePage={this.onChangePage}
              forcePage={page}
              head={(<tr>
                <th>{t('date')}</th>
                <th>{t('agencyName')}</th>
                <th>{t('typeTransaction')}</th>
                <th>{t('renderNumber')}</th>
                <th>{`${t('amount')} (${currency})`}</th>
                <th>{`${t('commission')} (${currency})`}</th>
                <th>{`${t('conciliate')} (${currency})`}</th>
                <th>{`${t('total')} (${currency})`}</th>
                <th>{t('state')}</th>
                <th>{t('actions')}</th>
              </tr>)}
              colNumber={10}
              lenghtData={totalElements !== undefined ? totalElements : 0}
            >
              {list && (list.map(row => (<TransactionRow
                key={row.id_account}
                helper={t('detailReport')}
              >
                <td>{row.date}</td>
                <td>{row.agency_name}</td>
                <td>{row.type_render}</td>
                <td>{row.lot_number}</td>
                <td>
                  <AmountFormat
                    name="amount"
                    value={row.amount}
                    displayType="text"
                    allowNegative
                  />
                </td>
                <td>
                  <AmountFormat
                    name="commission"
                    value={row.commission}
                    displayType="text"
                    allowNegative
                  />
                </td>
                <td>
                  <AmountFormat
                    name="conciliated"
                    value={row.conciliated}
                    displayType="text"
                    allowNegative
                  />
                </td>
                <td>
                  <AmountFormat
                    name="total"
                    value={row.total}
                    displayType="text"
                    allowNegative
                  />
                </td>
                <td>{row.statusText}</td>
                <td>
                  <Link to={{
                    pathname: '/detail-conciliation',
                    state: {
                      id: row.id_render,
                      status: row.statusText,
                      lot: row.render_number,
                      //filter to go back
                      state,
                      agency,
                      dateStart,
                      dateEnd,
                      first
                    }
                  }}
                  >
                    <button
                      className="button is-outlined is-small">{t('showDetail')}</button>
                  </Link>
                </td>
              </TransactionRow>)))}
            </TableList>
          </ContentHeader>
        </div>
      </Fragment>
    </div>);
  }
}

conciliationList.propTypes = {
  t: PropTypes.func.isRequired,
  showMessage: PropTypes.func,
  fetchList: PropTypes.func.isRequired,
  fetchTypePerson: PropTypes.func,
};

conciliationList.defaultProps = {
  showMessage: () => {
  },
  fetchTypePerson: () => {
  },
};

const mapStateToProps = ({ conciliation, auth, agency }) => ({
  conciliation,
  auth,
  agency,

});
export default compose(connect(mapStateToProps, {
  ...conciliationActions, ...authActions, ...agencyActions,
}), withNamespaces())(conciliationList);
