import React            from 'react';
import { confirmAlert } from 'react-confirm-alert';
import './styles.scss';

export const ConfirmAlert = (props, onAction, message) => {
  const { t } = props;
  const options = {
    customUI: ({ onClose }) => (
      <div className="box-alert columns is-multiline">
        <div className="column is-full has-text-centered">
          <p>{t(message)}</p>
        </div>
        <div className="column is-6 has-text-centered">
          <button className="button is-outlined" onClick={() => { onClose() }}>
            No
          </button>
        </div>
        <div className="column is-6 has-text-centered">
          <button className="button is-primary" onClick={() => {
            onAction();
            onClose();
          }}>
            {t('yes')}
          </button>
        </div>
      </div>
    ),
    closeOnEscape: false,
    closeOnClickOutside: false,
  };
  return (confirmAlert(options));
};

export const CloseConfirmAlert = () => (window.location.reload());
