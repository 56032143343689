import React, { Fragment, Component } from 'react';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { compose } from 'redux';
import { authActions } from '../../state/ducks/auth';
import { configurationsActions } from '../../state/ducks/configurations';
import { initTimer, stopTimer } from '../../util/initTimer';
import { TOAST_CONFIG } from '../../config/constants';
import UserEdit from '../userEdit';
import {
  Loading, Toggle, Filter, Panel,
} from '../../components';
import { List } from './components'

class SettingCharges extends Component {
  constructor (props) {
    super (props);
    this.state = {
      charge: 0,
      iva: 0,
    }
  }

  componentDidMount() {
    stopTimer();
    initTimer(this.props);
    this.callApiList();
  }

  callApiList = () => {
    const { t, showMessage, fetchChargesByServices, fetchComissionByServices } = this.props;
    fetchChargesByServices({
      callback: (response) => {
        if (!response.success) {
          toast.dismiss();
          showMessage({
            message: t('connectionError'),
            config: TOAST_CONFIG.ERROR,
          });
        }
      },
    });
    fetchComissionByServices({
      callback: (response) => {
        if (!response.success) {
          toast.dismiss();
          showMessage({
            message: t('connectionError'),
            config: TOAST_CONFIG.ERROR,
          });
        }
      },
    });
  }

  render () {
    const { t,
      auth: { loading },
      configurations: { charges, ivaCharge },
    } = this.props;

    return (
      <Fragment>
        { loading && <Loading /> }
        <Filter title={t('config')} />
        <Panel headingText={t('configChargeService')}>
          <div className='column is-full'>
            <h4>{t('transfers')}</h4>
          </div>
          <List list={charges.transfer} />
          <div className='column is-full'>
            <h4>{t('operationsQr')}</h4>
          </div>
          <List list={charges.qr_operations} />
          <div className='column is-full'>
            <h4>{t('servicePassenger')}</h4>
          </div>
          <List list={charges.passenger_services} />
          <div className='column is-full'>
            <h4>{t('susa')}</h4>
          </div>
          <List list={ivaCharge.susa} commission={t('commissionPercent')} />
          <div className='column is-full'>
            <h4>{t('transportCompany')}</h4>
          </div>
          <List list={ivaCharge.transport_company} commission={t('commissionPercent')} />
        </Panel>
      </Fragment>
    );
  }
}

SettingCharges.propTypes = {
  t: PropTypes.func.isRequired,
  showMessage: PropTypes.func,
  fetchChargesByServices: PropTypes.func.isRequired,
  fetchComissionByServices: PropTypes.func.isRequired,
};

SettingCharges.defaultProps = {
  showMessage: () => {},
  fetchChargesByServices: () => {},
  fetchComissionByServices: () => {},
};

const mapStateToProps = ({ auth, configurations }) => (
  { auth, configurations }
);

export default compose(connect(mapStateToProps, {
    ...configurationsActions,
    ...authActions,
  }),
  withNamespaces())(SettingCharges);
