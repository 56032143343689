import { transactionsBindActions, transactionsBindTypes } from '../ducks/transactionBind';
import { authActions, authTypes }                         from "../ducks/auth";
import * as fileSaver                                     from "file-saver";

const fetchTransactionsBind = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== transactionsBindTypes.FETCH_TRANSACTIONS_BIND) {
    return;
  }
  const { data, callback, data: { export: exportExcel } } = action.payload || {};
  dispatch({
    type: transactionsBindTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/api/find-result-transfers',
        data,
        responseType: exportExcel ? 'blob' : 'json',
      },
      authorization: true,
      onStart: transactionsBindActions.startFetch,
      onComplete: (responseData) => {
        if (exportExcel) {
          const blob = responseData.data;
          const file = new Blob([blob], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          fileSaver.saveAs(file, 'Transferencias Bind.xlsx');
        } else {
          const bindTransactions = responseData.data.data;
          dispatch(transactionsBindActions.updateTransactionsBind({ data: bindTransactions }));
          callback(responseData.data);
        }
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: transactionsBindActions.endFetch,
    },
  });
};

const fetchTransactionsExternalAccount = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== transactionsBindTypes.FETCH_TRANSACTIONS_EXTERNAL_ACCOUNT) {
    return;
  }
  const {
    data: dataSend, callback,
  } = action.payload || {};
  dispatch({
    type: transactionsBindTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/console/list-log-bind?date_start=${dataSend.dateStart}&date_end=${dataSend.dateEnd}&cause=${dataSend.cause}&cvu=${dataSend.cvu}&document_number=${dataSend.document_number}&username=${dataSend.username}&page=${dataSend.page}&pageSize=${dataSend.pageSize}`,
      },
      authorization: true,
      onStart: transactionsBindActions.startFetch,
      onComplete: (responseData) => {
        const { data } = responseData;
        if (data.success) {
          dispatch(transactionsBindActions.updateTransactionsExternalAccount(data));
        }
        callback(data);
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: transactionsBindActions.endFetch,
    },
  });
};

const fetchCauseBind = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== transactionsBindTypes.FETCH_CAUSE_BIND) {
    return;
  }
  const {
    callback,
  } = action.payload || {};
  dispatch({
    type: transactionsBindTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: '/console/causes',
      },
      authorization: true,
      onStart: transactionsBindActions.startFetch,
      onComplete: (responseData) => {
        const { data } = responseData;
        if (data.success) {
          dispatch(transactionsBindActions.updateCauseBind(data));
        }
      },
      onError: (response) => {
        console.log(response);
      },
      onEnd: transactionsBindActions.endFetch,
    },
  });
};

const fetchSwitchTransacctionsInbound = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== transactionsBindTypes.FETCH_SWITCH_TRANSACTIONS_INBOUND) {
    return;
  }
  const { data: dataSend, callback } = action.payload || {};
  dispatch({
    type: transactionsBindTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/console/list-transactions-in?page=${dataSend.page}`,
      },
      authorization: true,
      onStart: transactionsBindActions.startFetch,
      onComplete: (responseData) => {
        const { data: { data } } = responseData;
        dispatch(transactionsBindActions.updateSwitchTransacctionsInbound({ data }));
        callback(data);
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: transactionsBindActions.endFetch,
    },
  });
};

const fetchRejectedTransaction = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== transactionsBindTypes.FETCH_REJECTED_TRANSACTION) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload || {};
  dispatch({
    type: authTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/console/rejected-transaction-webhook',
        data,
      },
      authorization: true,
      onStart: authActions.startFetch,
      onComplete: (response) => {
        callback(response.data);
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: authActions.endFetch,
    },
  });
};

const fetchConfirmTransaction = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== transactionsBindTypes.FETCH_CONFIRM_TRANSACTION) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload || {};
  dispatch({
    type: authTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/console/review-transactions-webhook',
        data,
      },
      authorization: true,
      onStart: authActions.startFetch,
      onComplete: (response) => {
        callback(response.data);
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: authActions.endFetch,
    },
  });
};
const fetchTransactionsOutgoing = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== transactionsBindTypes.FETCH_TRANSACTIONS_OUTGOING) {
    return;
  }
  const { callback, data: dataSend } = action.payload || {};
  dispatch({
    type: transactionsBindTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/console/list-transactions-out?page=${dataSend.page}`,
      },
      authorization: true,
      onStart: transactionsBindActions.startFetch,
      onComplete: (responseData) => {
        const { data: { data } } = responseData;
        dispatch(transactionsBindActions.updateTransactionsOutgoing({ data }));
        callback(data);
      },
      onError: (response) => {
        console.log('error-in', response)
        //callback(response);
      },
      onEnd: transactionsBindActions.endFetch,
    },
  });
};

const fetchTransactionsRejected = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== transactionsBindTypes.FETCH_TRANSACTIONS_REJECTED) {
    return;
  }
  const { data: dataSend, callback } = action.payload || {};
  dispatch({
    type: transactionsBindTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/console/list-transactions-in?status=${dataSend.status}&page=${dataSend.page}`,
      },
      authorization: true,
      onStart: transactionsBindActions.startFetch,
      onComplete: (responseData) => {
        const { data: { data } } = responseData;
        dispatch(transactionsBindActions.updateTransactionsRejected({ data }));
        callback(data);
      },
      onError: (response) => {
        console.log('error-in', response)
        //callback(response);
      },
      onEnd: transactionsBindActions.endFetch,
    },
  });
};

const fetchTransactionsApproved = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== transactionsBindTypes.FETCH_TRANSACTIONS_APPROVED) {
    return;
  }
  const { data: dataSend, callback } = action.payload || {};
  dispatch({
    type: transactionsBindTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/console/list-transactions-in?status=${dataSend.status}&page=${dataSend.page}`,
      },
      authorization: true,
      onStart: transactionsBindActions.startFetch,
      onComplete: (responseData) => {
        const { data: { data } } = responseData;
        dispatch(transactionsBindActions.updateTransactionsApproved({ data }));
        callback(data);
      },
      onError: (response) => {
        console.log('error-in', response)
        //callback(response);
      },
      onEnd: transactionsBindActions.endFetch,
    },
  });
};

const fetchRejectedTransactionOut = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== transactionsBindTypes.FETCH_REJECTED_TRANSACTION_OUT) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload || {};
  dispatch({
    type: authTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/console/reject-transaction-out',
        data,
      },
      authorization: true,
      onStart: authActions.startFetch,
      onComplete: (response) => {
        callback(response.data);
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: authActions.endFetch,
    },
  });
};

const fetchApprovedTransactionOut = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== transactionsBindTypes.FETCH_APPROVED_TRANSACTION_OUT) {
    return;
  }

  const {
    data,
    callback = () => {
    },
  } = action.payload || {};
  dispatch({
    type: authTypes.API_CALL,
    payload: {
      config: {
        method: 'POST',
        url: '/console/approved-transaction-out',
        data,
      },
      authorization: true,
      onStart: authActions.startFetch,
      onComplete: (response) => {
        callback(response.data);
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: authActions.endFetch,
    },
  });
};

const fetchTransactionsOutRejected = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== transactionsBindTypes.FETCH_TRANSACTIONS_OUT_REJECTED) {
    return;
  }
  const { data: dataSend, callback } = action.payload || {};
  dispatch({
    type: transactionsBindTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/console/list-transactions-out?status=${dataSend.status}&page=${dataSend.page}`,
      },
      authorization: true,
      onStart: transactionsBindActions.startFetch,
      onComplete: (responseData) => {
        const { data: { data } } = responseData;
        dispatch(transactionsBindActions.updateTransactionsOutRejected({ data }));
        callback(data);
      },
      onError: (response) => {
        console.log('error-in', response)
        //callback(response);
      },
      onEnd: transactionsBindActions.endFetch,
    },
  });
};

const fetchTransactionsOutApproved = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== transactionsBindTypes.FETCH_TRANSACTIONS_OUT_APPROVED) {
    return;
  }
  const { data: dataSend, callback } = action.payload || {};
  dispatch({
    type: transactionsBindTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: `/console/list-transactions-out?status=${dataSend.status}&page=${dataSend.page}`,
      },
      authorization: true,
      onStart: transactionsBindActions.startFetch,
      onComplete: (responseData) => {
        const { data: { data } } = responseData;
        dispatch(transactionsBindActions.updateTransactionsOutApproved({ data }));
        callback(data);
      },
      onError: (response) => {
        console.log('error-in', response)
        //callback(response);
      },
      onEnd: transactionsBindActions.endFetch,
    },
  });
};

const fetchTypeTransfersBind = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== transactionsBindTypes.FETCH_TYPE_TRANSFERS_BIND) {
    return;
  }
  const { callback = () => { } } = action.payload || {};
  dispatch({
    type: transactionsBindTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: '/console/transaction-type-bind'
      },
      authorization: true,
      onStart: transactionsBindActions.startFetch,
      onComplete: ({ data: { data } }) => {
        dispatch(transactionsBindActions.updateTypeTransfersBind({ data }));
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: transactionsBindActions.endFetch,
    },
  });
}

const fetchStatusTransferBind = ({ dispatch }) => next => (action) => {
  next(action);
  if (action.type !== transactionsBindTypes.FETCH_STATUS_TRANSFERS_BIND) {
    return;
  }
  const { callback = () => { } } = action.payload || {};
  dispatch({
    type: transactionsBindTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: '/console/transaction-status-bind'
      },
      authorization: true,
      onStart: transactionsBindActions.startFetch,
      onComplete: ({ data: { data } }) => {
        dispatch(transactionsBindActions.updateStatusTransferBind({ data }));
      },
      onError: (response) => {
        callback(response);
      },
      onEnd: transactionsBindActions.endFetch,
    },
  });
}

export default [
  fetchTransactionsBind,
  fetchTransactionsExternalAccount,
  fetchCauseBind,
  fetchSwitchTransacctionsInbound,
  fetchRejectedTransaction,
  fetchConfirmTransaction,
  fetchTransactionsOutgoing,
  fetchTransactionsRejected,
  fetchTransactionsOutRejected,
  fetchTransactionsOutApproved,
  fetchApprovedTransactionOut,
  fetchRejectedTransactionOut,
  fetchTypeTransfersBind,
  fetchStatusTransferBind,
];
