import React, { Component, Fragment } from 'react';
import PropTypes                      from 'prop-types';
import { connect }                    from 'react-redux';
import { toast }                      from 'react-toastify';
import { withNamespaces }             from 'react-i18next';
import { compose }                    from 'redux';
import './styles.scss';
import {
  ContentHeader,
  DefaultButton,
  Filter,
  Form,
  TextInput
} from "../../../../components";
import {TOAST_CONFIG} from "../../../../config/constants";
import {initTimer, stopTimer} from "../../../../util/initTimer";
import {usersActions} from "../../../../state/ducks/users";

class FormUserOperator extends Component{
  constructor(props) {
    super(props);
    const { id,  name, lastname, email, document_type, document_number, document_number_aux, document_type_aux, } = this.props;
    this.state = {
      id: id || '',
      email: email || '',
      name: name || '',
      lastname: lastname || '',
      document_number: document_number || '',
      document_number_aux: document_number_aux || '',
      document_type: document_type || 'DNI',
      document_type_aux: document_type_aux || 'CUIL/CUIT',
      password: '',
      repeatpassword: '',
      emailAvailable: false,
    };

  }

  componentDidMount() {
    stopTimer();
    initTimer(this.props);
  }

  handleInputChange = (event) => {
    stopTimer();
    initTimer(this.props);
    const { target: { value: targetValue, name } } = event;
    this.setState({
      [name]: targetValue,
    });
  };

  handleFieldEmpty = () => {
    const {  name, lastname, email, document_type, document_number, document_number_aux, document_type_aux, } = this.state;
    return (name === '' || lastname === '' || email === '' || document_number === '' || document_type === '' || document_number_aux === '' || document_type_aux === '');
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const {t, showMessage, newUserOperator, editDataUser, handleChangeView } = this.props;
    const { id,  name, lastname, email, document_type, document_number, document_number_aux, document_type_aux} = this.state;


    const data =   {
      name,
      lastname,
      document_type,
      document_number,
      document_type_aux,
      document_number_aux,
      email,
    };

  if(id === '') {

    newUserOperator({
      data,
      callback: (responseData) => {
        const {success, data} = responseData;
        if (data) {
          if(success)
            handleChangeView();

          toast.dismiss();
          showMessage({
            message: success ? t('operatorSuccess') : data.message,
            config: success ? TOAST_CONFIG.SUCCESS : TOAST_CONFIG.INFO,
          });
        } else {
          toast.dismiss();
          showMessage({
            message: t('connectionError'),
            config: TOAST_CONFIG.ERROR,
          });
        }
      },

    });
  }
  else{
    data.id_person = id;
    editDataUser({
      data,
      callback: (responseData) => {
        const {success, data} = responseData;
        if (success) {
          handleChangeView();
          toast.dismiss();
          showMessage({
            message: t('operatorSuccess'),
            config: TOAST_CONFIG.ERROR,
          });
        } else {
          toast.dismiss();
          showMessage({
            message: t('connectionError'),
            config: TOAST_CONFIG.ERROR,
          });
        }
      },
    });
  }

  }

  render() {
    const {t, handleChangeView } = this.props;
    const { id,  name, lastname, email, document_type, document_number, document_number_aux, document_type_aux } = this.state;
    const disabled = this.handleFieldEmpty();

    return(
      <div className="content-new-rate">
        <div className="wow animated slideInDown">
          <Filter title={t('userOperator')} />
        </div>
        <ContentHeader
          headingText={
            <DefaultButton name={t('goBack')} onClick={() => { handleChangeView(); }} />
          }
          classFirstColumn="is-paddingless"
        >
          <div className="Tabs-panel">
            <div className="p-16px content-xl-center">
              <Form
                submitText={t('save')}
                onSubmit={(ev) => {
                  this.handleSubmit(ev);
                }}
                nameButton="save"
                disabled={disabled}
              >
                <div className="columns is-multiline">
                  <div className="column is-full">
                    <h4>{t('newUserOperator')}</h4>
                    <hr className="dots" />
                  </div>
                  <div className="column is-6">
                    <div className="pt-8px">
                      <span className="pr-8px">
                        {t('nameCuit')}
                        :
                      </span>
                      <span>
                        <TextInput
                          name="document_number_aux"
                          value={document_number_aux}
                          type="number"
                          inputClasses="txt-input"
                          onChange={(event) => {
                            this.handleInputChange(event);
                            this.setState({
                              document_number: event.target.value.slice(2, -1).replace(/^0+/, ''),
                            });
                          }}
                          max="99999999999999999"
                          min="0"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="column is-6">
                    <div className="pt-8px">
                      <span className="pr-8px">
                        {t('nameDni')}
                        :
                      </span>
                      <span>
                        <TextInput
                          name="document_number"
                          value={document_number}
                          type="number"
                          inputClasses="txt-input"
                          onChange={(e) => { this.handleInputChange(e); }}
                          max="99999999999999999"
                          min="0"
                          disabled={true}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="column is-6">
                    <div className="pt-8px">
                      <span className="pr-8px">
                        {t('name')}
                        :
                      </span>
                      <span>
                        <TextInput
                          name="name"
                          value={name}
                          type="text"
                          inputClasses="txt-input"
                          onChange={(e) => { this.handleInputChange(e); }}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="column is-6">
                    <div className="pt-8px">
                      <span className="pr-8px">
                        {t('lastName')}
                        :
                      </span>
                      <span>
                        <TextInput
                          name="lastname"
                          value={lastname}
                          type="text"
                          inputClasses="txt-input"
                          onChange={(e) => { this.handleInputChange(e); }}
                        />
                      </span>
                    </div>
                  </div>
                  <div className="column is-6">
                    <div className="pt-8px">
                      <span className="pr-8px">
                        {t('email')}
                        :
                      </span>
                      <span>
                        <TextInput
                          name="email"
                          value={email}
                          type="text"
                          inputClasses="txt-input"
                          onChange={(e) => { this.handleInputChange(e); }}
                        />
                      </span>
                    </div>
                  </div>

                </div>
              </Form>
            </div>
          </div>
        </ContentHeader>
      </div>
    )}

}

FormUserOperator.propTypes = {
  t: PropTypes.func.isRequired,
  showMessage: PropTypes.func,
  handleChangeView: PropTypes.func,
};

FormUserOperator.defaultProps = {
  showMessage: () => {},
  handleChangeView: () => {},
  auth: {},
};

const
  mapStateToProps = ({ users }) => (
    {
      users,
    }
  );
export default compose(connect(mapStateToProps, {
    ...usersActions,
  }),
  withNamespaces())(FormUserOperator);