import { operatorActions, operatorTypes } from "../ducks/operator";

const fetchListSimpleRoles = ({ dispatch }) => next => (action) => {
  next(action);

  if (action.type !== operatorTypes.FETCH_STATUS_LOT) {
    return;
  }
  const { callback } = action.payload || {};
  dispatch({
    type: operatorTypes.API_CALL,
    payload: {
      config: {
        method: 'GET',
        url: '/admin/list-simple-roles',
      },
      authorization: true,
      onStart: operatorActions.startFetch,
      onComplete: ({ data }) => {
        dispatch(operatorActions.setStatusLot(data));
      },
      onError: (response) => {
        console.log(response);
      },
      onEnd: operatorActions.endFetch,
    },
  });
};

export default [
  fetchListSimpleRoles,
];