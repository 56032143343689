import {createAction} from 'redux-actions';
import types from './types';

const startFetch = createAction(types.START_FETCH);
const endFetch = createAction(types.END_FETCH);
const fetchListHolidays = createAction(types.FETCH_LIST_HOLIDAYS);
const updateListHolidays = createAction(types.UPDATE_LIST_HOLIDAYS);
const fetchEditHoliday = createAction(types.FETCH_EDIT_HOLIDAY);
const updateDetailHoliday = createAction(types.UPDATE_DETAIL_HOLIDAY);
const deleteHoliday = createAction(types.DELETE_HOLIDAY);
const createHoliday = createAction(types.CREATE_HOLIDAY);
const fetchChargesByServices = createAction(types.FETCH_CHARGES_BY_SERVICES);
const updateChargesByServices = createAction(types.UPDATE_CHARGES_BY_SERVICES);
const editChargesByServices = createAction(types.EDIT_CHARGES_BY_SERVICES);
const setEditChargesByServices = createAction(types.SET_EDIT_CHARGES_BY_SERVICES);
const fetchComissionByServices = createAction(types.FETCH_COMISSION_BY_SERVICES);
const updateComissionByServices = createAction(types.UPDATE_COMISSION_BY_SERVICES);
const editComissionByServices = createAction(types.EDIT_COMISSION_BY_SERVICES);
const setEditComissionByServices = createAction(types.SET_EDIT_COMISSION_BY_SERVICES);

const showMessage = createAction(types.MESSAGE,
  ({message}) => ({
    message,
  }),
  ({config}) => ({
    config,
  }));

export default {
  startFetch,
  endFetch,
  showMessage,
  fetchListHolidays,
  updateListHolidays,
  fetchEditHoliday,
  updateDetailHoliday,
  deleteHoliday,
  createHoliday,
  fetchChargesByServices,
  updateChargesByServices,
  editChargesByServices,
  setEditChargesByServices,
  fetchComissionByServices,
  updateComissionByServices,
  editComissionByServices,
  setEditComissionByServices,
};
