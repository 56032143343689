import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import {withNamespaces} from 'react-i18next';
import './styles.scss';


const SubMenu = (props) => {
  const {
    t, items, open, pathname, onClick,
  } = props;
  const classOpen = open ? 'open' : '';
  return (
    <ul className={`subMenu subMenuItem ${classOpen}`}>
      {items.map((value) => {
        const active = pathname === value.url ? 'active' : '';
        return (
          ( !value.disabled  ?
          <li
            key={value.id}
            className={`${active}`}
            onClick={(ev) => {
              onClick(pathname, value.url);
            }}
          >
            <Link to={value.url}>
              <div className="iconList">
                <i className={value.icon} aria-hidden="true"/>
              </div>
              <span>
                {t(value.name)}
              </span>
            </Link>
          </li>
              : null )
        );
      })}
    </ul>
  );
};

SubMenu.propTypes = {
  t: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      url: PropTypes.string,
      name: PropTypes.string,
      icon: PropTypes.string,
    }),
  ).isRequired,
  open: PropTypes.bool.isRequired,
  pathname: PropTypes.string.isRequired,
};

export default withNamespaces()(SubMenu);
