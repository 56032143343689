import React, { Component, Fragment }                            from 'react';
import PropTypes                                                 from 'prop-types';
import { compose }                                               from 'redux';
import { connect }                                               from 'react-redux';
import { withNamespaces }                                        from 'react-i18next';
import { initTimer, stopTimer }                                  from '../../util/initTimer';
import { Form, Loading, Panel }                                  from '../../components';
import { AddressDetailForm, OperatorDetailForm, UserDetailForm } from '../userDetail';
import { usersActions }                                          from '../../state/ducks/users';
import { authActions }                                           from '../../state/ducks/auth';
import { agencyActions }                                         from '../../state/ducks/agency';
import { TOAST_CONFIG }                                          from "../../config/constants";

class UserOperatorForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      view: 'first',
      country: 'AR',
      dni: '',
      cuit: '',
      name: '',
      lastname: '',
      phoneNumber: '',
      province: '',
      location: '',
      city: '',
      address: '',
      zipCode: '',
      idAgency: '',
      rol: '',
      email: '',
      finished: false,
    };
  }

  componentDidMount() {
    const {
      fetchCompanyTypes,
      getProvinces,
      fetchAgencyList,
      fetchListRoles,
    } = this.props;
    stopTimer();
    initTimer(this.props);
    fetchCompanyTypes();
    getProvinces();
    fetchAgencyList();
    fetchListRoles();
  }

  callApiList = () => {
    const { fetchCreateOperator } = this.props;
    const {
      dni,
      cuit,
      name,
      lastname,
      phoneNumber,
      city: { value: id_city },
      address,
      zipCode,
      idAgency,
      rol,
      email,
    } = this.state;

    fetchCreateOperator({
      data: {
        name,
        lastname,
        document_number: dni,
        document_number_aux: cuit,
        phone_number: phoneNumber,
        email,
        address,
        id_city: id_city,
        zip_code: zipCode,
        id_agency: idAgency,
        rol

      },
      callback: ({data, success}) => {
        if (success) {
          this.setState({
            finished: true
          });
        }
        else{
          this.setState({
            view: 'first',
            finished: false
          });
        }
      }
    });
  }

  handleInputChange = ({ target: { value: targetValue, name } }) => {
    stopTimer();
    initTimer(this.props);
    this.setState({
      [name]: targetValue,
    });
  };

  handlePhoneChange = (value, name) => {
    stopTimer();
    initTimer(this.props);
    this.setState({
      [name]: value,
    });
  };

  handleVerifyCuit = (ev) => {
    this.setState({
      dni: ev.target.value.substr(2, 8)
        .replace(/^0+/, ''),
    });
  };

  onSubmit = (event) => {
    stopTimer();
    initTimer(this.props);
    event.preventDefault();
    this.callApiList()
  };

  handleValidate = () => {
    const { t, showMessage } = this.props;
    const {
      dni,
      cuit,
      name,
      lastname,
      phoneNumber,
      province,
      location,
      city,
      address,
      zipCode,
      idAgency,
      rol,
      email,
    } = this.state;


    if (dni === ''
      || cuit === ''
      || name === ''
      || lastname === ''
      || phoneNumber === ''
      || province === ''
      || location === ''
      || city === ''
      || address === ''
      || zipCode === ''
      || idAgency === ''
      || rol === ''
      || email === '') {
      showMessage({
        message: t('allRequiredFields'),
        config: TOAST_CONFIG.ERROR,
      });
      return false;
    }

    const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
    const result = pattern.test(email);
    if(result !==true){
      showMessage({
        message: t('emailInvalid'),
        config: TOAST_CONFIG.ERROR,
      });
      return false;
    }

    return true;
  }

  handleChangeView = (event, view = 'first') => {
    event.preventDefault();
    if (this.handleValidate()) {
      this.setState({
        view,
      });
    }
  }

  render() {
    const {
      view,
      dni,
      cuit,
      name,
      lastname,
      phoneNumber,
      country,
      province,
      location,
      city,
      address,
      zipCode,
      idAgency,
      rol,
      email,
      finished
    } = this.state;
    const person = {
      dni,
      cuit,
      name,
      lastname,
      phoneNumber,
      email,
    };
    const operator = {
      idAgency,
      rol,
    };
    const fullAddress = {
      country,
      province,
      location,
      city,
      address,
      zipCode,
    };
    const {
      t, users: { loading },
      auth: { loading: loadingAuth, },

    } = this.props;

    let { children, nextView, prevView, submitText } = ''

    if (view === 'first') {
      submitText = t('next');
      nextView = 'second';
      children = (
        <Fragment>
          <div className="mb-16px has-text-grey-light has-text-right">{t('allRequiredFields')}</div>
          <UserDetailForm
            handleInputChange={this.handleInputChange}
            handlePhoneChange={this.handlePhoneChange}
            handleVerifyCuit={this.handleVerifyCuit}
            title={t('operatorData')}
            person={person}
            editable
          />
          <AddressDetailForm
            handleInputChange={this.handleInputChange}
            handlePhoneChange={this.handlePhoneChange}
            address={fullAddress}
            editable
          />
          <OperatorDetailForm
            handleInputChange={this.handleInputChange}
            operator={operator}
            editable
          />
        </Fragment>
      );
    } else if (view === 'second') {
      prevView = t('first');
      submitText = t('save');
      children = (
        <Fragment>
          <UserDetailForm
            handleInputChange={this.handleInputChange}
            handlePhoneChange={this.handlePhoneChange}
            handleVerifyCuit={this.handleVerifyCuit}
            person={person}
            editable={false}
          />
          <AddressDetailForm
            handleInputChange={this.handleInputChange}
            handlePhoneChange={this.handlePhoneChange}
            address={fullAddress}
            editable={false}
          />
          <OperatorDetailForm
            handleInputChange={this.handleInputChange}
            operator={operator}
            editable={false}
          />
        </Fragment>
      );
    }
    return (
      <div>
        {(loading || loadingAuth) && <Loading />}
        <Fragment>
          <Panel titleContentHeader={t('operatorRegister')} headingText={t('newOperatorForm')}>
            <div className="pr-16px pl-16px pt-0 content-xl-center">
              {finished && <h3 className="text-success">{t('successfulRegister')}</h3>}
              <Form
                submitText={finished ? t('finish') : submitText}
                nameButton="configButton"
                onSubmit={(e) => {
                  if (view === 'first') {
                    this.handleChangeView(e, 'second')
                  } else if (view === 'second' && !finished) {
                    this.onSubmit(e);
                  }
                  else if (finished) {
                    window.location.reload();
                }
                }}
                returnText={(view === 'second' && !finished) ? t('return') : null}
                handleActionButton={(event) => {
                  if (view === 'second') {
                    this.handleChangeView(event);
                  }
                }}
              >
                {children}
              </Form>
            </div>
          </Panel>
        </Fragment>
      </div>
    );
  }
}


UserOperatorForm.propTypes = {
  t: PropTypes.func.isRequired,
  users: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({}),
  ]).isRequired,
  logout: PropTypes.func.isRequired,
};

const mapStateToProps = ({ users, auth, agency }) => (
  {
    users,
    auth,
    agency,
  }
);
export default compose(
  connect(mapStateToProps, {
    ...usersActions,
    ...authActions,
    ...agencyActions,
  }),
  withNamespaces(),
)(UserOperatorForm);
