/*
 Constante para definir el entorno en el que se encuentra la app
 Posibles valores:
 - production
 - stage
 - qa
 - dev
 - local
 */

export const TIME = 30;

export const ENV = 'production';

// Constante para definir la version de la API
export const API_VERSION = 'v3';

// ENDPOINTS de la aplicacion en los diferentes entornos
// Nota: las claves de este objeto deben coincidir con el (o los) valores de la constante ENV
export const ENDPOINTS = {
  API: {
    production: 'https://api-sandbox-susa.nodobanka.com',
    stage: '',
    qa: '',
    dev: 'http://api-dev.medullaris.com:6001',
    local: 'http://192.168.11.43:80/business_camepagos/app/api/web',
  },
};
