import types            from './types';
import { initialState } from '../../../App';

const auth = (state = {}, action) => {
  switch (action.type) {
    case types.CLEAR: {
      return {
        ...state, ...initialState.auth,
      };
    }
    case types.LOGGED: {
      return {
        ...state,
        logged: true,
      };
    }
    case types.UPDATE_PROFILE: {
      const { payload } = action;
      return {
        ...state,
        profile: {
          ...state.profile,
          ...(
            payload !== null ? payload : {}
          ),
        },
      };
    }
    case types.UPDATE_USER: {
      const { payload } = action;
      return {
        ...state,
        user: {
          ...state.user,
          ...(
            payload !== null ? payload : {}
          ),
        },
      };
    }
    case types.UPDATE_ACCOUNT: {
      const { payload } = action;
      return {
        ...state,
        account: {
          ...state.account,
          ...(
            payload !== null ? payload : {}
          ),
        },
      };
    }

    case types.UPDATE_CONTACTS: {
      const { payload } = action;
      return {
        ...state,
        contacts: payload !== null ? payload : [],
      };
    }
    case types.CHANGE_LANGUAGE: {
      const { payload: { language } } = action;
      return {
        ...state,
        language,
      };
    }
    case types.UPDATE_TOKEN_EXP: {
      const { payload: { expToken } } = action;
      if (expToken !== undefined) {
        return {
          ...state,
          expToken,
        };
      } else {
        return {
          ...state,
        };
      }
    }
    case types.START_FETCH: {
      return {
        ...state,
        loading: true,
      };
    }
    case types.END_FETCH: {
      return {
        ...state,
        loading: false,
      };
    }
    case types.UPDATE_PROVINCES: {
      const { data } = action.payload;
      const item = [];
      data.map((row) => {
        const l = { label: row.name, value: row.id };
        item.push(l);
      });
      return {
        ...state,
        provinces: item || [],
      };
    }
    case types.UPDATE_LOCATIONS: {
      const { data } = action.payload;
      const items = [];
      data.map((row) => {
        const l = { label: row.name, value: row.id };
        items.push(l);
      });
      return {
        ...state,
        locations: items || [],
      };
    }
    case types.UPDATE_CITIES: {
      const { data } = action.payload;
      const items = [];
      data.map((row) => {
        const l = { label: row.name, value: row.id };
        items.push(l);
      });
      return {
        ...state,
        cities: items || [],
      };
    }
    case types.UPDATE_ENTITIES: {
      const { data } = action.payload;
      return {
        ...state,
        entities: data || [],
      };
    }
    case types.UPDATE_RUBROS: {
      const { data } = action.payload;
      return {
        ...state,
        rubros: data || [],
      };
    }
    default:
      return state;
  }
};

export default auth;
