import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {Link, Route} from 'react-router-dom';
import {
    Form,
    FormElement,
    Panel,
    AmountFormat,
    TransactionRow,
    Loading,
    Filter,
    ContentHeader, DefaultButton, TableList, Box, BalanceBox
} from '../../../components';
import { initTimer, stopTimer } from '../../../util/initTimer';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { transactionsActions } from '../../../state/ducks/transactions';
import { authActions } from '../../../state/ducks/auth';
import './styles.scss';

class DetailTransation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            transaction: '',
            dateStart: '',
            dateEnd: '',
            page: '',
            emailSender: '',
            emailDestination: '',
            transactionType: '',
            transactionCode: '',
            destinataryDocument: ''
        }
    }
    componentDidMount() {
        const { location } = this.props;
        stopTimer();
        initTimer(this.props);
        if (location !== undefined && location.state.transaction !== undefined) {
            this.setState({
              transaction: location.state.transaction,
              dateStart: location.state.dateStart,
              dateEnd: location.state.dateEnd,
              page: location.state.page,
              emailSender: location.state.page,
              emailDestination: location.state.emailDestination,
              transactionCode: location.state.transactionCode,
              transactionType: location.state.transactionType,
              destinataryDocument: location.state.destinataryDocument,
            });
        }
    }

    /*onSubmit = (event) => {
      this.onChangePage();
    };*/

    render () {
        const { transaction,  dateStart, dateEnd, page, emailSender, emailDestination, transactionCode, destinataryDocument, transactionType } = this.state;
        const {t} = this.props;
        return (
            <div>
                <Filter title={t('transactionsReport')+': '+ t('detailTransaction')} />
                <Link to={{
                  pathname: '/transactions-report',
                  state: {
                    dateStart,
                    dateEnd,
                    page,
                    transactionType,
                    emailSender,
                    emailDestination,
                    transactionCode,
                    destinataryDocument,
                  }
                }}
                >
                  <DefaultButton name={t('goBack')}  />
                </Link>

                <Panel headingText={t('detailOperationTransaction')}>

                    <div className="column is-full">

                        <div className="column is-full">
                            <h4>{t('dataTransaction')}</h4>
                            <hr className="dots" />
                        </div>
                        <div className="columns">
                          <div className="column title-left">
                            <span className="title-bold">{t('dateHour')}:</span> {transaction.date}
                          </div>
                          <div className="column title-right">
                            <span className="title-bold">{t('typeOperation')}:</span> {transaction.transaction_type}
                          </div>

                        </div>

                        <div className="columns">
                          <div className="column title-left">
                            <span className="title-bold">{t('codeofTransaction')}:</span> {transaction.transaction_code}
                          </div>
                          <div className="column title-right">
                            <span className="title-bold">{t('ammountTransaction')}:</span> {transaction.amount}
                          </div>
                        </div>
                      <div className="columns">
                        <div className="column title-left">
                          <span className="title-bold">{t('state')}:</span> {transaction.status}
                        </div>
                        {transaction.type_operation === null ?
                            <div className="column"></div>
                          :
                            <>
                            {transaction.transaction_type === 'Pagos con QR' || transaction.transaction_type === 'Devolución Pagos con QR' ?
                              <div className="column title-right">
                                <span className="title-bold">{t('statusPassenger')}:</span> {transaction.status_passenger}
                              </div>
                            :
                              <div className="column"></div>
                            }
                            </>
                        }
                      </div>
                      {transaction.type_operation === null ?
                          ''
                        :
                          <>
                          {transaction.transaction_type === 'Pagos con QR' || transaction.transaction_type === 'Devolución Pagos con QR' ?
                            <div>
                              <div className="columns">
                                <div className="column title-left">
                                  <span className="title-bold">{t('refCheckIn')}:</span> {transaction.number_operation_checkin}
                                </div>
                                <div className="column title-right">
                                  <span className="title-bold">{t('finalAmount')}:</span> {transaction.total}
                                </div>
                              </div>
                                <div className="columns">
                                    <div className="column title-left"><span className="title-bold">{t('idDriver')}:</span> {transaction.id_driver} </div>
                                    <div className="column title-right"></div>
                                </div>
                                <div className="columns">
                                    <div className="column title-left"> </div>
                                    <div className="column title-right"> </div>
                                </div>
                            </div>

                            :
                            ''
                          }
                          </>
                      }
                        <div className="column is-full">
                            <h4>{t('dataUserSender')}</h4>
                            <hr className="dots" />
                        </div>
                      <div className="columns">
                        <div className="column title-left"><span className="title-bold">{t('fullName')}:</span> {transaction.source_fullname}</div>
                        <div className="column title-right"><span className="title-bold">{t('document')}:</span> {transaction.source_document}</div>
                      </div>
                      <div className="columns">
                        <div className="column title-left"><span className="title-bold">{t('email')}:</span> {transaction.source}</div>
                        <div className="column title-right"><span className="title-bold">{t('userType')}:</span> {transaction.source_type_user}</div>
                      </div>


                        <div className="column is-full">
                            <h4>{t('dataUserDestination')}</h4>
                            <hr className="dots" />
                        </div>

                      <div className="columns">
                        <div className="column title-left"><span className="title-bold">{t('fullName')}:</span> {transaction.destinatary_fullname}</div>
                        <div className="column title-right"><span className="title-bold">{t('document')}:</span> {transaction.destinatary_document}</div>
                      </div>
                      <div className="columns">
                        <div className="column title-left"><span className="title-bold">{t('email')}:</span> {transaction.destinatary}</div>
                        <div className="column title-right"><span className="title-bold">{t('userType')}:</span> {transaction.destinatary_type_user}</div>
                      </div>
                      <div className="columns">
                        <div className="bar-bottom"></div>
                      </div>
                    </div>
                </Panel>
        </div>
        )
    }
}
DetailTransation.propTypes = {
    t: PropTypes.func.isRequired,
    list_susa: PropTypes.func,
};
const mapStateToProps = ({ transactions, }) => ({
        transactions,
});
export default compose(connect(mapStateToProps, {
        ...transactionsActions,
}), withNamespaces(),)(DetailTransation);
